import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select'; 

import ChevronDown from '../Icons/ChevronDown';
import CalendarIcon from '../Icons/CalendarIcon';

import "react-datepicker/dist/react-datepicker.css";
import './Input.scss';

export function InputRow({className, children}) {
    return (
        <div className={`admin-input-row ${className ? className : ''}`}>{children}</div>
    );
}

export function InputGroup({label, className, errors, children}) {
    return (
        <div className={`admin-input-group ${className ? className : ''} ${errors ? 'error' : ''}`}>
            { label ? <label className="label">{label}</label> : null }
            <div className="admin-input-group-inner">
                {children}
            </div>
            { errors ? 
                <div className="admin-input-error-message">
                    {errors.map((error,index) => <div key={index}>{error}</div>)}
                </div> 
            : null }
        </div>
    );
}

export default function Input({
    className, 
    label = false, 
    name, 
    type, 
    placeholder, 
    selectText, 
    value, 
    options = [], 
    productClass, 
    onChange, 
    errors, 
    size, 
    append = false, 
    readonly = false, 
    loading = false, 
    required = false, 
    checked, 
    multiple = false
}) {
    const [id, setId] = useState();

    useEffect( () => {
        if ( type === 'radio' ) {
            setId(`${name}-${value}`);
        } else {
            setId(name);
        }
    }, [type, name, value] );

    return (
        <div className={`admin-input ${className ? className : ''} ${type ? `${type}-input` : ''} ${errors ? 'error' : ''} ${loading ? 'loading' : ''} ${productClass ? productClass : ''}`}>
            { label ? <label className="label" htmlFor={id}>{label}</label> : null }
            <div className="admin-input-inner">
                <div className={`admin-input-inner-input ${size ? size : ''}`}>
                    { type === 'select' && multiple && value && options ?
                        <div className="select-multiple">
                            <Select options={options}
                                closeMenuOnSelect={false}
                                isDisabled={readonly}
                                isLoading={loading}
                                placeholder={placeholder}
                                classNamePrefix="select-multiple"
                                isMulti={true}
                                isSearchable={true}
                                name={name}
                                defaultValue={ value && options ? options.filter( option => value.includes(option.value) ) : null }
                                onChange={onChange} />
                            <ChevronDown className="icon" />
                        </div>
                    : null }

                    { type === 'select' && !multiple ?
                        <div className="select">
                            <select id={name}
                                className={!value ? 'invalid' : ''} 
                                name={name} 
                                value={value} 
                                onChange={onChange} 
                                required={required}>

                                { selectText ? <option value="">{selectText}</option> : null }

                                { options ? options.map((option,index) => {
                                    return (
                                        <option value={option?.value} key={option?.value} disabled={readonly}>{option?.name}</option>
                                    );
                                }) : null }
                            </select>
                            <ChevronDown className="icon" />
                        </div>
                    : null }

                    { type === 'datepicker' ? 
                        <label className="datepicker">
                            <DatePicker id={id} 
                                name={name} 
                                selected={value} 
                                minDate={new Date()} 
                                onChange={onChange} 
                                dateFormat="dd MMMM yy" 
                                clearIcon={null} 
                                calendarIcon={null} 
                                placeholderText={placeholder} 
                                autoComplete="off" 
                                required={required} 
                                readOnly={readonly} />

                                <CalendarIcon className="icon" />
                        </label> 
                    : null }

                    { type !== 'select' && type !== 'datepicker' && type !== 'radio' && type !== 'checkbox' ? 
                        <input id={id} 
                            name={name} 
                            type={type} 
                            value={value} 
                            onChange={onChange} 
                            readOnly={readonly} 
                            placeholder={placeholder} 
                            required={required} /> 
                    : null }

                    { type === 'radio' ? 
                        <label className="radio-wrap" htmlFor={id}>
                            <input id={id} 
                                name={name} 
                                type={type} 
                                value={value} 
                                onChange={onChange} 
                                readOnly={readonly} 
                                placeholder={placeholder} 
                                required={required} 
                                checked={checked} />

                            <div className="radio"></div>
                        </label> 
                    : null }

                    { type === 'checkbox' ? 
                        <label className="checkbox-wrap">
                            <input id={id} 
                                name={name} 
                                type={type} 
                                value={value} 
                                onChange={onChange} 
                                readOnly={readonly} 
                                placeholder={placeholder} 
                                required={required} 
                                checked={checked} />

                            <div className="checkbox">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.989" height="8.547">
                                    <path d="M1.06 4.998l2.829 2.8 5.05-6.747" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                                </svg>
                            </div>
                            { append }
                        </label> 
                    : null }

                    { errors ? 
                        <div className="admin-input-error-message">
                            {errors.map((error,index) => <div key={index}>{error}</div>)}
                        </div> 
                    : null }
                </div>
                { append && type !== 'checkbox' ? <label className="append" htmlFor={id}>{append}</label> : null }
            </div>
        </div>
    );
}
