import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../../Store';

import QuestionnaireHeader from '../QuestionnaireHeader/QuestionnaireHeader';
import QuestionnaireFooter from '../QuestionnaireFooter/QuestionnaireFooter';
import QuestionnaireSteps from './QuestionnaireSteps/QuestionnaireSteps';
import QuestionnaireReview from './QuestionnaireReview/QuestionnaireReview';
import QuestionnaireCompleted from './QuestionnaireCompleted/QuestionnaireCompleted';
import QuestionnaireContent from './QuestionnaireContent/QuestionnaireContent';
import QuestionnaireBtns from './QuestionnaireBtns/QuestionnaireBtns';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import handIllustration from '../../images/hand-illustration.svg';
import meerkatsIllustration from '../../images/meerkats-illustration.svg';

import './Questionnaire.scss';

export default function Questionnaire() {
	const { state } = useContext(Store);
	const { apiUrl, fullScreenHeight } = state;

	const { bearerToken } = useParams();

	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(false);
	const [sections, setSections] = useState([]);
	const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([]);
	const [activeSectionId, setActiveSectionId] = useState([]);
	const [showReview, setShowReview] = useState(false);
	const [showCompleted, setShowCompleted] = useState(false);
	const [leaderName, setLeaderName] = useState(false);
	const [isLeader, setIsLeader] = useState(false);
	const [surveyName, setSurveyName] = useState(false);
	const [respondentName, setRespondentName] = useState(false);
	const [productType, setProductType] = useState();
	const [teamName, setTeamName] = useState();

	const questionnaireContentRef = useRef();

	const scrollToTop = () => {
		const scrollTo =
			window.scrollY +
			questionnaireContentRef.current.getBoundingClientRect().top;

		if (window.scrollY > scrollTo) {
			window.scrollTo({
				top: scrollTo,
				left: 0,
			});
		}
	};

	const fetchSections = useCallback(() => {
		setLoading(true);
		setErrorMessage(false);

		axios.get(`${apiUrl}sections`, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		}).then((response) => {
			setSections(response.data?.sections);
			setActiveSectionId(response.data?.currentSectionId);
			setMultipleChoiceAnswers(response.data?.multipleChoiceAnswers);
			setLeaderName(response.data?.leaderName);
			setIsLeader(response.data?.isLeader);
			setSurveyName(response.data?.surveyName);
			setRespondentName(response.data?.respondentName);
			setProductType(response.data.productType);
			setTeamName(response.data.teamName);

			if (response.data.sections.findIndex((section) => !section.completed) === -1) {
				setShowReview(true);
			}

			setLoading(false);
		}).catch((err) => {
			if (err.response.status === 409) {
				setShowCompleted(err.response.data.errors[0]);
			} else {
				setErrorMessage(err.response.data.errors ?? err.message);
			}
			
			scrollToTop();
			setLoading(false);
		});
	}, [apiUrl, bearerToken]);

	const getActiveSection = () => {
		return sections.find((section) => section.id === activeSectionId);
	};

	const getActiveSectionAnswers = () => {
		let answers = [];

		getActiveSection().questions.forEach((question) => {
			answers.push({
				questionId: question.id,
				answer: question.answer ?? '',
			});
		});

		return answers;
	};

	const validateAnswers = () => {
		let sectionsData = sections;

		const sectionIndex = sectionsData.findIndex((section) => section.id === activeSectionId);

		sectionsData[sectionIndex].questions.map((question, index) => {
			if (question.required) {
				return (question.error = !sectionsData[sectionIndex].questions[index].answer || !sectionsData[sectionIndex].questions[index].answer.length ? true : false);
			}

			return true;
		});

		setSections(sectionsData);
	};

	const submitSectionAnswers = (sectionId, nextSectionId) => {
		setLoading(true);
		setErrorMessage(false);

		axios.post(`${apiUrl}answers`, {
			sectionId: sectionId,
			answers: getActiveSectionAnswers(),
		}, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		}).then((response) => {
			if (nextSectionId) {
				setActiveSectionId(nextSectionId);
			} else {
				setActiveSectionId(false);
				setShowReview(true);
			}

			let sectionsData = sections;
			const sectionIndex = sectionsData.findIndex((section) => section.id === sectionId);
			
			sectionsData[sectionIndex].completed = true;

			setSections(sectionsData);

			scrollToTop();
			setLoading(false);
		}).catch((err) => {
			setErrorMessage(err.response.data.errors ?? err.message);
			validateAnswers();
			scrollToTop();
			setLoading(false);
		}).finally((apiRes) => {
			console.log(apiRes); // Could be success or error
		});
	};

	const completeQuestionnaire = () => {
		setLoading(true);
		setErrorMessage(false);

		axios.post(`${apiUrl}Answers/Complete`, false, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		}).then(() => {
			setShowReview(false);
			setShowCompleted('Your responses have been received');
			scrollToTop();
			setLoading(false);
		}).catch((err) => {
			setErrorMessage(err.response.data.errors ?? err.message);
			scrollToTop();
			setLoading(false);
		});
	};

	const setAnswer = (questionId, questionAnswer) => {
		let sectionsData = sections;

		const sectionIndex = sectionsData.findIndex((section) => section.id === activeSectionId);
		const questionIndex = sectionsData[sectionIndex].questions.findIndex((question) => question.id === questionId);

		sectionsData[sectionIndex].questions[questionIndex].answer = questionAnswer;

		setSections(sectionsData);
	};

	useEffect(() => {
		fetchSections();
	}, [fetchSections]);

	const changeSection = (sectionId) => {
		setErrorMessage(false);
		setShowReview(false);
		setActiveSectionId(sectionId);
	};

	if (showCompleted) {
		return (
			<div className="page-wrap" style={{ minHeight: fullScreenHeight }}>
				<QuestionnaireHeader productType={productType} surveyName={surveyName} clientName={false} isLeader={isLeader} leaderName={leaderName} respondentName={respondentName} teamName={teamName} />

				<main className="quiestionnaire-main-content" ref={questionnaireContentRef}>
					<QuestionnaireCompleted showCompleted={showCompleted} productType={productType} />
				</main>

				<QuestionnaireFooter />
			</div>
		);
	}

	return (
		<div className="page-wrap" style={{ minHeight: fullScreenHeight }}>
			{loading ? <div className="loading-circle"></div> : null}

			<QuestionnaireHeader productType={productType} surveyName={surveyName} clientName={false} isLeader={isLeader} leaderName={leaderName} respondentName={respondentName} teamName={teamName} />

			<main className="quiestionnaire-main-content" ref={questionnaireContentRef}>
				<QuestionnaireSteps productType={productType} activeSectionId={activeSectionId} sections={sections} changeSection={changeSection} setShowReview={setShowReview} />

        		{errorMessage ? <div className="container-lg container-padding-lg"><ErrorMessage message={errorMessage} /></div> : null}

				{showReview ? <QuestionnaireReview sections={sections} completeQuestionnaire={completeQuestionnaire} /> : null}

				{!showReview ? <>
					<QuestionnaireContent productType={productType} loading={loading} leaderName={leaderName} isLeader={isLeader} surveyName={surveyName} sections={sections} activeSectionId={activeSectionId} getActiveSection={getActiveSection} multipleChoiceAnswers={multipleChoiceAnswers} setAnswer={setAnswer} submitSectionAnswers={submitSectionAnswers} />
					
					<QuestionnaireBtns getActiveSection={getActiveSection} setActiveSectionId={setActiveSectionId} submitSectionAnswers={submitSectionAnswers} />
				</> : null}

				{sections.find((section, index) => index === 0 && section.id === activeSectionId) ?
					<div className="questionnaire-illustration container-lg container-padding-lg">
						{ productType === 1 ? <img src={handIllustration} alt="" /> : null }
						{ productType === 2 ? <img src={meerkatsIllustration} alt="" /> : null }
					</div>
				: null}
      		</main>

			<QuestionnaireFooter />
		</div>
	);
}
