import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Store } from '../../../../../Store';
import { parseISO, format, isValid, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getClients, getProgrammes, getProgramme, getCohorts, deleteCohort, getClientReports } from '../../../../../api';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import AdminTable, { AdminTableHead, AdminTableBody, AdminTableActionsCell } from '../../../AdminTable/AdminTable';
import Input, { InputRow, InputGroup } from '../../../../Input/Input';
import AdminModal, { AdminModalBtns } from '../../../AdminModal/AdminModal';
import AdminStatusLabel from '../../../AdminStatusLabel/AdminStatusLabel';

import BinIcon from '../../../../Icons/BinIcon';
import PlusIcon from '../../../../Icons/PlusIcon';
import PencilIcon from '../../../../Icons/PencilIcon';

import './Programmes.scss';

function ProgrammeDetails({
    programme, 
    clientId, 
    programmeId, 
    clients, 
    cohorts, 
    setCohorts, 
    cohortsInfo, 
    setCohortsInfo, 
    urlPrepend
}) {
    const { state } = useContext(Store);
    const { surveyTypes } = state;

    return (
        <AdminCard className="programme-details">
            <AdminCardBody>
                <div className="programme-details-top">
                    <div>
                        { clients && programme?.name ? 
                            <div>
                                <h1>
                                    {clients.filter(client => client.id === clientId).length ? 
                                        `${clients.filter(client => client.id === clientId)[0]?.name}: ` 
                                    : ''}

                                    {programme.name}
                                </h1>
                            </div> 
                        : null }

                        <div>
                            <Link className="edit-btn" 
                                to={`${urlPrepend}/client/${clientId}/programme/${programme.id}/edit`}>

                                <PencilIcon />
                            </Link>
                        </div>
                    </div>

                    <div>
                        <div className="stats">
                            { cohortsInfo?.totalCohorts !== false ? 
                                <div>
                                    <strong>Total: {cohortsInfo.totalCohorts}</strong> 
                                    
                                    { cohortsInfo.totalCohorts === 1 ? ' cohort' : ' cohorts' }
                                </div> 
                            : null }

                            { cohortsInfo?.totalLeaders !== false ? 
                                <div>
                                    <strong>{cohortsInfo.totalLeaders}</strong> 

                                    { cohortsInfo.totalLeaders === 1 ? ' leader' : ' leaders' }
                                </div> 
                            : null }
                        </div>

                        <div>
                            <Link className="btn blue" 
                                to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/add-cohort?previousLink=${encodeURIComponent(`/?clientId=${clientId}&programmeId=${programmeId}`)}`}>
                                    
                                <PlusIcon />

                                <div>Add New Cohort</div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="programme-details-top">
                    <div>
                        <div className="programme-info">
                            { cohortsInfo?.agentName ?
                                <div>
                                    <h3>Holos Programme Lead</h3>

                                    <div>{cohortsInfo.agentName}</div>
                                </div>
                            : null }

                            { programme?.debriefDate && isValid(parseISO(programme.debriefDate)) ?
                                <div>
                                    <h3>Debrief Start Date:</h3>

                                    <div>
                                        {format(utcToZonedTime(parseISO(programme.debriefDate), 'UTC'), 'dd MMM yy, HH:mm')} 
                                        <strong>UTC</strong>
                                    </div>
                                </div>
                            : null }
                            
                            { programme?.surveyType && surveyTypes?.length && surveyTypes?.filter(surveyType => surveyType.value === programme.surveyType).length ?
                                <div>
                                    <h3>Survey Type</h3>
                                    <div>{surveyTypes.filter(surveyType => surveyType.value === programme.surveyType)[0].name}</div>
                                </div>
                            : null }
                        </div>
                    </div>

                    <div className="last-updated">
                        {/* <div>Last updated 12 Oct 20</div> */}
                    </div>
                </div>

                <AdminTable className="programme-cohort-table">
                    <AdminTableHead>
                        <tr>
                            <th className="cohort-name-cell">Cohort Name</th>
                            <th className="holos-cohort-lead-cell">Holos Cohort Lead</th>
                            <th className="invitation-send-date-cell">Invitation <br />Send Date</th>
                            <th className="leaders-cell">Leaders</th>
                            <th className="completed-reports-cell">Completed <br />Reports</th>
                            <th className="completion-deadline-cell">Completion <br />Deadline</th>
                            <th colSpan="2">Status</th>
                        </tr>
                    </AdminTableHead>

                    <AdminTableBody>
                        { cohorts.length && cohorts?.filter(cohort => !cohort.revoked)?.length ? 
                            cohorts.filter(cohort => !cohort.revoked).map( cohort => {
                                return (
                                    <ProgrammeCohortTableRow key={cohort.id} 
                                        cohort={cohort} 
                                        cohorts={cohorts} 
                                        programmeId={programmeId} 
                                        clientId={clientId} 
                                        cohortsInfo={cohortsInfo} 
                                        setCohorts={setCohorts} 
                                        setCohortsInfo={setCohortsInfo} 
                                        urlPrepend={urlPrepend} />
                                );
                            } ) 
                        : null }
                    </AdminTableBody>
                </AdminTable>

                { cohorts?.length === 0 ?
                    <div className="no-cohorts">
                        You have not created any Cohorts<br />
                        for this programme
                    </div>
                : null }
            </AdminCardBody>
        </AdminCard>
    );
}

function ProgrammeCohortTableRow({
    cohort, 
    cohorts, 
    programmeId, 
    clientId, 
    cohortsInfo, 
    setCohorts, 
    setCohortsInfo, 
    urlPrepend
}) {
    const { state } = useContext(Store);
    const { statuses } = state;

    const [deleted, setDeleted] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onClickDelete = () => {
        setShowDeleteModal(true);
    };

    const onClickDeleteConfirm = () => {
        if (cohort.status !== 0 && cohort.status !== 1) return;

        deleteCohort(clientId, programmeId, cohort.id).then(response => {
            if (response.success) {
                setDeleted(true);
                setShowDeleteModal(false);

                setTimeout(() => {
                    let cohortsInfoData = cohortsInfo;

                    cohortsInfoData['totalCohorts'] = cohortsInfoData.totalCohorts - 1;
                    cohortsInfoData['totalLeaders'] = cohortsInfoData.totalLeaders - cohort.leaders;

                    setCohortsInfo(cohortsInfo);
                    setCohorts(cohorts.filter(filteredCohort => filteredCohort.id !== cohort.id));
                }, 250);
            }
        });
    };

    return (
        <tr className={`admin-table-row ${deleted ? 'deleted' : ''}`}>
            <td className="cohort-name-cell">
                <div className="cell-icon-wrap">
                    <Link to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohort.id}`}>
                        {cohort.name}
                    </Link>

                    { cohort?.emailBounced ? <div className="warning">!</div> : null }
                </div>
            </td>

            <td className="holos-cohort-lead-cell">
                {cohort?.agentName ?? <div className="cell-dash"></div> }
            </td>

            <td className="invitation-send-date-cell">
                { cohort?.nominationsSent && isValid(parseISO(cohort.nominationsSent)) ? 
                    <>
                        <div>{format(utcToZonedTime(parseISO(cohort.nominationsSent), 'UTC'), 'dd MMM yy')}</div>
                        <div>{format(utcToZonedTime(parseISO(cohort.nominationsSent), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                    </>
                : null }

                { !cohort.nominationsSent ? <div className="cell-dash"></div> : null }
            </td>

            <td className="leaders-cell">{cohort?.leaders ?? <div className="cell-dash"></div> }</td>

            <td className="completed-reports-cell">{cohort?.completedReports ?? <div className="cell-dash"></div> }</td>

            <td className={`completion-deadline-cell ${ cohort?.status !== 4 && cohort?.deadline && isValid(parseISO(cohort.deadline)) && isAfter(new Date(), parseISO(cohort.deadline)) ? 'red-cell' : '' }`}>
                { cohort?.deadline && isValid(parseISO(cohort.deadline)) ? 
                    <>
                        <div>{format(utcToZonedTime(parseISO(cohort.deadline), 'UTC'), 'dd MMM yy')}</div>
                        <div>{format(utcToZonedTime(parseISO(cohort.deadline), 'UTC'), 'H:mm')} <strong>UTC</strong></div> 
                    </>
                : null }

                { !cohort.deadline ? <div className="cell-dash"></div> : null }
            </td>

            <td className="status-cell no-border">
                { cohort?.status && statuses?.filter(status => status.id === cohort.status).length ? 
                    <AdminStatusLabel text={statuses?.filter(status => status.id === cohort.status)[0]?.text} 
                        colour={statuses?.filter(status => status.id === cohort.status)[0]?.colour} /> 

                : <div className="cell-dash"></div> }
            </td>

            <AdminTableActionsCell>
                <div>
                    <button className="delete" disabled={cohort.status !== 0 && cohort.status !== 1} onClick={onClickDelete}><BinIcon className="icon" /></button>

                    <AdminModal show={showDeleteModal} closeModal={() => setShowDeleteModal(false)}>
                        { cohort ? <h2>Are you sure you want to delete {cohort.name}?</h2> : null }

                        <AdminModalBtns>
                            <button className="btn" 
                                type="button" 
                                onClick={() => setShowDeleteModal(false)}>
                                    
                                Cancel
                            </button>

                            <button className="btn blue" 
                                type="button" 
                                onClick={onClickDeleteConfirm}>
                                    
                                Delete Cohort
                            </button>
                        </AdminModalBtns>
                    </AdminModal>
                </div>

                <div>
                    <Link className="edit" 
                        to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohort.id}/edit`}>
                            
                        <PencilIcon className="icon" />
                    </Link>
                </div>
            </AdminTableActionsCell>
        </tr>
    );
}

export default function Programmes({
    setHeaderBreadcrumbs, 
    urlPrepend, 
    productName, 
    productType
}) {
    const location = useLocation();
    const history = useHistory();

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [clients, setClients] = useState(false);
    const [programmes, setProgrammes] = useState();
    const [cohortReports, setCohortReports] = useState();
    const [quarterlyReports, setQuarterlyReports] = useState();
    
    const [programme, setProgramme] = useState();

    const [clientValue, setClientValue] = useState();
    const [programmeValue, setProgrammeValue] = useState();

    const [cohortsInfo, setCohortsInfo] = useState(false);
    const [cohorts, setCohorts] = useState(false);

    const fetchClients = useCallback(() => {
        getClients().then(response => {
            if (response.success) {
                setClients(response.clients.sort((a, b) => {
                    if ( a.name < b.name ) return -1;
            
                    if ( a.name > b.name ) return 1;
            
                    return 0;
                }));
            } else {
                setPageError(response.errors);
            }
        }).catch(error => {
            if ( error.response?.data?.errors ) {
                setPageError(error.response.data.errors);
            } else if ( error.response?.data?.message ) {
                setPageError(error.response.data.message);
            } else {
                setPageError('There was an error. Please try again.');
            }
        }).finally(() => {
            setPageLoading(false);
        })
    }, []);

    const fetchClientProgrammes = useCallback((clientId, productType) => {
        getProgrammes(clientId, productType).then(response => {
            if (response.success) {
                setProgrammes(response.programmes);
                setCohortReports(response.cohortReports);
            } else {
                setProgrammes(false);
                setCohortReports(false);
            }
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchClientProgrammeCohorts = useCallback((clientId, programmeId) => {
        getCohorts(clientId, programmeId).then(response => {
            if (response.success) {
                setCohortsInfo(response);
                setCohorts(response.cohorts);
            }
        });
    }, []);

    const fetchClientReports = useCallback((clientId, productType) => {
        getClientReports(clientId, productType).then(response => {
            if (response.success) {
                setQuarterlyReports(response.reports);
            } else {
                setQuarterlyReports(false);
            }
        })
    }, []);
    
    useEffect(() => {
        document.title = `Programmes - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);
    
    useEffect(() => {
        setHeaderBreadcrumbs();
    }, [setHeaderBreadcrumbs]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (!clients) fetchClients();

        if (params.get('clientId')) {
            fetchClientProgrammes(params.get('clientId'), productType);
            setClientValue(params.get('clientId'));
            fetchClientReports(params.get('clientId'), productType);
        } else {
            setProgrammes();
            setCohortReports(false);
            setQuarterlyReports(false);
            setClientValue('');
        }

        if (params.get('clientId') && params.get('programmeId')) {
            fetchProgramme(params.get('clientId'), params.get('programmeId'));
            setProgrammeValue(params.get('programmeId'));
            fetchClientProgrammeCohorts(params.get('clientId'), params.get('programmeId'));
        } else {
            setProgramme(false);
            setProgrammeValue('');
            setCohorts(false);
        }
    }, [
        location, 
        clients, 
        productType, 
        fetchClientProgrammes, 
        fetchClients, 
        fetchProgramme, 
        fetchClientProgrammeCohorts, 
        fetchClientReports
    ]);

    const onChangeClientSelect = e => {
        history.push({
            pathname: `${urlPrepend}/`,
            search: `?clientId=${e.target.value}`
        });
    };

    const onChangeClientProgrammeSelect = e => {
        history.push({
            pathname: `${urlPrepend}/`,
            search: `?clientId=${clientValue}&programmeId=${e.target.value}`
        });
    };

    const onChangeCohortReportsSelect = e => {
        window.open(`/report/${e.target.value}`);
    };

    const onChangeQuarterlyReportsSelect = e => {
        window.open(`/report/${e.target.value}`);
    };

    return (
        <AdminContainer pageError={pageError} 
            pageLoading={pageLoading}>

            <div>
                <AdminTop header="Programmes">
                    <div>
                        <Link className="btn blue" 
                            to={`${urlPrepend}/create-programme`}>
                                
                            <PlusIcon />

                            <div>Create a New Programme</div>
                        </Link>
                    </div>
                </AdminTop>

                <AdminCard>
                    <AdminCardBody>
                        <InputRow className="find-a-programme">
                            <div className="header">
                                <h2>Find a programme:</h2>
                            </div>

                            <InputGroup>
                                <Input selectText="Choose Client" 
                                    name="add-user-type" 
                                    type="select" 
                                    value={clients ? clientValue : ''} 
                                    options={clients ? clients.map( client => {
                                        return {
                                            value: client.id,
                                            name: client.name
                                        }
                                    } ) : null} 
                                    onChange={onChangeClientSelect} />

                                { programmes?.length ? 
                                    <Input type="select" 
                                        selectText="Choose Programme" 
                                        value={programmeValue} 
                                        options={ programmes.slice(0).reverse().map(client => {
                                            return {
                                                value: client.id,
                                                name: client.name
                                            }
                                        } ) } 
                                        onChange={onChangeClientProgrammeSelect} /> 
                                : null }

                                { clientValue && programmes?.length === 0 ? 
                                    <div className="align-center">You currently have no programmes</div> 
                                : null }
                            </InputGroup>

                            { cohortReports?.length || quarterlyReports?.length ?
                                <InputGroup className="find-a-programme-reports">
                                    { cohortReports?.length ?
                                        <Input type="select" 
                                            selectText="Cohort Reports" 
                                            options={ cohortReports.map(report => {
                                                return {
                                                    value: report.token,
                                                    name: report.name
                                                }
                                            } ) } 
                                            value="" 
                                            onChange={onChangeCohortReportsSelect} />
                                    : null }

                                    { quarterlyReports?.length ?
                                        <Input type="select" selectText="Quarterly Reports" 
                                            options={quarterlyReports.map(report => {
                                                return {
                                                    value: report.token,
                                                    name: report.name
                                                }
                                            } ) } 
                                            value="" 
                                            onChange={onChangeQuarterlyReportsSelect} />
                                    : null }
                                </InputGroup>
                            : null }
                        </InputRow>
                    </AdminCardBody>
                </AdminCard>
                { programme ? <ProgrammeDetails programme={programme} clientId={clientValue} programmeId={programmeValue} clients={clients} cohorts={cohorts} setCohorts={setCohorts} cohortsInfo={cohortsInfo} setCohortsInfo={setCohortsInfo} urlPrepend={urlPrepend} /> : null }
            </div>
        </AdminContainer>
    );
}
