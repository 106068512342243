import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Store } from '../../../Store';

import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import ReportTop from '../ReportTop/ReportTop';
import ReportMainContent from '../ReportMainContent/ReportMainContent';
import ReportScoreCard from '../ReportScoreCard/ReportScoreCard';

import ChevronLeft from '../../Icons/ChevronLeft';

import './ReportResponsesQuestions.scss';

function FreeTextQuestion({productType, reportType, question}) {
    const data = {
        datasets: [{
            data: question.answers.map(answer => answer.percentageAnswered),
            backgroundColor: question.answers.map(answer => {
                if (answer.answer === "0") {
                    return '#D81919';
                } else if (answer.answer === "5") {
                    return '#FFA01F';
                } else if (answer.answer === "35") {
                    return '#BBBBC4';
                } else if (answer.answer === "40") {
                    return '#00DBA3';
                } else if (answer.answer === "70") {
                    return '#28C5FF';
                }

                return '';
            })
        }]
    };

    const getAnswerColour = percentage => {
        if (percentage === "0") {
            return 'red';
        } else if (percentage === "5") {
            return 'yellow';
        } else if (percentage === "35") {
            return 'grey';
        } else if (percentage === "40") {
            return 'green';
        } else if (percentage === "70") {
            return 'light-blue';
        }

        return false;
    }

    return (
        <div id={question.id}>
            <div className="report-card">
                <div><strong>Q{question.number}.</strong></div>
                <div className="question">
                    <div className="text">{question.question}</div>
                    <ReportScoreCard text="Average score" score={question?.averageScorePercentage} />
                </div>
                <div className="chart-wrap">
                    <div className="key">
                        <h2 className="header">Team Responses</h2>
                        { question.answers.map((answer, index) => {
                            return (
                                <div key={index}><div className={`icon ${getAnswerColour(answer.answer)}`}></div>{answer.totalAnswered}</div>
                            );
                        }) }
                        { reportType === 0 && productType === 1 ?
                            <div className="leader"><div className={`icon ${getAnswerColour(question.leaderAnswer)}`}></div>Leader Response</div>
                        : null }
                    </div>
                    <div className="chart"><Pie height={300} data={data} options={{ tooltips: false }} /></div>
                </div>
            </div>
        </div>
    )
}

export default function ReportResponsesQuestions({productType, reportType, downloadFileName, setActivePage}) {
    setActivePage('responses-questions');

    const { state } = useContext(Store);
    const { apiUrl } = state;

    const { bearerToken, sectionId } = useParams();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const [sectionName, setSectionName] = useState(false);
    const [multipleChoiceQuestions, setMultipleChoiceQuestions] = useState(false);

    const fetchSectionQuestionData = useCallback(() => {
        setLoading(true);

        axios.get(`${apiUrl}Reports/Leader/Section/${sectionId}/Questions`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        }).then(response => {
            console.log(response);

            setMultipleChoiceQuestions(response.data.multipleChoiceQuestions ? response.data.multipleChoiceQuestions : false);
            setSectionName(response.data.name ? response.data.name : false);

            setLoading(false);
        }).catch(err => {
            setErrorMessage(err.response.data.errors ?? err.message);
            setLoading(false);
        });
    }, [apiUrl, bearerToken, sectionId]);

    useEffect(() => {
        fetchSectionQuestionData();
    }, [fetchSectionQuestionData, sectionId]);

    return (
        <div className="report-content">

            {loading ? <div className="loading-circle"></div> : null}
            { errorMessage ? <div className="container-lg container-padding-lg"><ErrorMessage message={errorMessage} /></div> : null }

            <ReportTop downloadFileName={downloadFileName}>
                { sectionName ?
                    <Link className="report-responses-questions-back-to-context" to={`/report/${bearerToken}/responses/${sectionId}`}>
                        <ChevronLeft className="icon" />Back to {sectionName} overview
                    </Link>
                : null }
            </ReportTop>

            { !loading && !errorMessage ? 
                <div className="container-lg">
                    <div className="report-content-container">
                        <ReportMainContent>
                            { multipleChoiceQuestions && multipleChoiceQuestions.length ? 
                                <div className="report-responses-questions-free-text">
                                    { multipleChoiceQuestions.map(question => {
                                        return (
                                            <FreeTextQuestion key={question.id} productType={productType} reportType={reportType} question={question} />
                                        );
                                    }) }
                                </div>
                            : null }
                        </ReportMainContent>
                    </div>
                </div>
            : null }

            { !loading && !errorMessage ? 
                <div className="report-responses-questions-key">
                    <div className="report-responses-questions-key-inner container-lg">
                        <div><div className="icon red"></div>Rarely</div>
                        <div><div className="icon yellow"></div>Occasionally</div>
                        <div><div className="icon green"></div>Usually</div>
                        <div><div className="icon light-blue"></div>Frequently</div>
                        <div><div className="icon grey"></div>Don't Know</div>
                    </div>
                </div>
            : null }
        </div>
    );
}
