import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import { Store } from '../../../../../Store';
import { getAgents, getClients, createProgramme } from '../../../../../api';
import { sortNameAlphabetically } from '../../../../../helpers';

import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import Input, { InputRow } from '../../../../Input/Input';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import PlusIcon from '../../../../Icons/PlusIcon';

import './CreateProgramme.scss';

export default function CreateProgramme({setHeaderBreadcrumbs, urlPrepend, productName, productClass, productType}) {
    const { state } = useContext(Store);
    const { surveyTypes } = state;

    const location = useLocation();

    const [clients, setClients] = useState();
    const [agents, setAgents] = useState();

    const [clientValue, setClientValue] = useState(false);
    const [programmeNameValue, setProgrammeNameValue] = useState('');
    const [programmeEmailAddressValue, setProgrammeEmailAddressValue] = useState('');
    const [surveyTypeValue, setSurveyTypeValue] = useState(surveyTypes?.length === 1 ? surveyTypes[0].value : '');
    const [agentValue, setAgentValue] = useState('');

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [clientError, setClientError] = useState(false);
    const [programmeNameError, setProgrammeNameError] = useState(false);
    const [programmeEmailAddressError, setProgrammeEmailAddressError] = useState(false);
    const [surveyTypeError, setSurveyTypeError] = useState(false);
    const [agentError, setAgentError] = useState(false);

    const [formChange, setFormChange] = useState(false);

    const [redirect, setRedirect] = useState(false);

    const fetchClients = useCallback(() => {
        getClients().then(response => {
            if (response.success) setClients(response.clients);
        });
    }, []);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response.users);
        });
    }, []);

    useEffect(() => {
        document.title = `Create Programme - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        setSurveyTypeValue(surveyTypes?.length === 1 ? surveyTypes[0].value : '');
    }, [setSurveyTypeValue, surveyTypes]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (!clientValue) {
            if (params.get('clientId')) {
                setClientValue(params.get('clientId'));
            } else if (clients) {
                setClientValue(clients.sort(sortNameAlphabetically)[0].id);
            }
        }
    }, [clientValue, clients, location, setClientValue]);

    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: 'Create new programme'
            }
        ]);
    }, [urlPrepend, setHeaderBreadcrumbs]);

    const onSubmitCreateNewProgrammeForm = e => {
        e.preventDefault();

        setFormLoading(true);

        setClientError(false);
        setProgrammeNameError(false);
        setProgrammeEmailAddressError(false);
        setSurveyTypeError(false);
        setAgentError(false);
        setFormErrors(false);

        if(!clientValue) {
            setClientError(['Client name is required']);
            setFormLoading(false);
            return;
        }
        
        createProgramme(clientValue, {
            'surveyType': surveyTypeValue ? parseInt(surveyTypeValue) : false,
            'productType': productType,
            'name': programmeNameValue,
            'emailAddress': programmeEmailAddressValue,
            'agentId': agentValue,
        }).then(response => {
            if (response.success) {
                setFormChange(false);
                setRedirect(`${urlPrepend}/client/${clientValue}/programme/${response.id}/edit-comms`);
            } else {
                if (response.errors) {
                    setProgrammeNameError(response?.errors?.Name ?? false);
                    setProgrammeEmailAddressError(response?.errors?.EmailAddress ?? false);
                    setSurveyTypeError(response?.errors?.SurveyType ?? false);
                    setAgentError(response?.errors?.AgentId ?? false);

                    let errors = Object.assign({}, response.errors);

                    delete errors.Name;
                    delete errors.EmailAddress;
                    delete errors.SurveyType;
                    delete errors.AgentId;

                    setFormErrors(errors);
                }
            }

            setFormLoading(false);
        }).catch(() => {
            setFormLoading(false);
        });
    };

    const onChangeClientSelect = e => {
        setClientValue(e.target.value);
        setFormChange(true);
    };

    const onChangeProgrammeNameInput = e => {
        setProgrammeNameValue(e.target.value);
        setFormChange(true);
    };

    const onChangeProgrammeEmailAddressInput = e => {
        setProgrammeEmailAddressValue(e.target.value);
        setFormChange(true);
    };

    const onChangeSurveyTypeSelect = e => {
        setSurveyTypeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeAgentSelect = e => {
        setAgentValue(e.target.value);
        setFormChange(true);
    };

    return (
        <div className="admin-container container-padding-lg">
            <AdminTop header="Create new programme" />
            <AdminCard>
                <AdminCardBody>
                    { formErrors ? <ErrorMessage message={formErrors} /> : null }

                    <form className="create-programme-form" onSubmit={onSubmitCreateNewProgrammeForm}>
                        { formLoading ? <div className="loading-circle"></div> : null }

                        <div className={`create-programme-form-inner ${formLoading ? 'loading' : ''}`}>
                            <InputRow>
                                <Input type="select" 
                                    label="Client name*" 
                                    name="client-name" 
                                    value={clientValue} 
                                    options={ clients?.sort(sortNameAlphabetically).map( client => {
                                        return {
                                            value: client.id,
                                            name: client.name
                                        }
                                    } ) } 
                                    onChange={onChangeClientSelect} 
                                    errors={clientError} 
                                    loading={!clients} />

                                <div className="align-bottom">
                                    <Link className="btn small" to={`${urlPrepend}/add-client?redirect=create-programme`}><PlusIcon /><div>Add a New Client</div></Link>
                                </div>
                            </InputRow>
                            <InputRow>
                                <Input label="Programme Name*" name="programme-name" type="text" value={programmeNameValue} errors={programmeNameError} onChange={onChangeProgrammeNameInput} />

                                <Input label="Programme email address*" name="programme-comms-email-address" type="email" value={programmeEmailAddressValue} errors={programmeEmailAddressError} onChange={onChangeProgrammeEmailAddressInput} />

                                { surveyTypes?.length > 1 ?
                                    <Input label="Survey type*" 
                                        name="survey-type" 
                                        type="select" 
                                        selectText="Survey Type" 
                                        value={surveyTypeValue} 
                                        options={ surveyTypes ? surveyTypes.map( surveyType => {
                                            return {
                                                value: surveyType.value,
                                                name: surveyType.name
                                            }
                                        } ) : null} 
                                        size="small" 
                                        errors={surveyTypeError} 
                                        loading={!surveyTypes} 
                                        onChange={onChangeSurveyTypeSelect} />
                                : null }

                                <Input type="select" 
                                    label="Assign a Holos agent for this programme*" 
                                    name="assign-agent" 
                                    selectText="Holos Agent" 
                                    value={agentValue} 
                                    selectedValue={agentValue} 
                                    options={ agents?.sort(sortNameAlphabetically).map( agent => {
                                        return {
                                            value: agent.id,
                                            name: agent.name
                                        }
                                    } ) } 
                                    errors={agentError} 
                                    loading={!agents} 
                                    onChange={onChangeAgentSelect} />
                            </InputRow>
                            <div className="create-programme-form-bottom">
                                <button className="btn blue align-right" type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </AdminCardBody>
            </AdminCard>

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>This programme has not been saved.</p>
            </AdminLeavingPageModal>

            { redirect ? <Redirect to={redirect} /> : null }
        </div>
    );
}
