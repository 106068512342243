import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { getClient, getProgramme, getCohort, createSurveys } from '../../../../../api';

import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import Input, { InputRow } from '../../../../Input/Input';
import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import PlusIcon from '../../../../Icons/PlusIcon';

import './AddLeader.scss';

function AddLeaderRow({leader, index, updateLeader, removeLeader, errors}) {
    const [firstName, setFirstName] = useState(leader.firstName);
    const [lastName, setLastName] = useState(leader.lastName);
    const [emailAddress, setEmailAddress] = useState(leader.emailAddress);

    useEffect(() => {
        setFirstName(leader.firstName);
        setLastName(leader.lastName);
        setEmailAddress(leader.emailAddress);
    }, [leader]);

    const onChangeFirstNameInput = e => {
        setFirstName(e.target.value);
        updateLeader(index, 'firstName', e.target.value);
    };

    const onChangeLastNameInput = e => {
        setLastName(e.target.value);
        updateLeader(index, 'lastName', e.target.value);
    };

    const onChangeEmailAddressInput = e => {
        setEmailAddress(e.target.value);
        updateLeader(index, 'emailAddress', e.target.value);
    };

    const onClickRemoveLeaderBtn = () => {
        removeLeader(index);
    };

    return (
        <InputRow>
            <Input label="Leader first name*" type="text" name="leader-first-name" value={firstName} onChange={onChangeFirstNameInput} errors={errors?.firstName ?? false} />
            <Input label="Leader last name*" type="text" name="leader-last-name" value={lastName} onChange={onChangeLastNameInput} errors={errors?.lastName ?? false} />
            <Input label="Leader email address*" type="email" name="leader-email-address" value={emailAddress} onChange={onChangeEmailAddressInput} errors={errors?.emailAddress ?? false} />
            { index !== 0 ? <div className="align-bottom"><button className="btn small" type="button" onClick={onClickRemoveLeaderBtn}>Remove</button></div> : null }
        </InputRow>
    );
}

export default function AddLeader({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [cohort, setCohort] = useState(false);

    const [leaders, setLeaders] = useState([{firstName: '', lastName: '', emailAddress: '', languageId: '3fa85f64-5717-4562-b3fc-2c963f66afa6', formChange: false}]);
    const [leadersErrors, setLeadersErrors] = useState(false);

    const [redirect, setRedirect] = useState(false);

    const {clientId, programmeId, cohortId} = useParams();

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchCohort = useCallback((clientId, programmeId, cohortId) => {
        getCohort(clientId, programmeId, cohortId).then(response => {
            if (response.success) setCohort(response);
        });
    }, []);


    useEffect(() => {
        document.title = `Add Leader - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchCohort(clientId, programmeId, cohortId);
    }, [fetchCohort, clientId, programmeId, cohortId]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: programme.name,
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: cohort.name,
                link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`
            }, {
                text: 'Add a new leader'
            }
        ]);
    }, [programme, cohort, urlPrepend, clientId, programmeId, cohortId, setHeaderBreadcrumbs]);

    const updateLeader = (index, field, value) => {
        let leadersData = [...leaders];
        leadersData[index][field] = value;
        if ( field !== 'formChange' ) leadersData[index]['formChange'] = true;
        setLeaders(leadersData);
        return true;
    };

    const removeLeader = index => {
        setLeaders(leaders.filter((leader, leaderIndex) => leaderIndex !== index));
    };

    const onClickAddAnotherLeaderBtn = () => {
        setLeaders(leaders.concat({firstName: '', lastName: '', emailAddress: '', languageId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'}));
    };

    const onClickSaveToCohortBtn = () => {
        setLoading(true);
        setLeadersErrors(false);
        setFormErrors(false);

        createSurveys({ clientId, programmeId, cohortId, leaders }).then(response => {
            if (response.success) {
                leaders.forEach((leader, index) => {
                    updateLeader(index, 'formChange', false);
                });
                
                setRedirect(`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`);
            } else {
                let responseLeadersErrors = [];

                leaders.forEach((leader, index) => {
                    responseLeadersErrors.push({ 
                        firstName: response.errors[`Leaders[${index}].FirstName`] ? response.errors[`Leaders[${index}].FirstName`] : false,
                        lastName: response.errors[`Leaders[${index}].LastName`] ? response.errors[`Leaders[${index}].LastName`] : false,
                        emailAddress: response.errors[`Leaders[${index}].EmailAddress`] ? response.errors[`Leaders[${index}].EmailAddress`] : false,
                    });
                });

                setLeadersErrors(responseLeadersErrors);

                let errors = Object.assign({}, response.errors);

                delete errors.ClientName;

                leaders.forEach((leader, index) => {
                    delete errors[`Leaders[${index}].FirstName`];
                    delete errors[`Leaders[${index}].LastName`];
                    delete errors[`Leaders[${index}].EmailAddress`];
                });

                setFormErrors(errors);
            }

            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    };

    return (
        <div>
            <AdminInfoBar>
                { client?.name && programme?.name ? 
                    <div>
                        <h3>{client.name}</h3>
                        <div>{programme.name}</div>
                    </div>
                : null }
                { cohort ?
                    <div>
                        <h3>Cohort:</h3>
                        <div>{cohort.name}</div>
                    </div>
                : null }
            </AdminInfoBar>
            <div className="admin-container container-padding-lg">
                <AdminTop header="Add a new leader" />
                <AdminCard className={`add-leader-card ${loading ? 'loading' : ''}`}>
                    {loading ? <div className="loading-circle"></div> : null}

                    <div className="add-leader-card-inner">
                        <AdminCardBody>
                            { formErrors ? <ErrorMessage message={formErrors} /> : null }

                            { leaders?.length ?
                                leaders.map((leader, index) => {
                                    return (
                                        <AddLeaderRow key={index} leader={leader} index={index} updateLeader={updateLeader} removeLeader={removeLeader} errors={leadersErrors[index]} />
                                    );
                                })
                            : null }
                            
                            <div className="add-another-leader-btn-wrap">
                                <button className="btn blue" type="button" onClick={onClickAddAnotherLeaderBtn}><PlusIcon /><div>Add Another Leader</div></button>
                            </div>
                        </AdminCardBody>
                    </div>
                </AdminCard>
            </div>
            <AdminActionBar>
                <div></div>
                <div>
                    <div><Link className="btn white-border" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`}>Cancel</Link></div>
                    <div><button className="btn" type="button" onClick={onClickSaveToCohortBtn}>Save to Cohort</button></div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={leaders?.length ? leaders.filter(leader => leader.formChange === true).length : false}>
                <h2>Leaving this page?</h2>
                <p>These leaders have not been saved.</p>
            </AdminLeavingPageModal>

            { redirect ? <Redirect to={redirect} /> : null }
        </div>
    )
}
