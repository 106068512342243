import { authFetch } from '../auth';
import { getConfig } from '../Store';
import { logout } from '../auth';

function checkResponse(response) {
    if (response?.status === 401) {
        logout();
        return false;
    }

    return response.json();
}

/**
 * Get survey types
 * 
 * @param  int
 * @return promise
 */
export async function getSurveyTypes(productType) {
    return authFetch(`${getConfig().apiUrl}Enums/SurveyTypes?ProductType=${productType}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get respondent types
 * 
 * @return promise
 */
export async function getRespondentTypes() {
    return authFetch(`${getConfig().apiUrl}Enums/RespondentTypes`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get cohort statuses
 * 
 * @return promise
 */
export async function getCohortStatuses() {
    return authFetch(`${getConfig().apiUrl}Enums/CohortStatus`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get clients
 * 
 * @return promise
 */
export async function getClients() {
    return authFetch(`${getConfig().apiUrl}Clients`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

export async function getAdminClients() {
    return authFetch(`${getConfig().apiUrl}Clients/Admin`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get client
 * 
 * @param  string
 * @return promise
 */
export async function getClient(clientId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Create client
 * 
 * @param  object
 * @return promise
 */
export async function createClient(body) {
    return authFetch(`${getConfig().apiUrl}Clients`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Get programmes
 * 
 * @param  string
 * @return promise
 */
export async function getProgrammes(clientId, productType) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/ProductType/${productType}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get programme
 * 
 * @param  string|string
 * @return promise
 */
export async function getProgramme(clientId, programmeId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Create programme
 * 
 * @param  string|object
 * @return promise
 */
export async function createProgramme(clientId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Get cohorts
 * 
 * @param  string|string
 * @return promise
 */
export async function getCohorts(clientId, programmeId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get cohort
 * 
 * @param  string|string|string
 * @return promise
 */
export async function getCohort(clientId, programmeId, cohortId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts/${cohortId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Create cohort
 * 
 * @param  string|string|string|string|string
 * @return promise
 */
export async function createCohort(clientId, programmeId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Update cohort
 * 
 * @param  string|string|string|string|string
 * @return promise
 */
export async function updateCohort(clientId, programmeId, cohortId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts/${cohortId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Update respondent
 * 
 * @param  string|object
 * @return promise
 */
export async function updateRespondent(surveyId, respondentId, body) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/UpdateRespondent/${respondentId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Update respondent email address
 * 
 * @param  string|object
 * @return promise
 */
export async function updateRespondentEmailAddress(surveyId, respondentId, body) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/UpdateRespondentEmailAddress/${respondentId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Update leader email address
 * 
 * @param  string|object
 * @return promise
 */
export async function updateLeaderEmailAddress(surveyId, body) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/UpdateLeaderEmailAddress`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Delete cohort
 * 
 * @param  string
 * @return promise
 */
export async function deleteCohort(clientId, programmeId, cohortId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts/${cohortId}`, {
        method: 'DELETE'
    }).then(checkResponse);
};

/**
 * Get surveys
 * 
 * @param  string|string|string
 * @return promise
 */
export async function getSurveys(clientId, programmeId, cohortId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts/${cohortId}/Surveys`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get survey
 * 
 * @param  string
 * @return promise
 */
export async function getSurvey(surveyId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get agents
 * 
 * @return promise
 */
export async function getAgents() {
    return authFetch(`${getConfig().apiUrl}Users/Agents`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
};

/**
 * Get agents
 * 
 * @param  string|string|object
 * @return promise
 */
export async function updateProgramme(clientId, programmeId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Get users
 * 
 * @return promise
 */
export async function getUsers() {
    return authFetch(`${getConfig().apiUrl}Users`).then(checkResponse);
}

/**
 * Create user
 * 
 * @param  object
 * @return promise
 */
export async function createUser(body) {
    return authFetch(`${getConfig().apiUrl}Users`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Update user
 * 
 * @param  string|object
 * @return promise
 */
export async function updateUser(userId, body) {
    return authFetch(`${getConfig().apiUrl}Users/${userId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Delete user
 * 
 * @param  string
 * @return promise
 */
export async function deleteUser(userId, body) {
    return authFetch(`${getConfig().apiUrl}Users/${userId}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Upload leaders file
 * 
 * @param  string|string|string|object
 * @return promise
 */
export async function uploadLeadersFile(clientId, programmeId, cohortId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Cohorts/${cohortId}/UploadLeaders`, {
        method: 'POST',
        body: body
    }).then(checkResponse);
}

/**
 * Create surveys
 * 
 * @param  string
 * @return promise
 */
export async function createSurveys(body) {
    return authFetch(`${getConfig().apiUrl}Surveys`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Create respondents
 * 
 * @param  string
 * @return promise
 */
export async function createRespondents(surveyId, body) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/AddRespondents`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(checkResponse);
}

/**
 * Revoke Respondent
 * 
 * @param  string|string
 * @return promise
 */
export async function revokeRespondent(surveyId, respondentId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/RevokeRespondent/${respondentId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Unrevoke Respondent
 * 
 * @param  string|string
 * @return promise
 */
export async function unrevokeRespondent(surveyId, respondentId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/UnRevokeRespondent/${respondentId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Revoke Survey
 * 
 * @param  string|string
 * @return promise
 */
export async function revokeSurvey(surveyId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/Revoke`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Unrevoke Survey
 * 
 * @param  string|string
 * @return promise
 */
export async function unrevokeSurvey(surveyId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/UnRevoke`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Delete Survey
 * 
 * @param  string
 * @return promise
 */
export async function deleteSurvey(surveyId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}`, {
        method: 'DELETE'
    }).then(checkResponse);
};

/**
 * Generate Report
 * 
 * @param  string
 * @return promise
 */
export async function generateReport(surveyId) {
    return authFetch(`${getConfig().apiUrl}Reports/${surveyId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Get Report
 * 
 * @param  string
 * @return promise
 */
export async function getReportOverview(surveyId) {
    return authFetch(`${getConfig().apiUrl}Reports/${surveyId}/Overview`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Send Respondent Reminder
 * 
 * @param  string|string
 * @return promise
 */
export async function sendRespondentReminder(surveyId, respondentId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/SendRespondentReminder/${respondentId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Send Leader Nominate
 * 
 * @param  string
 * @return promise
 */
export async function sendLeaderNominate(surveyId) {
    return authFetch(`${getConfig().apiUrl}Surveys/${surveyId}/SendLeaderNominate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Download Report
 * 
 * @param  string
 * @return promise
 */
export async function downloadReport(surveyId) {
    return authFetch(`${getConfig().apiUrl}Reports/${surveyId}/Download`, {
        method: 'GET'
    }).then(response => response.blob());
}

/**
 * Generate Cohort Dates
 * 
 * @param  string|string
 * @return promise
 */
export async function generateCohortDates(nominationInviteDate, surveyDeadlineDate) {
    return authFetch(`${getConfig().apiUrl}cohorts/dates?NominationInviteDate=${encodeURIComponent(nominationInviteDate)}&SurveyDeadline=${encodeURIComponent(surveyDeadlineDate)}`, {
        method: 'GET'
    }).then(checkResponse);
}

/**
 * Get Email Template
 * 
 * @param  string|string
 * @return promise
 */
export async function getEmailTemplate(clientId, programmeId, templateKey) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/EmailTemplate/${templateKey}`, {
        method: 'GET'
    }).then(checkResponse);
}

/**
 * Update Email Template
 * 
 * @param  string|string|string|object
 * @return promise
 */
export async function updateEmailTemplate(clientId, programmeId, templateKey, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/EmailTemplate/${templateKey}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Send Email Template Test
 * 
 * @param  string|string|string
 * @return promise
 */
export async function sendEmailTemplateTest(clientId, programmeId, templateKey) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/EmailTemplate/${templateKey}/Send`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Account Change Password
 * 
 * @param  object
 * @return promise
 */
export async function changePassword(body) {
    return authFetch(`${getConfig().apiUrl}Account/ChangePassword`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Edit Account
 * 
 * @param  object
 * @return promise
 */
export async function editAccount(body) {
    return authFetch(`${getConfig().apiUrl}Account`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Edit Account
 * 
 * @return promise
 */
export async function getAccount() {
    return authFetch(`${getConfig().apiUrl}Account`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Edit Account
 * 
 * @param  string
 * @return promise
 */
export async function deleteClient(clientId) {
    return authFetch(`${getConfig().apiUrl}Client/${clientId}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Edit Account
 * 
 * @param  string|object
 * @return promise
 */
export async function updateClient(clientId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Get Teams
 * 
 * @param  string|string
 * @return promise
 */
export async function getTeams(clientId, programmeId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Create Team
 * 
 * @param  string|string
 * @return promise
 */
export async function createTeam(clientId, programmeId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Get Team
 * 
 * @param  string|string|string
 * @return promise
 */
export async function getTeam(clientId, programmeId, teamId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams/${teamId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Update Team
 * 
 * @param  string|string|string
 * @return promise
 */
export async function updateTeam(clientId, programmeId, teamId, body) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams/${teamId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Delete Team
 * 
 * @param  string|string|string
 * @return promise
 */
export async function deleteTeam(clientId, programmeId, teamId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams/${teamId}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Get Team Members
 * 
 * @param  string|string|string
 * @return promise
 */
export async function getTeamMembers(clientId, programmeId, teamId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams/${teamId}/Members`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Get Team Members
 * 
 * @param  string|string|string
 * @return promise
 */
export async function createTeamMembers(teamId, body) {
    return authFetch(`${getConfig().apiUrl}Teams/${teamId}/AddTeamMembers`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}

/**
 * Generate Team Dates
 * 
 * @param  string|string
 * @return promise
 */
export async function generateTeamDates(nominationInviteDate, surveyDeadlineDate) {
    return authFetch(`${getConfig().apiUrl}Teams/Dates?NominationInviteDate=${encodeURIComponent(nominationInviteDate)}&SurveyDeadline=${encodeURIComponent(surveyDeadlineDate)}`, {
        method: 'GET'
    }).then(checkResponse);
}

/**
 * Send report to leader
 * 
 * @param  string
 * @return promise
 */
export async function sendReport(surveyId) {
    return authFetch(`${getConfig().apiUrl}Reports/${surveyId}/SendToLeader`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Get client reports
 * 
 * @param  string
 * @return promise
 */
export async function getClientReports(clientId, productType) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Reports/${productType}`, {
        method: 'GET'
    }).then(checkResponse);
}

/**
 * Generate programme report
 * 
 * @param  string|string
 * @return promise
 */
export async function generateProgrammeReport(clientId, programmeId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/report`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Generate survey dummy data
 * 
 * @param  string|string
 * @return promise
 */
export async function generateSurveyDummyData(surveyId) {
    return authFetch(`${getConfig().apiUrl}surveys/${surveyId}/dummy`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(checkResponse);
}

/**
 * Get Team Report
 * 
 * @param  string|string
 * @return promise
 */
export async function getTeamReport(clientId, programmeId) {
    return authFetch(`${getConfig().apiUrl}Clients/${clientId}/Programmes/${programmeId}/Teams/reportGenerated`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(checkResponse);
}
