import React from 'react';

import './ReportMainContent.scss';

export default function ReportMainContent({children}) {
    return (
        <div className="report-main-content">
            {children}
        </div>
    );
}
