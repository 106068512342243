import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { getClient, getProgramme, getCohort, getSurvey, createRespondents, getRespondentTypes } from '../../../../../api';

import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import Input, { InputRow } from '../../../../Input/Input';
import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import PlusIcon from '../../../../Icons/PlusIcon';

import './AddRespondent.scss';

function AddRespondentRow({respondent, index, updateRespondent, removeRespondent, respondentTypes, errors}) {
    const [firstName, setFirstName] = useState(respondent.firstName);
    const [lastName, setLastName] = useState(respondent.lastName);
    const [emailAddress, setEmailAddress] = useState(respondent.emailAddress);
    const [respondentTypeValue, setRespondentTypeValue] = useState(false);

    useEffect(() => {
        setFirstName(respondent.firstName);
        setLastName(respondent.lastName);
        setEmailAddress(respondent.emailAddress);
        
        if (!respondentTypeValue && respondentTypes?.length) {
            setRespondentTypeValue(respondentTypes[0].value);
            updateRespondent(index, 'respondentType', respondentTypes[0].value, false);
        }
    }, [respondent, respondentTypes, index, respondentTypeValue, updateRespondent]);

    const onChangeFirstNameInput = e => {
        setFirstName(e.target.value);
        updateRespondent(index, 'firstName', e.target.value);
    };

    const onChangeLastNameInput = e => {
        setLastName(e.target.value);
        updateRespondent(index, 'lastName', e.target.value);
    };

    const onChangeEmailAddressInput = e => {
        setEmailAddress(e.target.value);
        updateRespondent(index, 'emailAddress', e.target.value);
    };

    const onChangeRespondentTypeSelect = e => {
        setRespondentTypeValue(e.target.value);
        updateRespondent(index, 'respondentType', parseInt(e.target.value));
    }

    const onClickRemoveRespondentBtn = () => {
        removeRespondent(index);
    };

    return (
        <InputRow>
            <Input label="Respondent first name*" type="text" name="respondent-first-name" value={firstName} size="small" onChange={onChangeFirstNameInput} errors={errors?.firstName ?? false} />
            <Input label="Respondent last name*" type="text" name="respondent-last-name" value={lastName} size="small" onChange={onChangeLastNameInput} errors={errors?.lastName ?? false} />
            <Input label="Respondent email address*" type="email" name="respondent-email-address" value={emailAddress} onChange={onChangeEmailAddressInput} errors={errors?.emailAddress ?? false} />
            <Input label="Respondent role*" 
                type="select" 
                name="respondent-email-address" 
                value={respondentTypeValue} 
                options={respondentTypes ? respondentTypes.map(respondentType => {
                    return {
                        value: respondentType.value,
                        name: respondentType.name
                    }
                } ) : null} 
                loading={!respondentTypes} 
                size="small" 
                onChange={onChangeRespondentTypeSelect}
                errors={errors ? errors[`Respondents[${index}].RespondentType`] : false} />
            
            { index !== 0 ? <div className="align-bottom"><button className="btn small" type="button" onClick={onClickRemoveRespondentBtn}>Remove</button></div> : null }
        </InputRow>
    );
}

export default function AddRespondent({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [respondentTypes, setRespondentTypes] = useState(false);

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [cohort, setCohort] = useState(false);
    const [survey, setSurvey] = useState(false);

    const [respondents, setRespondents] = useState([{firstName: '', lastName: '', emailAddress: '', respondentType: '', languageId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'}]);
    const [respondentsErrors, setRespondentsErrors] = useState(false);

    const [formChange, setFormChange] = useState(false);

    const [redirect, setRedirect] = useState(false);

    const {clientId, programmeId, cohortId, surveyId} = useParams();

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchCohort = useCallback((clientId, programmeId, cohortId) => {
        getCohort(clientId, programmeId, cohortId).then(response => {
            if (response.success) setCohort(response);
        });
    }, []);

    const fetchSurvey = useCallback(surveyId => {
        getSurvey(surveyId).then(response => {
            if (response.success) setSurvey(response);
        });
    }, []);

    const fetchRespondentTypes = useCallback(() => {
        getRespondentTypes().then(response => {
            setRespondentTypes(response);
        });
    }, []);

    useEffect(() => {
        document.title = `Add Respondent - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchCohort(clientId, programmeId, cohortId);
    }, [fetchCohort, clientId, programmeId, cohortId]);

    useEffect(() => {
        fetchSurvey(surveyId);
    }, [fetchSurvey, surveyId]);

    useEffect(() => {
        fetchRespondentTypes();
    }, [fetchRespondentTypes]);

    useEffect(() => {
        if (!programme || !cohort || !survey) return;

        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: programme.name,
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: cohort.name,
                link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`
            }, {
                text: survey.leaderName,
                link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/survey/${surveyId}`
            }, {
                text: 'Add a new respondent'
            }
        ]);
    }, [programme, cohort, survey, urlPrepend, clientId, programmeId, cohortId, surveyId, setHeaderBreadcrumbs]);

    const updateRespondent = (index, field, value, enableFormChange = true) => {
        let respondentsData = [...respondents];
        respondentsData[index][field] = value;
        setRespondents(respondentsData);
        
        if (enableFormChange) setFormChange(true);
    };

    const removeRespondent = index => {
        setRespondents(respondents.filter((respondent, respondentIndex) => respondentIndex !== index));
    };

    const onClickAddAnotherRespondentBtn = () => {
        setRespondents(respondents.concat({firstName: '', lastName: '', emailAddress: '', respondentType: '', languageId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'}));
    };

    const onClickSaveToLeaderBtn = () => {
        setLoading(true);
        setRespondentsErrors(false);
        setFormErrors(false);

        createRespondents(surveyId, { respondents }).then(response => {
            if (response.success) {
                setFormChange(false);
                setRedirect(`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/survey/${surveyId}`);
            } else {
                let responseRespondentsErrors = [];

                respondents.forEach((respondent, index) => {
                    responseRespondentsErrors.push({ 
                        firstName: response.errors[`Respondents[${index}].FirstName`] ? response.errors[`Respondents[${index}].FirstName`] : false,
                        lastName: response.errors[`Respondents[${index}].LastName`] ? response.errors[`Respondents[${index}].LastName`] : false,
                        emailAddress: response.errors[`Respondents[${index}].EmailAddress`] ? response.errors[`Respondents[${index}].EmailAddress`] : false,
                    });
                });

                setRespondentsErrors(responseRespondentsErrors);

                let errors = Object.assign({}, response.errors);

                delete errors.ClientName;

                respondents.forEach((contact, index) => {
                    delete errors[`Respondents[${index}].FirstName`];
                    delete errors[`Respondents[${index}].LastName`];
                    delete errors[`Respondents[${index}].EmailAddress`];
                });

                setFormErrors(errors);
            }

            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    };

    return (
        <div>
            <AdminInfoBar>
                { client?.name && programme?.name ? 
                    <div>
                        <h3>{client.name}</h3>
                        <div>{programme.name}</div>
                    </div>
                : null }
                { cohort ?
                    <div>
                        <h3>Cohort:</h3>
                        <div>{cohort.name}</div>
                    </div>
                : null }
                { survey?.leaderName ?
                    <div>
                        <h3>Leader:</h3>
                        <div>{survey.leaderName}</div>
                    </div>
                : null }
            </AdminInfoBar>

            <div className="admin-container container-padding-lg">
                <AdminTop header="Add a new respondent" />
                <AdminCard className={`add-respondent-card ${loading ? 'loading' : ''}`}>
                    {loading ? <div className="loading-circle"></div> : null}

                    <div className="add-respondent-card-inner">
                        <AdminCardBody>
                            { formErrors ? <ErrorMessage message={formErrors} /> : null }

                            { respondents?.length ?
                                respondents.map((respondent, index) => {
                                    return (
                                        <AddRespondentRow key={index} respondent={respondent} index={index} updateRespondent={updateRespondent} removeRespondent={removeRespondent} respondentTypes={respondentTypes} errors={respondentsErrors[index]} />
                                    );
                                })
                            : null }
                            
                            <div className="add-another-respondent-btn-wrap">
                                <button className="btn blue" type="button" onClick={onClickAddAnotherRespondentBtn}><PlusIcon /><div>Add Another Respondent</div></button>
                            </div>
                        </AdminCardBody>
                    </div>
                </AdminCard>
            </div>

            <AdminActionBar>
                <div></div>
                <div>
                    <div><Link className="btn white-border" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/survey/${surveyId}`}>Cancel</Link></div>
                    <div><button className="btn" type="button" onClick={onClickSaveToLeaderBtn}>Save to Leader</button></div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>These respondents have not been saved.</p>
            </AdminLeavingPageModal>

            { redirect ? <Redirect to={redirect} /> : null }
        </div>
    )
}
