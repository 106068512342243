import React from 'react';
import { Link } from 'react-router-dom';

import AdminStatusLabel from '../AdminStatusLabel/AdminStatusLabel';

import PencilIcon from '../../Icons/PencilIcon';
import ChevronLeft from '../../Icons/ChevronLeft';

import './AdminTop.scss';

export default function AdminTop({labelText = null, labelColour = null, header = null, editTo = null, backLink = null, backLinkText = null, children}) {
    return (
        <div className="admin-top">
            { backLink ? <div className="back-link"><Link to={backLink}><ChevronLeft className="icon" /><div className="text">{ backLinkText ? backLinkText : 'Back' }</div></Link></div> : null }
            <div>
                { labelText && labelColour ? <AdminStatusLabel text={labelText} colour={labelColour} /> : null }
                { header ? <div><h1 className="header">{header}</h1></div> : null }
                { editTo ? <div className="edit-btn"><Link to={editTo}><PencilIcon className="icon" /></Link></div> : null }
            </div>
            <div className="btn-wrap">
                {children}
            </div>
        </div>
    );
}
