import React from 'react';

import './QuestionnaireHeader.scss';

import logo from '../../images/logo.svg';

export default function QuestionnaireHeader({ productType, surveyName, clientName, teamName, isLeader, leaderName, respondentName }) {
    return (
        <header className={`questionnaire-site-header ${productType === 1 ? 'leadership-360' : ''} ${productType === 2 ? 'team-diagnostics' : ''}`}>
            <div className="questionnaire-site-header-container">
                <img className="logo" src={logo} alt="Holos" />
            </div>
            
            { productType === 1 && ( surveyName || clientName || leaderName || respondentName ) ?
                <div className="header container-lg container-padding-lg">
                    { surveyName ? 
                        <h1 className="survey-title">{surveyName}</h1> 
                    : null }

                    { clientName ? 
                        <h2 className="survey-name">{clientName}</h2> 
                    : null }

                    { isLeader && leaderName ? 
                        <div className="names">Review for <strong>{leaderName}</strong></div> 
                    : null } 

                    { !isLeader && respondentName && leaderName ? 
                        <div className="names">
                            <strong>{respondentName}</strong> reviewing <strong>{leaderName}</strong>
                        </div> 
                    : null }
                </div>
            : null }
            
            { productType === 2 && ( surveyName || clientName || teamName || leaderName ) ?
                <div className="header container-lg container-padding-lg">
                    { surveyName ? 
                        <h1 className="survey-title">{surveyName}</h1> 
                    : null }

                    { clientName ? 
                        <h2 className="survey-name">{clientName}</h2> 
                    : null }

                    { teamName ? 
                        <div className="names"><strong>{teamName}</strong></div> 
                    : null } 
                </div>
            : null }
        </header>
    )
}
