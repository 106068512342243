import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Store } from '../../Store';
import { getSurveyTypes } from '../../api';
import jwt_decode from 'jwt-decode';

import AdminHeader from './AdminHeader/AdminHeader';

import './Admin.scss';

export default function Admin({productType, productName, productClass, urlPrepend, routes}) {
    const location = useLocation();

    const { state, dispatch } = useContext(Store);
    const { testSite } = state;

    const [roles, setRoles] = useState(false);
    const [headerBreadcrumbs, setHeaderBreadcrumbs] = useState();

    const decodeToken = useCallback(() => {
        if (!localStorage.getItem('REACT_TOKEN_AUTH_KEY')) return;

        const tokenDecode = jwt_decode(JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY')).accessToken);

        setRoles(tokenDecode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);

        dispatch({
            type: 'SET_USER_NAME',
            payload: tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
        });
    }, [dispatch]);

    const fetchSurveyTypes = useCallback(() => {
        if (!productType) {
            return;
        }

        getSurveyTypes(productType).then(response => {
            dispatch({
                type: 'SET_SURVEY_TYPES',
                payload: response
            });
        });
    }, [dispatch, productType]);

    useEffect(() => {
        fetchSurveyTypes();
    }, [fetchSurveyTypes]);

    useEffect(() => {
        decodeToken();
    }, [decodeToken]);

    useEffect(() => {
        dispatch({
            type: 'SET_LAST_ADMIN_PAGE',
            payload: window.location.pathname + window.location.search
        });
    }, [location, dispatch]);

    useEffect(() => {
        document.querySelector('html').classList.add('admin');

        return () => {
            document.querySelector('html').classList.remove('admin');
        }
    }, [location, dispatch]);

    return (
        <div>
            { testSite ? 
                <div className="staging-site-warning">
                    This is a demonstration version only. Please use <a href="https://digitaltools.holoschange.com">digitaltools.holoschange.com</a> for live data.
                </div>
            : null }

            <AdminHeader headerBreadcrumbs={headerBreadcrumbs} roles={roles} productName={productName} productClass={productClass} urlPrepend={urlPrepend} />

            <main className="admin-main-content">
                <Switch>
					{ routes ? routes.map(({ path, Component }) => (
						<Route key={path} path={path} exact>
                            <Component setHeaderBreadcrumbs={setHeaderBreadcrumbs} productType={productType} productName={productName} productClass={productClass} urlPrepend={urlPrepend} />
						</Route>
					)) : null }
                </Switch>
            </main>
        </div>
    );
}
