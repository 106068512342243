import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, Link, Redirect } from 'react-router-dom';
import { getClient, getProgramme, getTeam, createRespondents, getRespondentTypes } from '../../../../../api';

import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import Input, { InputRow } from '../../../../Input/Input';
import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';
import AdminContainer from '../../../AdminContainer/AdminContainer';

import PlusIcon from '../../../../Icons/PlusIcon';

import './AddTeamMembers.scss';

function AddTeamMemberRow({respondent, index = -1, updateRespondent = () => {}, removeTeamMember, respondentTypes, errors, readonly = false}) {
    const [firstName, setFirstName] = useState(respondent.firstName);
    const [lastName, setLastName] = useState(respondent.lastName);
    const [emailAddress, setEmailAddress] = useState(respondent.emailAddress);
    const [respondentTypeValue, setRespondentTypeValue] = useState(false);

    useEffect(() => {
        setFirstName(respondent.firstName);
        setLastName(respondent.lastName);
        setEmailAddress(respondent.emailAddress);
        
        if (!respondentTypeValue && respondentTypes?.length) {
            setRespondentTypeValue(respondentTypes[0].value);
            updateRespondent(index, 'respondentType', respondentTypes[0].value, false);
        }
    }, [respondent, respondentTypes, index, respondentTypeValue, updateRespondent]);

    const onChangeFirstNameInput = e => {
        setFirstName(e.target.value);
        updateRespondent(index, 'firstName', e.target.value);
    };

    const onChangeLastNameInput = e => {
        setLastName(e.target.value);
        updateRespondent(index, 'lastName', e.target.value);
    };

    const onChangeEmailAddressInput = e => {
        setEmailAddress(e.target.value);
        updateRespondent(index, 'emailAddress', e.target.value);
    };

    const onChangeRespondentTypeSelect = e => {
        setRespondentTypeValue(e.target.value);
        updateRespondent(index, 'respondentType', parseInt(e.target.value));
    }

    const onClickremoveTeamMemberBtn = () => {
        removeTeamMember(index);
    };

    return (
        <InputRow className="add-team-member-row">
            <Input label={ index === -1 ? 'Respondent first name*' : ''} type="text" name={`respondent-first-name-${index}`} value={firstName} size="small" onChange={onChangeFirstNameInput} errors={errors?.firstName ?? false} readonly={readonly} />
            <Input label={ index === -1 ? 'Respondent last name*' : ''} type="text" name={`respondent-last-name-${index}`} value={lastName} size="small" onChange={onChangeLastNameInput} errors={errors?.lastName ?? false} readonly={readonly} />
            <Input label={ index === -1 ? 'Respondent email address*' : ''} type="email" name={`respondent-email-address-${index}`} value={emailAddress} onChange={onChangeEmailAddressInput} errors={errors?.emailAddress ?? false} readonly={readonly} />

            { readonly ?
                <Input label="Respondent role*" type="email" name={`respondent-role-${index}`} value="Leader" size="small" readonly={readonly} />
            : null }

            { !readonly ?
                <Input type="select" name={`respondent-role-${index}`} value={respondentTypeValue} 
                    options={respondentTypes ? respondentTypes.map(respondentType => {
                        return {
                            value: respondentType.value,
                            name: respondentType.name
                        }
                    } ) : null} 
                    loading={!respondentTypes} size="small" onChange={onChangeRespondentTypeSelect}
                    errors={errors ? errors[`Respondents[${index}].RespondentType`] : false} />
            : null }
            
            { index !== 0 && index !== -1 ? <div><button className="btn small" type="button" onClick={onClickremoveTeamMemberBtn}>Remove</button></div> : null }
        </InputRow>
    );
}

export default function AddTeamMembers({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const location = useLocation();
    const params = useMemo(()=> new URLSearchParams(location.search), [location] );

    const addRespondentCardFormSubmit = useRef();

    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [respondentTypes, setRespondentTypes] = useState(false);

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [team, setTeam] = useState(false);

    const [teamMembers, setTeamMembers] = useState([{firstName: '', lastName: '', emailAddress: '', respondentType: '', languageId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'}]);
    const [teamMembersErrors, setTeamMembersErrors] = useState(false);

    const [formChange, setFormChange] = useState(false);

    const [redirect, setRedirect] = useState(false);

    const [pageLoading, setPageLoading] = useState(true);

    const [previousLink, setPreviousLink] = useState();

    const {clientId, programmeId, teamId} = useParams();

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchTeam = useCallback((clientId, programmeId, teamId) => {
        getTeam(clientId, programmeId, teamId).then(response => {
            if (response.success) setTeam(response);
        });
    }, []);

    const fetchRespondentTypes = useCallback(() => {
        getRespondentTypes().then(response => {
            setRespondentTypes(response);
        });
    }, []);

    useEffect(() => {
        document.title = `Add Team Member - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchTeam(clientId, programmeId, teamId);
    }, [fetchTeam, clientId, programmeId, teamId]);

    useEffect(() => {
        fetchRespondentTypes();
    }, [fetchRespondentTypes]);

    useEffect(() => {
        if (!programme || !team) return;

        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: programme.name,
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: team.name,
                link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}`
            }, {
                text: 'Add a Team Member'
            }
        ]);
    }, [programme, team, urlPrepend, clientId, programmeId, teamId, setHeaderBreadcrumbs]);

    useEffect( () => {
        if (team && teamMembers.length) {
            setPageLoading(false);
        }
    }, [team, teamMembers] );
    
    useEffect( () => {
        console.log(params.get('previousLink'));
        if ( params.get('previousLink') ) {
            let currentLinkUrl = new URL(window.location.href);
            
            let previousLinkUrl = new URL(params.get('previousLink'), window.location.origin);
            let previousLinkSearchParams = previousLinkUrl.searchParams;

            previousLinkSearchParams.set('nextLink', currentLinkUrl.toString().replace(window.location.origin, ''));
            previousLinkSearchParams.set('nextLinkText', 'Next Step: Add a Team Member');
            
            setPreviousLink( previousLinkUrl.toString().replace(window.location.origin, '') );
        }
    }, [params] );

    const updateRespondent = (index, field, value, enableFormChange = true) => {
        let teamMembersData = [...teamMembers];
        teamMembersData[index][field] = value;
        setTeamMembers(teamMembersData);
        
        if (enableFormChange) setFormChange(true);
    };

    const removeTeamMember = index => {
        setTeamMembers(teamMembers.filter((teamMember, teamMemberIndex) => teamMemberIndex !== index));
    };

    const onClickAddAnotherTeamMemberBtn = () => {
        setTeamMembers(teamMembers.concat({firstName: '', lastName: '', emailAddress: '', respondentType: '', languageId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'}));
    };

    const onClickSaveToTeamBtn = () => {
        if (!addRespondentCardFormSubmit?.current) return;

        addRespondentCardFormSubmit.current.click();
    };

    const submitAddRespondentForm = () => {
        setLoading(true);
        setTeamMembersErrors(false);
        setFormErrors(false);

        createRespondents(team.surveyId, { respondents: teamMembers }).then(response => {
            if (response.success) {
                setFormChange(false);
                setRedirect(`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}`);
            } else {
                let responseTeamMembersErrors = [];

                teamMembers.forEach((teamMember, index) => {
                    responseTeamMembersErrors.push({ 
                        firstName: response.errors[`Members[${index}].FirstName`] ? response.errors[`Members[${index}].FirstName`] : false,
                        lastName: response.errors[`Members[${index}].LastName`] ? response.errors[`Members[${index}].LastName`] : false,
                        emailAddress: response.errors[`Members[${index}].EmailAddress`] ? response.errors[`Members[${index}].EmailAddress`] : false,
                    });
                });

                setTeamMembersErrors(responseTeamMembersErrors);

                let errors = Object.assign({}, response.errors);

                delete errors.ClientName;

                teamMembers.forEach((contact, index) => {
                    delete errors[`Members[${index}].FirstName`];
                    delete errors[`Members[${index}].LastName`];
                    delete errors[`Members[${index}].EmailAddress`];
                });

                setFormErrors(errors);
            }

            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    };

    const onSubmitAddRespondentForm = e => {
        e.preventDefault();

        submitAddRespondentForm();
    }

    return (
        <div>
            <AdminInfoBar>
                { client?.name && programme?.name ? 
                    <div>
                        <h3>{client.name}</h3>
                        <div>{programme.name}</div>
                    </div>
                : null }
                { team ?
                    <div>
                        <h3>Team:</h3>
                        <div>{team.name}</div>
                    </div>
                : null }
            </AdminInfoBar>

            <AdminContainer pageLoading={pageLoading}>
                <AdminTop header="Add a Team Member" />
                <AdminCard className={`add-respondent-card ${loading ? 'loading' : ''}`}>
                    {loading ? <div className="loading-circle"></div> : null}

                    <div className="add-respondent-card-inner">
                        <form onSubmit={onSubmitAddRespondentForm}>
                            <AdminCardBody>
                                { formErrors ? <ErrorMessage message={formErrors} /> : null }

                                { team ?
                                    <AddTeamMemberRow respondent={{ firstName: team.leaderFirstName, lastName: team.leaderLastName, emailAddress: team.leaderEmailAddress }} respondentTypes={respondentTypes} readonly={true} />
                                : null }

                                { teamMembers?.length ?
                                    teamMembers.map((respondent, index) => {
                                        return (
                                            <AddTeamMemberRow key={index} respondent={respondent} index={index} updateRespondent={updateRespondent} removeTeamMember={removeTeamMember} respondentTypes={respondentTypes} errors={teamMembersErrors[index]} />
                                        );
                                    })
                                : null }
                                
                                <div className="add-another-respondent-btn-wrap">
                                    <button className="btn blue" type="button" onClick={onClickAddAnotherTeamMemberBtn}><PlusIcon /><div>Add Another Team Member</div></button>

                                    <button ref={addRespondentCardFormSubmit} type="submit"></button>
                                </div>
                            </AdminCardBody>
                        </form>
                    </div>
                </AdminCard>
            </AdminContainer>

            <AdminActionBar>
                <div>
                    { previousLink ? <div><Link className="btn white-border" to={previousLink}>Previous</Link></div> : null }
                    { !previousLink ? <div><Link className="btn white-border" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}`}>Cancel</Link></div> : null }
                </div>
                <div>
                    <div><button className="btn" type="button" onClick={onClickSaveToTeamBtn}>Save to Team</button></div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>These team members have not been saved.</p>
            </AdminLeavingPageModal>

            { redirect ? <Redirect to={redirect} /> : null }
        </div>
    )
}
