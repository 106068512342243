import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { isValid, parseISO, format, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
    getProgramme,
    getTeamMembers,
    updateLeaderEmailAddress,
    updateRespondent,
    updateRespondentEmailAddress,
    revokeRespondent,
    unrevokeRespondent,
    generateReport,
    sendRespondentReminder,
    sendReport, 
    getRespondentTypes
} from '../../../../../api';
import { Store } from '../../../../../Store';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import AdminTable, { AdminTableBody, AdminTableHead, AdminTableActionsCell } from '../../../AdminTable/AdminTable';
import AdminStatusLabel from '../../../AdminStatusLabel/AdminStatusLabel';
import Input from '../../../../Input/Input';
import ToolTip from '../../../../ToolTip/ToolTip';
import AdminModal, { AdminModalBtns } from '../../../AdminModal/AdminModal';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';

import CheckIcon from '../../../../Icons/CheckIcon';
import PlusIcon from '../../../../Icons/PlusIcon';
import PlusCircleIcon from '../../../../Icons/PlusCircleIcon';
import CancelIcon from '../../../../Icons/CancelIcon';
import MailIcon from '../../../../Icons/MailIcon';
import BellIcon from '../../../../Icons/BellIcon';
import PencilIcon from '../../../../Icons/PencilIcon';

import './Team.scss';

function TeamTableRow({teamMember, surveyId}) {
    const [loading, setLoading] = useState(false);
    const [revoked, setRevoked] = useState(teamMember?.revoked);
    const [showEdit, setShowEdit] = useState(teamMember?.emailBounced);
    const [showRespondentEdit, setShowRespondentEdit] = useState(false);

    const [emailAddressValue, setEmailAddressValue] = useState(teamMember?.emailAddress ?? '');
    const [emailAddressErrors, setEmailAddressErrors] = useState(false);

    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [respondentTypeValue, setRespondentTypeValue] = useState(0);

    const [reSendSurveyLoading, setReSendSurveyLoading] = useState(false);
    const [showSendTeamMemberReminderModal, setShowSendTeamMemberReminderModal] = useState(false);

    const [respondentTypes, setRespondentTypes] = useState(false);

    const fetchRespondentTypes = useCallback(() => {
        getRespondentTypes().then(response => {
            setRespondentTypes(response);
        });
    }, []);

    const onChangeEmailAddressInput = e => {
        setEmailAddressValue(e.target.value);
    };

    const updateTeamMemberEmailAddress = () => {
        setLoading(true);
        setEmailAddressErrors(false);

        const thenResponse = response => {
            if (response.success) {
                setShowEdit(false);
            } else {
                setEmailAddressErrors(Object.values(response.errors));
            }

            setLoading(false);
            console.log(response);
        };
        
        if (teamMember?.role === 'Leader') {
            updateLeaderEmailAddress(surveyId, {
                emailAddress: emailAddressValue
            }).then(thenResponse).catch(() => {
                setLoading(false);
            });
        } else {
            updateRespondentEmailAddress(surveyId, teamMember.id, {
                emailAddress: emailAddressValue
            }).then(thenResponse).catch(() => {
                setLoading(false);
            });
        }
    };

    const onSubmitTeamMemberEmailAddressForm = e => {
        e.preventDefault();

        updateTeamMemberEmailAddress();
    };

    const onClickResendInviteBtn = () => {
        updateTeamMemberEmailAddress();
    };

    const onClickReSendSurveyBtn = () => {
        setShowSendTeamMemberReminderModal(true);
    };

    const onClickReSendSurveyConfirmBtn = () => {
        setReSendSurveyLoading(true);

        sendRespondentReminder(surveyId, teamMember.id).then(response => {
            if (response.success) setShowSendTeamMemberReminderModal(false);
        }).finally(() => {
            setReSendSurveyLoading(false);
        });
    };

    const onClickRevokeBtn = () => {
        setLoading(true);

        revokeRespondent(surveyId, teamMember.id).then(response => {
            console.log(response);

            if (response.success) {
                setRevoked(true);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onClickReinstateBtn = () => {
        setLoading(true);

        unrevokeRespondent(surveyId, teamMember.id).then(response => {
            console.log(response);

            if (response.success) {
                setRevoked(false);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const onClickEditRespondentBtn = () => {
        setShowRespondentEdit(!showRespondentEdit);
    }

    const onClickUpdateRespondentsBtn = () => {
        updateRespondent(surveyId, teamMember.id, {
            firstName: firstNameValue,
            lastName: lastNameValue,
            respondentType: parseInt(respondentTypeValue)
        }).then(response => {
            console.log(response);
        });
    }

    useEffect(() => {
        fetchRespondentTypes();
    }, [fetchRespondentTypes]);

    return (
        <tr className={`admin-table-row ${loading ? 'loading' : ''} ${revoked ? 'revoked' : ''}`}>
            <td className="name-cell">
                {showRespondentEdit ?
                    <form>
                        <Input type="text" 
                            size="small"
                            name="first-name" 
                            value={firstNameValue} 
                            placeholder="First Name" 
                            onChange={e => setFirstNameValue(e.target.value)} />

                        <Input type="text" 
                            size="small"
                            name="last-name" 
                            value={lastNameValue} 
                            placeholder="Last Name" 
                            onChange={e => setLastNameValue(e.target.value)} />
                    </form>
                : null}

                {!showRespondentEdit ?
                    <>
                        { teamMember?.name ?? '' }
                        { teamMember?.function ? <div className="team-member-function">{teamMember.function}</div> : '' }
                    </>
                : null}
            </td>
            <td className="email-address-cell">
                <div className="cell-icon-wrap">
                    { !showEdit ? <div>{emailAddressValue}</div> : null }
                    { showEdit ? <form className="full-width" onSubmit={onSubmitTeamMemberEmailAddressForm}><Input type="email" value={emailAddressValue} size="full" onChange={onChangeEmailAddressInput} errors={emailAddressErrors} /></form> : null }
                    { teamMember?.emailBounced ? <div className="warning">!</div> : null }
                </div>
            </td>
            <td className="role-cell">
                {showRespondentEdit ?
                    <form>
                        <Input
                            type="select"
                            name="respondent-role" 
                            value={respondentTypeValue} 
                            options={respondentTypes ? respondentTypes.map(respondentType => {
                                return {
                                    value: respondentType.value,
                                    name: respondentType.name
                                }
                            } ) : null} 
                            loading={!respondentTypes}
                            size="small"
                            onChange={e => setRespondentTypeValue(e.target.value)} />
                    </form>
                : null}

                {!showRespondentEdit ?
                    teamMember?.role === 'Leader' ? <AdminStatusLabel text="Leader" colour="blue" /> : teamMember?.role
                : null}
            </td>
            <td className="survey-sent-cell">
                <div className="cell-icon-wrap">
                    <div>
                        { teamMember?.surveySent ? 
                            <>
                                <div>{format(utcToZonedTime(parseISO(teamMember.surveySent), 'UTC'), 'dd MMM yy')}</div>
                                <div>{format(utcToZonedTime(parseISO(teamMember.surveySent), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                            </>
                        : null }
                    </div>
                    { teamMember?.remindersSent?.length ?
                        <ToolTip className="alert date" toolTipText={teamMember.remindersSent.map((date, index) => isValid(parseISO(date)) ? `${format(utcToZonedTime(parseISO(date), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong>: Reminder ${index + 1} received` : false )}>
                            <BellIcon />
                            <div className="overlay-no">{teamMember.remindersSent.length}</div>
                        </ToolTip>
                    : null }
                </div>
            </td>
            <td className="status-cell">
                <div className="status-percentage">
                    <div className="status-bar">
                        <div style={{width: `${teamMember.progress ? Math.round(teamMember.progress) : 0}%`}}></div>
                    </div>
                    <div className="percentage">{teamMember.progress ? Math.round(teamMember.progress) : 0}%</div>
                </div>
            </td>
            <td className="survey-completed-cell">
                { !teamMember?.surveyCompleted ? <div className="cell-dash"></div> : null }
                { teamMember?.surveyCompleted ? 
                    <>
                        <div>{format(utcToZonedTime(parseISO(teamMember.surveyCompleted), 'UTC'), 'dd MMM yy')}</div>
                        <div>{format(utcToZonedTime(parseISO(teamMember.surveyCompleted), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                    </> 
                : null }
            </td>
            <td className="included-in-report-cell">
                { !teamMember?.includedInReport ? <div className="cell-dash"></div> : null }
                { teamMember?.includedInReport ? <div className="icon"><CheckIcon /></div> : null }
            </td>
            <AdminTableActionsCell>
                { teamMember?.emailBounced ?
                    <div>
                        <button className={`btn small ${!showEdit ? 'hide' : ''}`} type="button" onClick={onClickResendInviteBtn}>
                            Re-Send {teamMember?.role === 'Leader' ? 'Invite' : 'Survey'}
                        </button>
                    </div>
                : null }

                {showRespondentEdit ?
                    <div>
                        <button className="btn small"
                            type="button"
                            onClick={onClickUpdateRespondentsBtn}>
                            
                            Update
                        </button>
                    </div>
                : null}

                <div className="admin-table-actions-btn-wrap" style={{ display: 'none' }}>
                    <button type="button" onClick={onClickEditRespondentBtn}>
                        <ToolTip toolTipText="Edit Respondent">
                            <PencilIcon className="icon" />
                        </ToolTip>
                    </button>
                </div>

                <div className="admin-table-actions-btn-wrap">
                    <button type="button" onClick={onClickReSendSurveyBtn}>
                        <ToolTip toolTipText="Re-Send Survey Link">
                            <MailIcon className="icon" />
                        </ToolTip>
                    </button>

                    <AdminModal className={`resend-survey-modal ${reSendSurveyLoading ? 'loading' : ''}`} show={showSendTeamMemberReminderModal} closeModal={() => setShowSendTeamMemberReminderModal(false)}>
                        {reSendSurveyLoading ? <div className="loading-circle"></div> : null}

                        <div className="resend-survey-modal-inner">
                            {teamMember?.role === 'Leader' ? <>
                                <h2>Re-send the nomination invite link</h2>

                                <p>Are you sure you want to re-send the <br />nomination invite link to {teamMember?.name}</p>
                            </> : null }

                            {teamMember?.role !== 'Leader' ? <>
                                <h2>Re-send the survey link</h2>

                                <p>Are you sure you want to re-send the <br />survey link to {teamMember?.name}</p>
                            </> : null }

                            <AdminModalBtns>
                                <button className="btn" type="button" onClick={() => setShowSendTeamMemberReminderModal(false)}>Close</button>
                                <button className="btn blue" type="button" onClick={onClickReSendSurveyConfirmBtn}>
                                    Re-Send {teamMember?.role === 'Leader' ? 'Invite' : 'Survey'}
                                </button>
                            </AdminModalBtns>
                        </div>
                    </AdminModal>
                </div>
                <div>
                    {!revoked ? 
                        <button type="button" onClick={onClickRevokeBtn}>
                            <ToolTip toolTipText="Ignore">
                                <CancelIcon className="icon" />
                            </ToolTip>
                        </button>
                    : null }
                    {revoked ? 
                        <button type="button" onClick={onClickReinstateBtn}>
                            <ToolTip toolTipText="Include">
                                <PlusCircleIcon className="icon light-blue" />
                            </ToolTip>
                        </button>
                    : null }
                </div>
            </AdminTableActionsCell>
        </tr>
    );
}

export default function Team({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const { state } = useContext(Store);
    const { statuses } = state;

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [loadingTeamMembers, setLoadingTeamMembers] = useState(true);
    const [team, setTeam] = useState(false);
    const [programme, setProgramme] = useState(false);

    const [reGenerateReportLoading, setReGenerateReportLoading] = useState(false);
    const [generateReportLoading, setGenerateReportLoading] = useState(false);
    const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
    const [showGenerateReportSuccessModal, setShowGenerateReportSuccessModal] = useState(false);

    const [sendReportLoading, setSendReportLoading] = useState(false);
    const [showSendReportModal, setShowSendReportModal] = useState(false);
    const [sendReportSuccess, setSendReportSuccess] = useState(false);
    const [sendReportError, setSendReportError] = useState(false);

    const {clientId, programmeId, teamId} = useParams();

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchTeam = useCallback((clientId, programmeId, teamId) => {
        getTeamMembers(clientId, programmeId, teamId).then(response => {
            if (response.success) {
                setTeam(response);
            } else {
                setPageError(response.errors);
            }
            
            setLoadingTeamMembers(false);
            setPageLoading(false);
        });
    }, []);

    useEffect(() => {
        document.title = `Team - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchTeam(clientId, programmeId, teamId);
    }, [fetchTeam, clientId, programmeId, teamId]);

    useEffect(() => {
        if (!team) return;

        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: team.programmeName,
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: team.name
            }
        ]);
    }, [setHeaderBreadcrumbs, urlPrepend, clientId, programmeId, team]);

    const onClickGenerateReportBtn = () => {
        if (team.totalCompleted === team.totalRespondents) {
            setReGenerateReportLoading(true);
            
            generateReport(team.surveyId).then(response => {
                if (response.success) setShowGenerateReportSuccessModal(true);
    
                setReGenerateReportLoading(false);
            }).catch(() => {
                setReGenerateReportLoading(false);
            });
        } else {
            setShowGenerateReportModal(true);
        }
    };

    const onClickGenerateReportConfirmBtn = () => {
        setGenerateReportLoading(true);

        generateReport(team.surveyId).then(response => {
            if (response.success) {
                setShowGenerateReportModal(false);
                setShowGenerateReportSuccessModal(true);
            }

            setGenerateReportLoading(false);
        }).catch(() => {
            setGenerateReportLoading(false);
        });
    };

    const onClickSendReportToLeaderBtn = () => {
        setSendReportLoading(false);
        setSendReportSuccess(false);
        setSendReportError(false);
        setShowSendReportModal(true);
    };

    const onClickSendReportConfirmBtn = () => {
        setSendReportLoading(true);

        sendReport(team.surveyId).then(response => {
            if (response.success) {
                setSendReportSuccess(true);
            }
        }).catch(err => {
            if (err?.response?.data?.errors) {
                setSendReportError(err.response.data.errors);
            }
        }).finally(() => {
            setSendReportLoading(false);
        });
    };

    return (
        <AdminContainer pageError={pageError} pageLoading={pageLoading}>
            <AdminTop labelText={team.status && statuses?.length ? statuses.filter(status => status.id === team.status)[0]?.text : false} 
                labelColour={team.status && statuses?.length ? statuses.filter(status => status.id === team.status)[0]?.colour : false} 
                header={team?.name} editTo={`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}/edit`}
                backLink={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}
                backLinkText="Back to Programme">

                { team?.reportLink ?
                    <div>
                        <div className="team-view-report">
                            { team?.reportGeneratedOn && isValid(parseISO(team.reportGeneratedOn)) ?
                                <div>
                                    <div className="team-view-report-generated-on">Generated {format(utcToZonedTime(parseISO(team.reportGeneratedOn), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                            <div>
                                <a className="btn blue" href={team.reportLink} target="_blank" rel="noopener noreferrer">View Completed Report</a>
                            </div>
                        </div>
                    </div>
                : null }

                { team?.reportLink ?
                    <div>
                        <button className="btn" type="button" onClick={onClickSendReportToLeaderBtn}>Send Report to Leader</button>
                    </div>
                : null }

                { programme?.onlyManualEntry && team?.surveyDeadline && isBefore(new Date(), parseISO(team.surveyDeadline)) ?
                    <div>
                        <Link className="btn blue" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}/add-team-members`}><PlusIcon /><div>Add Team Members</div></Link>
                    </div>
                : null }
            </AdminTop>

            <AdminCard>
                <AdminCardBody>
                    <div className="info-panel">
                        <div>
                            { team?.clientName && team?.programmeName ? 
                                <div>
                                    <h3>{team.clientName}</h3>
                                    <div><Link to={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}>{team.programmeName}</Link></div>
                                </div>
                            : null }
                            { team?.agentName ?
                                <div>
                                    <h3>Holos Team Lead:</h3>
                                    <div>{team.agentName}</div>
                                </div>
                            : null }
                            { team?.nominationInviteDate ?
                                <div>
                                    <h3>Invitation Date:</h3>
                                    <div>{format(utcToZonedTime(parseISO(team.nominationInviteDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                            { team?.nominationsDeadline ?
                                <div>
                                    <h3>Nomination Deadline:</h3>
                                    <div>{format(utcToZonedTime(parseISO(team.nominationsDeadline), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                            { team?.surveyDeadline ?
                                <div>
                                    <h3>Completion Deadline:</h3>
                                    <div>{format(utcToZonedTime(parseISO(team.surveyDeadline), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                            { team?.reportSentDate ?
                                <div>
                                    <h3>Report sent:</h3>
                                    <div>{format(utcToZonedTime(parseISO(team.reportSentDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                        </div>
                        <div>
                            { team?.totalCompleted !== null || team?.reportLink ?
                                <div>
                                    <div className={`generate-report-btn ${reGenerateReportLoading ? 'loading' : ''}`}>
                                        <button className="btn blue" 
                                            disabled={team.totalCompleted < 3 && !team?.reportLink} 
                                            onClick={onClickGenerateReportBtn}>
                                            
                                            {team?.reportLink ? 'Re-Generate Report' : 'Generate Report' }
                                        </button>
                                    </div>
                                </div>
                            : null }
                        </div>
                    </div>
                </AdminCardBody>
                <AdminCardBody className="team-members-details">
                    <div className="table-details">
                        { team?.members ? <div>Showing <strong>{team?.members.length}</strong> {team?.members.length === 1 ? 'member' : 'members'}</div> : null }
                    </div>
                    <AdminTable className="team-members-table">
                        <AdminTableHead>
                            <tr>
                                <th className="name-cell">Name</th>
                                <th className="email-address-cell">Email address</th>
                                <th className="role-cell">Role</th>
                                <th className="survey-sent-cell">Survey <br />Sent</th>
                                <th className="status-cell">Status</th>
                                <th className="survey-completed-cell">Survey <br />Complete</th>
                                <th className="included-in-report-cell">Included <br />in report</th>
                                <th className="actions-cell"></th>
                            </tr>
                        </AdminTableHead>
                            {team?.members?.length ?
                                <AdminTableBody>
                                    { team.members.map(teamMember => {
                                        return (
                                            <TeamTableRow key={teamMember.id} teamMember={teamMember} surveyId={team.surveyId} />
                                        )
                                    }) }
                                </AdminTableBody>
                            : null }
                    </AdminTable>
                    { !loadingTeamMembers && team?.members?.length === 0 ?
                        <div className="no-members">
                            You have not added any Members<br />
                            for this team
                        </div>
                    : null }
                </AdminCardBody>
            </AdminCard>
            <AdminModal className={`generate-report-modal ${generateReportLoading ? 'loading' : ''}`} show={showGenerateReportModal} closeModal={() => {
                setShowGenerateReportModal(false);
                setTimeout(() => { setGenerateReportLoading(false) }, 500);
            }}>
                {generateReportLoading ? <div className="loading-circle"></div> : null}
                
                <div className="generate-report-modal-inner">
                    <h2>Are you sure you want to generate report?</h2>

                    <p>You still only have {team?.totalCompleted} respondents but this does meet the minimum number needed to generate a report.</p>

                    <AdminModalBtns>
                        <button className="btn" type="button" onClick={() => setShowGenerateReportModal(false)}>Close</button>
                        <button className="btn blue" type="button" onClick={onClickGenerateReportConfirmBtn}>Generate Report</button>
                    </AdminModalBtns>
                </div>
            </AdminModal>
            <AdminModal show={showGenerateReportSuccessModal} closeModal={() => setShowGenerateReportSuccessModal(false)}>
                <h2>Generating Report</h2>

                <p>Your report will be emailed to you once successfully generated.</p>

                <AdminModalBtns>
                    <button className="btn blue" type="button" onClick={() => setShowGenerateReportSuccessModal(false)}>Close</button>
                </AdminModalBtns>
            </AdminModal>

            <AdminModal className={`send-report-modal ${sendReportLoading ? 'loading' : ''}`} show={showSendReportModal} closeModal={() => {
                setShowSendReportModal(false);
                setTimeout(() => { setSendReportLoading(false) }, 500);
            }}>
                {sendReportLoading ? <div className="loading-circle"></div> : null}
                
                <div className="send-report-modal-inner">
                    <h2>Send report to leader?</h2>

                    { sendReportSuccess ? <p>The report has been successfully sent to the leader.</p> : null }

                    { sendReportError ? <ErrorMessage message={sendReportError} /> : null }

                    { !sendReportSuccess && !sendReportError ? <>
                        <p>
                            Are you sure you want to send the report PDF to <br />
                            { team?.members?.filter(member => member?.role === 'Leader').length ? 
                                team?.members?.filter(member => member?.role === 'Leader')[0]?.name 
                            : 'the leader' }?
                        </p>
                        { team?.automaticReportSendDate ? <p>There is already an automatic send date set up for {format(utcToZonedTime(parseISO(team.automaticReportSendDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong>.</p> : null }
                    </> : null }

                    <AdminModalBtns>
                        <button className="btn" type="button" onClick={() => setShowSendReportModal(false)}>Close</button>
                        <button className="btn blue" type="button" disabled={sendReportSuccess} onClick={onClickSendReportConfirmBtn}>Send Report</button>
                    </AdminModalBtns>
                </div>
            </AdminModal>
        </AdminContainer>
    );
}
