import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import Input from '../../../Input/Input';
import { editAccount, getAccount, changePassword } from '../../../../api';
import { Store } from '../../../../Store';

import AdminCard, { AdminCardBody } from '../../AdminCard/AdminCard';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../../SuccessMessage/SuccessMessage';
import AdminLeavingPageModal from '../../AdminLeavingPageModal/AdminLeavingPageModal';

import './EditProfile.scss';

function EditProfileForm({ setFormChange }) {
    const { dispatch } = useContext(Store);

    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const [firstNameError, setFirstNameError] = useState();
    const [lastNameError, setLastNameError] = useState();
    const [emailAddressError, setEmailAddressError] = useState();

    const onSubmitEditProfileForm = e => {
        e.preventDefault();

        setFormLoading(true);
        setFormSuccess(false);
        setFormErrors();

        editAccount({
            firstName,
            lastName,
            emailAddress
        }).then(response => {
            if (response.errors) {
                setFirstNameError(response?.errors?.FirstName ?? false);
                setLastNameError(response?.errors?.LastName ?? false);
                setEmailAddressError(response?.errors?.EmailAddress ?? false);

                let errors = Object.assign({}, response.errors);

                delete errors.FirstName;
                delete errors.LastName;
                delete errors.EmailAddress;

                setFormErrors(errors);
            }

            if (response.success) {
                setFormSuccess('Your profile has been successfully updated.');

                dispatch({
                    type: 'SET_USER_NAME',
                    payload: `${firstName} ${lastName}`
                });

                setFormChange(false);
            }

            setFormLoading(false);
        }).catch(() => {
            setFormLoading(false);
        });
    };

    const onChangeFirstNameInput = e => {
        setFirstName(e.target.value);
        setFormChange(true);
    };

    const onChangeLastNameInput = e => {
        setLastName(e.target.value);
        setFormChange(true);
    };

    const onChangeEmailAddressInput = e => {
        setEmailAddress(e.target.value);
        setFormChange(true);
    };

    const fetchAccountDetails = () => {
        setFormLoading(true);

        getAccount().then(response => {
            if (response.success) {
                setFirstName(response.firstName);
                setLastName(response.lastName);
                setEmailAddress(response.emailAddress);
            }

            setFormLoading(false);
        }).catch(() => {
            setFormLoading(false);
        });
    };

    useEffect(() => {
        fetchAccountDetails();
    }, []);
    
    return (
        <form className="edit-profile-form" onSubmit={onSubmitEditProfileForm}>
            { formLoading ? <div className="loading-circle"></div> : null }

            { formErrors ? <ErrorMessage message={formErrors} /> : null }

            { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

            <div className={`edit-profile-form-inner ${formLoading ? 'loading' : ''}`}>
                <Input label="First name*" name="first-name" type="text" value={firstName} size="full" onChange={onChangeFirstNameInput} errors={firstNameError} />
                <Input label="Last name*" name="last-name" type="text" value={lastName} size="full" onChange={onChangeLastNameInput} errors={lastNameError} />
                <Input label="Email Address*" name="email-address" type="text" value={emailAddress} size="full" onChange={onChangeEmailAddressInput} errors={emailAddressError} />

                <button className="btn blue" type="submit">Update Profile</button>
            </div>
        </form>
    );
}

function EditPasswordForm({ setFormChange }) {
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    
    const onSubmitEditPasswordForm = e => {
        e.preventDefault();

        setFormLoading(true);
        setFormSuccess(false);
        setFormErrors();

        changePassword({
            oldPassword,
            newPassword,
            confirmPassword
        }).then(response => {
            if (response.success) {
                setFormSuccess('Your password has been successfully updated.');
                setOldPassword('');
                setNewPassword('');
                setconfirmPassword('');

                setFormChange(false);
            } else {
                setFormErrors(response.errors);
            }

            setFormLoading(false);
        }).catch(() => {
            setFormLoading(false);
        });
    };

    const onChangeOldPasswordInput = e => {
        setOldPassword(e.target.value);
        setFormChange(true);
    };

    const onChangeNewPasswordInput = e => {
        setNewPassword(e.target.value);
        setFormChange(true);
    };

    const onChangeconfirmPasswordInput = e => {
        setconfirmPassword(e.target.value);
        setFormChange(true);
    };

    return (
        <form className="edit-profile-form" onSubmit={onSubmitEditPasswordForm}>
            { formLoading ? <div className="loading-circle"></div> : null }

            { formErrors ? <ErrorMessage message={formErrors} /> : null }

            { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

            <div className={`edit-profile-form-inner ${formLoading ? 'loading' : ''}`}>
                <Input label="Old password*" name="old-password" type="password" value={oldPassword} size="full" onChange={onChangeOldPasswordInput} />
                <Input label="New password*" name="new-password" type="password" value={newPassword} size="full" onChange={onChangeNewPasswordInput} />
                <Input label="Confirm new password*" name="confirm-new-password" type="password" value={confirmPassword} size="full" onChange={onChangeconfirmPasswordInput} />

                <button className="btn blue" type="submit">Change Password</button>
            </div>
        </form>
    );
}

export default function EditProfile({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const [formChange, setFormChange] = useState(false);

    useEffect(() => {
        document.title = `Edit Profile - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        setHeaderBreadcrumbs();
    }, [setHeaderBreadcrumbs]);

    return (
        <div>
            <nav className="edit-profile-nav">
                <div className="admin-container container-padding-lg">
                    <div className="edit-profile-nav-inner">
                        <NavLink activeClassName="active" to={`${urlPrepend}/edit-profile`} exact>Profile</NavLink>
                        <NavLink to={`${urlPrepend}/edit-profile/password`} exact>Password</NavLink>
                    </div>
                </div>
            </nav>
            
            <div className="edit-profile admin-container container-padding-lg">
                <AdminCard className="edit-profile-inner">
                    <AdminCardBody>
                        <Switch>
                            <Route path={`${urlPrepend}/edit-profile`} exact>
                                <EditProfileForm setFormChange={setFormChange} />
                            </Route>
                            <Route path={`${urlPrepend}/edit-profile/password`} exact>
                                <EditPasswordForm setFormChange={setFormChange} />
                            </Route>
                        </Switch>
                    </AdminCardBody>
                </AdminCard>
            </div>

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>Your profile has not been saved.</p>
            </AdminLeavingPageModal>
        </div>
    );
}
