import React from 'react';

import './QuestionnaireCompleted.scss';

import handIllustration from '../../../images/hand-illustration.svg';
import meerkatsIllustration from '../../../images/meerkats-illustration.svg';

export default function QuestionnaireCompleted({showCompleted, productType}) {
	return (
		<div className="questionnaire-completed container-lg container-padding-lg">
			<h2 className="header">{showCompleted}</h2>

			{ productType === 1 ? <img className="illustration" src={handIllustration} alt="" /> : null }
			{ productType === 2 ? <img className="illustration" src={meerkatsIllustration} alt="" /> : null }
		</div>
	);
}
