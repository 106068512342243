import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../../../Store';
import fileDownload from 'js-file-download';

import './ReportTop.scss';

export default function ReportTop({className, children, downloadFileName}) {
    const { bearerToken } = useParams();

    const { state } = useContext(Store);
    const { apiUrl } = state;

    const [downloadingReport, setDownloadingReport] = useState(false);

    const onClickDownloadReport = () => {
        setDownloadingReport(true);

        axios.get(`${apiUrl}Reports/Leader/Download`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
            responseType: 'blob'
        }).then(response => {
            if (response.data) {
                fileDownload(response.data, downloadFileName);
            }
        }).finally(() => {
            setDownloadingReport(false);
        });
    };

    return (
        <div className="container-lg">
            <div className={`report-top ${className}`}>
                <div className="report-top__header">
                    {children}
                </div>

                <div className="report-top__btn">
                    <button className="btn blue" 
                        type="button" 
                        disabled={downloadingReport} 
                        onClick={onClickDownloadReport}>
                    
                        Download PDF Version
                    </button>
                </div>
            </div>
        </div>
    );
}
