import React from 'react';

import './AdminStatusLabel.scss';

export default function AdminStatusLabel({text, colour}) {
    return (
        <div className={`admin-status-label ${colour}`}>
            {text}
        </div>
    );
}
