import React from 'react';

import './AdminTable.scss';

export function AdminTableHead({children, className}) {
    return (
        <thead className={`admin-table-head ${className ? className : ''}`}>
            {children}
        </thead>
    );
}

export function AdminTableBody({children, className}) {
    return (
        <tbody className={`admin-table-body ${className ? className : ''}`}>
            {children}
        </tbody>
    );
}

export function AdminTableActionsCell({children}) {
    return (
        <td className="admin-table-actions-cell">
            <div className="actions">
                {children}
            </div>
        </td>
    );
}

export default function AdminTable({children, className}) {
    return (
        <div className={`admin-table ${className ? className : ''}`}>
            <div className="admin-table-inner">
                <table>
                    {children}
                </table>
            </div>
        </div>
    );
}
