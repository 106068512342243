import React from 'react';

import './ErrorMessage.scss';

export default function ErrorMessage({className, message}) {
    if (!message) return false;
    
    if (typeof message === 'object') {
        message = Object.values(message);

        if (!message.length) {
            return false;
        }
    }
    
    return (
        <div className={`error-message ${className ?? ''}`}>
            <div className="error-message-inner">
                <div className="error-message-icon">!</div>
                <div className="error-message-text">
                    {typeof message === 'object' && message !== null ? message.map((errorMessage, key) => <div key={key}>{errorMessage}</div>) : null}
                    {typeof message === 'string' ? <div>{message}</div> : null}
                </div>
            </div>
        </div>
    );
}
