import React, { useState } from 'react';
import { uploadLeadersFile } from '../../../api';

import AdminModal, { AdminModalBtns } from '../AdminModal/AdminModal';
import Input from '../../Input/Input';

import UploadIcon from '../../Icons/UploadIcon';

import './UploadLeadersFormModal.scss';

export default function UploadLeadersFormModal({clientId, programmeId, cohortId, showUploadLeadersModal, setShowUploadLeadersModal, onSubmitSuccess}) {
    const [uploadLeadersFileValue, setUploadLeadersFileValue] = useState();
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const onChangeUploadLeadersFileInput = e => {
        if (!e.target?.files.length) {
            setUploadLeadersFileValue();
            return;
        }

        setUploadLeadersFileValue(e.target?.files.length ? e.target.files[0] : '');
    };

    const onSubmitUploadLeadersForm = e => {
        e.preventDefault();

        setFormErrors(false);

        setFormLoading(true);

        let formData = new FormData();
        formData.append('file', uploadLeadersFileValue);
        
        uploadLeadersFile(clientId, programmeId, cohortId, formData).then(response => {
            console.log('uploadLeadersFile', response);

            if (response.success) {
                onSubmitSuccess();
            } else {
                setFormErrors(Object.values(response.errors));
            }

            setFormLoading(false);
        }).catch(() => {
            setFormLoading(false);
        });
    };

    const onClickCancelUploadFileBtn = () => {
        setShowUploadLeadersModal(false);
    };

    return (
        <AdminModal show={showUploadLeadersModal} closeModal={() => setShowUploadLeadersModal(false)}>
            <h2>Upload Leaders File</h2>

            <form className={`upload-leaders-form ${formLoading ? 'loading' : ''}`} onSubmit={onSubmitUploadLeadersForm}>
                {formLoading ? <div className="loading-circle"></div> : null}

                <div className="upload-leaders-form-inner">
                    <Input type="file" size="full" onChange={onChangeUploadLeadersFileInput} errors={formErrors} />
                    <AdminModalBtns>
                        <button className="btn" type="button" onClick={onClickCancelUploadFileBtn}>Cancel</button>
                        <button className="btn blue" type="submit"><UploadIcon /><div>Upload File</div></button>
                    </AdminModalBtns>
                </div>
            </form>
        </AdminModal>
    )
}
