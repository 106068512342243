import React, { useRef, useState, useEffect } from 'react';

import CheckIcon from '../Icons/CheckIcon';

import './SuccessMessage.scss';

export default function SuccessMessage({className, message, setFormSuccess}) {
    const isMountedRef = useRef(false);

    const [hide, setHide] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;

        if (isMountedRef.current) {
            setTimeout(() => {
                setHide(true);

                setTimeout(() => {
                    setFormSuccess(false);
                }, 500);
            }, 3000);
        }

        return () => isMountedRef.current = false;
    }, [setFormSuccess]);

    if (typeof message === 'object') {
        message = Object.values(message);

        if (!message.length) {
            return false;
        }
    }
    
    return (
        <>
            { message ?
                <div className={`success-message ${className ?? ''} ${hide ? 'hide' : ''}`}>
                    <div className="success-message-inner">
                        <div className="success-message-icon"><CheckIcon /></div>
                        <div className="success-message-text">
                            {typeof message === 'object' && message !== null ? message.map((successMessage, key) => <div key={key}>{successMessage}</div>) : null}
                            {typeof message === 'string' ? <div>{message}</div> : null}
                        </div>
                    </div>
                </div>
            : null }
        </>
    );
}
