import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Store } from '../../../../../Store';
import { getClient, getProgramme, getAgents, getEmailTemplate, updateEmailTemplate, sendEmailTemplateTest } from '../../../../../api';
import { SelectionState, ContentState, EditorState, RichUtils, Modifier, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody, AdminCardAccordion } from '../../../AdminCard/AdminCard';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage';
import AdminModal, { AdminModalBtns } from '../../../AdminModal/AdminModal';
import Input from '../../../../Input/Input';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import ChevronDown from '../../../../Icons/ChevronDown';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EditProgrammeComms.scss';

import boldIcon from '../../../../../images/bold-icon.svg';
import italicIcon from '../../../../../images/italic-icon.svg';
import underlineIcon from '../../../../../images/underline-icon.svg';
import linkIcon from '../../../../../images/link-icon.svg';

const editorLabels = {
    'components.controls.blocktype.h2': 'Heading',
    'components.controls.blocktype.normal': 'Body',
};

const styleMap = {
    'Normal': {
        fontSize: '12px',
        lineHeight: '1.8'
    },
    'H2': {
        fontSize: '24px',
        fontWeight: 'normal',
        lineHeight: '1.5em'
    },
    'tag': {
        color: '#28C5FF'
    },
};

const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        text
    );

    let newEditorState = RichUtils.toggleInlineStyle(editorState, 'tag');
    newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
};

const insertLink = (url, text, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    // create new content with text
    const newContent = Modifier.replaceText(currentContent, currentSelection, text);

    // create new link entity
    const newContentWithEntity = newContent.createEntity('LINK', 'MUTABLE', { url }, false);

    const entityKey = newContentWithEntity.getLastCreatedEntityKey();

    // create new selection with the inserted text
    const anchorOffset = currentSelection.getAnchorOffset();

    const newSelection = new SelectionState({
        anchorKey: currentSelection.getAnchorKey(),
        anchorOffset,
        focusKey: currentSelection.getAnchorKey(),
        focusOffset: anchorOffset + text.length,
    });

    // and aply link entity to the inserted text
    const newContentWithLink = Modifier.applyEntity(newContentWithEntity, newSelection, entityKey);

    // create new state with link text
    const newEditorState = EditorState.push(editorState, newContentWithLink, 'insert-characters');

    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
};

function Tags({tags, selected, label, placeholder, onChange}) {
    const onChangeTag = e => {
        onChange(e);
    };

    if (tags?.length) {
        return(
            <div className="wysiwyg-tags">
                { label ? <div className="wysiwyg-label"><label>{label}</label></div> : null }
                <div className="wysiwyg-dropdown">
                    <select value={selected ?? ''} onChange={onChangeTag}>
                        { placeholder ? <option>{placeholder}</option> : null }
                        { tags.map(field => {
                            return <option key={field} value={`[${field}]`}>[{field}]</option>
                        })}
                    </select>
                    <ChevronDown className="icon" />
                </div>
            </div>
        );
    }

    return false;
}

function WYSIWYGSingleLine({label, editorState, tags, urlTags, setEditorState, onChange}) {
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    const onChangeTag = e => {
        setEditorState(insertText(e.target.value, editorState));
    };

    return (
        <div className="wysiwyg-single-line">
            <div className="wysiwyg-top">
                { label ? <div><strong>{label}</strong></div> : null }
                <div className="wysiwyg-toolbar">
                    <div className="wysiwyg-toolbar-inner">
                        <Tags tags={tags} label="Tags" placeholder="Select Tag" onChange={onChangeTag} />
                    </div>
                </div>
            </div>

            <Editor
                customStyleMap={styleMap}
                toolbar={false}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onEditorStateChange={onChange} />
        </div>
    );
}

function WYSIWYGBlock({label, editorState, tags, urlTags, setEditorState, onChange}) {
    const [showUrlTagBox, setShowUrlTagBox] = useState(false);
    const [urlTagValue, setUrlTagValue] = useState('');
    const [urlTagLinkTextValue, setUrlTagLinkTextValue] = useState('');

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    const onChangeTag = e => {
        setEditorState(insertText(e.target.value, editorState));
    };

    const onChangeUrlTag = e => {
        setUrlTagValue(e.target.value);
    };

    const onClickUrlTagsButton = () => {
        if (!showUrlTagBox) {
            let selection = editorState.getSelection();
            const anchorKey = selection.getAnchorKey();
            const currentContent = editorState.getCurrentContent();
            const currentBlock = currentContent.getBlockForKey(anchorKey);
            
            //Then based on the docs for SelectionState -
            const start = selection.getStartOffset();
            const end = selection.getEndOffset();
            const selectedText = currentBlock.getText().slice(start, end);

            setUrlTagLinkTextValue(selectedText);
    
        }

        setShowUrlTagBox(!showUrlTagBox);
    };

    const onClickInsertLinkButton = () => {
        setShowUrlTagBox(true);

        setEditorState(insertLink(urlTagValue, urlTagLinkTextValue, editorState));

        setShowUrlTagBox(false);

        setTimeout(() => {
            setUrlTagValue('');
            setUrlTagLinkTextValue('');
        }, 500);
    };

    return (
        <div className="wysiwyg-block">
            <div className="wysiwyg-top">
                <div><strong>{label}</strong></div>
            </div>

            <div className="wysiwyg-wrap">
                <div className="wysiwyg-tags-wrap">
                    <Tags tags={tags} label="Tags" placeholder="Select Tag" onChange={onChangeTag} />
                    {urlTags?.length ? 
                        <div className="url-tags-wrap">
                            <button type="button" className="btn small" onClick={onClickUrlTagsButton}>URL Tags</button>
                            <div className={`url-tags-box ${showUrlTagBox ? 'show' : ''}`}>
                                <div className="url-tags-box-inner">
                                    <Tags tags={urlTags} selected={urlTagValue} label="Tags" placeholder="Select Tag" onChange={onChangeUrlTag} />
                                    <Input type="text" label="Link Text" name="link-text" value={urlTagLinkTextValue} size="full" onChange={e => setUrlTagLinkTextValue(e.target.value)} />
                                    <button className="btn blue small" type="button" onClick={onClickInsertLinkButton}>Insert Link</button>
                                </div>
                            </div>
                        </div>
                    : null }
                </div>

                <Editor
                    customStyleMap={styleMap}
                    toolbar={{
                        options: ['blockType', 'inline', 'link'],
                        blockType: {
                            options: [ 'Normal', 'H2' ],
                        },
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                            bold: { icon: boldIcon },
                            italic: { icon: italicIcon },
                            underline: { icon: underlineIcon }
                        },
                        link: {
                            options: ['link'],
                            link: { icon: linkIcon }
                        }
                    }}
                    localization={{ locale: 'en', translations: editorLabels }}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onEditorStateChange={onChange} />
            </div>
        </div>
    );
}

function EditProgrammeCommsForm({templateKey, setFormChange}) {
    const {clientId, programmeId} = useParams();

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    
    const [showSendTestEmailModal, setShowSendTestEmailModal] = useState(false);

    const [subjectLine, setSubjectLine] = useState(EditorState.createEmpty());
    const [headline, setHeadline] = useState(EditorState.createEmpty());
    const [bodyCopy, setBodyCopy] = useState(EditorState.createEmpty());
    const [tags, setTags] = useState();
    const [urlTags, setUrlTags] = useState();
    const [lastSaved, setLastSaved] = useState();

    const fetchEmailTemplate = useCallback((clientId, programmeId, templateKey) => {
        setFormLoading(true);

        getEmailTemplate(clientId, programmeId, templateKey).then(response => {
            const bodyBlocks = htmlToDraft(response.body);
            const bodyContentState = ContentState.createFromBlockArray(bodyBlocks.contentBlocks, bodyBlocks.entityMap);

            setSubjectLine(EditorState.createWithContent(ContentState.createFromText(response.subject)));
            setHeadline(EditorState.createWithContent(ContentState.createFromText(response.title)));
            setBodyCopy(EditorState.createWithContent(bodyContentState));
            setTags(response.fields.filter(field => !field.includes('Url')));
            setUrlTags(response.fields.filter(field => field.includes('Url')));
            setLastSaved(response.lastSaved);
        }).finally(() => {
            setFormLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchEmailTemplate(clientId, programmeId, templateKey);
    }, [fetchEmailTemplate, clientId, programmeId, templateKey]);
        
    const convertEditorToText = editorState => {
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        return value;
    }

    const onSubmitEditProgrammeCommsForm = e => {
        e.preventDefault();

        setFormLoading(true);
        setFormSuccess(false);

        updateEmailTemplate(clientId, programmeId, templateKey, {
            subject: convertEditorToText(subjectLine),
            title: convertEditorToText(headline),
            body: draftToHtml(convertToRaw(bodyCopy.getCurrentContent()))
        }).then(response => {
            if (response.success) {
                setLastSaved(response.lastSaved ?? lastSaved);
                setFormSuccess('Your changes have been saved.');
                setFormChange(false);
            } else {
                setFormErrors(response.errors);
            }
        }).finally(() => {
            setFormLoading(false);
        });
    };

    const onChangeSubjectLine = editorState => {
        setSubjectLine(editorState);
        setFormChange(true);
    };

    const onChangeHeadline = editorState => {
        setHeadline(editorState);
        setFormChange(true);
    };

    const onChangeBodyCopy = editorState => {
        setBodyCopy(editorState);
        setFormChange(true);
    };

    const onClickSendTestEmail = () => {
        updateEmailTemplate(clientId, programmeId, templateKey, {
            subject: convertEditorToText(subjectLine),
            title: convertEditorToText(headline),
            body: draftToHtml(convertToRaw(bodyCopy.getCurrentContent()))
        }).then(response => {
            if (response.success) {
                sendEmailTemplateTest(clientId, programmeId, templateKey).then(response => {
                    if (response.success) {
                        setShowSendTestEmailModal(true);
                    }
                });
                
                setFormChange(false);
            }
        });
    };

    return (
        <form className="edit-programme-comms-form" onSubmit={onSubmitEditProgrammeCommsForm}>
            { formLoading ? <div className="loading-circle"></div> : null }

            { formErrors ? <ErrorMessage message={formErrors} /> : null }

            { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

            <div className="edit-programme-comms-form-inner">
                <div>
                    <WYSIWYGSingleLine label="Subject Line" editorState={subjectLine} tags={tags} urlTags={urlTags} setEditorState={setSubjectLine} onChange={onChangeSubjectLine} />
                    <WYSIWYGSingleLine label="Headline" editorState={headline} tags={tags} urlTags={urlTags} setEditorState={setHeadline} onChange={onChangeHeadline} />
                </div>
                <div>
                    <WYSIWYGBlock label="Body Copy" editorState={bodyCopy} tags={tags} urlTags={urlTags} setEditorState={setBodyCopy} onChange={onChangeBodyCopy} />
                </div>
            </div>
            <div className="edit-programme-comms-btns">
                { lastSaved ? <div className="edit-programme-last-saved-date">Last saved {format(utcToZonedTime(parseISO(lastSaved), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div> : null }
                <div><button className="btn" type="button" onClick={onClickSendTestEmail}>Send Test</button></div>
                <div><button className="btn blue" type="submit">Save</button></div>
            </div>
            <AdminModal show={showSendTestEmailModal} closeModal={() => setShowSendTestEmailModal(false)}>
                <h2>Test Email</h2> 

                <p>A test email has been successfully sent.</p>

                <AdminModalBtns>
                    <button className="btn" type="button" onClick={() => setShowSendTestEmailModal(false)}>Close</button>
                </AdminModalBtns>
            </AdminModal>
        </form>
    );
}

export default function EditProgrammeComms({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const { state } = useContext(Store);
    const { surveyTypes } = state;

    const {clientId, programmeId} = useParams();

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [agents, setAgents] = useState(false);

    const [form1Change, setForm1Change] = useState(false);
    const [form2Change, setForm2Change] = useState(false);
    const [form3Change, setForm3Change] = useState(false);

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response);
        });
    }, []);

    useEffect(() => {
        document.title = `Edit Programme Comms - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: 'Edit Programme details',
                link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/edit`
            }, {
                text: 'Edit Programme comms'
            }
        ]);
    }, [urlPrepend, clientId, programmeId, setHeaderBreadcrumbs]);

    return (
        <div>
            { client && programme && agents ?
                <AdminInfoBar>
                    { client?.name && programme?.name ? 
                        <div>
                            <h3>{client.name}</h3>
                            <div>{programme.name}</div>
                        </div>
                    : null }
                    { programme?.agentId && agents?.length && agents?.filter(agent => agent.id === programme.agentId)?.length ?
                        <div>
                            <h3>Holos Programme Lead</h3>
                            <div>{agents.filter(agent => agent.id === programme.agentId)[0].name}</div>
                        </div>
                    : null }
                    { programme?.debriefDate ?
                        <div>
                            <h3>Debrief Start Date</h3>
                            <div>{format(utcToZonedTime(parseISO(programme.debriefDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                        </div>
                    : null }
                    { programme?.surveyType && surveyTypes ?
                        <div>
                            <h3>Survey Type</h3>
                            <div>{ surveyTypes?.filter(surveyType => surveyType.value === programme.surveyType)[0]?.name }</div>
                        </div>
                    : null }
                </AdminInfoBar>
            : null }

            <div className="admin-container container-padding-lg">
                <AdminTop header="Edit programme comms" />
                { programme?.onlyManualEntry === true ? <>
                    <AdminCard>
                        <AdminCardAccordion header="Email 1: Survey Email">
                            <AdminCardBody>
                                <EditProgrammeCommsForm templateKey="RespondentInvite" setFormChange={setForm2Change} />
                            </AdminCardBody>
                        </AdminCardAccordion>
                    </AdminCard>
                </> : null }

                { programme?.onlyManualEntry === false ? <>
                    <AdminCard>
                        <AdminCardAccordion header="Email 1: Nomination Email">
                            <AdminCardBody>
                                <EditProgrammeCommsForm templateKey="LeaderNominate" setFormChange={setForm1Change} />
                            </AdminCardBody>
                        </AdminCardAccordion>
                    </AdminCard>
                    <AdminCard>
                        <AdminCardAccordion header="Email 2: Leader Survey Email">
                            <AdminCardBody>
                                <EditProgrammeCommsForm templateKey="LeaderInvite" setFormChange={setForm2Change} />
                            </AdminCardBody>
                        </AdminCardAccordion>
                    </AdminCard>
                    <AdminCard>
                        <AdminCardAccordion header="Email 3: Team Members Survey Email">
                            <AdminCardBody>
                                <EditProgrammeCommsForm templateKey="RespondentInvite" setFormChange={setForm3Change} />
                            </AdminCardBody>
                        </AdminCardAccordion>
                    </AdminCard>
                </> : null }
            </div>

            <AdminActionBar>
                <div>
                    <div><Link className="btn white-border" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/edit`}>Previous</Link></div>
                </div>
                <div>
                    <div><Link className="btn white-border" to={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}>Return to Programme</Link></div>
                    <div><Link className="btn" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/add-team?previousLink=${encodeURIComponent(`${urlPrepend}/client/${clientId}/programme/${programmeId}/edit-comms`)}`}>Next Step: Add Team</Link></div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={form1Change || form2Change || form3Change}>
                <h2>Leaving this page?</h2>
                <p>These changes have not been saved.</p>
            </AdminLeavingPageModal>
        </div>
    );
}
