import React, { useState, useRef, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import './ReportNav.scss';

import PieChartIcon from '../../Icons/PieChartIcon';
import BarChartIcon from '../../Icons/BarChartIcon';
import ChevronDown from '../../Icons/ChevronDown';

function ReportLink({ 
    link = false, 
    icon, 
    text, 
    childLinks = false, 
    exactMatch = false, 
    active 
}) {
    const [mouseOver, setMouseOver] = useState(false);

    const reportSubNavRef = useRef();

    const onNavLinkWrapMouseEnter = () => {
        setMouseOver(true);
    };

    const onNavLinkWrapMouseLeave = () => {
        setMouseOver(false);
    };

    return (
        <div className={`report-nav-link-wrap ${childLinks && childLinks.length ? 'has-sub-nav' : ''} ${mouseOver || active ? 'active' : ''}`} 
            onMouseEnter={e => childLinks && childLinks.length ? onNavLinkWrapMouseEnter() : null} 
            onMouseLeave={e => childLinks && childLinks.length ? onNavLinkWrapMouseLeave() : null}>

            { link ? 
                <NavLink className="report-nav-link" to={link}>
                    <div className="icon">{icon}</div>

                    <div className="text">{text}</div>

                    { childLinks && childLinks.length ? 
                        <div className="icon-down"><ChevronDown /></div>
                    : null }
                </NavLink>
            :
                <div className="report-nav-link">
                    <div className="icon">{icon}</div>

                    <div className="text">{text}</div>

                    { childLinks && childLinks.length ? 
                        <div className="icon-down"><ChevronDown /></div>
                    : null }
                </div>
            }

            { childLinks && childLinks.length ? 
                <div ref={reportSubNavRef} 
                    className="report-nav-sub-nav">

                    { childLinks.map(link => {
                        return (
                            <div key={link.link} 
                                className="report-nav-sub-nav-link-wrap">

                                <NavLink to={link.link} 
                                    activeClassName="active">

                                    <div>{link.text}</div>
                                </NavLink>
                            </div>
                        );
                    }) } 
                </div>
            : null }
        </div>
    );
}

export default function ReportNav({
    productType, 
    reportType, 
    leaderName, 
    clientName, 
    teamName, 
    programmeName, 
    sections, 
    activePage, 
    completedOn
}) {
    const { bearerToken } = useParams();

    const [responseNav, setResponseNav] = useState();

    useEffect(() => {
        let reponseNavItems = [];

        sections.forEach(section => {
            if ( reportType === 0 || !section.onlyFreeText ) {
                reponseNavItems.push({
                    text: section.name,
                    link: `/report/${bearerToken}/responses/${section.id}`
                });
            }
        });

        setResponseNav(reponseNavItems);
    }, [sections, bearerToken, reportType, setResponseNav]);

    return (
        <div className="report-nav">
            { leaderName && productType === 1 && ( reportType === 0 || reportType === 1 ) ? 
                <div className="report-nav-report-name">{leaderName} Report</div>
            : null }

            { programmeName && productType === 2 && ( reportType === 0 || reportType === 1 ) ? 
                <div className="report-nav-report-name">{programmeName}</div>
            : null }

            { programmeName && reportType === 3 ? 
                <div className="report-nav-report-name">{programmeName}</div>
            : null }

            { completedOn && reportType === 2 ? 
                <div className="report-nav-report-name">
                    Q{format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')}
                </div>
            : null }

            <nav className="report-nav-nav">
                <ReportLink link={`/report/${bearerToken}`} 
                    icon={<PieChartIcon />} 
                    text="Summary" 
                    exactMatch={true} 
                    active={activePage === 'overview'} />

                <ReportLink icon={<BarChartIcon />} 
                    text="Responses" 
                    childLinks={responseNav} 
                    active={activePage === 'responses' || activePage === 'responses-questions'} />
            </nav>
        </div>
    );
}
