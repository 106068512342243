import React from 'react';

import './QuestionnaireBtns.scss';

export default function QuestionnaireBtns({getActiveSection, setActiveSectionId, submitSectionAnswers}) {
    if (!getActiveSection()) {
        return false;
    }

    return (
        <div className="questionnaire-btns container-lg container-padding-lg">
            { getActiveSection().previousSectionId ? 
                <button type="button" className="btn" onClick={() => { setActiveSectionId(getActiveSection().previousSectionId) }}>Back</button> 
            : null }
            { getActiveSection().nextSectionId && !getActiveSection().previousSectionId ? 
                <button type="button" className="btn blue" onClick={() => { submitSectionAnswers(getActiveSection().id, getActiveSection().nextSectionId) }}>
                    Start 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.8 11.2"><path d="M26.7 6.9l-.1-.1c-2-1.6-4.2-3-6.4-4.1C17.9 1.4 15.5.6 13 0c-.3-.1-.6.1-.8.3-.5.7-.8 1.5-.8 2.4 0 .3.3.6.6.5.2 0 .5-.2.5-.4 0-.4.2-.8.5-1.2 2.2.7 4.4 1.5 6.5 2.5 1.7.9 3.4 1.9 5 3-1.6.6-3.2 1-4.8 1.2-1.9.4-3.7.9-5.6 1.3.1-.8.1-1.5.1-2.3.8-.1 1.6-.2 2.3-.3.8-.1.5-1.1-.2-1l-2.1.3c0-.9-.1-1.8-.3-2.6-.1-.7-1.2-.4-1.1.3.1.8.2 1.6.2 2.5-4.1.4-8.3.5-12.5.3-.7 0-.7 1 0 1 4.2.3 8.4.2 12.6-.2 0 .9-.1 1.9-.3 2.8-.1.3.1.7.5.8h.3l6.9-1.5c2.1-.3 4.1-.9 6-1.7.3-.3.4-.7.2-1.1z"/></svg>
                </button> 
            : null }
            { getActiveSection().previousSectionId ? 
                <button type="button" className="btn blue" onClick={() => { submitSectionAnswers(getActiveSection().id, getActiveSection().nextSectionId ?? false) }}>
                    Next
                </button> 
            : null }
        </div>
    );
}
