import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import { createClient, getAgents } from '../../../../api';
import { login } from '../../../../auth';

import AdminTop from '../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../AdminCard/AdminCard';
import Input, { InputRow } from '../../../Input/Input';
import AdminActionBar from '../../AdminActionBar/AdminActionBar';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import AdminLeavingPageModal from '../../AdminLeavingPageModal/AdminLeavingPageModal';

import PlusIcon from '../../../Icons/PlusIcon';

import './AddClient.scss';

function ContactDetails({contact, index, onClickRemoveClient, onChangeContactInput, errors}) {
    const [name, setName] = useState(contact.name);
    const [emailAddress, setEmailAddress] = useState(contact.emailAddress);
    const [phoneNumber, setPhoneNumber] = useState(contact.phoneNumber);

    const onChangeNameInput = e => {
        setName(e.target.value);
        onChangeContactInput(index, 'name', e.target.value);
    };

    const onChangeEmailAddressInput = e => {
        setEmailAddress(e.target.value);
        onChangeContactInput(index, 'emailAddress', e.target.value);
    };

    const onChangePhoneNumberInput = e => {
        setPhoneNumber(e.target.value);
        onChangeContactInput(index, 'phoneNumber', e.target.value);
    };

    return (
        <InputRow>
            <Input label="Contact name*" 
                name={`contact-name-${index}`} 
                type="text" 
                value={name} 
                onChange={onChangeNameInput} 
                errors={errors ? errors.name : false} />

            <Input label="Contact email*" 
                name={`contact-email-${index}`} 
                type="text" 
                value={emailAddress} 
                onChange={onChangeEmailAddressInput} 
                errors={errors ? errors.emailAddress : false} />

            <Input label="Contact phone number*" 
                name={`contact-phone-number-${index}`} 
                type="text" 
                value={phoneNumber} 
                onChange={onChangePhoneNumberInput} 
                errors={errors ? errors.phoneNumber : false} />

            { index !== 0 ?
                <div className="add-client-remove-contact-btn-wrap">
                    <button className="btn small" 
                        type="button" 
                        onClick={() => onClickRemoveClient(index)}>
                            
                        Remove
                    </button>
                </div>
            : null }
        </InputRow>
    );
}

export default function AddClient({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [loadingForm, setLoadingForm] = useState(false);

    const [clientName, setClientName] = useState('');
    const [contacts, setContacts] = useState([{name: '', emailAddress: '', phoneNumber: ''}]);
    const [agentAccessValue, setAgentAccessValue] = useState([]);

    const [agents, setAgents] = useState();

    const [clientNameErrors, setClientNameErrors] = useState(false);
    const [agentAccessErrors, setAgentAccessErrors] = useState(false);
    const [contactsErrors, setContactsErrors] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [formChange, setFormChange] = useState(false);

    const [redirect, setRedirect] = useState(false);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response.users);
        });
    }, []);

    useEffect(() => {
        document.title = `Add Client - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);
        
    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        
        if (params.get('redirect') === 'create-programme') {
            setHeaderBreadcrumbs([
                {
                    text: 'Programmes',
                    link: `${urlPrepend}/`
                }, {
                    text: 'Create new programme',
                    link: `${urlPrepend}/create-programme`
                }, {
                    text: 'Add a new client'
                }
            ]);
        } else {
            setHeaderBreadcrumbs([
                {
                    text: 'Programmes',
                    link: `${urlPrepend}/`
                }, {
                    text: 'Clients',
                    link: `${urlPrepend}/clients`
                }, {
                    text: 'Add a new client'
                }
            ]);
        }
    }, [setHeaderBreadcrumbs, urlPrepend, location]);

    const onChangeClientNameInput = e => {
        setClientName(e.target.value);
        setFormChange(true);
    };

    const onChangeAgentAccessSelect = options => {
        const values = options.map(option => option.value);

        setAgentAccessValue(values);
    };

    const onChangeContactInput = (index, field, value) => {
        let contactsData = [...contacts];
        contactsData[index][field] = value;
        setContacts(contactsData);

        setFormChange(true);
    };

    const onClickAddAnotherContact = () => {
        setContacts(contacts.concat({name: '', emailAddress: '', phoneNumber: ''}));
    };

    const onClickRemoveClient = removeIndex => {
        setContacts(contacts.filter((contact, index) => index !== removeIndex));
    };

    const onClickSaveToCohortBtn = () => {
        submitAddClientForm();
    };

    const onSubmitAddNewClientForm = () => {
        submitAddClientForm();
    };

    const submitAddClientForm = () => {
        const params = new URLSearchParams(location.search);
        
        setClientNameErrors(false);
        setAgentAccessErrors(false);
        setContactsErrors(false);
        setFormErrors(false);
        setLoadingForm(true);

        createClient({
            'clientName': clientName,
            'contacts': contacts,
            'userIds': agentAccessValue?.length ? agentAccessValue.filter(agent => agent !== '') : []
        }).then(response => {
            if (response.success) {
                setFormChange(false);

                if ( response?.token ) {
                    const REACT_TOKEN_AUTH_KEY = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    
                    const accessToken = response.token;
                    const refreshToken = REACT_TOKEN_AUTH_KEY.refreshToken;
    
                    login({ accessToken, refreshToken });
                }

                if (params.get('redirect') === 'create-programme') {
                    setRedirect(`${urlPrepend}/create-programme?clientId=${response.id}`);
                } else {
                    setRedirect(`${urlPrepend}/clients`);
                }
            } else {
                setClientNameErrors(response.errors.ClientName);
                setAgentAccessErrors(response.errors.UserIds);

                let responseContactsErrors = [];

                contacts.forEach((contact, index) => {
                    responseContactsErrors.push({ 
                        name: response.errors[`Contacts[${index}].Name`] ? response.errors[`Contacts[${index}].Name`] : false,
                        emailAddress: response.errors[`Contacts[${index}].EmailAddress`] ? response.errors[`Contacts[${index}].EmailAddress`] : false,
                        phoneNumber: response.errors[`Contacts[${index}].PhoneNumber`] ? response.errors[`Contacts[${index}].PhoneNumber`] : false
                    });
                });

                setContactsErrors(responseContactsErrors);

                let errors = Object.assign({}, response.errors);

                delete errors.ClientName;
                delete errors.UserIds;

                contacts.forEach((contact, index) => {
                    delete errors[`Contacts[${index}].Name`];
                    delete errors[`Contacts[${index}].EmailAddress`];
                    delete errors[`Contacts[${index}].PhoneNumber`];
                });

                setFormErrors(errors);
            }
        }).finally(() => {
            setLoadingForm(false);
        });
    };

    return (
        <div className="admin-container container-padding-lg">
            <AdminTop header="Add a new client" />

            <AdminCard>
                <AdminCardBody>
                    { formErrors ? <ErrorMessage message={formErrors} /> : null }

                    <form className="add-a-new-client-form" 
                        onSubmit={onSubmitAddNewClientForm}>

                        {loadingForm ? <div className="loading-circle"></div> : null}

                        <div className={`add-a-new-client-form-inner ${loadingForm ? 'loading' : ''}`}>
                            <InputRow>
                                <Input label="Client name*" 
                                    name="add-client-name" 
                                    type="text" 
                                    value={clientName} 
                                    onChange={onChangeClientNameInput} 
                                    errors={clientNameErrors} />

                                <Input label="Agent Access" 
                                    className="double-width" 
                                    name="agent-access" 
                                    type="select" 
                                    placeholder="Select agents" 
                                    multiple={true} 
                                    options={agents ? agents.map(agent => {
                                        return {
                                            value: agent.id,
                                            label: agent.name,
                                        }
                                    } ) : null} 
                                    value={agentAccessValue} 
                                    loading={!agents} 
                                    onChange={onChangeAgentAccessSelect} 
                                    errors={agentAccessErrors} />
                            </InputRow>

                            {contacts.map((contact, index) => {
                                return (
                                    <ContactDetails key={index} 
                                        contact={contact} 
                                        index={index} 
                                        onClickRemoveClient={onClickRemoveClient} 
                                        onChangeContactInput={onChangeContactInput} 
                                        errors={contactsErrors[index]} />
                                );
                            })}

                            <button className="add-another-contact-btn btn blue" 
                                type="button" 
                                onClick={onClickAddAnotherContact}>
                                    
                                <PlusIcon />
                                <div>Add Another Contact</div>
                            </button>
                        </div>
                    </form>
                </AdminCardBody>
            </AdminCard>

            <AdminActionBar>
                <div>
                    { params.get('redirect') === 'create-programme' ? 
                        <div>
                            <Link className="btn white-border" 
                                to={`${urlPrepend}/create-programme`}>
                                
                                Previous
                            </Link>
                        </div> 
                    : null }

                    { !params.get('redirect') ? 
                        <div>
                            <Link className="btn white-border" 
                                to={`${urlPrepend}/clients`}>
                                    
                                Previous
                            </Link>
                        </div> 
                    : null }
                </div>
                <div>
                    <div></div>
                    <div><button className="btn" 
                        type="button" 
                        onClick={onClickSaveToCohortBtn}>
                            
                        Save to Programme
                        </button>
                    </div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>This client has not been saved.</p>
            </AdminLeavingPageModal>

            { redirect ? <Redirect to={redirect} /> : null }
        </div>
    );
}
