import { parse, format, setHours, setMinutes } from 'date-fns';

export function sortNameAlphabetically(a, b) {
    if (a.name < b.name) return -1;

    if (a.name > b.name) return 1;

    return 0;
}

function ArrayBufferToBinary(buffer) {
    // Convert an array buffer to a string bit-representation: 0 1 1 0 0 0...
    var dataView = new DataView(buffer);
    var response = "", offset = (8/8); 
    for(var i = 0; i < dataView.byteLength; i += offset) {
        response += dataView.getInt8(i).toString(2); 
    }
    return response;
 }

export function convertFileToBinary(file) {
    if (!file) return;

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function(event) {
            var data = event.target.result;
            console.log(ArrayBufferToBinary(data));
        };

        reader.readAsArrayBuffer(file); //gets an ArrayBuffer of the file
    });
}

export function dateTimeToUTC(date, time) {
    if (!date || !time) return false;

    // Add time
    if (time) {
        const parseTime = parse(time, 'HH:mm', new Date());

        date = setHours(date, format(parseTime, 'HH'));
        date = setMinutes(date, format(parseTime, 'mm'));
    }

    // Convert to UTC
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);

    return date.toISOString();
}

export function getReportScoreColour(percentage) {
    if (percentage >= 81) return 'light-blue';
    
    if (percentage >= 56) return 'green';

    return 'yellow';
};