import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import AdminModal from '../AdminModal/AdminModal';

import './AdminLeavingPageModal.scss';

export default function AdminLeavingPageModal({ children, formChange }) {
    const [showModal, setShowModal] = useState(false);
    const [historyBlock, setHistoryBlock] = useState(true);
    const [historyLocation, setHistoryLocation] = useState('');

    const unblockRef = useRef();

    const history = useHistory();

    useEffect(() => {
        unblockRef.current = history.block(location => {
            if (!formChange) return true;

            setHistoryLocation(location.pathname);
            setShowModal(true);
            return !historyBlock;
        });

        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [history, formChange, historyBlock, setHistoryLocation]);

    const onClickCloseModal = () => {
        setShowModal(false);
    };

    const onClickLeave = () => {
        if (unblockRef) {
            unblockRef.current()
        }

        setHistoryBlock(false);
    }

    return (
        <AdminModal className="admin-leaving-page-modal" show={showModal} closeModal={onClickCloseModal}>
            <div className="leaving-page-modal-content">
                <div>
                    <div className="leaving-page-modal-icon">!</div>
                </div>
                <div className="leaving-page-modal-message">
                    {children}
                </div>
                <div className="leaving-page-modal-btns">
                    { historyLocation ? <Link to={historyLocation} onClick={onClickLeave}>Leave</Link> : null }
                    <button onClick={onClickCloseModal}>Stay</button>
                </div>
            </div>
        </AdminModal>
    )
}
