import React from 'react';

import './AdminInfoBar.scss';

export default function AdminInfoBar({children}) {
    return (
        <section className="admin-info-bar">
            <div className="admin-container container-padding-lg">
                <div className="admin-info-bar-inner">
                    {children}
                </div>
            </div>
        </section>
    );
}
