import { createAuthProvider } from 'react-token-auth';
import { getConfig } from '../Store';

export const [useAuth, authFetch, login, logout] =
    createAuthProvider({
        accessTokenKey: 'accessToken',
        onUpdateToken: (token) => fetch(`${getConfig().apiUrl}Account/Refresh`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'accessToken': token.accessToken,
                'refreshToken': token.refreshToken,
            })
        }).then(response => {
            return response.json();
        }).then(response => {
            return {
                accessToken: response.token,
                refreshToken: response.refreshToken
            };
        })
    });
