import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import "./QuestionnaireContent.scss";

function QuestionnaireRadios({ id, multipleChoiceAnswers, answer, setAnswer }) {
  const [value, setValue] = useState(answer);

  const updateValue = (newValue) => {
    setValue(newValue);
    setAnswer(id, newValue);
  };

  return (
    <div>
      {multipleChoiceAnswers.length
        ? multipleChoiceAnswers.map((answer) => {
            return (
              <div key={`${id}-${answer.value}`}>
                <label htmlFor={`${id}-${answer.value}`}>
                  <div className="radio-wrap">
                    <div className="radio-input-wrap">
                      <input
                        id={`${id}-${answer.value}`}
                        type="radio"
                        name={id}
                        value={answer.value}
                        onChange={(e) => updateValue(e.target.value)}
                        checked={value === answer.value.toString()}
                      />
                      <div className="radio"></div>
                    </div>
                    <div>{answer.answer}</div>
                  </div>
                </label>
              </div>
            );
          })
        : null}
    </div>
  );
}

function QuestionnaireInput({ id, answer, setAnswer, disabled }) {
  const [value, setValue] = useState(answer);

  const updateValue = (newValue) => {
    setValue(newValue);
    setAnswer(id, newValue);
  };

  return (
    <TextareaAutosize
      id={id}
      name={id}
      placeholder="Your answer"
      onChange={(e) => updateValue(e.target.value)}
      disabled={disabled}
      value={value ?? ""}
    >
    </TextareaAutosize>
  );
}

export default function QuestionnaireContent({
  productType,
  loading,
  leaderName,
  isLeader,
  surveyName,
  sections,
  activeSectionId,
  getActiveSection,
  multipleChoiceAnswers,
  setAnswer,
  submitSectionAnswers,
}) {
  if (!getActiveSection()) {
    return false;
  }
  return (
    <div className={`questionnaire-content ${loading ? "loading" : ""} ${productType === 1 ? 'leadership-360' : ''} ${productType === 2 ? 'team-diagnostics' : ''}`}>
        <div className="questionnaire-content-intro">
          <div className="container-lg container-padding-lg">
            {getActiveSection().name ? (
              <h2 className="header">{getActiveSection().name}</h2>
            ) : null}
            {getActiveSection().introText ? (
              <div className="questionnaire-content-text"
                dangerouslySetInnerHTML={{ __html: getActiveSection().introText }}
              ></div>
            ) : null}

            {getActiveSection().questions ? (
              <form
                className="questionnaire-questions"
                onSubmit={() => {
                  submitSectionAnswers(
                    getActiveSection().currentSectionId,
                    getActiveSection().nextSectionId
                  );
                }}
              >
                {getActiveSection().questions.map((question) => {
                  return (
                    <div
                      key={question.id}
                      className={`question ${question.error ? "error" : ""}`}
                    >
                      <div className="number">{question.number}</div>
                      <div className="question-inner">
                        <label htmlFor={question.id}>
                          {question.question}{" "}
                          {question.required && <span>*</span>}
                        </label>
                        {question.type === 1 ? (
                          <QuestionnaireRadios
                            id={question.id}
                            multipleChoiceAnswers={multipleChoiceAnswers}
                            answer={question.answer}
                            setAnswer={setAnswer}
                          />
                        ) : null}
                        {question.type >= 2 ? (
                          <QuestionnaireInput
                            id={question.id}
                            answer={question.answer}
                            setAnswer={setAnswer}
                            disabled={question.type > 2}
                          />
                        ) : null}
                        {question.error ? (
                          <div className="error-message">
                            *This is a required field
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </form>
            ) : null}
          </div>
        </div>
    </div>
  );
}
