import React, { useContext, useState } from 'react';
import axios from 'axios';
import { getReportScoreColour } from '../../../helpers';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Store } from '../../../Store';

import ReportTop from '../ReportTop/ReportTop';
import ReportMainContent from '../ReportMainContent/ReportMainContent';
import ReportSidebar from '../ReportSidebar/ReportSidebar';
import ReportRadarChart from '../ReportRadarChart/ReportRadarChart';
import ReportScoreCard from '../ReportScoreCard/ReportScoreCard';
import Input from '../../Input/Input';

import './ReportOverview.scss';

function getScoreDescriptionText(sectionName) {
    switch (sectionName) {
        case 'Psychological Safety':
            return (
                <ul>
                    <li>Quality of professional relationships</li>
                    <li>Extent of pride in completion and achievement</li>
                    <li>Levels of honesty and trust</li>
                    <li>Willingness to be vulnerable</li>
                </ul>
            )
        case 'Structure & Clarity':
            return (
                <ul>
                    <li>Capability in prioritisation</li>
                    <li>Quality of decision making</li>
                    <li>Level of interdependence</li>
                    <li>Clarity of vision</li>
                </ul>
            )
        case 'Capacity & Inclusion':
            return (
                <ul>
                    <li>Quality of understanding of context</li>
                    <li>Bandwidth</li>
                    <li>Quality of decision making pool</li>
                    <li>Diversity and inclusion</li>
                </ul>
            )
        case 'Team Impact':
        case 'Reliability & Adaptability':
            return (
                <ul>
                    <li>Workload</li>
                    <li>Speak up</li>
                    <li>Complexity</li>
                    <li>Adaptability</li>
                </ul>
            )
        case 'Impact & Meaning':
            return (
                <ul>
                    <li>Sense of purpose</li>
                    <li>Level of collective motivation and meaning</li>
                </ul>
            )
        default:
            return null;
    }
}

function getTargetDescriptionText(sectionName) {
    switch (sectionName) {
        case 'Psychological Safety':
            return (
                <ul>
                    <li>Deep relationships based in knowing each other better</li>
                    <li>Realise benefits of a fully collaborative approach</li>
                    <li>Vulnerability sets up higher frequencies of leadership</li>
                </ul>
            )
        case 'Structure & Clarity':
            return (
                <ul>
                    <li>Clear Context, Cause and Conditions</li>
                    <li>Clear roles and collaboration skill</li>
                    <li>Excellence in prioritisation and decision making</li>
                </ul>
            )
        case 'Capacity & Inclusion':
            return (
                <ul>
                    <li>Time for horizon-scanning, thinking and planning</li>
                    <li>Solve for the system, not the task</li>
                    <li>Develop specific personal daily habits</li>
                </ul>
            )
        case 'Team Impact':
        case 'Reliability & Adaptability':
            return (
                <ul>
                    <li>A strong reliable foundation</li>
                    <li>Working groups for the adaptable projects - playing with iteration &amp; experimentation</li>
                </ul>
            )
        case 'Impact & Meaning':
            return (
                <ul>
                    <li>Translate meaning into both personal and professional spheres</li>
                    <li>Develop team impact through value creation</li>
                </ul>
            )
        default:
            return null;
    }
}

export function ComparisonDifference({score1, score2}) {
    return (
        <div className="comparison-diffrerence">
            { score1 > score2 ?
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.2 6" width="6.2" height="6">
                        <path d="M4.2 6V3h2L4.5 1.4 3.1 0 1.7 1.4 0 3h2v3h2.2" opacity=".5" fill="#081e64"/>
                    </svg>
                    { score1 - score2 }%
                </>
            : null }
            { score1 < score2 ?
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.2 6" width="6.2" height="6">
                        <path d="M4.2 0H2v3H0l1.7 1.7L3.1 6l1.4-1.4L6.2 3h-2V0" opacity=".5" fill="#081e64"/>
                    </svg>
                    { score2 - score1 }%
                </>
            : null }
        </div>
    );
}

export default function ReportOverview({
    productType, 
    reportType, 
    leaderName, 
    clientName, 
    teamName, 
    cohortName, 
    averageScorePercentage, 
    respondentsCompleted, 
    respondentsInvited, 
    sections, 
    downloadFileName, 
    completedOn, 
    reports, 
    reportId, 
    setActivePage
}) {
    setActivePage('overview');

    const { state } = useContext(Store);
    const { apiUrl } = state;

    const [loadingComparison, setLoadingComparison] = useState(false);

    const [selectedComparisonReport, setSelectedComparisonReport] = useState(false);
    const [comparisonSections, setComparisonSections] = useState(false);
    const [comparisonCohortName, setComparisonCohortName] = useState(false);
    const [comparisonCompletedOn, setComparisonCompletedOn] = useState(false);
    const [comparisonAverageScorePercentage, setComparisonAverageScorePercentage] = useState(false);

    const [showRespondentBarPoints, setShowRespondentBarPoints] = useState(true);
    const [showLeaderBarPoints, setShowLeaderBarPoints] = useState( productType === 1 && ( reportType === 1 || reportType === 2 ) );

    const [showComparisonRespondentBarPoints, setShowComparisonRespondentBarPoints] = useState(true);
    const [showComparisonLeaderBarPoints, setShowComparisonLeaderBarPoints] = useState( productType === 1 && ( reportType === 1 || reportType === 2 ) );

    const onChangeCohortReportsSelect = e => {
        const report = reports.filter(report => report.id === e.target.value);

        if ( !report || !report?.length ) return;

        window.location.href = `/report/${report[0].token}`;
    };

    const onChangeQuarterlyReportsSelect = e => {
        const report = reports.filter(report => report.reportId === e.target.value);

        if ( !report || !report?.length ) return;


        window.location.href = `/report/${report[0].token}`;
    };

    const onChangeComparisonSelect = e => {
        setSelectedComparisonReport(e.target.value ?? false);

        if (!e.target.value) {
            setComparisonSections(false);
            setComparisonCohortName(false);
            setComparisonCompletedOn(false);
            setComparisonAverageScorePercentage(false);
            return;
        }

        setLoadingComparison(true);

        axios.get(`${apiUrl}Reports/Leader/Overview`, {
            headers: {
                Authorization: `Bearer ${e.target.value}`,
            },
        }).then(response => {
            if (response?.data?.success) {
                setComparisonSections(response?.data?.sections);
                setComparisonCohortName(response?.data?.cohortName);
                setComparisonCompletedOn(response?.data?.completedOn);
                setComparisonAverageScorePercentage(response?.data?.averageScorePercentage ? Math.round(response.data.averageScorePercentage) : false);
            }
        }).finally(() => {
            setLoadingComparison(false);
        });
    }

    return (
        <div className="report-content">
            { reportType === 0 ?
                <ReportTop downloadFileName={downloadFileName}>
                    { productType === 1 ?
                        <h1>{leaderName}</h1>
                    : null }

                    { productType === 2 ?
                        <h1>{teamName}</h1>
                    : null }

                    <div>
                        <div>{clientName}</div>
                    </div>
                </ReportTop>
            : null }

            { reportType === 1 ?
                <ReportTop className="report-type-2" 
                    downloadFileName={downloadFileName}>
                        
                    <h1>{clientName}</h1>
                    
                    { reports && reports.filter(report => report.id !== reportId)?.length ?
                        <Input className="small" 
                            type="select" 
                            selectText="Cohort Reports" 
                            options={reports.map(report => {
                                return {
                                    value: report.id,
                                    name: report.name
                                }
                            } ) } 
                            value={reportId} 
                            onChange={onChangeCohortReportsSelect} />
                    : null }
                </ReportTop>
            : null }

            { reportType === 2 || reportType === 3 ?
                <ReportTop className="report-type-2" 
                    downloadFileName={downloadFileName}>

                    <h1>{clientName}</h1>

                    { reports && reports.filter(report => report.reportId !== reportId)?.length ?
                        <Input className="small" 
                            type="select" 
                            selectText="Quarterly Reports" 
                            options={reports.map(report => {
                                return {
                                    value: report.reportId,
                                    name: report.name
                                }
                            } ) } 
                            value={reportId} 
                            onChange={onChangeQuarterlyReportsSelect} />
                    : null }
                </ReportTop>
            : null }

            <div className="container-lg">
                <div className={`report-content-container ${reportType === 1 || reportType === 2 || reportType === 3 ? 'report-averages' : ''}`}>
                    { reportType === 0 ?
                        <ReportSidebar>
                            <ReportScoreCard text="Total Average" score={averageScorePercentage} />

                            { respondentsCompleted && respondentsInvited ?
                                <div className="report-card">
                                    <h2 className="sub-header">Contributors</h2>

                                    <div className="grey-text">
                                        {respondentsCompleted}/{respondentsInvited} completed
                                    </div>
                                </div>
                            : null }

                            <div className="report-text-block">
                                { productType === 1 ? <>
                                    <h2 className="sub-header">Welcome to your Leadership report overview.</h2>
                                    <p>You can navigate around the four sections of your results by clicking on the quadrants in the diagram opposite.</p>
                                    <p>Your own input has been separated from your respondents' views, and is shown when you rollover each point on the diagram. Potential development areas are coloured in orange, developed habits in green, and high performance areas in blue.</p>
                                    <p>You can see your verbatim feedback and interrogate specific questions and areas of the report by using the menu on the left of your screen.</p>
                                    <p>Enjoy your results and be curious about the information it reveals!</p>
                                </> : null}

                                { productType === 2 ? <>
                                    <h2 className="sub-header">Welcome to your Post-Conventional Team Diagnostics Report</h2>
                                    <p>The report is divided into 5 sections and can be seen at three levels. The total score level, the section score level and the individual question level. In all cases high scores mean that this is an area in which the team is already doing well and there is little opportunity for improvement.  Low scores are the areas of greatest opportunity and therefore often the most useful to focus on. Each section also has the option for verbatim comments, these comments often help to understand or get more insight into scores for a particular question. </p>

                                    <p>The greatest value of any such report is in the quality of the conversation it generates and the changes that are made because of those conversations. We encourage team members to discuss the report with each other and to agree to take action.</p>
                                </> : null}
                            </div>
                        </ReportSidebar>
                    : null }

                    { reportType === 1 || reportType === 2 || reportType === 3 ?
                        <ReportSidebar>
                            <div className="report-comparison-options report-card">
                                { reportType === 1 && reports && reports.filter(report => report.id !== reportId)?.length ? 
                                    <div>
                                        <h2>Compare With</h2>

                                        <div>
                                            <Input className="small" 
                                                type="select" 
                                                selectText="Cohort Reports" 
                                                options={reports.filter(report => report.id !== reportId).map(report => {
                                                    return {
                                                        value: report.token,
                                                        name: report.name
                                                    }
                                                } ) } 
                                                value={selectedComparisonReport} 
                                                loading={loadingComparison} 
                                                onChange={onChangeComparisonSelect} />
                                        </div>
                                    </div>
                                : null }

                                { reportType === 2 && reports && reports.filter(report => report.reportId !== reportId)?.length ? 
                                    <div>
                                        <h2>Compare With</h2>

                                        <div>
                                            <Input className="small" 
                                                type="select" 
                                                selectText="Quarterly Reports" 
                                                options={reports.filter(report => report.reportId !== reportId).map(report => {
                                                    return {
                                                        value: report.token,
                                                        name: report.name
                                                    }
                                                } ) } 
                                                value={selectedComparisonReport} 
                                                loading={loadingComparison} 
                                                onChange={onChangeComparisonSelect} />
                                        </div>
                                    </div>
                                : null }

                                <div>
                                    <h2>Avg. Respondent Scores</h2>

                                    <table className="report-comparison-options__scores-table">
                                        { comparisonSections ?
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        { ( reportType === 0 || reportType === 1 ) && cohortName ? cohortName : null }
                                                        { reportType === 2 && completedOn ? `Q${format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')}` : null }
                                                    </th>
                                                    <th>
                                                        { ( reportType === 0 || reportType === 1 ) && comparisonCohortName ? comparisonCohortName : null }
                                                        { reportType === 2 && comparisonCompletedOn ? `Q${format(utcToZonedTime(parseISO(comparisonCompletedOn), 'UTC'), 'Q yy')}` : null }
                                                    </th>
                                                </tr>
                                            </thead>
                                        : null }
                                        <tbody>
                                            <tr>
                                                <th className="total-cell">Total</th>
                                                <td className={`score-cell ${getReportScoreColour(averageScorePercentage)}`}>
                                                    {averageScorePercentage}%
                                                    
                                                    { comparisonAverageScorePercentage ? 
                                                        <ComparisonDifference score1={averageScorePercentage} score2={comparisonAverageScorePercentage} />
                                                    : null }
                                                </td>
                                                { comparisonAverageScorePercentage ? 
                                                    <td className={`score-cell ${getReportScoreColour(comparisonAverageScorePercentage)}`}>{comparisonAverageScorePercentage}%</td>
                                                : null }
                                            </tr>
                                            { sections.filter(section => !section.onlyFreeText).map( (section, index) => {
                                                return(
                                                    <tr key={section.id}>
                                                        <th>{section.name}</th>
                                                        <td className={`score-cell ${getReportScoreColour(section.averageScorePercentage)}`}>
                                                            {section.averageScorePercentage}%

                                                            { comparisonSections ? 
                                                                <ComparisonDifference score1={section.averageScorePercentage} score2={comparisonSections[index].averageScorePercentage} />
                                                            : null }
                                                        </td>
                                                        { comparisonSections ? 
                                                            <td className={`score-cell ${getReportScoreColour(comparisonSections[index].averageScorePercentage)}`}>
                                                                {comparisonSections[index].averageScorePercentage}%
                                                            </td>
                                                        : null }
                                                    </tr>
                                                )
                                            } ) }
                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    <h2>Key</h2>

                                    <div className="report-comparison-options__key">
                                        <div>
                                            <div className="icon"><div className="circle yellow"></div></div>
                                            <div>Untapped Opportunity - up to 55%</div>
                                        </div>

                                        <div>
                                            <div className="icon"><div className="circle green"></div></div>
                                            <div>Emergent Habit - 56-80%</div>
                                        </div>

                                        <div>
                                            <div className="icon"><div className="circle light-blue"></div></div>
                                            <div>Established Habit - over 80%</div>
                                        </div>
                                    </div>
                                </div>

                                { productType === 1 ?
                                    <div>
                                        <h2>Average Scores by Question</h2>

                                        <Input className="report-comparison-options__checkbox" 
                                            type="checkbox" 
                                            append={
                                                <>
                                                    <svg className="icon" width="14.7" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 5">
                                                        <path d="M14.2 2H4.9C4.7.9 3.7 0 2.5 0 1.1 0 0 1.1 0 2.5S1.1 5 2.5 5c1.2 0 2.2-.9 2.4-2h9.3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z" fill="#081e64"/>
                                                    </svg>
                                                    <div>
                                                        { ( reportType === 0 || reportType === 1 ) && cohortName ? `${cohortName} - ` : null }
                                                        { reportType === 2 && completedOn ? `Q${format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')} - ` : null }
                                                        Respondents
                                                    </div>
                                                </>
                                            } 
                                            options={['']} 
                                            checked={showRespondentBarPoints} 
                                            onChange={() => { setShowRespondentBarPoints(!showRespondentBarPoints) }} />
                                            
                                        <Input className="report-comparison-options__checkbox" 
                                            type="checkbox" 
                                            append={
                                                <>
                                                    <svg className="icon" width="14.7" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 5" fill="#081e64">
                                                        <path d="M10.4 2h-2c-.2 0-.5.2-.5.5s.3.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM6.4 2H4.9C4.7.9 3.7 0 2.5 0 1.1 0 0 1.1 0 2.5S1.1 5 2.5 5c1.2 0 2.2-.9 2.4-2h1.5c.3 0 .5-.2.5-.5S6.7 2 6.4 2zM2.5 4C1.7 4 1 3.3 1 2.5S1.7 1 2.5 1 4 1.7 4 2.5 3.3 4 2.5 4zM14.2 2h-1.8c-.3 0-.5.2-.5.5s.3.5.5.5h1.8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/>
                                                    </svg>
                                                    <div>
                                                        { ( reportType === 0 || reportType === 1 ) && cohortName ? `${cohortName} - ` : null }
                                                        { reportType === 2 && completedOn ? `Q${format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')} - ` : null }
                                                        Leaders
                                                    </div>
                                                </>
                                            } 
                                            options={['']} 
                                            checked={showLeaderBarPoints} 
                                            onChange={() => { setShowLeaderBarPoints(!showLeaderBarPoints) }} />

                                        { comparisonSections ?
                                            <>
                                                <Input className="report-comparison-options__checkbox" 
                                                    type="checkbox" 
                                                    append={
                                                        <>
                                                            <svg className="icon" width="14.7" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 5">
                                                                <path d="M14.2 2H4.9C4.7.9 3.7 0 2.5 0 1.1 0 0 1.1 0 2.5S1.1 5 2.5 5c1.2 0 2.2-.9 2.4-2h9.3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z" fill="#28C5FF"/>
                                                            </svg>
                                                            <div>
                                                                { ( reportType === 0 || reportType === 1 ) && comparisonCohortName ? `${comparisonCohortName} - ` : null }
                                                                { reportType === 2 && comparisonCompletedOn ? `Q${format(utcToZonedTime(parseISO(comparisonCompletedOn), 'UTC'), 'Q yy')} - ` : null }
                                                                Respondents
                                                            </div>
                                                        </>
                                                    } 
                                                    options={['']} 
                                                    checked={showComparisonRespondentBarPoints} 
                                                    values={['']} 
                                                    onChange={() => { setShowComparisonRespondentBarPoints(!showComparisonRespondentBarPoints) }} />
                                                    
                                                <Input className="report-comparison-options__checkbox" 
                                                    type="checkbox" 
                                                    append={
                                                        <>
                                                            <svg className="icon" width="14.7" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 5" fill="#28C5FF">
                                                                <path d="M10.4 2h-2c-.2 0-.5.2-.5.5s.3.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM6.4 2H4.9C4.7.9 3.7 0 2.5 0 1.1 0 0 1.1 0 2.5S1.1 5 2.5 5c1.2 0 2.2-.9 2.4-2h1.5c.3 0 .5-.2.5-.5S6.7 2 6.4 2zM2.5 4C1.7 4 1 3.3 1 2.5S1.7 1 2.5 1 4 1.7 4 2.5 3.3 4 2.5 4zM14.2 2h-1.8c-.3 0-.5.2-.5.5s.3.5.5.5h1.8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/>
                                                            </svg>
                                                            <div>
                                                                { ( reportType === 0 || reportType === 1 ) && comparisonCohortName ? `${comparisonCohortName} - ` : null }
                                                                { reportType === 2 && comparisonCompletedOn ? `Q${format(utcToZonedTime(parseISO(comparisonCompletedOn), 'UTC'), 'Q yy')} - ` : null }
                                                                Leaders
                                                            </div>
                                                        </>
                                                    } 
                                                    options={['']} 
                                                    checked={showComparisonLeaderBarPoints} 
                                                    values={['']} 
                                                    onChange={() => { setShowComparisonLeaderBarPoints(!showComparisonLeaderBarPoints) }} />
                                            </>
                                        : null }
                                    </div>
                                : null }

                                { comparisonSections && comparisonCompletedOn ?
                                    <div>
                                        <h2>Average Scores by Question</h2>

                                        <div className="report-comparison-options__line-key">
                                            <svg className="icon" width="14.7" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 5"><path d="M14.2 2H4.9C4.7.9 3.7 0 2.5 0 1.1 0 0 1.1 0 2.5S1.1 5 2.5 5c1.2 0 2.2-.9 2.4-2h9.3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z" fill="#081e64"/></svg><div>Q{format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')} - Respondents</div>
                                        </div>

                                        <div className="report-comparison-options__line-key">
                                            <svg className="icon" width="14.7" height="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 5"><path d="M14.2 2H4.9C4.7.9 3.7 0 2.5 0 1.1 0 0 1.1 0 2.5S1.1 5 2.5 5c1.2 0 2.2-.9 2.4-2h9.3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z" fill="#28C5FF"/></svg><div>Q{format(utcToZonedTime(parseISO(comparisonCompletedOn), 'UTC'), 'Q yy')} - Respondents</div>
                                        </div>
                                    </div>
                                : null }
                            </div>
                        </ReportSidebar>
                    : null }

                    <ReportMainContent>
                        <div className="report-card">
                            <div className="report-overview-main__header">
                                <div>
                                    <h2 className="header">Report Overview</h2>

                                    { completedOn && ( reportType === 0 || reportType === 1 ) ? 
                                        <div className="grey-text">
                                            {format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'yyyy')}
                                        </div>
                                    : null }

                                    { completedOn && reportType === 2 ? 
                                        <div className="grey-text">
                                            Q{format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')}
                                        </div>
                                    : null }
                                </div>
                                {/*
                                <div className="report-overview-main__header__completed">
                                    <div><strong>{respondentsCompleted}/{respondentsInvited}</strong></div>
                                    Respondents <br />Completed
                                </div>
                                */}
                            </div>

                            { sections ? 
                                <ReportRadarChart sections={sections} 
                                    comparisonSections={comparisonSections} 
                                    productType={productType} 
                                    reportType={reportType} 
                                    showRespondentBarPoints={showRespondentBarPoints} 
                                    showLeaderBarPoints={showLeaderBarPoints} 
                                    showComparisonRespondentBarPoints={showComparisonRespondentBarPoints} 
                                    showComparisonLeaderBarPoints={showComparisonLeaderBarPoints} 
                                    completedOn={completedOn}
                                    comparisonCompletedOn={comparisonCompletedOn} /> 
                            : null }

                            { reportType === 0 ?
                                <div className="results-radar-chart-key">
                                    <div>
                                        <div className="icon"><div className="circle yellow"></div></div>
                                        <div>Untapped Opportunity - up to 55%</div>
                                    </div>
                                    <div>
                                        <div className="icon"><div className="circle green"></div></div>
                                        <div>Emergent Habit - 56-80%</div>
                                    </div>
                                    <div>
                                        <div className="icon"><div className="circle light-blue"></div></div>
                                        <div>Established Habit - over 80%</div>
                                    </div>
                                    <div>
                                        <div className="icon"><div className="line blue"></div></div>
                                        <div>Respondents Score</div>
                                    </div>
                                </div>
                            : null }
                        </div>
                    </ReportMainContent>
                </div>

                { productType === 2 ?
                    <div className="report-content-container">
                        <ReportMainContent>
                            <div className="report-card">
                                <h2 className="header">From &gt; To</h2>
                                
                                { sections ? 
                                    <table className="from-to-table">
                                        <tbody>
                                            { sections.filter(section => !section.onlyFreeText).map(section => 
                                                <tr key={section.id}>
                                                    <td className="name-cell">{ section.name }</td>
                                                    <td className={`score-cell ${getReportScoreColour(section.averageScorePercentage)}`}>{section.averageScorePercentage}%</td>
                                                    <td className="description-cell">{getScoreDescriptionText(section.name)}</td>
                                                    <td className="arrow-cell"></td>
                                                    <td className="score-cell light-blue">&gt;80%</td>
                                                    <td className="description-cell">{getTargetDescriptionText(section.name)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                : null }
                            </div>
                        </ReportMainContent>
                    </div>
                : null }
            </div>
        </div>
    )
}
