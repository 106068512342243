import React from 'react';

import ErrorMessage from '../../ErrorMessage/ErrorMessage';

import './AdminContainer.scss';

export default function AdminContainer({pageError, pageLoading, children}) {
    return (
        <div className="admin-container container-padding-lg">
            { pageLoading ? <div className="loading-circle"></div> : null }
            { pageError ? <div className="container-md"><ErrorMessage className="admin-container-page-error" message={pageError} /></div> : null }
            { !pageError && !pageLoading ? children : null }
        </div>
    );
}
