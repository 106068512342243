import React from 'react';

import './QuestionnaireSteps.scss';

export default function QuestionnaireSteps({productType, activeSectionId, sections, changeSection}) {
    return (
        <div className={`questionnaire-steps ${productType === 1 ? 'leadership-360' : ''} ${productType === 2 ? 'team-diagnostics' : ''}`}>
            <div className="questionnaire-steps-padding">
                <div className="questionnaire-steps-inner">
                    { sections.map(section => {
                        if (section.name) {
                            return (
                                <button key={section.id} className={`${section.id === activeSectionId ? 'active' : ''} ${section.completed ? 'complete' : ''}`} onClick={() => { changeSection(section.id); }}>
                                    <div className="icon">
                                        <div className="check"></div>
                                    </div>
                                    <div className="text">{section.name}</div>
                                </button>
                            );
                        }

                        return false;
                    }) }
                </div>
            </div>
        </div>
    );
}
