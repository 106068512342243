import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getAdminClients } from '../../../../api';

import AdminTop from '../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody, AdminCardHeader } from '../../AdminCard/AdminCard';
import AdminTable, { AdminTableHead, AdminTableBody, AdminTableActionsCell } from '../../AdminTable/AdminTable';

import PencilIcon from '../../../Icons/PencilIcon';
import PlusIcon from '../../../Icons/PlusIcon';

import './Clients.scss';

function ClientTableRow({client, urlPrepend}) {
    return (
        <tr className="client-table-row">
            <td className="client-name no-border"><Link to={`${urlPrepend}/client/${client.id}`}>{client.name}</Link></td>
            <AdminTableActionsCell>
                <div>
                    <Link to={`${urlPrepend}/client/${client.id}`}><PencilIcon className="icon" /></Link>
                </div>
            </AdminTableActionsCell>
        </tr>
    );
}

export default function Clients({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const [clients, setClients] = useState(false);
    const [loadingClients, setLoadingClients] = useState(false);

    const fetchClients = useCallback(() => {
        setLoadingClients(true);

        getAdminClients().then(response => {
            if (response.success) {
                setClients(response.clients);
            }

            setLoadingClients(false);
        }).catch(() => {
            setLoadingClients(false);
        });
    }, []);

    useEffect(() => {
        document.title = `Clients - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        setHeaderBreadcrumbs();
    }, [setHeaderBreadcrumbs]);

    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    return (
        <div className="admin-container container-padding-lg">
            <AdminTop header="Clients">
                <div><Link className="btn blue" to={`${urlPrepend}/add-client`}><PlusIcon /><div>Add a New Client</div></Link></div>
            </AdminTop>
            
            <AdminCard>
                <AdminCardHeader header="All clients" />
                {loadingClients ? <div className="loading-circle relative"></div> : null}

                {!loadingClients && clients.length ?
                    <AdminCardBody>
                        <div className="all-clients-count">Showing <strong>{clients.length}</strong> clients</div>
                        <AdminTable className="clients-table">
                            <AdminTableHead>
                                <tr>
                                    <th colSpan="2">Client name</th>
                                </tr>
                            </AdminTableHead>
                            <AdminTableBody>
                                { clients.slice(0).reverse().map(client => {
                                    return (
                                        <ClientTableRow key={client.id} client={client} clients={clients} setClients={setClients} urlPrepend={urlPrepend} />
                                    );
                                }) }
                            </AdminTableBody>
                        </AdminTable>
                    </AdminCardBody>
                : null }
            </AdminCard>
        </div>
    );
}
