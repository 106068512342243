import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { Store } from '../../../../Store';
import { getClient, getProgramme, getAgents, updateProgramme } from '../../../../api';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../../SuccessMessage/SuccessMessage';

import AdminTop from '../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../AdminCard/AdminCard';
import Input, { InputRow } from '../../../Input/Input';
import AdminActionBar from '../../AdminActionBar/AdminActionBar';
import AdminLeavingPageModal from '../../AdminLeavingPageModal/AdminLeavingPageModal';

import './EditProgramme.scss';

function OrderedSelect({label, name, values, selectedValue, onChange, loading, required}) {
    const [valuesOrdered, setValuesOrdered] = useState();

    useEffect(() => {
        if (values) {
            setValuesOrdered(values.sort((a, b) => {
                if ( a.name < b.name ){
                    return -1;
                }
        
                if ( a.name > b.name ){
                    return 1;
                }
        
                return 0;
            }));
        }
    }, [values]);

    return (
        <Input label={label} 
            name={name} 
            type="select" 
            value={selectedValue} 
            options={valuesOrdered ? valuesOrdered.map(value => {
                return {
                    value: value.id,
                    name: value.name
                }
            } ) : null} 
            required={required} 
            onChange={onChange} 
            loading={loading} />
    );
}

export default function EditProgramme({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const { state } = useContext(Store);
    const { surveyTypes } = state;

    const location = useLocation();
    const params = useMemo(()=> new URLSearchParams(location.search), [location] );

    const [nextLink, setNextLink] = useState();

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [agents, setAgents] = useState(false);

    const [programmeNameValue, setProgrammeNameValue] = useState('');
    const [programmeEmailAddressValue, setProgrammeEmailAddressValue] = useState('');
    const [surveyTypeValue, setSurveyTypeValue] = useState(surveyTypes ? surveyTypes[0] : '');
    const [agentValue, setAgentValue] = useState('');

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const [programmeNameError, setProgrammeNameError] = useState(false);
    const [programmeEmailAddressError, setProgrammeEmailAddressError] = useState(false);
    const [surveyTypeError, setSurveyTypeError] = useState(false);
    const [agentError, setAgentError] = useState(false);

    const {clientId, programmeId} = useParams();

    const [formChange, setFormChange] = useState(false);

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) {
                setClient(response);
            }
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) {
                setProgramme(response);
                setProgrammeNameValue(response.name ? response.name : '');
                setProgrammeEmailAddressValue(response.emailAddress ? response.emailAddress : '');
                setSurveyTypeValue(response.surveyType ? response.surveyType : '');
                setAgentValue(response.agentId ? response.agentId : '');
            }
        });
    }, []);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response.users);
        });
    }, []);

    useEffect(() => {
        document.title = `Edit Programme - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: 'Edit Programme details'
            }
        ]);
    }, [urlPrepend, setHeaderBreadcrumbs]);

    useEffect(() => {
        let nextLinkUrl = new URL(`${urlPrepend}/client/${clientId}/programme/${programmeId}/edit-comms`, window.location.origin);

        if (params.get('commsNextLink')) {
            let nextLinkSearchParams = nextLinkUrl.searchParams;
            nextLinkSearchParams.set('commsNextLink', params.get('commsNextLink'));
        }
        
        setNextLink(nextLinkUrl.toString().replace(window.location.origin, ''));
    }, [urlPrepend, clientId, programmeId, params]);

    const onSubmitEditProgrammeForm = e => {
        e.preventDefault();

        setFormLoading(true);

        setProgrammeNameError(false);
        setProgrammeEmailAddressError(false);
        setSurveyTypeError(false);
        setAgentError(false);
        setFormErrors(false);
        setFormSuccess(false);

        updateProgramme(clientId, programmeId, {
            'surveyType': surveyTypeValue ? parseInt(surveyTypeValue) : false,
            'name': programmeNameValue,
            'emailAddress': programmeEmailAddressValue,
            'agentId': agentValue
        }).then(response => {
            if (response.success) {
                setFormLoading(false);
                setFormSuccess('Your changes have been saved.');
                setFormChange(false);
            } else {
                if (response.errors) {
                    setProgrammeNameError(response?.errors?.Name ?? false);
                    setProgrammeEmailAddressError(response?.errors?.EmailAddress ?? false);
                    setSurveyTypeError(response?.errors?.SurveyType ?? false);
                    setAgentError(response?.errors?.AgentId ?? false);

                    let errors = Object.assign({}, response.errors);

                    delete errors.Name;
                    delete errors.EmailAddress;
                    delete errors.SurveyType;
                    delete errors.AgentId;

                    setFormErrors(errors);
                }

                setFormLoading(false);
            }
        }).catch(() => {
            setFormLoading(false);
        });
    };

    const onChangeProgrammeNameInput = e => {
        setProgrammeNameValue(e.target.value);
        setFormChange(true);
    };

    const onChangeProgrammeEmailAddressInput = e => {
        setProgrammeEmailAddressValue(e.target.value);
        setFormChange(true);
    };

    const onChangeSurveyTypeSelect = e => {
        setSurveyTypeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeAgentSelect = e => {
        setAgentValue(e.target.value);
        setFormChange(true);
    };

    return (
        <div className="admin-container container-padding-lg">
            <AdminTop header="Edit programme" />
            <AdminCard>
                <AdminCardBody>
                    { formErrors ? <ErrorMessage message={formErrors} /> : null }

                    { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

                    <form className="edit-programme-form" onSubmit={onSubmitEditProgrammeForm}>
                        { formLoading ? <div className="loading-circle"></div> : null }

                        <div className={`edit-programme-form-inner ${formLoading ? 'loading' : ''}`}>
                            <InputRow>
                                { client ? 
                                    <div>
                                        <div><strong>Client name*</strong></div>
                                        <div>{client.name}</div>
                                    </div>
                                : null }
                            </InputRow>

                            <InputRow>
                                <Input label="Programme Name*" name="programme-name" type="text" value={programmeNameValue} onChange={onChangeProgrammeNameInput} errors={programmeNameError} />

                                <Input label="Programme email address*" name="programme-comms-email-address" type="email" value={programmeEmailAddressValue} errors={programmeEmailAddressError} onChange={onChangeProgrammeEmailAddressInput} />
                                
                                { surveyTypes?.length > 1 ?
                                    <Input label="Survey type*" 
                                        name="survey-type"
                                        type="select" 
                                        value={surveyTypeValue} 
                                        options={surveyTypes ? surveyTypes.map(surveyType => {
                                            return {
                                                value: surveyType.value,
                                                name: surveyType.name
                                            }
                                        } ) : null} 
                                        onChange={onChangeSurveyTypeSelect} 
                                        size="small" 
                                        errors={surveyTypeError} 
                                        loading={!surveyTypes} />
                                : null }

                                <OrderedSelect label="Assign a Holos agent for this programme*" 
                                    name="assign-agent" 
                                    values={agents} 
                                    selectedValue={agentValue} 
                                    onChange={onChangeAgentSelect} 
                                    errors={agentError} 
                                    loading={!agents} />
                            </InputRow>

                            <button className="btn blue align-right" type="submit">Save Changes</button>
                        </div>
                    </form>
                </AdminCardBody>
            </AdminCard>

            { programme ?
                <AdminActionBar>
                    <div></div>
                    <div>
                        <div><Link className="btn white-border" to={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}>Return to Programme</Link></div>
                        <div><Link className="btn" to={nextLink}>Next Step: Edit Comms</Link></div>
                    </div>
                </AdminActionBar>
            : null }

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>This programme has not been saved.</p>
            </AdminLeavingPageModal>
        </div>
    );
}
