import React, { useContext, useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Store } from "../../Store";
import { isValid, parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import QuestionnaireHeader from "../QuestionnaireHeader/QuestionnaireHeader";
import QuestionnaireFooter from "../QuestionnaireFooter/QuestionnaireFooter";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Input, { InputRow } from '../Input/Input';
import ToolTip from '../ToolTip/ToolTip';

import PlusIcon from '../Icons/PlusIcon';
import CheckIcon from '../Icons/CheckIcon';
import BellIcon from '../Icons/BellIcon';

import "./Nominate.scss";

function NominateRespondentGroup({respondent, index, updateRespondentsData, respondentErrors, removeRespondent, minimumRespondents, respondentTypes, productType}) {
	const { state } = useContext(Store);
	const { apiUrl } = state;

	const { bearerToken } = useParams();

	const showEdit = respondent?.isNew || !respondent?.readOnly;

	const [loading, setLoading] = useState(false);
	
	const [firstNameValue, setFirstNameValue] = useState(respondent.firstName);
	const [lastNameValue, setLastNameValue] = useState(respondent.lastName);
	const [emailValue, setEmailValue] = useState(respondent.emailAddress);
	const [respondentTypeValue, setRespondentTypeValue] = useState(respondent.respondentType);

	let remindersSent = [];

	if (respondent?.inviteSent && isValid(parseISO(respondent.inviteSent))) {
		remindersSent.push(`${format(utcToZonedTime(parseISO(respondent.inviteSent), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong>: Invite received`);
	}

	if (respondent?.remindersSent?.length) {
		let remindersSentIndex = remindersSent.length;

		respondent.remindersSent.forEach(date => {
			if (isValid(parseISO(date))) {
				remindersSent.push(`${format(utcToZonedTime(parseISO(date), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong>: Reminder ${remindersSentIndex} received`);
			}
			
			remindersSentIndex++;
		});
	}

	const onChangeFirstNameInput = e => {
		setFirstNameValue(e.target.value);
		updateRespondentsData(respondent.index, 'firstName', e.target.value);
	};

	const onChangeLastNameInput = e => {
		setLastNameValue(e.target.value);
		updateRespondentsData(respondent.index, 'lastName', e.target.value);
	};

	const onChangeEmailInput = e => {
		setEmailValue(e.target.value);
		updateRespondentsData(respondent.index, 'emailAddress', e.target.value);
	};

	const onChangeRespondentTypeSelect = e => {
		setRespondentTypeValue(e.target.value);
		updateRespondentsData(respondent.index, 'respondentType', parseInt(e.target.value));
	};

	const onClickRemoveBtn = () => {
		removeRespondent(respondent.index);
	};

	const onClickRevokeBtn = () => {
		setLoading(true);

		axios.put(`${apiUrl}Surveys/${respondent.revoked ? 'UnRevokeRespondent' : 'RevokeRespondent'}/${respondent.id}`, false, {
				headers: {
					Authorization: `Bearer ${bearerToken}`,
				},
			}
		).then((response) => {
			if (response.data.success) {
				updateRespondentsData(respondent.index, 'revoked', !respondent.revoked);
			}
			
			setLoading(false);
		}).catch((err) => {
			console.log(err.response);
			setLoading(false);
		}).finally((apiRes) => {
			console.log(apiRes); // Could be success or error
		});
	};

	return (
		<div className={`nominate-respondent-group ${respondent?.error ? 'error' : '' } ${loading ? 'loading' : ''} ${respondent?.revoked ? 'revoked' : ''}`}>
			<div className="nominate-respondent-group-number">{index + 1}</div>
			{ showEdit ?
				<div className="nominate-respondent-group-inputs">
					<InputRow>
						<Input label="First name*" name={`first-name-${respondent.index}`} type="text" value={firstNameValue} onChange={onChangeFirstNameInput} errors={respondentErrors && respondentErrors[`Respondents[${respondent.index}].FirstName`] ? respondentErrors[`Respondents[${respondent.index}].FirstName`] : false} />
						<Input label="Last name*" name={`last-name-${respondent.index}`} type="text" value={lastNameValue} onChange={onChangeLastNameInput} errors={respondentErrors && respondentErrors[`Respondents[${respondent.index}].LastName`] ? respondentErrors[`Respondents[${respondent.index}].LastName`] : false} />
						<Input className="email-input" label="Email*" name={`email-${respondent.index}`} type="email" value={emailValue} onChange={onChangeEmailInput} errors={respondentErrors && respondentErrors[`Respondents[${respondent.index}].EmailAddress`] ? respondentErrors[`Respondents[${respondent.index}].EmailAddress`] : false} />
						{ respondent?.error ? <>
							{ productType === 1 ?
                        		<ToolTip className="warning-wrap" toolTipText="The email to this respondent wasn't delivered. Please enter the correct email address and re-submit"><div className="warning">!</div></ToolTip>
							: null }
							{ productType === 2 ?
                        		<ToolTip className="warning-wrap" toolTipText="The email to this team member wasn't delivered. Please enter the correct email address and re-submit"><div className="warning">!</div></ToolTip>
							: null }
						</> : null }
					</InputRow>
					<InputRow>
						<Input label="Role*" type="select" name="respondent-email-address" value={respondentTypeValue} 
							options={respondentTypes ? respondentTypes.map(respondentType => {
								return {
									value: respondentType.value,
									name: respondentType.name
								}
							} ) : null} 
							loading={!respondentTypes} size="small" onChange={onChangeRespondentTypeSelect}
							errors={respondentErrors && respondentErrors[`Respondents[${respondent.index}].RespondentType`] ? respondentErrors[`Respondents[${respondent.index}].RespondentType`] : false} />
						{ respondent.index >= minimumRespondents && respondent.isNew ?
							<div className="nominate-respondent-remove-btn align-bottom">
								<button className="btn small white" type="button" onClick={onClickRemoveBtn}>Remove</button>
							</div>
						: null }
					</InputRow>
				</div>
			: null }
			{ !showEdit ?
				<div className="nominate-respondent-group-details">
					<div className="nominate-respondent-group-details-stats">
						<div>
							<div><strong>{firstNameValue} {lastNameValue}</strong></div>
							<div className="email-address">{emailValue}</div>
							<div>{respondentTypes ? respondentTypes.filter(respondentType => respondentType.value === respondentTypeValue)[0]?.name : null}</div>
						</div>
						<div>
							<div><strong>Survey Progress</strong></div>
							<div className="nominate-respondent-group-survey-progress">
								<div className={`status-percentage ${respondent?.isCompleted ? 'green' : ''}`}>
									<div className="status-bar">
										<div style={{width: `${respondent.progressPercentage ? Math.round(respondent.progressPercentage) : 0}%`}}></div>
									</div>
									<div className="percentage">{respondent.progressPercentage ? Math.round(respondent.progressPercentage) : 0}%</div>
								</div>
								{ respondent?.isCompleted ? <div className="check"><CheckIcon /></div> : null }
							</div>
							{ respondent?.inviteSent && isValid(parseISO(respondent.inviteSent)) && respondent?.progressPercentage === 0 && respondent?.reminderCount === 0 ? 
								<div className="reminders">
									<div>{`Invite Received: ${format(utcToZonedTime(parseISO(respondent.inviteSent), 'UTC'), 'dd MMM yy, H:mm')}`} <strong>UTC</strong></div>
								</div> 
							: null }
							{ respondent?.progressPercentage === 0 && respondent?.reminderCount > 0 ?
								<div className="reminders">
									<ToolTip className="icon" toolTipText={remindersSent ?? false}>
										<BellIcon />
										<div className="overlay-no">{remindersSent?.length}</div>
									</ToolTip>
									<div><strong>{respondent.reminderCount}</strong> { respondent.reminderCount === 1 ? 'reminder' : 'reminders' } sent</div>
								</div>
							: null }
						</div>
					</div>
					{ !respondent?.isCompleted ?
						<div className="nominate-respondent-group-details-revoke-btn">
							{ productType === 1 ?
								<ToolTip toolTipText="Revoking this respondent means that their feedback will no longer appear in the final report."><button className="btn small white" type="button" onClick={onClickRevokeBtn}>{ respondent.revoked ? 'Reinstate' : 'Revoke' }</button></ToolTip>
							: null }
							{ productType === 2 ?
								<ToolTip toolTipText="Revoking this team member means that their feedback will no longer appear in the final report."><button className="btn small white" type="button" onClick={onClickRevokeBtn}>{ respondent.revoked ? 'Reinstate' : 'Revoke' }</button></ToolTip>
							: null }
						</div>
					: null }
				</div>
			: null }
		</div>
	)
}

export default function Nominate() {
	const { state } = useContext(Store);
	const { apiUrl, fullScreenHeight } = state;

	const { bearerToken } = useParams();

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showCompleted, setShowCompleted] = useState(false);
	const [showResubmitCompleted, setShowResubmitCompleted] = useState(false);
	const [startLeadershipReviewBtnToken, setStartLeadershipReviewBtnToken] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState(false);
	const [respondents, setRespondents] = useState([
		{ index: 0, firstName: '', lastName: '', emailAddress: '', respondentType: 2, updated: true, isNew: true },
		{ index: 1, firstName: '', lastName: '', emailAddress: '', respondentType: 3, updated: true, isNew: true },
		{ index: 2, firstName: '', lastName: '', emailAddress: '', respondentType: 4, updated: true, isNew: true },
	]);
	const [respondentErrors, setRespondentErrors] = useState();
	const [surveyName, setSurveyName] = useState();
	const [surveyDeadline, setSurveyDeadline] = useState();
	const [recommendedRespondents, setRecommendedRespondents] = useState();
	const [minimumRespondents, setMinimumRespondents] = useState();
	const [respondentTypes, setRespondentTypes] = useState();
	const [leaderName, setLeaderName] = useState();
	const [programmeEmailAddress, setProgrammeEmailAddress] = useState();
	const [productType, setProductType] = useState();
	const [teamName, setTeamName] = useState();
	const [respondentsSet, setRespondentsSet] = useState();

	const fetchRespondents = useCallback(() => {
		setLoading(true);

		axios.get(`${apiUrl}Surveys/Respondents`, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		}).then((response) => {
			if (response.data.ghanaFlag) {
				let respondentsData = [];

				response.data.respondents.forEach((respondent,index) => {
					respondent.index = index;
					respondentsData.push(respondent);
				});

				setRespondents(respondentsData);
			} else {
				let respondentsData = response.data.respondents ?? [];
				let respondentIndex = 0;

				response.data.respondentTypeCounts.forEach(respondentType => {
					for (let i = 0; i < respondentType.count; i++) {
						respondentsData.push({ index: respondentIndex, firstName: '', lastName: '', emailAddress: '', respondentType: respondentType.respondentType, updated: true, isNew: true });
						respondentIndex++;
					}
				});
	
				setRespondents(respondentsData);
			}

			setSurveyName(response.data.surveyName);
			setSurveyDeadline(response.data.surveyDeadline);
			setRecommendedRespondents(response.data.recommendedRespondents);
			setMinimumRespondents(response.data.minimumRespondents);
			setLeaderName(response.data.leaderName);
			setProgrammeEmailAddress(response.data.programmeEmailAddress);
			setProductType(response.data.productType);
			setTeamName(response.data.teamName);

			setRespondentsSet(response.data.ghanaFlag);

			setShowForm(true);
			setLoading(false);
		}).catch((err) => {
			setErrorMessage(err.response.data.errors ?? err.message);
			setLoading(false);
		}).finally((apiRes) => {
			console.log(apiRes); // Could be success or error
		});
	}, [apiUrl, bearerToken]);

	const fetchRespondentTypes = useCallback(() => {
		axios.get(`${apiUrl}Enums/RespondentTypes`, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		}).then((response) => {
			setRespondentTypes(response.data);
		});
	}, [apiUrl, bearerToken]);

	const submitNominateForm = (e) => {
		e.preventDefault();

		setFormErrorMessage(false);
		setFormLoading(true);

		axios.put(`${apiUrl}Surveys/${respondentsSet ? "UpdateRespondents" : "AddRespondents"}`, {
				respondents: respondents.filter(respondent => respondent.updated),
			}, {
				headers: {
					Authorization: `Bearer ${bearerToken}`,
				},
			}
		).then((response) => {
			if (response.data.leaderToken) {
				setStartLeadershipReviewBtnToken(response.data.leaderToken);
			}

			if (respondentsSet) {
				setShowResubmitCompleted(true);
			} else {
				setShowCompleted(true);
			}
			
			setShowForm(false);
			setFormLoading(false);
		}).catch((err) => {
			console.log(err.response);
			setRespondentErrors(err.response.data.errors);
			
			let errors = Object.assign({}, err.response.data.errors);

			respondents.forEach((respondent,index) => {
                delete errors[`Respondents[${index}].EmailAddress`];
                delete errors[`Respondents[${index}].FirstName`];
				delete errors[`Respondents[${index}].LastName`];
			});

			setFormErrorMessage(errors);
			setFormLoading(false);

			window.scrollTo(0, 0);
		}).finally((apiRes) => {
			console.log(apiRes); // Could be success or error
		});
	};

	const updateRespondentsData = (index, field, value) => {
		let respondentsData = respondents;

		respondentsData.forEach((respondent, respondentIndex) => {
			if (index === respondent.index) {
				respondentsData[respondentIndex][field] = value;
				respondentsData[respondentIndex]['updated'] = true;
			}
		});

		return setRespondents(respondentsData);
	};

	const addRespondent = respondentType => {
		let index = respondents.length ? respondents[respondents.length - 1].index + 1 : 0;

		return setRespondents(respondents.concat({ index, firstName: '', lastName: '', emailAddress: '', respondentType, updated: true, isNew: true }));
	};

	const removeRespondent = index => {
		if (respondents.length > minimumRespondents) {
			return setRespondents(respondents.filter(respondent => index !== respondent.index));
		}
	};

	useEffect(() => {
		fetchRespondents();
	}, [fetchRespondents]);

	useEffect(() => {
		fetchRespondentTypes();
	}, [fetchRespondentTypes]);

	return (
		<div className="page-wrap" style={{ minHeight: fullScreenHeight }}>
			<QuestionnaireHeader productType={productType} surveyName={surveyName} isLeader={true} leaderName={leaderName} teamName={teamName} />
			<main className="nominate-main-content">
				{loading ? <div className="loading-circle"></div> : null}

				{errorMessage ? <div className="container-lg container-padding-lg"><ErrorMessage message={errorMessage} /></div> : null}

				{showCompleted ? (
					<div className="container-lg container-padding-lg">
						<h2 className="header">Next Steps</h2>

						{ productType === 1 ?
							<p>You and your respondents have now been sent an email to complete your leadership review.<br />
							You can complete this now by clicking on the button below OR following the link in the email. <br />
							The review takes 20-25 mins to complete.</p>
						: null }

						{ productType === 2 ?
							<p>You and your team members have now been sent an email to complete your Team Diagnostic.<br />
							You can complete this now by clicking on the button below OR following the link in the email. <br />
							The review takes 20-25 mins to complete.</p>
						: null }

						{startLeadershipReviewBtnToken ?
							<Link className="start-leadership-review-btn btn blue" to={`/survey/${startLeadershipReviewBtnToken}`}>
								{ productType === 1 ? <div>Start Leadership Review</div> : null }
								{ productType === 2 ? <div>Start Team Diagnostic</div> : null }
							</Link>
						: null}

						{ productType === 1 ?
							<p>You can return to this page at any time to see progress updates for you respondents.</p>
						: null }

						{ productType === 2 ?
							<p>You can return to this page at any time to see progress updates for your team members.</p>
						: null }
					</div>
				) : null}

				{showResubmitCompleted ? (
					<div className="container-lg container-padding-lg">
						<h2 className="header">Thank You</h2>
						
						{ productType === 1 ?
							<p>Your respondent will receive their invitation to your Leadership Review shortly.</p>
						: null }

						{ productType === 2 ?
							<p>Your respondent will receive their invitation shortly.</p>
						: null }

						<p>Thank you!</p>
					</div>
				) : null}

				{showForm ? (
					<div>
						{!respondentsSet ?
							<div className="nominate-intro-text container-lg container-padding-lg">
								{ productType === 1 ? <>
									<h2 className="header">Nominating your Respondents</h2>

									<p>A Review just isn’t a Review without input from the people we know and work with most.</p>

									<p>Please provide the names and email addresses below. Once you click Submit, your Respondents will automatically be emailed with their invitation to complete your Leadership Review. You may want to have a quick word with each of them before nominating and inviting them, so they know what to expect.</p>

									{ programmeEmailAddress ? <p>In the meantime, if you have any questions please contact: <a href={`mailto:${programmeEmailAddress}`}>{programmeEmailAddress}</a></p> : null }

									<p>Thank you!</p>
								</> : null }
								{ productType === 2 ? <>
									<h2 className="header">Nominating your Team</h2>

									<p>The Team Diagnostic works best when the whole team give really honest input.</p>

									<p>Please provide the names and email addresses below. Once you click Submit, your team members will automatically be emailed with their invitation to complete the Team Diagnostic.  You may want to let people know what to expect and let them know that their contributions will be used to help the team become even more effective and even more enjoyable.</p>

									{ programmeEmailAddress ? <p>In the meantime, if you have any questions please contact: <a href={`mailto:${programmeEmailAddress}`}>{programmeEmailAddress}</a></p> : null }

									<p>Thank you!</p>
								</> : null }
								<hr />
							</div>
						: null }

						<form
						className="nominate-form"
						onSubmit={(e) => submitNominateForm(e)}
						>
						{formLoading ? <div className="loading-circle"></div> : null}

						{formErrorMessage ? (
							<div className="container-lg container-padding-lg"><ErrorMessage message={formErrorMessage} /></div>
						) : null}

							<div className={`nominate-form-inner container-lg container-padding-lg ${formLoading ? "loading" : ""}`}>
								{!respondentsSet ?
									<div>
										{ productType === 1 ? <h3>Add Respondents</h3> : null }
										{ productType === 2 ? <h3>Add Team Members</h3> : null }
										{recommendedRespondents}
									</div>
								: null }

								{respondentsSet ?
									<div>
									{ productType === 1 ?
										<h2 className="header">Your Respondents</h2>
									: null }
									{ productType === 2 ?
										<h2 className="header">Team Members</h2>
									: null }
										{ surveyDeadline ? <p><strong>Completion Deadline: {format(utcToZonedTime(parseISO(surveyDeadline), 'UTC'), 'dd.MM.yyyy')}</strong></p> : null }
									</div>
								: null }

								{ respondents && respondents.length ? 
									<div>
										{ respondents.map((respondent, index) => <NominateRespondentGroup key={respondent.index} index={index} respondent={respondent} updateRespondentsData={updateRespondentsData} respondentErrors={respondentErrors} removeRespondent={removeRespondent} minimumRespondents={minimumRespondents} respondentTypes={respondentTypes} productType={productType} /> )}
									</div>
								: null }

								{ productType === 1 && minimumRespondents ? <div className="nominate-bottom-message">For this Leadership Review you only need {minimumRespondents} {minimumRespondents === 1 ? 'respondent' : 'respondents' } but if you would like to add one or two more then please add them below.</div> : null }

								<div className="nominate-form-btns">
									<div>
										<button className="add-nomination-btn btn" type="button" onClick={() => addRespondent(2)}>
											<PlusIcon />
											<div>
												{ productType === 1 ? 'Add Another Respondent' : null }
												{ productType === 2 ? 'Add Another Team Member' : null }
											</div>
										</button>
									</div>
									<div><button className="btn blue" type="submit">{respondentsSet ? 'Resubmit' : 'Submit'}</button></div>
								</div>
							</div>
						</form>
					</div>
				) : null}
      		</main>

      		<QuestionnaireFooter />
    	</div>
  	);
}
