import React, { useState } from 'react';

import ChevronDown from '../../Icons/ChevronDown';

import './AdminCard.scss';

export function AdminCardHeader({className, header}) {
    return (
        <div className={`admin-card-header ${className ? className : ''}`}>
            <h2 className="header">{header}</h2>
        </div>
    );
}

export function AdminCardBody({className, children}) {
    return (
        <div className={`admin-card-body ${className ? className : ''}`}>
            <div className="admin-card-body-inner">
                {children}
            </div>
        </div>
    );
}

export function AdminCardNumber({className, children}) {
    return (
        <div className={`admin-card-number ${className ? className : ''}`}>
            <div>
                {children}
            </div>
        </div>
    )
}

export function AdminCardAccordion({className, header, children}) {
    const [show, setShow] = useState(false);

    const onClickAdminCardHeaderButton = () => {
        setShow(!show);
    };

    return (
        <div className={`admin-card-accordion ${className ? className : ''} ${show ? 'show' : ''}`}>
            <button type="button" className="admin-card-accordion-header" onClick={onClickAdminCardHeaderButton}>
                <h2>{header}</h2><ChevronDown className="icon" />
            </button>
            <div className="admin-card-accordion-body">
                <div className="admin-card-accordion-body-inner">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default function AdminCard({className, children}) {
    return (
        <section className={`admin-card ${className ? className : ''}`}>
            {children}
        </section>
    );
}
