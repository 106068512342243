import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { logout } from '../../../auth';
import { Store } from '../../../Store';

import ChevronRight from '../../Icons/ChevronRight';
import ChevronDown from '../../Icons/ChevronDown';
import List from '../../Icons/List';

import Logo from '../../SVGImages/Logo';

import './AdminHeader.scss';

import logoWhiteWithoutSlogan from '../../../images/logo-white-without-slogan.svg';

export default function AdminHeader({headerBreadcrumbs, roles, productName, productClass, urlPrepend}) {
    const { state } = useContext(Store);
    const { products, userName } = state;

    const [activeDropdown, setActiveDropdown] = useState(false);

    return (
        <div>
            <header className={`admin-header ${productClass}`}>
                <div className="admin-header-inner">
                    <div>
                        <Link to={`${urlPrepend ?? ''}/`}><Logo className="logo" /></Link>
                    </div>
                    <div className="dropdown-wrap" onMouseEnter={() => setActiveDropdown('client')} onMouseLeave={() => setActiveDropdown(false)}>
                        <button className="client-link link" type="button">
                            <div>{productName}</div><ChevronDown className="icon down-icon" />
                        </button>

                        <div className={`dropdown ${activeDropdown === 'client' ? 'active' : ''}`}>
                            <div className="dropdown-inner">
                                { products ? products.map(product => <Link key={product.productType} to={`${product?.urlPrepend}/`}>{product?.productName}</Link> ) : null }
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div>
                        <Link className="link" to={`${urlPrepend ?? ''}/`}><List className="icon" /><div>Programmes</div></Link>
                    </div>
                </div>
                <div className="admin-header-inner">
                    { roles && roles.includes('HolosAdmin') ?
                        <>
                            <div>
                                <NavLink className="link" to={`${urlPrepend ?? ''}/agents`} activeClassName="active">Agents</NavLink>
                            </div>
                            <div>
                                <NavLink className="link" to={`${urlPrepend ?? ''}/clients`} activeClassName="active">Clients</NavLink>
                            </div>
                        </>
                    : null }
                    { roles && roles.includes('HolosAdmin') ? <div className="divider"></div> : null }
                    <div className="dropdown-wrap" onMouseEnter={() => setActiveDropdown('user')} onMouseLeave={() => setActiveDropdown(false)}>
                        <button className="user-link link" type="button"><div>{userName}</div><ChevronDown className="icon down-icon" /></button>

                        <div className={`dropdown ${activeDropdown === 'user' ? 'active' : ''}`}>
                            <div className="dropdown-inner">
                                <Link to={`${urlPrepend ?? ''}/edit-profile`}>Edit Profile</Link>
                                <Link to="/" onClick={e => (logout())}>Log Out</Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="user-icon">
                            <img className="logo" src={logoWhiteWithoutSlogan} alt="Holos" />
                        </div>
                    </div>
                </div>
            </header>

            { headerBreadcrumbs ?
                <div className="admin-breadcrumbs">
                    { headerBreadcrumbs.map((breadcrumb, index) => {
                        return (
                            <div key={index}>
                                { breadcrumb.link ? <Link to={breadcrumb.link}>{breadcrumb.text}</Link> : breadcrumb.text }
                                <ChevronRight className="icon" />
                            </div>
                        );
                    }) }
                </div>
            : null }
        </div>
    );
}
