import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Store } from '../../../../../Store';
import { getClient, getProgramme, getTeams, getAgents, updateTeam, getTeam, generateTeamDates } from '../../../../../api';
import { sortNameAlphabetically, dateTimeToUTC } from '../../../../../helpers';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody, AdminCardNumber } from '../../../AdminCard/AdminCard';
import Input, { InputRow, InputGroup } from '../../../../Input/Input';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import UploadLeadersFormModal from '../../../UploadLeadersFormModal/UploadLeadersFormModal';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import BellIcon from '../../../../Icons/BellIcon';

import './EditTeam.scss';

function EditTeamCard({team, number, agents, setFormChange, onlyManualEntry}) {
    const { state } = useContext(Store);
    const { times, defaultTime } = state;

    const {clientId, programmeId, teamId} = useParams();

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const [nameValue, setNameValue] = useState(team?.name ?? '');
    const [agentValue, setAgentValue] = useState(team?.agentId ?? '');
    const [nominationInviteDateValue, setNominationInviteDateValue] = useState(team?.nominationInviteDate ? utcToZonedTime(parseISO(team?.nominationInviteDate), 'UTC') : '');
    const [nominationInviteTimeValue, setNominationInviteTimeValue] = useState(team?.nominationInviteDate ? format(utcToZonedTime(parseISO(team?.nominationInviteDate), 'UTC'), 'HH:mm') : defaultTime);
    const [surveySendDateValue, setSurveySendDateValue] = useState(team?.surveySendDate ? utcToZonedTime(parseISO(team?.surveySendDate), 'UTC') : '');
    const [surveySendTimeValue, setSurveySendTimeValue] = useState(team?.surveySendDate ? format(utcToZonedTime(parseISO(team?.surveySendDate), 'UTC'), 'HH:mm') : defaultTime);
    const debriefDateValue = team.debriefDate ?? '';
    const debriefTimeValue = team.debriefTime ?? defaultTime;
    const [nominationReminder1DateValue, setNominationReminder1DateValue] = useState(team?.nominationsReminder1 ? utcToZonedTime(parseISO(team?.nominationsReminder1), 'UTC') : '');
    const [nominationReminder1TimeValue, setNominationReminder1TimeValue] = useState(team?.nominationsReminder1 ? format(utcToZonedTime(parseISO(team?.nominationsReminder1), 'UTC'), 'HH:mm') : defaultTime);
    const [nominationsDeadlineDateValue, setNominationsDeadlineDateValue] = useState(team?.nominationsDeadline ? utcToZonedTime(parseISO(team?.nominationsDeadline), 'UTC') : '');
    const [nominationsDeadlineTimeValue, setNominationsDeadlineTimeValue] = useState(team?.nominationsDeadline ? format(utcToZonedTime(parseISO(team?.nominationsDeadline), 'UTC'), 'HH:mm') : defaultTime);
    const [surveyReminder1DateValue, setSurveyReminder1DateValue] = useState(team?.surveyReminder1 ? utcToZonedTime(parseISO(team?.surveyReminder1), 'UTC') : '');
    const [surveyReminder1TimeValue, setSurveyReminder1TimeValue] = useState(team?.surveyReminder1 ? format(utcToZonedTime(parseISO(team?.surveyReminder1), 'UTC'), 'HH:mm') : defaultTime);
    const [surveyReminder2DateValue, setSurveyReminder2DateValue] = useState(team?.surveyReminder2 ? utcToZonedTime(parseISO(team?.surveyReminder2), 'UTC') : '');
    const [surveyReminder2TimeValue, setSurveyReminder2TimeValue] = useState(team?.surveyReminder2 ? format(utcToZonedTime(parseISO(team?.surveyReminder2), 'UTC'), 'HH:mm') : defaultTime);
    const [surveyDeadlineDateValue, setSurveyDeadlineDateValue] = useState(team?.surveyDeadline ? utcToZonedTime(parseISO(team?.surveyDeadline), 'UTC') : '');
    const [surveyDeadlineTimeValue, setSurveyDeadlineTimeValue] = useState(team?.surveyDeadline ? format(utcToZonedTime(parseISO(team?.surveyDeadline), 'UTC'), 'HH:mm') : defaultTime);

    const [nameError, setNameError] = useState(false);
    const [agentError, setAgentError] = useState(false);
    const [nominationInviteDateError, setNominationInviteDateError] = useState(false);
    const [surveySendDateError, setSurveySendDateError] = useState(false);
    const [nominationReminder1DateError, setNominationReminder1DateError] = useState(false);
    const [nominationsDeadlineDateError, setNominationsDeadlineDateError] = useState(false);
    const [surveyReminder1DateError, setSurveyReminder1DateError] = useState(false);
    const [surveyReminder2DateError, setSurveyReminder2DateError] = useState(false);
    const [surveyDeadlineDateError, setSurveyDeadlineDateError] = useState(false);

    const [showUploadLeadersModal, setShowUploadLeadersModal] = useState(false);

    const onSubmitEditTeamForm = e => {
        e.preventDefault();

        setFormLoading(true);

        setFormErrors(false);
        setNameError(false);
        setAgentError(false);
        setNominationInviteDateError(false);
        setNominationReminder1DateError(false);
        setNominationsDeadlineDateError(false);
        setSurveyReminder1DateError(false);
        setSurveyReminder2DateError(false);
        setSurveyDeadlineDateError(false);
        setFormSuccess(false);

        const saveThen = response => {
            if (response?.success) {
                setFormSuccess('Your changes have been saved.');
                setFormChange(false);
            }

            if (response?.errors) {
                setNameError(response.errors?.Name ?? false);
                setAgentError(response.errors?.AgentId ?? false);
                setNominationInviteDateError(response.errors?.NominationInviteDate ?? false);
                setNominationReminder1DateError(response.errors?.NominationsReminder1 ?? false);
                setNominationsDeadlineDateError(response.errors?.NominationsDeadline ?? false);
                setSurveyReminder1DateError(response.errors?.SurveyReminder1 ?? false);
                setSurveyReminder2DateError(response.errors?.SurveyReminder2 ?? false);
                setSurveyDeadlineDateError(response.errors?.SurveyDeadline ?? false);

                let errors = Object.assign({}, response.errors);

                delete errors.Name;
                delete errors.AgentId;
                delete errors.LeaderFirstName;
                delete errors.LeaderLastName;
                delete errors.LeaderEmailAddress;
                delete errors.SurveyReminder1;
                delete errors.SurveyReminder2;
                delete errors.SurveyDeadline;

                if ( onlyManualEntry ) {
                    setSurveySendDateError(response.errors?.SurveySendDate ?? false);

                    delete errors.SurveySendDate;
                } else {
                    setNominationInviteDateError(response.errors?.NominationInviteDate ?? false);
                    setNominationReminder1DateError(response.errors?.NominationsReminder1 ?? false);
                    setNominationsDeadlineDateError(response.errors?.NominationsDeadline ?? false);

                    delete errors.NominationInviteDate;
                    delete errors.NominationsReminder1;
                    delete errors.NominationsDeadline;
                }

                setFormErrors(errors);
            }

            setFormLoading(false);
        }

        if ( onlyManualEntry ) {
            updateTeam(clientId, programmeId, team.teamId, {
                name: nameValue,
                agentId: agentValue,
                surveySendDate: dateTimeToUTC(surveySendDateValue, surveySendTimeValue),
                debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue),
            }).then(response => saveThen(response));
        } else {
            updateTeam(clientId, programmeId, team.teamId, {
                name: nameValue,
                agentId: agentValue,
                nominationInviteDate: dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue),
                nominationsDeadline: dateTimeToUTC(nominationsDeadlineDateValue, nominationsDeadlineTimeValue),
                surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                nominationsReminder1: dateTimeToUTC(nominationReminder1DateValue, nominationReminder1TimeValue),
                surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue)
            }).then(response => saveThen(response));
        }
    };

    const onClickGenerateDatesBtn = () => {
        setFormLoading(true);
        setNominationInviteDateError(false);
        setSurveyDeadlineDateError(false);

        if (!dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue)) {
            setNominationInviteDateError(['Nomination deadline date is required']);
        }

        if (!dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue)) {
            setSurveyDeadlineDateError(['Survey deadline date is required']);
        }

        if (!dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue) || !dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue)) {
            setFormLoading(false);
            return false;
        }

        generateTeamDates(dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue), dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue))
            .then(response => {
                setNominationReminder1DateValue(response?.nominationsReminder1 ? utcToZonedTime(parseISO(response.nominationsReminder1), 'UTC') : null);
                setNominationReminder1TimeValue(response?.nominationsReminder1 ? format(utcToZonedTime(parseISO(response.nominationsReminder1), 'UTC'), 'HH:mm') : null);
                setNominationsDeadlineDateValue(response?.nominationsDeadline ? utcToZonedTime(parseISO(response.nominationsDeadline), 'UTC') : null);
                setNominationsDeadlineTimeValue(response?.nominationsDeadline ? format(utcToZonedTime(parseISO(response.nominationsDeadline), 'UTC'), 'HH:mm') : null);

                setSurveyReminder1DateValue(response?.surveyReminder1 ? utcToZonedTime(parseISO(response.surveyReminder1), 'UTC') : null);
                setSurveyReminder1TimeValue(response?.surveyReminder1 ? format(utcToZonedTime(parseISO(response.surveyReminder1), 'UTC'), 'HH:mm') : null);
                setSurveyReminder2DateValue(response?.surveyReminder2 ? utcToZonedTime(parseISO(response.surveyReminder2), 'UTC') : null);
                setSurveyReminder2TimeValue(response?.surveyReminder2 ? format(utcToZonedTime(parseISO(response.surveyReminder2), 'UTC'), 'HH:mm') : null);

                setFormLoading(false);
                setFormChange(true);
            });
    };

    const onSubmitUploadLeadersFormSuccess = () => {
        setShowUploadLeadersModal(false);
    };

    const onChangeNameInput = e => {
        setNameValue(e.target.value);
        setFormChange(true);
    };

    const onChangeAgentSelect = e => {
        setAgentValue(e.target.value);
        setFormChange(true);
    };

    const onChangeNominationInviteDateInput = date => {
        setNominationInviteDateValue(date);
        setFormChange(true);
    };

    const onChangeNominationInviteTimeInput = e => {
        setNominationInviteTimeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeSurveySendDateInput = date => {
        setSurveySendDateValue(date);
        setFormChange(true);
    };

    const onChangeSurveySendTimeInput = e => {
        setSurveySendTimeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeNominationReminder1DateInput = date => {
        setNominationReminder1DateValue(date);
        setFormChange(true);
    };

    const onChangeNominationReminder1TimeInput = e => {
        setNominationReminder1TimeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeNominationsDeadlineDateInput = date => {
        setNominationsDeadlineDateValue(date);
        setFormChange(true);
    };

    const onChangeNominationsDeadlineTimeInput = e => {
        setNominationsDeadlineTimeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeSurveyReminder1DateInput = date => {
        setSurveyReminder1DateValue(date);
        setFormChange(true);
    };

    const onChangeSurveyReminder1TimeInput = e => {
        setSurveyReminder1TimeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeSurveyReminder2DateInput = date => {
        setSurveyReminder2DateValue(date);
        setFormChange(true);
    };

    const onChangeSurveyReminder2TimeInput = e => {
        setSurveyReminder2TimeValue(e.target.value);
        setFormChange(true);
    };

    const onChangeSurveyDeadlineDateInput = date => {
        setSurveyDeadlineDateValue(date);
        setFormChange(true);
    };

    const onChangeSurveyDeadlineTimeInput = e => {
        setSurveyDeadlineTimeValue(e.target.value);
        setFormChange(true);
    };

    return (
        <AdminCard>
            <AdminCardNumber>
                {number}
            </AdminCardNumber>
            <AdminCardBody>
                { formErrors ? <ErrorMessage message={formErrors} /> : null }

                { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

                <form className="add-new-team-form" onSubmit={onSubmitEditTeamForm}>
                    { formLoading ? <div className="loading-circle"></div> : null }

                    <div className={`add-new-team-form-inner ${formLoading ? 'loading' : ''}`}>
                        <InputRow>
                            <Input type="text" label="Team Name*" name="name" value={nameValue} placeholder="Enter team name" errors={nameError} onChange={onChangeNameInput} />
                            <Input type="select" 
                                label="Assign a Holos agent for this team*" 
                                name="agent" 
                                selectText="Holos Agent" 
                                value={agentValue} 
                                options={agents?.users?.sort(sortNameAlphabetically).map(agent => {
                                    return {
                                        value: agent.id,
                                        name: agent.name
                                    }
                                } ) } 
                                loading={!agents?.users} 
                                errors={agentError} 
                                onChange={onChangeAgentSelect} />
                        </InputRow>

                        <InputRow>
                            { onlyManualEntry === true ?
                                <InputGroup className="date-time-input-group" label="Survey Send Date*" errors={surveySendDateError}>
                                    <Input type="datepicker" name="nomination-invite-date" size="small" value={surveySendDateValue} placeholder="Enter date" onChange={onChangeSurveySendDateInput} />
                                    <Input name="nomination-invite-time" 
                                        type="select" 
                                        value={surveySendTimeValue} 
                                        options={times.map(time => {
                                            return {
                                                value: time.value,
                                                name: time.text
                                            }
                                        } ) } 
                                        size="extra-small" 
                                        onChange={onChangeSurveySendTimeInput} />
                                </InputGroup>
                            : null }

                            { onlyManualEntry === false ?
                                <InputGroup className="date-time-input-group" label="Nomination Invite*" errors={nominationInviteDateError}>
                                    <Input type="datepicker" name="nomination-invite-date" size="small" value={nominationInviteDateValue} placeholder="Enter date" onChange={onChangeNominationInviteDateInput} />
                                    <Input name="nomination-invite-time" 
                                        type="select" 
                                        value={nominationInviteTimeValue} 
                                        options={times.map(time => {
                                            return {
                                                value: time.value,
                                                name: time.text
                                            }
                                        } ) } 
                                        size="extra-small" 
                                        onChange={onChangeNominationInviteTimeInput} />
                                </InputGroup>
                            : null }
                            
                            <InputGroup className="date-time-input-group" label="Survey Deadline*" errors={surveyDeadlineDateError}>
                                <Input type="datepicker" name="survey-deadline-date" size="small" value={surveyDeadlineDateValue} placeholder="Enter date" onChange={onChangeSurveyDeadlineDateInput} />
                                <Input name="survey-deadline-time" 
                                    type="select" 
                                    value={surveyDeadlineTimeValue} 
                                    options={times.map(time => {
                                        return {
                                            value: time.value,
                                            name: time.text
                                        }
                                    } ) } 
                                    size="extra-small" 
                                    onChange={onChangeSurveyDeadlineTimeInput} />
                            </InputGroup>

                            <div className="align-bottom"><button className="btn blue small" type="button" onClick={onClickGenerateDatesBtn}>Re-Generate Dates</button></div>
                        </InputRow>

                        <div className="add-team-reminder-dates">

                            <h3 className="sub-header"><div className="icon"><BellIcon /></div> Automated Email Reminders:</h3>

                            <div className="add-team-reminder-dates-inner">
                                <div className="add-team-reminder-dates-inputs">
                                    { onlyManualEntry === false ?
                                        <InputRow>
                                            <InputGroup className="date-time-input-group" label="Nomination Reminder 1" errors={nominationReminder1DateError}>
                                                <Input type="datepicker" name="nomination-reminder-1-date" size="small" value={nominationReminder1DateValue} placeholder="Enter date" onChange={onChangeNominationReminder1DateInput} />
                                                <Input name="nomination-reminder-1-time" 
                                                    type="select" 
                                                    value={nominationReminder1TimeValue} 
                                                    options={times.map(time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeNominationReminder1TimeInput} />
                                            </InputGroup>

                                            <InputGroup className="date-time-input-group" label="Nomination Deadline (Nomination Reminder 2)" errors={nominationsDeadlineDateError}>
                                                <Input type="datepicker" name="nominations-deadline-date" size="small" value={nominationsDeadlineDateValue} placeholder="Enter date" onChange={onChangeNominationsDeadlineDateInput} />
                                                <Input name="nomination-deadline-time" 
                                                    type="select" 
                                                    value={nominationsDeadlineTimeValue} 
                                                    options={times.map(time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeNominationsDeadlineTimeInput} />
                                            </InputGroup>
                                        </InputRow>
                                    : null }

                                    <InputRow>
                                        <InputGroup className="date-time-input-group" label="Survey Reminder 1" errors={surveyReminder1DateError}>
                                            <Input type="datepicker" name="survey-reminder-1-date" size="small" value={surveyReminder1DateValue} placeholder="Enter date" onChange={onChangeSurveyReminder1DateInput} />
                                            <Input name="survey-reminder-1-time" 
                                                type="select" 
                                                value={surveyReminder1TimeValue} 
                                                options={times.map(time => {
                                                    return {
                                                        value: time.value,
                                                        name: time.text
                                                    }
                                                } ) } 
                                                size="extra-small" 
                                                onChange={onChangeSurveyReminder1TimeInput} />
                                        </InputGroup>

                                        <InputGroup className="date-time-input-group" label="Survey Reminder 2" errors={surveyReminder2DateError}>
                                            <Input type="datepicker" name="survey-reminder-2-date" size="small" value={surveyReminder2DateValue} placeholder="Enter date" onChange={onChangeSurveyReminder2DateInput} />
                                            <Input name="survey-reminder-2-time" 
                                                type="select" 
                                                value={surveyReminder2TimeValue} 
                                                options={times.map(time => {
                                                    return {
                                                        value: time.value,
                                                        name: time.text
                                                    }
                                                } ) } 
                                                size="extra-small" 
                                                onChange={onChangeSurveyReminder2TimeInput} />
                                        </InputGroup>
                                    </InputRow>
                                </div>

                                <div className="add-team-reminder-dates-info">
                                    <div className="add-team-reminder-dates-info-inner">
                                        <div>
                                            <div className="icon">i</div>
                                        </div>
                                        The email reminder dates have been generated based on the dates above and will be sent out automatically until nominations and surveys are completed.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <InputRow className="add-new-team-btns">
                            <InputGroup>
                                <div>
                                    <button type="submit" className="btn blue">Save</button>
                                </div>
                            </InputGroup>
                        </InputRow>
                    </div>
                </form>

                <UploadLeadersFormModal clientId={clientId} programmeId={programmeId} teamId={teamId} showUploadLeadersModal={showUploadLeadersModal} setShowUploadLeadersModal={setShowUploadLeadersModal} onSubmitSuccess={onSubmitUploadLeadersFormSuccess} />
            </AdminCardBody>
        </AdminCard>
    );
}

export default function EditTeam({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const {clientId, programmeId, teamId} = useParams();

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [agents, setAgents] = useState(false);
    const [teams, setTeams] = useState(false);
    const [team, setTeam] = useState(false);

    const [formChange, setFormChange] = useState(false);

    const previousLink = params.get('previousLink') ? decodeURIComponent(params.get('previousLink')) : false;
    const nextLink = params.get('nextLink') ? decodeURIComponent(params.get('nextLink')) : false;
    const nextLinkText = params.get('nextLinkText') ?? 'Next';

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response);
        });
    }, []);

    const fetchTeams = useCallback((clientId, programmeId) => {
        getTeams(clientId, programmeId).then(response => {
            if (response.success) {
                setTeams(response);
            } else {
                setPageError(response.errors);
            }

            setPageLoading(false);
        })
    }, []);

    const fetchTeam = useCallback((clientId, programmeId, teamId) => {
        getTeam(clientId, programmeId, teamId).then(response => {
            if (response.success) setTeam(response);
        })
    }, []);

    useEffect(() => {
        document.title = `Edit team - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    useEffect(() => {
        fetchTeams(clientId, programmeId);
    }, [fetchTeams, clientId, programmeId]);

    useEffect(() => {
        fetchTeam(clientId, programmeId, teamId);
    }, [fetchTeam, clientId, programmeId, teamId]);

    useEffect(() => {
        if (programme && team) {
            setHeaderBreadcrumbs([
                {
                    text: 'Programmes',
                    link: `${urlPrepend}/`
                }, {
                    text: programme.name,
                    link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
                }, {
                    text: team.name,
                    link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}`
                }, {
                    text: 'Edit team'
                }
            ]);
        }
    }, [programme, team, urlPrepend, clientId, programmeId, teamId, setHeaderBreadcrumbs]);

    return (
        <div>
            { !pageLoading && !pageError && client && programme && teams ?
                <AdminInfoBar>
                    { client?.name && programme?.name ? 
                        <div>
                            <h3>{client.name}</h3>
                            <div>{programme.name}</div>
                        </div>
                    : null }
                    { team?.agentName ? 
                        <div>
                            <h3>Holos Programme Lead</h3>
                            <div>{team.agentName}</div>
                        </div>
                    : null }
                </AdminInfoBar>
            : null }

            <AdminContainer pageError={pageError} pageLoading={pageLoading}>
                <AdminTop header="Edit Team" />

                { team ? <EditTeamCard team={team} number={teams.teams && team ? teams.teams.map((thisTeam, index) => thisTeam.teamId === teamId ? index + 1 : null) : null} agents={agents} setFormChange={setFormChange} onlyManualEntry={ typeof programme?.onlyManualEntry === 'boolean' ? programme.onlyManualEntry : null} /> : null }
            </AdminContainer>

            <AdminActionBar>
                <div>
                    { previousLink ? <div><Link className="btn white-border" to={previousLink}>Previous</Link></div> : null }
                </div>
                <div>
                    { nextLink ? <div><Link className="btn" to={nextLink}>{ nextLinkText }</Link></div> : null }
                    { !nextLink ? <div><Link className="btn" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${teamId}`}>Return to team</Link></div> : null }
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={formChange}>
                <h2>Leaving this page?</h2>
                <p>The team has not been saved.</p>
            </AdminLeavingPageModal>
        </div>
    );
}
