import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import {
    Link,
    useLocation,
    useHistory
} from 'react-router-dom';
import {
    parseISO,
    format,
    isValid
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
    getClients,
    getProgrammes,
    getProgramme,
    getTeams,
    getTeamReport,
    deleteTeam,
    getClientReports,
    generateProgrammeReport
} from '../../../../../api';
import { getReportScoreColour } from '../../../../../helpers';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import AdminTable, {
    AdminTableHead,
    AdminTableBody,
    AdminTableActionsCell
} from '../../../AdminTable/AdminTable';
import Input, {
    InputRow,
    InputGroup
} from '../../../../Input/Input';
import AdminModal, { AdminModalBtns } from '../../../AdminModal/AdminModal';
import ToolTip from '../../../../ToolTip/ToolTip';

import BinIcon from '../../../../Icons/BinIcon';
import PlusIcon from '../../../../Icons/PlusIcon';
import PencilIcon from '../../../../Icons/PencilIcon';
import BellIcon from '../../../../Icons/BellIcon';
import CheckIcon from '../../../../Icons/CheckIcon';

import './Programmes.scss';

function ProgrammeDetails({
    programme,
    clientId,
    programmeId,
    clients,
    urlPrepend
}) {
    const [teamsInfo, setTeamsInfo] = useState(false);
    const [report, setReport] = useState(false);
    const [teams, setTeams] = useState(false);
    const [loadingGenerateReport, setLoadingGenerateReport] = useState(false);
    const [showReportGeneratedModal, setShowReportGeneratedModal] = useState(false);

    const fetchClientProgrammeTeams = useCallback((clientId, programmeId) => {
        getTeams(clientId, programmeId).then(response => {
            if (response.success) {
                setTeamsInfo(response);
                setReport({
                    reportGeneratedOn: response?.reportGeneratedOn,
                    reportLink: response?.reportLink,
                });
                setTeams(response.teams ?? []);
            }
        });
    }, []);

    useEffect(() => {
        fetchClientProgrammeTeams(clientId, programmeId);
    }, [fetchClientProgrammeTeams, clientId, programmeId]);

    let getTeamsInterval = null;

    const onSubmitGenerateReportForm = e => {
        e.preventDefault();

        setLoadingGenerateReport(true);
        
        generateProgrammeReport(clientId, programmeId).then(response => {
            if (response?.success) {
                if (getTeamsInterval) {
                    clearInterval(getTeamsInterval);
                }

                getTeamsInterval = setInterval(async () => {
                    await getTeamReport(clientId, programmeId).then(response => {
                        if (response.success && response?.reportGeneratedOn !== report?.reportGeneratedOn) {
                            setShowReportGeneratedModal(true);

                            setReport({
                                reportGeneratedOn: response?.reportGeneratedOn,
                                reportLink: response?.reportLink,
                            });
    
                            clearInterval(getTeamsInterval);
                            
                            setLoadingGenerateReport(false);
                        }

                        if (!response.success) {
                            setLoadingGenerateReport(false);
                        }
                    });
                }, 5000);
            } else {
                setLoadingGenerateReport(false);
            }
        });
    };

    return (
        <AdminCard className="programme-details">
            <AdminCardBody>
                <div className="programme-details-top">
                    <div>
                        { clients && programme?.name ? 
                            <div>
                                <h1>
                                    {clients.filter(client => client.id === clientId).length ? `${clients.filter(client => client.id === clientId)[0]?.name}: ` : ''}{programme.name}
                                </h1>
                            </div> 
                        : null }

                        <div>
                            <Link className="edit-btn"
                                to={`${urlPrepend}/client/${clientId}/programme/${programme.id}/edit`}>
                                
                                <PencilIcon />
                            </Link>
                        </div>
                    </div>

                    <div>
                        {teams.length ?
                            <>
                                {report?.reportLink ?
                                    <div>
                                        {report?.reportGeneratedOn && isValid(parseISO(report.reportGeneratedOn)) ?
                                            <div>
                                                <div className="survey-view-report-generated-on">
                                                    Generated {format(utcToZonedTime(parseISO(report.reportGeneratedOn), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong>
                                                </div>
                                            </div>
                                        : null}

                                        <a className="btn blue"
                                            href={report?.reportLink}
                                            target="_blank"
                                            rel="noopener noreferrer">

                                            View Completed Report
                                        </a>
                                    </div>
                                : null}

                                <div>
                                    <form onSubmit={onSubmitGenerateReportForm}>
                                        <button className="btn"
                                            type="submit"
                                            disabled={loadingGenerateReport}>

                                            {loadingGenerateReport ?
                                                <>Generating Cultural Report</>
                                            :
                                                <>
                                                    {report?.reportLink ?
                                                        <>Re-Generate Cultural Report</>
                                                    :
                                                        <>Create Cultural Diagnostic</>
                                                    }
                                                </>
                                            }
                                        </button>
                                    </form>

                                    <AdminModal show={showReportGeneratedModal} closeModal={() => setShowReportGeneratedModal(false)}>
                                        <h2>Cultural Report Generated</h2>

                                        <p>Your new cultural report has been successfully generated.</p>

                                        <AdminModalBtns>
                                            <button className="btn" type="button" onClick={() => setShowReportGeneratedModal(false)}>Close</button>

                                            <a className="btn blue"
                                                href={report?.reportLink}
                                                target="_blank"
                                                rel="noopener noreferrer">

                                                View Completed Report
                                            </a>
                                        </AdminModalBtns>
                                    </AdminModal>
                                </div>
                            </>
                        : null}

                        <div>
                            <div className="stats">
                                <div>
                                    <strong>Total: {teams?.length} </strong>
                                    { teams?.length === 1 ? 'team' : 'teams' }
                                </div>
                            </div>
                            
                            <Link className="btn blue"
                                to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/add-team?previousLink=${encodeURIComponent(`/?clientId=${clientId}&programmeId=${programmeId}`)}`}>
                                    
                                <PlusIcon />

                                <div>Add New Team</div>
                            </Link>
                        </div>

                        { teams?.reportOverviewsToken ? 
                            <div>
                                <a className="btn blue"
                                    href={`${teams.reportOverviewsToken}/`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                        
                                    View Comparison
                                </a>
                            </div>
                        : null }
                    </div>
                </div>

                <div className="programme-details-top">
                    <div>
                        <div className="programme-info">
                            { teamsInfo?.agentName ?
                                <div>
                                    <h3>Holos Programme Lead</h3>

                                    <div>{teamsInfo.agentName}</div>
                                </div>
                            : null }
                        </div>
                    </div>

                    <div className="last-updated">
                        {/* <div>Last updated 12 Oct 20</div> */}
                    </div>
                </div>

                <AdminTable className="programme-cohort-table">
                    <AdminTableHead>
                        <tr>
                            <th className="cohort-teams-cell">Teams</th>
                            <th className="holos-lead-agent-cell">Holos Lead Agent</th>
                            <th className="invitation-sent-cell">Invitation <br />Sent</th>
                            <th className="nomination-completed-cell">Nomination <br />Completed</th>
                            <th className="survey-completed-cell">Survey <br />Completed</th>
                            <th className="survey-deadline-cell">Survey <br />Deadline</th>
                            <th className="team-members-completed-cell">Team members completed</th>
                            <th className="report-score-cell" colSpan="2">Report Score</th>
                        </tr>
                    </AdminTableHead>

                    <AdminTableBody>
                        { teams.length && teams?.filter(team => !team.revoked)?.length ? teams.filter(team => !team.revoked).map(team => {
                            return (
                                <ProgrammeTeamTableRow key={team.teamId} 
                                    team={team}
                                    teams={teams}
                                    programmeId={programmeId}
                                    clientId={clientId}
                                    teamsInfo={teamsInfo}
                                    setTeams={setTeams}
                                    setTeamsInfo={setTeamsInfo}
                                    urlPrepend={urlPrepend} />
                            );
                        }) : null }
                    </AdminTableBody>
                </AdminTable>

                { teams?.length === 0 ?
                    <div className="no-teams">
                        You have not yet created any Teams<br />
                        for this programme
                    </div>
                : null }
            </AdminCardBody>
        </AdminCard>
    );
}

function ProgrammeTeamTableRow({team, teams, programmeId, clientId, teamsInfo, setTeams, setTeamsInfo, urlPrepend}) {
    const [deleted, setDeleted] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onClickDelete = () => {
        setShowDeleteModal(true);
    };

    const onClickDeleteConfirm = () => {
        deleteTeam(clientId, programmeId, team.teamId).then(response => {
            if (response.success) {
                setDeleted(true);
                setShowDeleteModal(false);

                setTimeout(() => {
                    setTeams(teams.filter(filteredTeam => filteredTeam.teamId !== team.teamId));
                }, 250);
            }
        });
    };

    return (
        <tr className={`admin-table-row ${deleted ? 'deleted' : ''}`}>
            <td className="cohort-teams-cell">
                <div className="cell-icon-wrap">
                    <Link to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${team.teamId}`}>{team.teamName}</Link>
                </div>
            </td>
            <td className="holos-team-lead-cell">
                {team?.agentName ?? <div className="cell-dash"></div> }
            </td>
            <td className="invitation-sent-cell">
                { team?.nominationsInvitesSent?.length && !team?.emailBounced ?
                    <div className="cell-icon-wrap">
                        <div>
                            <div>{format(utcToZonedTime(parseISO(team.nominationsInvitesSent[0]), 'UTC'), 'dd MMM yy')}</div>
                            <div>{format(utcToZonedTime(parseISO(team.nominationsInvitesSent[0]), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                        </div>
                        { team?.respondentInvitesSent ?
                            <div className="check"><CheckIcon /></div>
                        : null }
                        { !team?.respondentInvitesSent && team?.nominationsInvitesSent?.length > 1 ?
                            <ToolTip className="alert date" toolTipText={team.nominationsInvitesSent.filter((email, index) => index !== 0).map((date, index) => isValid(parseISO(date)) ? `${format(utcToZonedTime(parseISO(date), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong>: Reminder ${index + 1} sent` : false )}>
                                <BellIcon />
                                <div className="overlay-no">{team.nominationsInvitesSent.length - 1}</div>
                            </ToolTip> 
                        : null }
                    </div>
                : null }
                { team?.emailBounced ? <div className="cell-icon-wrap"><div className="warning">!</div></div> : null }
            </td>
            <td className="nomination-completed-cell">
                { team?.respondentInvitesSent ?
                    <div className="cell-icon-wrap">
                        <div>
                            <div>{format(utcToZonedTime(parseISO(team.respondentInvitesSent), 'UTC'), 'dd MMM yy')}</div>
                            <div>{format(utcToZonedTime(parseISO(team.respondentInvitesSent), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                        </div>
                        <div className="check"><CheckIcon /></div>
                    </div>
                : <div className="cell-dash"></div> }
            </td>
            <td className="survey-completed-cell">
                { team?.surveyCompleted ?
                    <div className="cell-icon-wrap">
                        <div>
                            <div>{format(utcToZonedTime(parseISO(team.surveyCompleted), 'UTC'), 'dd MMM yy')}</div>
                            <div>{format(utcToZonedTime(parseISO(team.surveyCompleted), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                        </div>
                        <div className="check"><CheckIcon /></div>
                    </div>
                : <div className="cell-dash"></div> }
            </td>
            <td className="survey-deadline-cell">
                { team?.surveyDeadline ?
                    <div className="cell-icon-wrap">
                        <div>
                            <div>{format(utcToZonedTime(parseISO(team.surveyDeadline), 'UTC'), 'dd MMM yy')}</div>
                            <div>{format(utcToZonedTime(parseISO(team.surveyDeadline), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                        </div>
                    </div>
                : <div className="cell-dash"></div> }
            </td>
            <td className="team-members-completed-cell">
                <div className="status-percentage">
                    <div className="status-bar">
                        <div style={{width: `${team?.respondentsCompleted && team?.totalRespondents ? team.respondentsCompleted * (100 / team.totalRespondents) : 0}%`}}></div>
                    </div>
                    <div className="percentage">{ team.respondentsCompleted ?? 0 }/{ team.totalRespondents ?? 0 }</div>
                </div>
            </td>
            <td className={`report-score-cell no-border ${team?.reportScore ? getReportScoreColour(Math.round(team.reportScore)) : ''}`}>
                { team?.reportScore ? `${Math.round(team.reportScore)}%` : null }
            </td>
            <AdminTableActionsCell>
                { team?.reportLink ? <div><a className="btn small" href={team.reportLink} target="_blank" rel="noopener noreferrer">View Report</a></div> : null }
                <div>
                    <button className="delete" disabled={team?.respondentInvitesSent} onClick={onClickDelete}><BinIcon className="icon" /></button>

                    <AdminModal show={showDeleteModal} closeModal={() => setShowDeleteModal(false)}>
                        { team ? <h2>Are you sure you want to delete {team?.teamName}?</h2> : null }
                        <AdminModalBtns>
                            <button className="btn" type="button" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                            <button className="btn blue" type="button" onClick={onClickDeleteConfirm}>Delete Team</button>
                        </AdminModalBtns>
                    </AdminModal>
                </div>
            </AdminTableActionsCell>
        </tr>
    );
}

export default function Programmes({setHeaderBreadcrumbs, urlPrepend, productName, productType}) {
    const location = useLocation();
    const history = useHistory();

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [clients, setClients] = useState(false);
    const [programmes, setProgrammes] = useState();
    const [quarterlyReports, setQuarterlyReports] = useState();
    
    const [programme, setProgramme] = useState();

    const [clientValue, setClientValue] = useState();
    const [programmeValue, setProgrammeValue] = useState();

    const fetchClients = useCallback(() => {
        getClients().then(response => {
            if (response.success) {
                setClients(response.clients.sort((a, b) => {
                    if ( a.name < b.name ) return -1;
            
                    if ( a.name > b.name ) return 1;
            
                    return 0;
                }));
            } else {
                setPageError(response.errors);
            }
        
            setPageLoading(false);
        }).catch(error => {
            if ( error.response?.data?.errors ) {
                setPageError(error.response.data.errors);
            } else if ( error.response?.data?.message ) {
                setPageError(error.response.data.message);
            } else {
                setPageError('There was an error. Please try again.');
            }
        }).finally(() => {
            setPageLoading(false);
        })
    }, []);

    const fetchClientProgrammes = useCallback((clientId, productType) => {
        getProgrammes(clientId, productType).then(response => {
            if (response.success) {
                setProgrammes(response.programmes);
            } else {
                setProgrammes(false);
            }
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchClientReports = useCallback((clientId, productType) => {
        getClientReports(clientId, productType).then(response => {
            if (response.success) {
                setQuarterlyReports(response.reports);
            } else {
                setQuarterlyReports(false);
            }
        })
    }, []);
    
    useEffect(() => {
        document.title = `Programmes - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);
        
    useEffect(() => {
        setHeaderBreadcrumbs();
    }, [setHeaderBreadcrumbs]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (!clients) fetchClients();

        if (params.get('clientId')) {
            fetchClientProgrammes(params.get('clientId'), productType);
            setClientValue(params.get('clientId'));
            fetchClientReports(params.get('clientId'), productType);
        } else {
            setProgrammes();
            setQuarterlyReports(false);
            setClientValue('');
        }

        if (params.get('clientId') && params.get('programmeId')) {
            fetchProgramme(params.get('clientId'), params.get('programmeId'));
            setProgrammeValue(params.get('programmeId'));
        } else {
            setProgramme(false);
            setProgrammeValue('');
        }
    }, [location, clients, productType, fetchClientProgrammes, fetchClients, fetchProgramme, fetchClientReports]);

    const onChangeClientSelect = e => {
        history.push({
            pathname: `${urlPrepend}/`,
            search: `?clientId=${e.target.value}`
        });
    };

    const onChangeClientProgrammeSelect = e => {
        history.push({
            pathname: `${urlPrepend}/`,
            search: `?clientId=${clientValue}&programmeId=${e.target.value}`
        });
    };

    const onChangeQuarterlyReportsSelect = e => {
        window.open(`/report/${e.target.value}`);
    };

    return (
        <AdminContainer pageError={pageError} pageLoading={pageLoading}>
            <div>
                <AdminTop header="Programmes">
                    <div>
                        <Link className="btn blue"
                            to={`${urlPrepend}/create-programme`}>
                                
                            <PlusIcon />
                            
                            <div>Create a New Programme</div>
                        </Link>
                    </div>
                </AdminTop>

                <AdminCard>
                    <AdminCardBody>
                        <InputRow className="find-a-programme">
                            <div className="header">
                                <h2>Find a programme:</h2>
                            </div>

                            <InputGroup>
                                <Input selectText="Choose Client" 
                                    name="add-user-type" 
                                    type="select" 
                                    value={clients ? clientValue : ''} 
                                    options={clients ? clients.map(client => {
                                        return {
                                            value: client.id,
                                            name: client.name
                                        }
                                    }) : null} 
                                    onChange={onChangeClientSelect} />

                                { programmes?.length ? 
                                    <Input type="select" 
                                        selectText="Choose Programme" 
                                        options={programmes.slice(0).reverse().map(client => {
                                            return {
                                                value: client.id,
                                                name: client.name
                                            } 
                                        })} 
                                        value={programmeValue} 
                                        onChange={onChangeClientProgrammeSelect} /> 
                                    : null }

                                { clientValue && programmes?.length === 0 ? <div className="align-center">You currently have no programmes</div> : null }
                            </InputGroup>

                            { quarterlyReports?.length ?
                                <InputGroup className="find-a-programme-reports">
                                    { quarterlyReports?.length ?
                                        <Input type="select" selectText="Quarterly Reports" 
                                            options={quarterlyReports.map(report => {
                                                return {
                                                    value: report.token,
                                                    name: report.name
                                                }
                                            } ) } 
                                            value="" 
                                            onChange={onChangeQuarterlyReportsSelect} />
                                    : null }
                                </InputGroup>
                            : null }
                        </InputRow>
                    </AdminCardBody>
                </AdminCard>
                { programme ? <ProgrammeDetails programme={programme} clientId={clientValue} programmeId={programmeValue} clients={clients} urlPrepend={urlPrepend} /> : null }
            </div>
        </AdminContainer>
    );
}
