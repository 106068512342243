import React, { useContext, useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../../../Store';
import { getReportScoreColour } from '../../../helpers';

import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import ReportTop from '../ReportTop/ReportTop';
import ReportMainContent from '../ReportMainContent/ReportMainContent';
import ReportSidebar from '../ReportSidebar/ReportSidebar';
import ReportScoreCard from '../ReportScoreCard/ReportScoreCard';

import './ReportResponses.scss';

function ReportResponsesQuestion({question}) {
    const [detailsPos, setDetailsPos] = useState(0);
    const percentage = Math.round(question.averageScorePercentage);

    const { bearerToken, sectionId } = useParams();

    const reportResponsesQuestionRef = useRef();
    const reportResponsesQuestionDetailsRef = useRef();

    const onMouseMove = e => {
        const arrowPos = reportResponsesQuestionDetailsRef.current.offsetWidth / 5;
        let position = e.clientX - reportResponsesQuestionRef.current.getBoundingClientRect().left - arrowPos;

        setDetailsPos(position);
    }

    return (
        <div key={question.id} className="report-responses-question">
            <div className="number">Q{question.number}.</div>
            <div ref={reportResponsesQuestionRef} className="bar-outer" onMouseEnter={e => onMouseMove(e)}>
                <div ref={reportResponsesQuestionDetailsRef} className="details-wrap" style={{left: detailsPos}}>
                    <div className="details">
                        <div className="details-inner">
                            <div className="percent">{percentage}%</div>
                            {question.question}
                            <HashLink className="btn-squared" to={`/report/${bearerToken}/responses/${sectionId}/questions#${question.id}`}>View Detail</HashLink>
                        </div>
                    </div>
                </div>
                <div className={`bar ${ getReportScoreColour(percentage) }`} style={{ width: `${percentage}%` }}></div>
            </div>
        </div>
    );
}

export default function ReportResponses({productType, downloadFileName, setActivePage}) {
    setActivePage('responses');

    const { state } = useContext(Store);
    const { apiUrl } = state;

    const { bearerToken, sectionId } = useParams();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const [header, setHeader] = useState(false);
    const [introText, setIntroText] = useState(false);
    const [sectionAverageScore, setSectionAverageScore] = useState(false);
    const [questions, setQuestions] = useState(false);
    const [freeTextQuestions, setFreeTextQuestions] = useState(false);

    const fetchSection = useCallback(sectionId => {
        setLoading(true);

        axios.get(`${apiUrl}Reports/Leader/Section/${sectionId}`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        }).then(response => {
            console.log(response);

            setHeader(response?.data?.name ?? false);
            setIntroText(response?.data?.introText ?? false);
            setSectionAverageScore(response?.data?.averageScorePercentage ? Math.round(response.data.averageScorePercentage) : false);
            setQuestions(response?.data?.questions ?? false);

            setLoading(false);
        }).catch(err => {
            setErrorMessage(err.response.data.errors ?? err.message);
            setLoading(false);
        });
    }, [apiUrl, bearerToken]);

    const fetchSectionQuestions = useCallback(sectionId => {
        setLoading(true);

        axios.get(`${apiUrl}Reports/Leader/Section/${sectionId}/Questions`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        }).then(response => {
            setFreeTextQuestions(response?.data?.freeTextQuestions ?? false);

            setLoading(false);
        }).catch(err => {
            setErrorMessage(err.response.data.errors ?? err.message);
            setLoading(false);
        });
    }, [apiUrl, bearerToken]);

    useEffect(() => {
        fetchSection(sectionId);
    }, [fetchSection, sectionId]);

    useEffect(() => {
        fetchSectionQuestions(sectionId)
    }, [fetchSectionQuestions, sectionId]);

    return (
        <div className="report-content">

            {loading ? <div className="loading-circle"></div> : null}
            { errorMessage ? <div className="container-lg container-padding-lg"><ErrorMessage message={errorMessage} /></div> : null }

            <ReportTop downloadFileName={downloadFileName}></ReportTop>

            { !loading && !errorMessage ? 
                <div className="container-lg">
                    <div className="report-content-container">
                        <ReportSidebar>
                            <ReportScoreCard text="Average score for this section" score={sectionAverageScore} />
                        </ReportSidebar>
                        
                        <ReportMainContent>
                            <div className="report-text-block">
                                {header ? <h1>{header}</h1> : null}
                                {introText ? <div dangerouslySetInnerHTML={{ __html: introText }}></div> : null}
                            </div>
                        </ReportMainContent>
                    </div>

                    <div className="report-content-container">
                        <ReportMainContent>
                            { questions && questions.length ?
                                <div className="report-card">
                                    <div className="report-responses-question-header-wrap">
                                        <h2 className="header">Scores by question</h2>
                                        <Link className="btn-squared" to={`/report/${bearerToken}/responses/${sectionId}/questions`}>View Question Details</Link>
                                    </div>

                                    <div className="report-responses-question-key">
                                        <div><div className="icon yellow"></div><div>UNTAPPED OPPORTUNITY - UP TO 55%</div></div>
                                        <div><div className="icon green"></div><div>EMERGENT HABIT - 55-80%</div></div>
                                        <div><div className="icon light-blue"></div><div>ESTABLISHED SKILL - OVER 80%</div></div>
                                        { productType === 1 ? <div>Leaders response not included in the chart below</div> : null }
                                    </div>

                                    { questions.map(question => {
                                        return (
                                            <ReportResponsesQuestion key={question.id} question={question} />
                                        )
                                    }) }
                                </div>
                            : null }

                            { freeTextQuestions?.length ?
                                freeTextQuestions.map((freeTextQuestion, index) => {
                                    return (
                                        <div key={index} className="report-card">
                                            { freeTextQuestion?.question ? <h2 className="header">{freeTextQuestion.question}</h2> : null }

                                            <div className="report-responses-comments">
                                                { freeTextQuestion?.answers?.length ?
                                                    freeTextQuestion.answers.map((answer, index) => {
                                                        return (
                                                            <div key={index} className="report-responses-comment">
                                                                {answer}
                                                            </div>
                                                        )
                                                    })
                                                : null }
                                            </div>
                                        </div>
                                    )
                                })
                            : null }
                        </ReportMainContent>
                    </div>
                </div>
            : null }
        </div>
    );
}
