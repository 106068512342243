import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Store } from "./Store";
import { useAuth } from './auth';
import { generateSurveyDummyData } from './api';
import { isIE } from 'react-device-detect';

import Admin from './components/Admin/Admin';
import Questionnaire from "./components/Questionnaire/Questionnaire";
import Nominate from './components/Nominate/Nominate';
import Report from './components/Report/Report';
import Login from './components/Login/Login';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ReportOverview from './components/ReportOverview/ReportOverview';
import AdminModal from './components/Admin/AdminModal/AdminModal';

import logo from './images/logo.svg';

import "./sass/app.scss";

const routes =[
  {
    path: "/survey/:bearerToken",
    Component: Questionnaire,
  },
  {
    path: '/nominate/:bearerToken',
    Component: Nominate,
  },
  {
    path: ['/report/:bearerToken', '/report/:bearerToken/responses/:sectionId', '/report/:bearerToken/responses/:sectionId/questions'],
    Component: Report,
  },
  {
    path: ['/report-overview/:bearerToken'],
    Component: ReportOverview,
  }
];

function App() {
  const { state, dispatch } = useContext(Store);
  const { products, lastAdminPage } = state;

  const [logged] = useAuth();

  useEffect(() => {
    /*
    let winWidth = window.innerWidth;

    const updateDimensions = () => {
      const currWinWidth = window.innerWidth;

      if (winWidth !== currWinWidth) {
        dispatch({
          type: "SET_FULL_SCREEN_HEIGHT",
          payload: document.documentElement.clientHeight,
        });

        winWidth = currWinWidth;
      }
    };

    window.addEventListener("resize", updateDimensions.bind(this));

    return () => {
      window.removeEventListener("resize", updateDimensions.bind(this));
    };
    */
  }, [dispatch]);

  function fillSurvey(surveyId) {
    if (!surveyId) return;

    generateSurveyDummyData(surveyId);
  }

  window.fillSurvey = fillSurvey;
  
  return (
    <Router>
      <Switch>
        {routes ? routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            <Component />
          </Route>
            ))
        : null}

        {!logged && <>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route render={({ location }) => location.pathname !== '/login' && location.pathname !== '/forgot-password' ? <Redirect to="/login" /> : null } />
        </>}
        
        {logged && <>
            <Route path={['/login', '/forgot-password']}>
              <Redirect to={lastAdminPage ?? '/'} />
            </Route>
            { products?.length ? products.map(({ productType, productName, productClass, urlPrepend, routes }) => (
              <Route key={productType} path={routes.map(route => route.path)} exact>
                <Admin productType={productType} productClass={productClass} productName={productName} urlPrepend={urlPrepend} routes={routes} />
              </Route>
            )) : null }
        </>}

        <Route path="*"></Route>
      </Switch>

      { isIE ? 
        <AdminModal className="browser-not-supported-modal" show={true}>
            <img src={logo} alt="Holos" />
            <div>Your browser is not supported. Please download Chrome, Firefox or Microsoft Edge.</div>
        </AdminModal>
      : null }
    </Router>
  );
}

export default App;
