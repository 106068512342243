import React, { useState, useRef, useCallback, useEffect } from 'react';

import './ToolTip.scss';

export default function ToolTip({className, children, toolTipText}) {
    const toolTipWrapRef = useRef();
    const toolTipRef = useRef();

    const [toolTipPos, setToolTipPos] = useState();

    const updateToolTipPos = useCallback(() => {
        if (!toolTipWrapRef?.current || !toolTipRef?.current) {
            return;
        }

        const toolTipWrapPos = toolTipWrapRef.current.getBoundingClientRect();

        setToolTipPos({x: toolTipWrapPos.x + (toolTipWrapRef.current.offsetWidth / 2), y: toolTipWrapPos.y - toolTipRef.current.offsetHeight});
    }, []);

    useEffect(() => {
        updateToolTipPos();

        window.addEventListener('scroll', updateToolTipPos);
        window.addEventListener('resize', updateToolTipPos);
    
        return () => {
          window.removeEventListener('scroll', updateToolTipPos);
          window.removeEventListener('resize', updateToolTipPos);
        };
    }, [updateToolTipPos]);

    return (
        <div className={`admin-tooltip ${className ?? ''}`} ref={toolTipWrapRef} onMouseEnter={updateToolTipPos}>
            {children}
            { toolTipText ?
                <div className="tooltip" ref={toolTipRef} style={toolTipPos ? { top: toolTipPos.y, left: toolTipPos.x } : null}>
                    <div className="tooltip-inner">
                        { Array.isArray(toolTipText) && toolTipText?.length ? toolTipText.map((text,index) => <div key={index} dangerouslySetInnerHTML={{ __html: text }}></div>) : toolTipText }
                    </div>
                </div>
            : null }
        </div>
    );
}
