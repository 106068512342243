import React, { createContext, useReducer } from 'react';

// Shared Admin Components
import EditProgramme from './components/Admin/Pages/EditProgramme/EditProgramme';
import Agents from './components/Admin/Pages/Agents/Agents';
import Clients from './components/Admin/Pages/Clients/Clients';
import EditProfile from './components/Admin/Pages/EditProfile/EditProfile';
import AddClient from './components/Admin/Pages/AddClient/AddClient';
import EditClient from './components/Admin/Pages/EditClient/EditClient';

// Leadership 360 Components
import Leadership360Programmes from './components/Admin/Leadership360/Pages/Programmes/Programmes';
import Leadership360CreateProgramme from './components/Admin/Leadership360/Pages/CreateProgramme/CreateProgramme';
import Leadership360EditProgrammeComms from './components/Admin/Leadership360/Pages/EditProgrammeComms/EditProgrammeComms';
import Leadership360AddCohort from './components/Admin/Leadership360/Pages/AddCohort/AddCohort';
import Leadership360Cohort from './components/Admin/Leadership360/Pages/Cohort/Cohort';
import Leadership360EditCohort from './components/Admin/Leadership360/Pages/EditCohort/EditCohort';
import Leadership360AddLeader from './components/Admin/Leadership360/Pages/AddLeader/AddLeader';
import Leadership360Survey from './components/Admin/Leadership360/Pages/Survey/Survey';
import Leadership360AddRespondent from './components/Admin/Leadership360/Pages/AddRespondent/AddRespondent';

// Team Diagnostics Components
import TeamDiagnosticsProgrammes from './components/Admin/TeamDiagnostics/Pages/Programmes/Programmes';
import TeamDiagnosticsCreateProgramme from './components/Admin/TeamDiagnostics/Pages/CreateProgramme/CreateProgramme';
import TeamDiagnosticsAddTeam from './components/Admin/TeamDiagnostics/Pages/AddTeam/AddTeam';
import TeamDiagnosticsEditProgrammeComms from './components/Admin/TeamDiagnostics/Pages/EditProgrammeComms/EditProgrammeComms';
import TeamDiagnosticsTeam from './components/Admin/TeamDiagnostics/Pages/Team/Team';
import TeamDiagnosticsEditTeam from './components/Admin/TeamDiagnostics/Pages/EditTeam/EditTeam';
import TeamDiagnosticsAddTeamMembers from './components/Admin/TeamDiagnostics/Pages/AddTeamMembers/AddTeamMembers';

export const Store = createContext();

export function getConfig() {
    let config = JSON.parse(
        (document.getElementById("configScript")).innerHTML
    );
  
    if ( config.apiUrl === "#{apiUrl}#") {
        config.apiUrl = "https://digitaltools.api.holoschange.com/";
        //config.apiUrl = "https://localhost:44319/";
    }
  
    if (config.teamDiagnosticsVisible === "#{teamDiagnosticsVisible}#" || config.teamDiagnosticsVisible === 'true') {
        config.teamDiagnosticsVisible = true;
    } else if (config.teamDiagnosticsVisible === 'false') {
        config.teamDiagnosticsVisible = false;
    }
  
    if (config.testSite === "#{testSite}#" || config.testSite === 'true') {
        config.testSite = true;
    } else if (config.testSite === 'false') {
        config.testSite = false;
    }
    
    return config;
}

function getTimes() {
    let times = [];

    for (let hour = 0; hour <= 23; hour++) {
        for (let min = 0; min < 60; min += getConfig().testSite ? 5 : 60) {
            times.push({ 
                value: (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min),
                text: (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min) + ' UTC'
            });
        }
    }

    return times;
}

const leadership360UrlPrepend = '';

let products = [
    {
        productType: 1,
        productName: 'Leadership 360',
        productClass: 'leadership-360',
        urlPrepend: leadership360UrlPrepend,
        routes: [
            { path: `${leadership360UrlPrepend}/`, Component: Leadership360Programmes },
            { path: `${leadership360UrlPrepend}/add-client`, Component: AddClient },
            { path: `${leadership360UrlPrepend}/client/:clientId`, Component: EditClient },
            { path: `${leadership360UrlPrepend}/create-programme`, Component: Leadership360CreateProgramme },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/edit`, Component: EditProgramme },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/edit-comms`, Component: Leadership360EditProgrammeComms },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/add-cohort`, Component: Leadership360AddCohort },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/cohort/:cohortId`, Component: Leadership360Cohort },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/cohort/:cohortId/edit`, Component: Leadership360EditCohort },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/cohort/:cohortId/add-leader`, Component: Leadership360AddLeader },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/cohort/:cohortId/survey/:surveyId`, Component: Leadership360Survey },
            { path: `${leadership360UrlPrepend}/client/:clientId/programme/:programmeId/cohort/:cohortId/survey/:surveyId/add-respondent`, Component: Leadership360AddRespondent },
            { path: `${leadership360UrlPrepend}/agents`, Component: Agents },
            { path: `${leadership360UrlPrepend}/clients`, Component: Clients },
            { path: `${leadership360UrlPrepend}/edit-profile`, Component: EditProfile },
            { path: `${leadership360UrlPrepend}/edit-profile/password`, Component: EditProfile }
        ]
    }
];

if (getConfig().teamDiagnosticsVisible) {
    const teamDiagnosticsUrlPrepend = '/team-diagnostics';

    products.push({
        productType: 2,
        productName: 'Team Diagnostics',
        productClass: 'team-diagnostics',
        urlPrepend: teamDiagnosticsUrlPrepend,
        routes: [
            { path: `${teamDiagnosticsUrlPrepend}/`, Component: TeamDiagnosticsProgrammes },
            { path: `${teamDiagnosticsUrlPrepend}/add-client`, Component: AddClient },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId`, Component: EditClient },
            { path: `${teamDiagnosticsUrlPrepend}/create-programme`, Component: TeamDiagnosticsCreateProgramme },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId/programme/:programmeId/edit`, Component: EditProgramme },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId/programme/:programmeId/edit-comms`, Component: TeamDiagnosticsEditProgrammeComms },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId/programme/:programmeId/add-team`, Component: TeamDiagnosticsAddTeam },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId/programme/:programmeId/team/:teamId`, Component: TeamDiagnosticsTeam },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId/programme/:programmeId/team/:teamId/edit`, Component: TeamDiagnosticsEditTeam },
            { path: `${teamDiagnosticsUrlPrepend}/client/:clientId/programme/:programmeId/team/:teamId/add-team-members`, Component: TeamDiagnosticsAddTeamMembers },
            { path: `${teamDiagnosticsUrlPrepend}/agents`, Component: Agents },
            { path: `${teamDiagnosticsUrlPrepend}/clients`, Component: Clients },
            { path: `${teamDiagnosticsUrlPrepend}/edit-profile`, Component: EditProfile },
            { path: `${teamDiagnosticsUrlPrepend}/edit-profile/password`, Component: EditProfile }
        ]
    })
}

const initialState = {
    fullScreenHeight: document.documentElement.clientHeight,
    apiUrl: getConfig().apiUrl,
    testSite: getConfig().testSite,
    surveyTypes: null,
    times: getTimes(),
    statuses: [
        {
            id: 1,
            text: 'Not Started',
            colour: 'light-grey'
        },
        {
            id: 2,
            text: 'In Progress',
            colour: 'light-blue'
        },
        {
            id: 3,
            text: 'Deadline Passed',
            colour: 'red'
        },
        {
            id: 4,
            text: 'Completed',
            colour: 'green'
        }
    ],
    uploadLeaderTemplateLink: 'https://holos.blob.core.windows.net/template/Holos Leaders Template.xlsx',
    setLastAdminPage: null,
    defaultTime: '09:00',
    userName: '',
    products: products,
    formChange: false
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FULL_SCREEN_HEIGHT':
            return { ...state, fullScreenHeight: action.payload };
        case 'API_URL':
            return { ...state, apiUrl: action.payload };
        case 'SET_SURVEY_TYPES':
            return { ...state, surveyTypes: action.payload };
        case 'SET_LAST_ADMIN_PAGE':
            return { ...state, lastAdminPage: action.payload };
        case 'SET_USER_NAME':
            return { ...state, userName: action.payload };
        case 'SET_FORM_CHANGE':
            return { ...state, formChange: action.payload };
        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return <Store.Provider value={value}>{props.children}</Store.Provider>
}
