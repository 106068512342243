import React, { useState, useEffect } from "react";

import AdminModal from '../../Admin/AdminModal/AdminModal';

import "./QuestionnaireReview.scss";

import '../../Admin/AdminLeavingPageModal/AdminLeavingPageModal.scss';

export default function QuestionnaireReview({
  sections,
  completeQuestionnaire,
}) {
  const uncompleted = sections.find((section) => section.completed === false);

  const [showLeavingWarning, setShowLeavingWarning] = useState(false);

  useEffect(() => {
    document.addEventListener('mouseout', e => {
      e = e ? e : window.event;
      const from = e.relatedTarget || e.toElement;

      if ( (!from || from.nodeName == "HTML") && (e.clientY <= 0 || e.clientX <= 0 || (e.clientX >= window.innerWidth || e.clientY >= window.innerHeight)) ) {
        setShowLeavingWarning(true);
      }
    })
    
    window.onbeforeunload = () => {
      return 'Don’t forget to click submit. You must click the submit button for your review to be included in the report.';
    };

    return (() => {
      window.onbeforeunload = null;
    });
  });

  const onClickCloseModal = () => {
    setShowLeavingWarning(false);
  }

  return (
    <div className="questionnaire-review container-lg container-padding-lg">
      {!uncompleted && (
        <>
          <h2 className="header">Almost there!</h2>
          <p>To complete your review please click SUBMIT below. You must click the button for your review to be included in the report.</p>
          <p>You can check or edit your responses using the section navigation.</p>
        </>
      )}
      {uncompleted ? (
        <p>Please complete all sections before proceeding.</p>
      ) : (
        <button
          type="button"
          className="submit-btn btn blue"
          onClick={() => {
            completeQuestionnaire();
          }}
        >
          Submit Responses
        </button>
      )}

      <AdminModal className="admin-leaving-page-modal" show={showLeavingWarning} closeModal={onClickCloseModal}>
        <div className="leaving-page-modal-content">
            <div>
                <div className="leaving-page-modal-icon">!</div>
            </div>
            <div className="leaving-page-modal-message">
              <h2>Don’t forget to click submit</h2>
              <p>You must click the submit button for your review to be included in the report.</p>
            </div>
            <div className="leaving-page-modal-btns">
                <button onClick={onClickCloseModal}>Stay</button>
            </div>
        </div>
      </AdminModal>
    </div>
  );
}
