import React, { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, Link, Redirect } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Store } from '../../../../../Store';
import { getClient, getProgramme, getTeams, getAgents, createTeam, updateTeam, generateTeamDates } from '../../../../../api';
import { sortNameAlphabetically, dateTimeToUTC } from '../../../../../helpers';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody, AdminCardNumber } from '../../../AdminCard/AdminCard';
import Input, { InputRow, InputGroup } from '../../../../Input/Input';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import UploadLeadersFormModal from '../../../UploadLeadersFormModal/UploadLeadersFormModal';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import BellIcon from '../../../../Icons/BellIcon';

import './AddTeam.scss';

function AddTeamCard({newTeam, index, number, agents, teams, removeTeam, updateTeamData, urlPrepend, setRedirect, onlyManualEntry}) {
    const location = useLocation();
    const params = useMemo(()=> new URLSearchParams(location.search), [location] );
    
    const { state } = useContext(Store);
    const { times, defaultTime } = state;

    const {clientId, programmeId} = useParams();

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [teamId, setTeamId] = useState(newTeam.id);
    const [nameValue, setNameValue] = useState(newTeam.name);
    const [agentValue, setAgentValue] = useState(newTeam.agentId);
    const [leaderFirstNameValue, setLeaderFirstNameValue] = useState(newTeam.leaderFirstName);
    const [leaderLastNameValue, setLeaderLastNameValue] = useState(newTeam.leaderLastName);
    const [leaderEmailAddressValue, setLeaderEmailAddressValue] = useState(newTeam.leaderEmailAddress);
    const [nominationInviteDateValue, setNominationInviteDateValue] = useState(newTeam.nominationInviteDate ?? '');
    const [nominationInviteTimeValue, setNominationInviteTimeValue] = useState(newTeam.nominationInviteTime ?? defaultTime);
    const [surveySendDateValue, setSurveySendDateValue] = useState(newTeam.surveySendDate ?? '');
    const [surveySendTimeValue, setSurveySendTimeValue] = useState(newTeam.surveySendTime ?? defaultTime);
    const [debriefDateValue, setDebriefDateValue] = useState(newTeam.debriefDate ?? '');
    const [debriefTimeValue, setDebriefTimeValue] = useState(newTeam.debriefTime ?? defaultTime);
    const [nominationReminder1DateValue, setNominationReminder1DateValue] = useState(newTeam.nominationReminder1Date ?? '');
    const [nominationReminder1TimeValue, setNominationReminder1TimeValue] = useState(newTeam.nominationReminder1Time ?? defaultTime);
    const [nominationsDeadlineDateValue, setNominationsDeadlineDateValue] = useState(newTeam.nominationsDeadlineDate ?? '');
    const [nominationsDeadlineTimeValue, setNominationsDeadlineTimeValue] = useState(newTeam.nominationsDeadlineTime ?? defaultTime);
    const [surveyReminder1DateValue, setSurveyReminder1DateValue] = useState(newTeam.surveyReminder1Date ?? '');
    const [surveyReminder1TimeValue, setSurveyReminder1TimeValue] = useState(newTeam.surveyReminder1Time ?? defaultTime);
    const [surveyReminder2DateValue, setSurveyReminder2DateValue] = useState(newTeam.surveyReminder2Date ?? '');
    const [surveyReminder2TimeValue, setSurveyReminder2TimeValue] = useState(newTeam.surveyReminder2Time ?? defaultTime);
    const [surveyDeadlineDateValue, setSurveyDeadlineDateValue] = useState(newTeam.surveyDeadlineDate ?? '');
    const [surveyDeadlineTimeValue, setSurveyDeadlineTimeValue] = useState(newTeam.surveyDeadlineTime ?? defaultTime);

    const [datesGenerated, setDatesGenerated] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [agentError, setAgentError] = useState(false);
    const [leaderFirstNameError, setLeaderFirstNameError] = useState(false);
    const [leaderLastNameError, setLeaderLastNameError] = useState(false);
    const [leaderEmailAddressError, setLeaderEmailAddressError] = useState(false);
    const [nominationInviteDateError, setNominationInviteDateError] = useState(false);
    const [surveySendDateError, setSurveySendDateError] = useState(false);
    const [nominationReminder1DateError, setNominationReminder1DateError] = useState(false);
    const [nominationsDeadlineDateError, setNominationsDeadlineDateError] = useState(false);
    const [surveyReminder1DateError, setSurveyReminder1DateError] = useState(false);
    const [surveyReminder2DateError, setSurveyReminder2DateError] = useState(false);
    const [surveyDeadlineDateError, setSurveyDeadlineDateError] = useState(false);

    const [showUploadLeadersModal, setShowUploadLeadersModal] = useState(false);

    const submitTeamForm = useCallback(() => {
        setFormLoading(true);

        setFormErrors(false);
        setNameError(false);
        setAgentError(false);
        setLeaderFirstNameError(false);
        setLeaderLastNameError(false);
        setLeaderEmailAddressError(false);
        setNominationInviteDateError(false);
        setSurveySendDateError(false);
        setNominationReminder1DateError(false);
        setNominationsDeadlineDateError(false);
        setSurveyReminder1DateError(false);
        setSurveyReminder2DateError(false);
        setSurveyDeadlineDateError(false);
        
        const saveThen = response => {
            if (response?.success) {
                updateTeamData(index, 'formChange', false);

                if (response?.id) {
                    let previousLinkUrl = new URL(`${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${response.id}/edit`, window.location.origin);
                    let previousLinkSearchParams = previousLinkUrl.searchParams;

                    previousLinkSearchParams.set('previousLink', encodeURIComponent(params.get('previousLink')));

                    setTeamId(response.id);
                    setRedirect( onlyManualEntry ? `${urlPrepend}/client/${clientId}/programme/${programmeId}/team/${response.id}/add-team-members?previousLink=${encodeURIComponent(previousLinkUrl.toString().replace(window.location.origin, ''))}` : `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`);
                }
            }
            
            if (response?.errors) {
                setNameError(response.errors?.Name ?? false);
                setAgentError(response.errors?.AgentId ?? false);
                setLeaderFirstNameError(response.errors?.LeaderFirstName ?? false);
                setLeaderLastNameError(response.errors?.LeaderLastName ?? false);
                setLeaderEmailAddressError(response.errors?.LeaderEmailAddress ?? false);
                setSurveyReminder1DateError(response.errors?.SurveyReminder1 ?? false);
                setSurveyReminder2DateError(response.errors?.SurveyReminder2 ?? false);
                setSurveyDeadlineDateError(response.errors?.SurveyDeadline ?? false);

                let errors = Object.assign({}, response.errors);

                delete errors.Name;
                delete errors.AgentId;
                delete errors.LeaderFirstName;
                delete errors.LeaderLastName;
                delete errors.LeaderEmailAddress;
                delete errors.SurveyReminder1;
                delete errors.SurveyReminder2;
                delete errors.SurveyDeadline;

                if ( onlyManualEntry ) {
                    setSurveySendDateError(response.errors?.SurveySendDate ?? false);

                    delete errors.SurveySendDate;
                } else {
                    setNominationInviteDateError(response.errors?.NominationInviteDate ?? false);
                    setNominationReminder1DateError(response.errors?.NominationsReminder1 ?? false);
                    setNominationsDeadlineDateError(response.errors?.NominationsDeadline ?? false);

                    delete errors.NominationInviteDate;
                    delete errors.NominationsReminder1;
                    delete errors.NominationsDeadline;
                }

                setFormErrors(errors);
            }

            setFormLoading(false);
        }

        if (!teamId) {
            if ( onlyManualEntry ) {
                createTeam(clientId, programmeId, {
                    name: nameValue,
                    agentId: agentValue,
                    surveySendDate: dateTimeToUTC(surveySendDateValue, surveySendTimeValue),
                    debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                    surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                    surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                    surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue),
                    leaderFirstName: leaderFirstNameValue,
                    leaderLastName: leaderLastNameValue,
                    leaderEmailAddress: leaderEmailAddressValue
                }).then(response => saveThen(response));
            } else {
                createTeam(clientId, programmeId, {
                    name: nameValue,
                    agentId: agentValue,
                    nominationInviteDate: dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue),
                    debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                    nominationsDeadline: dateTimeToUTC(nominationsDeadlineDateValue, nominationsDeadlineTimeValue),
                    surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                    nominationsReminder1: dateTimeToUTC(nominationReminder1DateValue, nominationReminder1TimeValue),
                    surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                    surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue),
                    leaderFirstName: leaderFirstNameValue,
                    leaderLastName: leaderLastNameValue,
                    leaderEmailAddress: leaderEmailAddressValue
                }).then(response => saveThen(response));
            }
        } else {
            if ( onlyManualEntry ) {
                updateTeam(clientId, programmeId, teamId, {
                    name: nameValue,
                    agentId: agentValue,
                    surveySendDate: dateTimeToUTC(surveySendDateValue, surveySendTimeValue),
                    debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                    surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                    surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                    surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue),
                    leaderFirstName: leaderFirstNameValue,
                    leaderLastName: leaderLastNameValue,
                    leaderEmailAddress: leaderEmailAddressValue
                }).then(response => saveThen(response));
            } else {
                updateTeam(clientId, programmeId, teamId, {
                    name: nameValue,
                    agentId: agentValue,
                    nominationInviteDate: dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue),
                    debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                    nominationsDeadline: dateTimeToUTC(nominationsDeadlineDateValue, nominationsDeadlineTimeValue),
                    surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                    nominationsReminder1: dateTimeToUTC(nominationReminder1DateValue, nominationReminder1TimeValue),
                    surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                    surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue),
                    leaderFirstName: leaderFirstNameValue,
                    leaderLastName: leaderLastNameValue,
                    leaderEmailAddress: leaderEmailAddressValue
                }).then(response => saveThen(response));
            }
        }
    }, [agentValue, clientId, debriefDateValue, debriefTimeValue, index, leaderEmailAddressValue, leaderFirstNameValue, leaderLastNameValue, nameValue, nominationInviteDateValue, nominationInviteTimeValue, nominationReminder1DateValue, nominationReminder1TimeValue, nominationsDeadlineDateValue, nominationsDeadlineTimeValue, programmeId, setRedirect, surveyDeadlineDateValue, surveyDeadlineTimeValue, surveyReminder1DateValue, surveyReminder1TimeValue, surveyReminder2DateValue, surveyReminder2TimeValue, teamId, updateTeamData, urlPrepend, onlyManualEntry, params, surveySendDateValue, surveySendTimeValue]);

    const onSubmitAddNewTeamForm = e => {
        e.preventDefault();

        submitTeamForm();
    };

    const onClickGenerateDatesBtn = () => {
        setFormLoading(true);
        setNominationInviteDateError(false);
        setSurveySendDateError(false);
        setSurveyDeadlineDateError(false);

        if ( onlyManualEntry === true ) {
            if (!dateTimeToUTC(surveySendDateValue, surveySendTimeValue)) {
                setSurveySendDateError(['Survey send date is required']);
            }
        }

        if ( onlyManualEntry === false ) {
            if (!dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue)) {
                setNominationInviteDateError(['Nomination deadline date is required']);
            }
        }

        if (!dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue)) {
            setSurveyDeadlineDateError(['Completion deadline date is required']);
        }

        if ( ( onlyManualEntry === true && !dateTimeToUTC(surveySendDateValue, surveySendTimeValue) ) || 
            ( onlyManualEntry === false && !dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue) ) || 
            !dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue)) {
            
            setFormLoading(false);
            return false;
        }

        generateTeamDates(onlyManualEntry ? dateTimeToUTC(surveySendDateValue, surveySendTimeValue) : dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue), 
            dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue))
            .then(response => {
                if ( !onlyManualEntry ) {
                    setNominationReminder1DateValue(response?.nominationsReminder1 ? utcToZonedTime(parseISO(response.nominationsReminder1), 'UTC') : null);
                    setNominationReminder1TimeValue(response?.nominationsReminder1 ? format(utcToZonedTime(parseISO(response.nominationsReminder1), 'UTC'), 'HH:mm') : null);
                    setNominationsDeadlineDateValue(response?.nominationsDeadline ? utcToZonedTime(parseISO(response.nominationsDeadline), 'UTC') : null);
                    setNominationsDeadlineTimeValue(response?.nominationsDeadline ? format(utcToZonedTime(parseISO(response.nominationsDeadline), 'UTC'), 'HH:mm') : null);
                }

                setSurveyReminder1DateValue(response?.surveyReminder1 ? utcToZonedTime(parseISO(response.surveyReminder1), 'UTC') : null);
                setSurveyReminder1TimeValue(response?.surveyReminder1 ? format(utcToZonedTime(parseISO(response.surveyReminder1), 'UTC'), 'HH:mm') : null);
                setSurveyReminder2DateValue(response?.surveyReminder2 ? utcToZonedTime(parseISO(response.surveyReminder2), 'UTC') : null);
                setSurveyReminder2TimeValue(response?.surveyReminder2 ? format(utcToZonedTime(parseISO(response.surveyReminder2), 'UTC'), 'HH:mm') : null);

                setDatesGenerated(true);
                setFormLoading(false);
            });
    };

    const onClickRemoveTeamBtn = e => {
        removeTeam(index);
    };

    const onSubmitUploadLeadersFormSuccess = () => {
        setShowUploadLeadersModal(false);
    };

    const onChangeNameInput = e => {
        setNameValue(e.target.value);
        updateTeamData(index, 'name', e.target.value);
    };

    const onChangeAgentSelect = e => {
        setAgentValue(e.target.value);
        updateTeamData(index, 'agentId', e.target.value);
    };

    const onChangeLeaderFirstNameInput = e => {
        setLeaderFirstNameValue(e.target.value);
        updateTeamData(index, 'leaderFirstName', e.target.value);
    };

    const onChangeLeaderLastNameInput = e => {
        setLeaderLastNameValue(e.target.value);
        updateTeamData(index, 'leaderLastName', e.target.value);
    };

    const onChangeLeaderEmailAddressInput = e => {
        setLeaderEmailAddressValue(e.target.value);
        updateTeamData(index, 'leaderEmailAddress', e.target.value);
    };

    const onChangeNominationInviteDateInput = date => {
        setNominationInviteDateValue(date);
        updateTeamData(index, 'nominationSendDate', date);
    };

    const onChangeNominationInviteTimeInput = e => {
        setNominationInviteTimeValue(e.target.value);
        updateTeamData(index, 'nominationSendTime', e.target.value);
    };

    const onChangeSurveySendDateInput = date => {
        setSurveySendDateValue(date);
        updateTeamData(index, 'surveySendDate', date);
    };

    const onChangeSurveySendTimeInput = e => {
        setSurveySendTimeValue(e.target.value);
        updateTeamData(index, 'surveySendTime', e.target.value);
    };

    const onChangeNominationReminder1DateInput = date => {
        setNominationReminder1DateValue(date);
        updateTeamData(index, 'nominationReminder1Date', date);
    };

    const onChangeNominationReminder1TimeInput = e => {
        setNominationReminder1TimeValue(e.target.value);
        updateTeamData(index, 'nominationReminder1Time', e.target.value);
    };

    const onChangeNominationsDeadlineDateInput = date => {
        setNominationsDeadlineDateValue(date);
        updateTeamData(index, 'nominationsDeadlineDate', date);
    };

    const onChangeNominationsDeadlineTimeInput = e => {
        setNominationsDeadlineTimeValue(e.target.value);
        updateTeamData(index, 'nominationsDeadlineTime', e.target.value);
    };

    const onChangeSurveyReminder1DateInput = date => {
        setSurveyReminder1DateValue(date);
        updateTeamData(index, 'surveyReminder1Date', date);
    };

    const onChangeSurveyReminder1TimeInput = e => {
        setSurveyReminder1TimeValue(e.target.value);
        updateTeamData(index, 'surveyReminder1Time', e.target.value);
    };

    const onChangeSurveyReminder2DateInput = date => {
        setSurveyReminder2DateValue(date);
        updateTeamData(index, 'surveyReminder2Date', date);
    };

    const onChangeSurveyReminder2TimeInput = e => {
        setSurveyReminder2TimeValue(e.target.value);
        updateTeamData(index, 'surveyReminder2Time', e.target.value);
    };

    const onChangeSurveyDeadlineDateInput = date => {
        setSurveyDeadlineDateValue(date);
        updateTeamData(index, 'surveyDeadlineDate', date);
    };

    const onChangeSurveyDeadlineTimeInput = e => {
        setSurveyDeadlineTimeValue(e.target.value);
        updateTeamData(index, 'surveyDeadlineTime', e.target.value);
    };

    useEffect(() => {
        setTeamId(newTeam.id);
        setNameValue(newTeam.name);

        if (newTeam.agentId) {
            setAgentValue(newTeam.agentId);
        } else if (agents?.users?.filter(agent => agent.name === teams.agentName).length) {
            setAgentValue(agents.users.filter(agent => agent.name === teams.agentName)[0].id);
        }

        setNominationInviteDateValue(newTeam.nominationInviteDate ?? '');
        setNominationInviteTimeValue(newTeam.nominationInviteTime ?? defaultTime);
        setDebriefDateValue(newTeam.debriefDate ?? '');
        setDebriefTimeValue(newTeam.debriefTime ?? defaultTime);
        setNominationReminder1DateValue(newTeam.nominationReminder1Date ?? '');
        setNominationReminder1TimeValue(newTeam.nominationReminder1Time ?? defaultTime);
        setNominationsDeadlineDateValue(newTeam.nominationsDeadlineDate ?? '');
        setNominationsDeadlineTimeValue(newTeam.nominationsDeadlineTime ?? defaultTime);
        setSurveyReminder1DateValue(newTeam.surveyReminder1Date ?? '');
        setSurveyReminder1TimeValue(newTeam.surveyReminder1Time ?? defaultTime);
        setSurveyReminder2DateValue(newTeam.surveyReminder2Date ?? '');
        setSurveyReminder2TimeValue(newTeam.surveyReminder2Time ?? defaultTime);
        setSurveyDeadlineDateValue(newTeam.surveyDeadlineDate ?? '');
        setSurveyDeadlineTimeValue(newTeam.surveyDeadlineTime ?? defaultTime);
    }, [newTeam, agents, teams, defaultTime]);

    return (
        <AdminCard>
            <AdminCardNumber>
                {number}
            </AdminCardNumber>
            <AdminCardBody>
                { formErrors ? <ErrorMessage message={formErrors} /> : null }

                <form className="add-new-team-form" onSubmit={onSubmitAddNewTeamForm}>
                    { formLoading ? <div className="loading-circle"></div> : null }

                    <div className={`add-new-team-form-inner ${formLoading ? 'loading' : ''}`}>
                        <InputRow>
                            <Input type="text" label="Team Name*" name="name" value={nameValue} placeholder="Enter team name" errors={nameError} onChange={onChangeNameInput} />
                            <Input type="select" 
                                label="Assign a Holos agent for this team*" 
                                name="agent" 
                                selectText="Holos Agent" 
                                value={agentValue} 
                                options={agents?.users?.sort(sortNameAlphabetically).map(agent => {
                                    return {
                                        value: agent.id,
                                        name: agent.name
                                    }
                                } ) } 
                                loading={!agents?.users} 
                                errors={agentError} 
                                onChange={onChangeAgentSelect} />
                        </InputRow>

                        <InputRow>
                            <Input type="text" label="Team Leader First Name*" name="leader-first-name" value={leaderFirstNameValue} placeholder="Enter team leader first name" errors={leaderFirstNameError} onChange={onChangeLeaderFirstNameInput} />
                            <Input type="text" label="Team Leader Last Name*" name="leader-last-name" value={leaderLastNameValue} placeholder="Enter team leader last name" errors={leaderLastNameError} onChange={onChangeLeaderLastNameInput} />
                            <Input type="email" label="Team Leader Email Address*" name="leader-email-address" value={leaderEmailAddressValue} placeholder="Enter team leader email address" errors={leaderEmailAddressError} onChange={onChangeLeaderEmailAddressInput} />
                        </InputRow>
                        <InputRow>
                            { onlyManualEntry === true ?
                                <InputGroup className="date-time-input-group" label="Survey Send Date*" errors={surveySendDateError}>
                                    <Input type="datepicker" name="nomination-invite-date" size="small" value={surveySendDateValue} placeholder="Enter date" onChange={onChangeSurveySendDateInput} />
                                    <Input name="nomination-invite-time" 
                                        type="select" 
                                        value={surveySendTimeValue} 
                                        options={times.map(time => {
                                            return {
                                                value: time.value,
                                                name: time.text
                                            }
                                        } ) } 
                                        size="extra-small" 
                                        onChange={onChangeSurveySendTimeInput} />
                                </InputGroup>
                            : null }

                            { onlyManualEntry === false ?
                                <InputGroup className="date-time-input-group" label="Nomination Invite*" errors={nominationInviteDateError}>
                                    <Input type="datepicker" name="nomination-invite-date" size="small" value={nominationInviteDateValue} placeholder="Enter date" onChange={onChangeNominationInviteDateInput} />
                                    <Input name="nomination-invite-time" 
                                        type="select" 
                                        value={nominationInviteTimeValue} 
                                        options={times.map(time => {
                                            return {
                                                value: time.value,
                                                name: time.text
                                            }
                                        } ) } 
                                        size="extra-small" 
                                        onChange={onChangeNominationInviteTimeInput} />
                                </InputGroup>
                            : null }
                            
                            <InputGroup className="date-time-input-group" label="Completion Deadline*" errors={surveyDeadlineDateError}>
                                <Input type="datepicker" name="survey-deadline-date" size="small" value={surveyDeadlineDateValue} placeholder="Enter date" onChange={onChangeSurveyDeadlineDateInput} />
                                <Input name="survey-deadline-time" 
                                    type="select" 
                                    value={surveyDeadlineTimeValue} 
                                    options={times.map(time => {
                                        return {
                                            value: time.value,
                                            name: time.text
                                        }
                                    } ) } 
                                    size="extra-small" 
                                    onChange={onChangeSurveyDeadlineTimeInput} />
                            </InputGroup>

                            <div className="align-bottom"><button className="btn blue small" type="button" onClick={onClickGenerateDatesBtn}>{ !datesGenerated ? 'Generate Reminder Dates' : 'Re-Generate Dates' }</button></div>
                        </InputRow>

                        { datesGenerated ?
                            <div className="add-team-reminder-dates">

                                <h3 className="sub-header"><div className="icon"><BellIcon /></div> Automated Email Reminders:</h3>

                                <div className="add-team-reminder-dates-inner">
                                    <div className="add-team-reminder-dates-inputs">
                                        { onlyManualEntry === false ?
                                            <InputRow>
                                                <InputGroup className="date-time-input-group" label="Nomination Reminder 1" errors={nominationReminder1DateError}>
                                                    <Input type="datepicker" name="nomination-reminder-1-date" size="small" value={nominationReminder1DateValue} placeholder="Enter date" onChange={onChangeNominationReminder1DateInput} />
                                                    <Input name="nomination-reminder-1-time" 
                                                        type="select" 
                                                        value={nominationReminder1TimeValue} 
                                                        options={times.map(time => {
                                                            return {
                                                                value: time.value, 
                                                                name: time.text
                                                            }
                                                        } ) } 
                                                        size="extra-small" 
                                                        onChange={onChangeNominationReminder1TimeInput} />
                                                </InputGroup>
                                                
                                                <InputGroup className="date-time-input-group" label="Nomination Deadline (Nomination Reminder 2)" errors={nominationsDeadlineDateError}>
                                                    <Input type="datepicker" name="nominations-deadline-date" size="small" value={nominationsDeadlineDateValue} placeholder="Enter date" onChange={onChangeNominationsDeadlineDateInput} />
                                                    <Input name="nomination-deadline-time" 
                                                        type="select" 
                                                        value={nominationsDeadlineTimeValue} 
                                                        options={times.map(time => {
                                                            return {
                                                                value: time.value,
                                                                name: time.text
                                                            }
                                                        } ) } 
                                                        size="extra-small" 
                                                        onChange={onChangeNominationsDeadlineTimeInput} />
                                                </InputGroup>
                                            </InputRow>
                                        : null }

                                        <InputRow>
                                            <InputGroup className="date-time-input-group" label="Survey Reminder 1" errors={surveyReminder1DateError}>
                                                <Input type="datepicker" name="survey-reminder-1-date" size="small" value={surveyReminder1DateValue} placeholder="Enter date" onChange={onChangeSurveyReminder1DateInput} />
                                                <Input name="survey-reminder-1-time" 
                                                    type="select" 
                                                    value={surveyReminder1TimeValue} 
                                                    options={times.map(time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeSurveyReminder1TimeInput} />
                                            </InputGroup>

                                            <InputGroup className="date-time-input-group" label="Survey Reminder 2" errors={surveyReminder2DateError}>
                                                <Input type="datepicker" name="survey-reminder-2-date" size="small" value={surveyReminder2DateValue} placeholder="Enter date" onChange={onChangeSurveyReminder2DateInput} />
                                                <Input name="survey-reminder-2-time" 
                                                    type="select" 
                                                    value={surveyReminder2TimeValue} 
                                                    options={times.map(time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeSurveyReminder2TimeInput} />
                                            </InputGroup>
                                        </InputRow>
                                    </div>

                                    <div className="add-team-reminder-dates-info">
                                        <div className="add-team-reminder-dates-info-inner">
                                            <div>
                                                <div className="icon">i</div>
                                            </div>
                                            The email reminder dates have been generated based on the dates above and will be sent out automatically until nominations and surveys are completed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null }

                        <InputRow className="add-new-team-btns">
                            <InputGroup>
                                { index && !teamId ?
                                    <div>
                                        <button type="button" className="btn" onClick={onClickRemoveTeamBtn}>Remove Team</button>
                                    </div>
                                : null }
                                { datesGenerated ?
                                    <div>
                                        <button type="submit" className="btn blue">{ !teamId ? 'Save Team' : 'Update Team' }</button>
                                    </div>
                                : null }
                            </InputGroup>
                        </InputRow>
                    </div>
                </form>

                <UploadLeadersFormModal clientId={clientId} programmeId={programmeId} teamId={teamId} showUploadLeadersModal={showUploadLeadersModal} setShowUploadLeadersModal={setShowUploadLeadersModal} onSubmitSuccess={onSubmitUploadLeadersFormSuccess} />
            </AdminCardBody>
        </AdminCard>
    );
}

export default function AddTeam({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const { state } = useContext(Store);
    const { defaultTime } = state;

    const location = useLocation();

    const {clientId, programmeId} = useParams();

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const params = useMemo(()=> new URLSearchParams(location.search), [location] );
    
    const newEmptyTeam = {
        id: false,
        name: '',  
        agentId: '' ,
        leaderFirstName: '',
        leaderLastName: '',
        leaderEmailAddress: '',
        nominationInviteDate: '',
        nominationInviteTime: defaultTime,
        surveySendDate: '',
        surveySendTime: defaultTime,
        debriefDate: '',
        debriefTime: defaultTime,
        nominationReminder1Date: '',
        nominationReminder1Time: defaultTime,
        nominationsDeadlineDate: '',
        nominationsDeadlineTime: defaultTime,
        surveyReminder1Date: '',
        surveyReminder1Time: defaultTime,
        surveyReminder2Date: '',
        surveyReminder2Time: defaultTime,
        surveyDeadlineDate: '',
        surveyDeadlineTime: defaultTime,
        formChange: false
    };

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [teams, setTeams] = useState(false);
    const [agents, setAgents] = useState(false);
    const [newTeams, setNewTeams] = useState([newEmptyTeam]);

    const [redirect, setRedirect] = useState(false);

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchTeams = useCallback((clientId, programmeId) => {
        getTeams(clientId, programmeId).then(response => {
            if (response.success) {
                setTeams(response);
            } else {
                setPageError(response.error);
            }

            setPageLoading(false);
        });
    }, []);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response);
        });
    }, []);

    useEffect(() => {
        document.title = `Add Team - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: 'Add a team'
            }
        ]);
    }, [urlPrepend, clientId, programmeId, setHeaderBreadcrumbs]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchTeams(clientId, programmeId);
    }, [fetchTeams, clientId, programmeId]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    const removeTeam = index => {
        setNewTeams(newTeams.filter((newTeam, newTeamIndex) => newTeamIndex !== index));
    };

    const updateTeamData = (index, field, value) => {
        let newTeamsData = [...newTeams];
        newTeamsData[index][field] = value;
        if ( field !== 'formChange' ) newTeamsData[index]['formChange'] = true;
        setNewTeams(newTeamsData);
    };

    return (
        <div>
            { !pageLoading && !pageError && client && programme && teams ?
                <AdminInfoBar>
                    { client?.name && programme?.name ? 
                        <div>
                            <h3>{client.name}</h3>
                            <div>{programme.name}</div>
                        </div>
                    : null }
                    { teams?.agentName ? 
                        <div>
                            <h3>Holos Programme Lead</h3>
                            <div>{teams.agentName}</div>
                        </div>
                    : null }
                </AdminInfoBar>
            : null }

            <AdminContainer pageError={pageError} pageLoading={pageLoading}>
                <AdminTop header="Add a Team" />

                <div className="add-new-team-card-wrap">
                    { newTeams ?
                        newTeams.map((newTeam, index) => {
                            return (
                                <AddTeamCard key={index} newTeam={newTeam} index={index} number={teams?.teams ? teams.teams.length + 1 + index : 1} agents={agents} teams={teams} removeTeam={removeTeam} updateTeamData={updateTeamData} urlPrepend={urlPrepend} setRedirect={setRedirect} onlyManualEntry={ typeof programme?.onlyManualEntry === 'boolean' ? programme.onlyManualEntry : null} />
                            )
                        }) 
                    : null }
                </div>

                {/*<button className="btn blue" type="button" onClick={onClickAddAnotherTeamBtn}><PlusIcon /><div>Add Another Team</div></button>*/}
            </AdminContainer>

            <AdminActionBar>
                <div>
                    { params.get('previousLink') ? <div><Link className="btn white-border" to={params.get('previousLink')}>Previous</Link></div> : null }
                </div>
                <div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={newTeams?.length ? newTeams.filter(team => team.formChange === true).length : false}>
                <h2>Leaving this page?</h2>
                <p>The team has not been saved.</p>
            </AdminLeavingPageModal>

            { redirect ? <Redirect to={redirect} /> : null }
        </div>
    );
}
