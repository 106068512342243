import React, { useRef, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getReportScoreColour } from '../../../helpers';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { ComparisonDifference } from '../ReportOverview/ReportOverview';

import './ReportRadarChart.scss';

function QuestionButton({productType, reportType, question, respondentsScorePercentage, leaderScorePercentage, completedOn, comparisonRespondentsScorePercentage, comparisonLeaderScorePercentage, comparisonCompletedOn, topPos, leftPos}) {
    const [showQuestion, setShowQuestion] = useState(false);

    return (
        <div className="question-button" style={{top: topPos, left: leftPos}}>
            <div className={`question ${showQuestion ? 'show' : ''}`}>
                {reportType === 0 || reportType === 3 ?
                    <div className="question-inner">
                        {respondentsScorePercentage !== null ? 
                            <div className="question-button-respondent-score">
                                <div className={`dot ${getReportScoreColour(Math.round(respondentsScorePercentage))}`}></div>{Math.round(respondentsScorePercentage)}%
                            </div> 
                        : null}
                        
                        {question}

                        {productType === 1 && leaderScorePercentage !== null ? 
                            <div className="question-button-leader-score">
                                <div className={`dot ${getReportScoreColour(Math.round(leaderScorePercentage))}`}></div>Leader score: {Math.round(leaderScorePercentage)}%
                            </div>
                        : null}
                    </div>
                : null}
                {reportType === 1 || reportType === 2 ?
                    <div className="question-inner">
                        {question}

                        <table>
                            <thead>
                                <tr>
                                    <th>Quarter</th>
                                    { productType === 1 ? <th>Respondents Avg.</th> : null }
                                    { productType === 2 ? <th>Team Members Avg.</th> : null }
                                    { productType === 1 ? <th>Leaders Avg.</th> : null }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{ completedOn ? `Q${format(utcToZonedTime(parseISO(completedOn), 'UTC'), 'Q yy')}` : '' }</th>

                                    <td>
                                        <div className={`dot ${getReportScoreColour(Math.round(respondentsScorePercentage))}`}></div>{Math.round(respondentsScorePercentage)}%

                                        { comparisonCompletedOn && comparisonRespondentsScorePercentage !== null ?
                                            <ComparisonDifference score1={Math.round(respondentsScorePercentage)} score2={Math.round(comparisonRespondentsScorePercentage)} />
                                        : null }
                                    </td>

                                    { productType === 1 ? 
                                        <td>
                                            <div className={`dot ${getReportScoreColour(Math.round(leaderScorePercentage))}`}></div>{Math.round(leaderScorePercentage)}%

                                            { comparisonCompletedOn && comparisonLeaderScorePercentage !== null ?
                                                <ComparisonDifference score1={Math.round(leaderScorePercentage)} score2={Math.round(comparisonLeaderScorePercentage)} />
                                            : null }
                                        </td>
                                    : null }
                                </tr>
                                { comparisonCompletedOn && comparisonRespondentsScorePercentage !== null && comparisonLeaderScorePercentage !== null ?
                                    <tr>
                                        <th>{ comparisonCompletedOn ? `Q${format(utcToZonedTime(parseISO(comparisonCompletedOn), 'UTC'), 'Q yy')}` : '' }</th>

                                        <td><div className={`dot ${getReportScoreColour(Math.round(comparisonRespondentsScorePercentage))}`}></div>{Math.round(comparisonRespondentsScorePercentage)}%</td>

                                        { productType === 1 ? 
                                            <td><div className={`dot ${getReportScoreColour(Math.round(comparisonLeaderScorePercentage))}`}></div>{Math.round(comparisonLeaderScorePercentage)}%</td>
                                        : null }
                                    </tr>
                                : null }
                            </tbody>
                        </table>
                    </div>
                : null}
            </div>
            <button type="button" 
                onMouseEnter={() => setShowQuestion(true)}  
                onMouseLeave={() => setShowQuestion(false)}
                onFocus={() => setShowQuestion(true)}  
                onBlur={() => setShowQuestion(false)}></button>
        </div>
    );
}

export default function ReportRadarChart({sections, comparisonSections, productType, reportType, showRespondentBarPoints, showLeaderBarPoints, showComparisonRespondentBarPoints, showComparisonLeaderBarPoints, completedOn, comparisonCompletedOn}) {
    const radarChartRef = useRef();
    const [questionPopUps, setQuestionPopUps] = useState();

    const { bearerToken } = useParams();

    const [context, setContext] = useState(false);

    const getXCoord = (i, total, sectionsTotal, factor, radians, halfWidth, offsetX = 0) => {
        if (sectionsTotal % 2 !== 0) {
            i = (i * 2) - (total / sectionsTotal);
            total *= 2;
        }
    
        return halfWidth * (1 - factor * Math.sin((i * -radians) / total)) + offsetX;
    };
    
    const getYCoord = (i, total, sectionsTotal, factor, radians, halfHeight, offsetY = 0) => {
        if (sectionsTotal % 2 !== 0) {
            i = (i * 2) - (total / sectionsTotal);
            total *= 2;
        }
    
        return halfHeight * (1 - factor * Math.cos((i * -radians) / total)) + offsetY;
    };
    
    const isLowerChartSection = (multichoiceSections, index) => {
        if (multichoiceSections > 2) {
            if (multichoiceSections % 2 === 0) {
                if ((multichoiceSections - 2) % 4 === 0) {
                    if (index > multichoiceSections * 0.25 && index + 1 <= multichoiceSections * 0.75) {
                        return true;
                    }
                } else {
                    if (index + 1 > multichoiceSections * 0.25 && index + 1 <= multichoiceSections * 0.75) {
                        return true;
                    }
                }
            } else {
                if ((multichoiceSections - 1) % 4 === 0 && index > multichoiceSections * 0.25 && index <= multichoiceSections * 0.75) {
                    return true;
                }
            }
        }
    };
    
    const getWidthOfText = (txt, fontname, fontsize) => {
        if (getWidthOfText.c === undefined) {
            getWidthOfText.c=document.createElement('canvas');
            getWidthOfText.context=getWidthOfText.c.getContext('2d');
        }

        const fontspec = fontsize + ' ' + fontname;

        if (getWidthOfText.context.font !== fontspec)
            getWidthOfText.context.font = fontspec;

        return getWidthOfText.context.measureText(txt).width;
    }

    useEffect(() => {
        setContext(radarChartRef.current.getContext('2d'));
    }, [radarChartRef]);

    useEffect(() => {
        if ( context ) {
            context.clearRect(0, 0, radarChartRef.current.width, radarChartRef.current.height);

            const radians = Math.PI * 2;
            const blue = '#081E64';
            const lightBlue = '#28C5FF';
            const width = radarChartRef.current.width;
            const height = radarChartRef.current.height;
            const halfWidth = width / 2;
            const halfHeight = height / 2;

            const innerWidth = width - 180;
            const innerHeight = height - 180;
            const halfInnerWidth = innerWidth / 2;
            const halfInnerHeight = innerHeight / 2;

            const centerCircleWidth = halfInnerWidth / 11;

            const percentageFraction = percentage => {
                return (((halfInnerWidth - centerCircleWidth) * percentage / 100) + centerCircleWidth) / halfInnerWidth;
            };
            
            // Light blue circle
            context.fillStyle = '#BEEEFE';
            context.strokeStyle = '#FFFFFF';
            context.lineWidth = 10;
            context.beginPath();
            context.arc(halfWidth, halfHeight, halfInnerWidth + (context.lineWidth / 2), 0, radians);
            context.closePath();
            context.fill();
            context.stroke();
            
            // Green circle
            context.fillStyle = '#D8FFE8';
            context.beginPath();
            context.arc(halfWidth, halfHeight, ((halfInnerWidth - centerCircleWidth) * 0.8) + centerCircleWidth, 0, radians);
            context.closePath();
            context.fill();
            
            // Orange circle
            context.fillStyle = '#FFE2BB';
            context.beginPath();
            context.arc(halfWidth, halfHeight, ((halfInnerWidth - centerCircleWidth) * 0.55) + centerCircleWidth, 0, radians);
            context.closePath();
            context.fill();

            // Center circle
            context.fillStyle = '#FFFFFF';
            context.beginPath();
            context.arc(halfWidth, halfHeight, centerCircleWidth, 0, radians);
            context.closePath();
            context.fill();

            // Radar 10% circles
            for (var i = 2; i <= 10; i++) {
                context.fillStyle = '#FFFFFF';
                context.strokeStyle = '#FFFFFF';
                context.lineWidth = 2;
                context.beginPath();
                context.arc(halfWidth, halfHeight, (halfInnerWidth / 11) * i, 0, radians);
                context.closePath();
                context.stroke();
            }

            const multichoiceSections = sections.filter(section => !section.onlyFreeText);
            const comparisonMultichoiceSections = comparisonSections?.length ? comparisonSections.filter(section => !section.onlyFreeText) : null;


            const getSectionChartPoints = (sections, key) => {
                let barPoints = [];

                sections.forEach((section, sectionIndex) => {
                    section.questions.forEach((question, index) => {
                        const coordIndex = (section.questions.length * (sectionIndex * 2)) + (index * 2) + 1;
                        const coordTotal = section.questions.length * multichoiceSections.length * 2;
    
                        // Respondent
                        const respondentsPercentageFraction = percentageFraction(question[key]);
    
                        const respondentsX = getXCoord(coordIndex, coordTotal, multichoiceSections.length, respondentsPercentageFraction, radians, halfInnerWidth, 90);
                        const respondentsY = getYCoord(coordIndex, coordTotal, multichoiceSections.length, respondentsPercentageFraction, radians, halfInnerHeight, 90);
            
                        barPoints.push({x: respondentsX, y: respondentsY});
                    });
                });

                return barPoints;
            };
            
            // Radar section dividers
            multichoiceSections.forEach((section, sectionIndex) => {
                const x = getXCoord(sectionIndex, multichoiceSections.length, multichoiceSections.length, 1, radians, halfWidth);
                const y = getYCoord(sectionIndex, multichoiceSections.length, multichoiceSections.length, 1, radians, halfHeight);

                context.strokeStyle = '#FFFFFF';
                context.lineWidth = 4;
                
                context.beginPath();

                context.lineTo(halfWidth, halfHeight, 6, 0, 0);
                context.lineTo(x, y, 6, 0, 0);
        
                context.closePath();
                context.stroke();
            });

            let questions = [];

            // Respondents bar points
            if (showRespondentBarPoints) {
                const respondentBarPoints = getSectionChartPoints(multichoiceSections, 'respondentsScorePercentage');
                
                if (respondentBarPoints) {
                    context.strokeStyle = blue;
                    context.lineWidth = 2;
                    
                    context.beginPath();
                    context.lineJoin = 'round';

                    respondentBarPoints.forEach(points => {
                        context.lineTo(points.x, points.y, 3, 0, radians);
                    });

                    context.closePath();
                    context.stroke();

                    respondentBarPoints.forEach(points => {
                        context.fillStyle = blue;
                        context.beginPath();
                        context.arc(points.x, points.y, 3, 0, radians);
                        context.closePath();
                        context.fill();
                    });

                    let multichoiceSectionQuestionIndex = 0;

                    multichoiceSections.forEach((section, sectionIndex) => {
                        section.questions.forEach((question, questionIndex) => {
                            questions.push({
                                question: question.question,
                                leaderScorePercentage: question.leaderScorePercentage,
                                respondentsScorePercentage: question.respondentsScorePercentage,
                                comparisonLeaderScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].leaderScorePercentage : null,
                                comparisonRespondentsScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].respondentsScorePercentage : null,
                                position: {
                                    x: respondentBarPoints[multichoiceSectionQuestionIndex].x / 2,
                                    y: respondentBarPoints[multichoiceSectionQuestionIndex].y / 2
                                }
                            });

                            multichoiceSectionQuestionIndex++;
                        });
                    });
                }
            }

            // Leader bar points
            if (showLeaderBarPoints) {
                const leaderBarPoints = getSectionChartPoints(multichoiceSections, 'leaderScorePercentage');
                
                if (leaderBarPoints) {
                    context.strokeStyle = blue;
                    context.lineWidth = 1;
                    
                    context.beginPath();
                    context.lineJoin = 'round';
                    context.setLineDash([5, 3]);

                    leaderBarPoints.forEach(points => {
                        context.lineTo(points.x, points.y, 3, 0, radians);
                    });

                    context.closePath();
                    context.stroke();
                    context.setLineDash([]);

                    leaderBarPoints.forEach(points => {
                        context.fillStyle = '#fff';
                        context.strokeStyle = blue;
                        context.beginPath();
                        context.arc(points.x, points.y, 3, 0, radians);
                        context.closePath();
                        context.fill();
                        context.stroke();
                    });

                    context.closePath();
                    
                    let multichoiceSectionQuestionIndex = 0;

                    multichoiceSections.forEach((section, sectionIndex) => {
                        section.questions.forEach((question, questionIndex) => {
                            questions.push({
                                question: question.question,
                                leaderScorePercentage: question.leaderScorePercentage,
                                respondentsScorePercentage: question.respondentsScorePercentage,
                                comparisonLeaderScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].leaderScorePercentage : null,
                                comparisonRespondentsScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].respondentsScorePercentage : null,
                                position: {
                                    x: leaderBarPoints[multichoiceSectionQuestionIndex].x / 2,
                                    y: leaderBarPoints[multichoiceSectionQuestionIndex].y / 2
                                }
                            });

                            multichoiceSectionQuestionIndex++;
                        });
                    });
                }
            }

            if ( comparisonSections ) {
                // Respondents bar points
                if (showComparisonRespondentBarPoints) {
                    const respondentBarPoints = getSectionChartPoints(comparisonMultichoiceSections, 'respondentsScorePercentage');
                    
                    if (respondentBarPoints) {
                        context.strokeStyle = lightBlue;
                        context.lineWidth = 2;
                        
                        context.beginPath();
    
                        respondentBarPoints.forEach(points => {
                            context.lineTo(points.x, points.y, 3, 0, radians);
                        });
    
                        context.closePath();
                        context.stroke();
    
                        respondentBarPoints.forEach(points => {
                            context.fillStyle = lightBlue;
                            context.beginPath();
                            context.arc(points.x, points.y, 3, 0, radians);
                            context.closePath();
                            context.fill();
                        });
                    
                        let multichoiceSectionQuestionIndex = 0;
    
                        multichoiceSections.forEach((section, sectionIndex) => {
                            section.questions.forEach((question, questionIndex) => {
                                questions.push({
                                    question: question.question,
                                    leaderScorePercentage: question.leaderScorePercentage,
                                    respondentsScorePercentage: question.respondentsScorePercentage,
                                    comparisonLeaderScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].leaderScorePercentage : null,
                                    comparisonRespondentsScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].respondentsScorePercentage : null,
                                    position: {
                                        x: respondentBarPoints[multichoiceSectionQuestionIndex].x / 2,
                                        y: respondentBarPoints[multichoiceSectionQuestionIndex].y / 2
                                    }
                                });
    
                                multichoiceSectionQuestionIndex++;
                            });
                        });
                    }
                }
    
                // Leader bar points
                if (showComparisonLeaderBarPoints) {
                    const leaderBarPoints = getSectionChartPoints(comparisonMultichoiceSections, 'leaderScorePercentage');
                    
                    if (leaderBarPoints) {
                        context.strokeStyle = lightBlue;
                        context.lineWidth = 1;
                        
                        context.beginPath();
                        context.setLineDash([5, 3]);
    
                        leaderBarPoints.forEach(points => {
                            context.lineTo(points.x, points.y, 3, 0, radians);
                        });
    
                        context.closePath();
                        context.stroke();
                        context.setLineDash([]);
    
                        leaderBarPoints.forEach(points => {
                            context.fillStyle = '#fff';
                            context.strokeStyle = lightBlue;
                            context.beginPath();
                            context.arc(points.x, points.y, 3, 0, radians);
                            context.closePath();
                            context.fill();
                            context.stroke();
                        });
    
                        context.closePath();
                    
                        let multichoiceSectionQuestionIndex = 0;
    
                        multichoiceSections.forEach((section, sectionIndex) => {
                            section.questions.forEach((question, questionIndex) => {
                                questions.push({
                                    question: question.question,
                                    leaderScorePercentage: question.leaderScorePercentage,
                                    respondentsScorePercentage: question.respondentsScorePercentage,
                                    comparisonLeaderScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].leaderScorePercentage : null,
                                    comparisonRespondentsScorePercentage: comparisonSections ? comparisonMultichoiceSections[sectionIndex].questions[questionIndex].respondentsScorePercentage : null,
                                    position: {
                                        x: leaderBarPoints[multichoiceSectionQuestionIndex].x / 2,
                                        y: leaderBarPoints[multichoiceSectionQuestionIndex].y / 2
                                    }
                                });
    
                                multichoiceSectionQuestionIndex++;
                            });
                        });
                    }
                }
            }

            setQuestionPopUps(questions);
        }
    }, [sections, comparisonSections, showRespondentBarPoints, showLeaderBarPoints, showComparisonRespondentBarPoints, showComparisonLeaderBarPoints, context]);

    return (
        <div className="results-radar-chart">
            <div className="chart">
                { questionPopUps && questionPopUps.length ? questionPopUps.map((question, index) => {
                    return (
                        <QuestionButton key={index} 
                            productType={productType} 
                            reportType={reportType} 
                            question={question.question} 
                            respondentsScorePercentage={question.respondentsScorePercentage} 
                            leaderScorePercentage={question.leaderScorePercentage} 
                            completedOn={completedOn} 
                            topPos={question.position.y} 
                            leftPos={question.position.x} 
                            comparisonRespondentsScorePercentage={question.comparisonRespondentsScorePercentage}
                            comparisonLeaderScorePercentage={question.comparisonLeaderScorePercentage} 
                            comparisonCompletedOn={comparisonCompletedOn} />
                    );
                }) : null }

                { sections.filter(filteredSection => !filteredSection.onlyFreeText).map((section, index) => {
                    const radians = Math.PI * 2;
                    const width = 540;
                    const height = 540;
                    const halfWidth = width / 2;
                    const halfHeight = height / 2;

                    const multichoiceSections = sections.filter(filteredSection => !filteredSection.onlyFreeText);

                    const percentage = Math.round(section.averageScorePercentage);

                    const textString = `${section.name}${ reportType === 0 ? ` - ${percentage}%` : ''}`;

                    let textOffset, rotate, dotRotate;

                    const isLowerSection = isLowerChartSection(multichoiceSections.length, index);

                    let textWidthDivision = 6.5;

                    if ( reportType === 1 || reportType === 2 ) {
                        textWidthDivision = 8
                    }

                    if (isLowerSection) {
                        textOffset = 64;

                        rotate = (360 / multichoiceSections.length) * (index + 0.5) - 180 + (getWidthOfText(textString.toUpperCase(), 'Chivo', '12px') / textWidthDivision);

                        if (multichoiceSections.length % 2 !== 0) {
                            rotate -= (180 / multichoiceSections.length);
                        }
                        
                        dotRotate = rotate + 180 - (getWidthOfText(textString.toUpperCase(), 'Chivo', '12px') / 3.25);
                    } else {
                        textOffset = 75;

                        rotate = (360 / multichoiceSections.length) * (index + 0.5) - (getWidthOfText(textString.toUpperCase(), 'Chivo', '12px') / textWidthDivision);

                        if (multichoiceSections.length % 2 !== 0) {
                            rotate -= (180 / multichoiceSections.length);
                        }
                        
                        dotRotate = rotate + (getWidthOfText(textString.toUpperCase(), 'Chivo', '12px') / 3.25);
                    }
                    
                    return (
                        <div key={section.id}>
                            <div className="text-svg" style={{transform: `rotate(${rotate}deg)`}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 540">
                                    <defs>
                                        { isLowerSection ?
                                            <path d="M524,523.9H16v-254c0,140.2,113.8,254,254,254s254-113.8,254-254V523.9z" id={`report-radar-chart-section-name-${section.id}-${index}`}/>
                                        :
                                            <path d="M514.8,269.7c0,135.3-109.7,245-245,245s-245-109.7-245-245s109.7-245,245-245S514.8,134.3,514.8,269.7z" id={`report-radar-chart-section-name-${section.id}-${index}`} />
                                        }
                                    </defs> 
                                    <text>
                                        <textPath xlinkHref={`#report-radar-chart-section-name-${section.id}-${index}`} 
                                            startOffset={`${textOffset}%`}>
                                            {textString}
                                        </textPath>
                                    </text>
                                </svg>
                            </div>
                            { reportType === 0 ?
                                <div className="text-svg" style={{transform: `rotate(${dotRotate}deg)`}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 540">
                                        <g className={`percentage-dot percentage-dot-${index}`}>
                                            <circle className={`dot ${ getReportScoreColour(percentage) }`} cx="270" cy="21" r="5"/>
                                            <circle className="bg-circle" cx="270" cy="270" r="270"/>
                                        </g>
                                    </svg>
                                </div>
                            : null }
                            <div className="link-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 540">
                                    <Link to={`/report/${bearerToken}/responses/${section.id}`}>
                                        <path d={`M270 270 
                                            L${getXCoord(index, multichoiceSections.length, multichoiceSections.length, 2, radians, halfWidth)} ${getYCoord(index, multichoiceSections.length, multichoiceSections.length, 2, radians, halfHeight)} 
                                            L${getXCoord(index + 1, multichoiceSections.length, multichoiceSections.length, 2, radians, halfWidth)} ${getYCoord(index + 1, multichoiceSections.length, multichoiceSections.length, 2, radians, halfHeight)} Z`} />
                                    </Link>
                                </svg>
                            </div>
                        </div>
                    )
                })}
                <canvas ref={radarChartRef} width="1080" height="1080"></canvas>
            </div>
        </div>
    );
}
