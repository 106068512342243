import React from "react";

import "./QuestionnaireFooter.scss";

import twitterCircle from "../../images/twitter-circle.svg";
import linkedinCircle from "../../images/linkedin-circle.svg";
import youtubeCircle from "../../images/youtube-circle.svg";

export default function QuestionnaireFooter() {
  return (
    <footer className="questionnaire-footer">
      <div className="container-lg container-padding-lg">
        <div className="questionnaire-footer-top">
          <div className="questionnaire-footer-email">
            <a href="mailto:leadership@holoschange.com">
              leadership@holoschange.com
            </a>
          </div>
          <div className="questionnaire-footer-social">
            <a
              href="https://twitter.com/holoschange"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterCircle} alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/holos-change-ltd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinCircle} alt="LinkedIn" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCov8uYER3OBIgCe2Ygd-DNQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeCircle} alt="YouTube" />
            </a>
          </div>
        </div>

        <div className="questionnaire-footer-bottom">
          <nav className="questionnaire-footer-nav">
            <div>
              <a
                href="https://holoschange.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
          </nav>

          <div className="questionnaire-footer-copyright">
            2020 &copy; Copyright Holos Change Ltd.
          </div>
        </div>
      </div>
    </footer>
  );
}
