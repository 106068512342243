import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { isValid, parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import axios from 'axios';
import { getReportScoreColour } from '../../helpers';
import { Store } from '../../Store';

import ReportOverviewHeader from './ReportOverviewHeader/ReportOverviewHeader';
import AdminContainer from '../Admin/AdminContainer/AdminContainer';
import AdminTop from '../Admin/AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../Admin/AdminCard/AdminCard';
import AdminTable, { AdminTableHead, AdminTableBody } from '../Admin/AdminTable/AdminTable';
import AdminModal, { AdminModalBtns } from '../Admin/AdminModal/AdminModal';

import './ReportOverview.scss';

export default function ReportOverview() {
    const { state } = useContext(Store);
    const { apiUrl } = state;

    const { bearerToken } = useParams();

	const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(false);

    const [report, setReport] = useState();

    const [generateReportLoading, setGenerateReportLoading] = useState(false);
    const [showGenerateReportSuccessModal, setShowGenerateReportSuccessModal] = useState(false);

    const fetchReportOverview = useCallback(() => {
        setPageLoading(true);
        setPageError(false);
  
        axios.get(`${apiUrl}Reports/Cohort`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        }).then(response => {
            if (response.data.success) {
                setReport(response.data);
            }

            setPageLoading(false);
        }).catch(err => {
            setPageError(err?.response?.data?.errors ?? err?.message ?? '');
            setPageLoading(false);
        });
    }, [apiUrl, bearerToken]);
    
    useEffect(() => {
        fetchReportOverview();
    }, [fetchReportOverview]);

    useEffect(() => {
        document.querySelector('html').classList.add('report-overview');

        return () => {
            document.querySelector('html').classList.remove('report-overview');
        }
    }, []);

    const onClickEmailComparisonPDFButton = () => {
        setGenerateReportLoading(true);
        
        axios.post(`${apiUrl}Reports/SendCohortOverview`, false, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        }).then(response => {
            console.log(response);

            if (response.data.success) {
                setShowGenerateReportSuccessModal(true);
            }

            setGenerateReportLoading(false);
        }).catch(err => {
            setGenerateReportLoading(false);
        });
    };

    return (
        <div>
            <ReportOverviewHeader header={report?.clientName && report?.programmeName ? `${report.clientName} - ${report.programmeName}` : false} subheader={report?.cohortName} />

            <AdminContainer pageError={pageError} pageLoading={pageLoading}>
                <AdminTop header="Detailed scores">
                    <div>
                        <button className={`btn blue ${generateReportLoading ? 'loading' : ''}`} type="button" onClick={onClickEmailComparisonPDFButton}>Email Comparison PDF</button>
                    </div>
                </AdminTop>

                <AdminCard>
                    <AdminCardBody>
                        <div className="info-panel">
                            <div>
                                { report?.clientName && report?.programmeName ?
                                    <div>
                                        <h3>{report.clientName}</h3>
                                        <div>{report.programmeName}</div>
                                    </div>
                                : null }
                                { report?.cohortName ?
                                    <div>
                                        <h3>Cohort:</h3>
                                        <div>{report.cohortName}</div>
                                    </div>
                                : null }
                                { report?.agentName ?
                                    <div>
                                        <h3>Holos Cohort Lead:</h3>
                                        <div>{report.agentName}</div>
                                    </div>
                                : null }
                                { report?.completionDeadline && isValid(parseISO(report.completionDeadline)) ?
                                    <div>
                                        <h3>Completion Deadline:</h3>
                                        <div>{format(utcToZonedTime(parseISO(report.completionDeadline), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong></div>
                                    </div>
                                : null }
                            </div>
                            <div></div>
                        </div>
                    </AdminCardBody>
                    <AdminCardBody>
                        { report?.reports ?
                            <div className="table-details">
                                <div>Showing {report.reports.length} completed {report.reports.length === 1 ? 'report' : 'reports'}</div>
                            </div>
                        : null }
                        <AdminTable className="report-overview-table">
                            <AdminTableHead>
                                <tr>
                                    <th className="leader-name-cell">Leader Name</th>
                                    <th className="email-address-cell">Email address</th>
                                    <th className="respondents-completed-cell">Respondents <br />Completed</th>
                                    { report?.sectionNames?.length ? report.sectionNames.map((section, index) => <th key={index} className="section-cell">{section}</th>) : null }
                                    <th className="section-cell blue">Total Score</th>
                                </tr>
                            </AdminTableHead>
                            { report?.reports?.length ?
                                <AdminTableBody>
                                    { report.reports.map((report, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="leader-name-cell">{report.leaderName}</td>
                                                <td className="email-address-cell">{report.leaderEmailAddress}</td>
                                                <td className="respondents-completed-cell">{report.respondentsCompleted}/{report.totalRespondents}</td>
                                                { report?.sectionScores?.length ? report.sectionScores.map((sectionScore, index) => <td key={index} className={`section-cell ${getReportScoreColour(sectionScore)}`}>{sectionScore}%</td>) : null }
                                                <td className={`section-cell ${report?.totalScore ? getReportScoreColour(report.totalScore) : ''}`}>{report?.totalScore ? `${report?.totalScore}%` : null}</td>
                                            </tr>
                                        )
                                    }) }
                                </AdminTableBody>
                            : null }
                        </AdminTable>
                    </AdminCardBody>
                </AdminCard>
            </AdminContainer>
            
            <AdminModal show={showGenerateReportSuccessModal} closeModal={() => setShowGenerateReportSuccessModal(false)}>
                <h2>Generating Comparison PDF</h2>

                <p>Your comparison PDF will be emailed to you once successfully generated.</p>

                <AdminModalBtns>
                    <button className="btn blue" type="button" onClick={() => setShowGenerateReportSuccessModal(false)}>Close</button>
                </AdminModalBtns>
            </AdminModal>
        </div>
    );
}
