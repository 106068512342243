import React, { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Store } from '../../../../../Store';
import { getClient, getProgramme, getCohorts, getAgents, createCohort, updateCohort, generateCohortDates } from '../../../../../api';
import { sortNameAlphabetically, dateTimeToUTC } from '../../../../../helpers';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminInfoBar from '../../../AdminInfoBar/AdminInfoBar';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody, AdminCardNumber } from '../../../AdminCard/AdminCard';
import Input, { InputRow, InputGroup } from '../../../../Input/Input';
import AdminActionBar from '../../../AdminActionBar/AdminActionBar';
import UploadLeadersFormModal from '../../../UploadLeadersFormModal/UploadLeadersFormModal';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage';
import AdminLeavingPageModal from '../../../AdminLeavingPageModal/AdminLeavingPageModal';

import UploadIcon from '../../../../Icons/UploadIcon';
import PlusIcon from '../../../../Icons/PlusIcon';
import BellIcon from '../../../../Icons/BellIcon';

import './AddCohort.scss';

function AddCohortCard({cohort, index, number, agents, cohortAgent, removeCohort, updateCohortData, urlPrepend, newCohort}) {
    const { state } = useContext(Store);
    const { times, defaultTime, uploadLeaderTemplateLink } = state;

    const {clientId, programmeId} = useParams();

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [addLeadersSuccess, setAddLeadersSuccess] = useState(false);

    const [cohortId, setCohortId] = useState(cohort.id);
    const [nameValue, setNameValue] = useState(cohort.name);
    const [agentValue, setAgentValue] = useState(cohort.agentId);
    const [nominationInviteDateValue, setNominationInviteDateValue] = useState();
    const [nominationInviteTimeValue, setNominationInviteTimeValue] = useState();
    const [debriefDateValue, setDebriefDateValue] = useState(cohort.debriefDate ?? '');
    const [debriefTimeValue, setDebriefTimeValue] = useState(cohort.debriefTime ?? defaultTime);
    const [nominationReminder1DateValue, setNominationReminder1DateValue] = useState(cohort.nominationReminder1Date ?? '');
    const [nominationReminder1TimeValue, setNominationReminder1TimeValue] = useState(cohort.nominationReminder1Time ?? defaultTime);
    const [nominationsDeadlineDateValue, setNominationsDeadlineDateValue] = useState(cohort.nominationsDeadlineDate ?? '');
    const [nominationsDeadlineTimeValue, setNominationsDeadlineTimeValue] = useState(cohort.nominationsDeadlineTime ?? defaultTime);
    const [surveyReminder1DateValue, setSurveyReminder1DateValue] = useState(cohort.surveyReminder1Date ?? '');
    const [surveyReminder1TimeValue, setSurveyReminder1TimeValue] = useState(cohort.surveyReminder1Time ?? defaultTime);
    const [surveyReminder2DateValue, setSurveyReminder2DateValue] = useState(cohort.surveyReminder2Date ?? '');
    const [surveyReminder2TimeValue, setSurveyReminder2TimeValue] = useState(cohort.surveyReminder2Time ?? defaultTime);
    const [surveyDeadlineDateValue, setSurveyDeadlineDateValue] = useState(cohort.surveyDeadlineDate ?? '');
    const [surveyDeadlineTimeValue, setSurveyDeadlineTimeValue] = useState(cohort.surveyDeadlineTime ?? defaultTime);

    const [datesGenerated, setDatesGenerated] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [agentError, setAgentError] = useState(false);
    const [nominationInviteDateError, setNominationInviteDateError] = useState(false);
    const [nominationReminder1DateError, setNominationReminder1DateError] = useState(false);
    const [nominationsDeadlineDateError, setNominationsDeadlineDateError] = useState(false);
    const [surveyReminder1DateError, setSurveyReminder1DateError] = useState(false);
    const [surveyReminder2DateError, setSurveyReminder2DateError] = useState(false);
    const [surveyDeadlineDateError, setSurveyDeadlineDateError] = useState(false);

    const [showUploadLeadersModal, setShowUploadLeadersModal] = useState(false);

    useEffect(() => {
        setCohortId(cohort.id);
        setNameValue(cohort.name);

        if (cohort.agentId) {
            setAgentValue(cohort.agentId);
        } else if (agents?.users?.filter(agent => agent.name === cohortAgent).length) {
            setAgentValue(agents.users.filter(agent => agent.name === cohortAgent)[0].id);
        }

        setNominationInviteDateValue(cohort?.nominationsSend ? utcToZonedTime(parseISO(cohort.nominationsSend), 'UTC') : (cohort.nominationInviteDate ?? ''));
        setNominationInviteTimeValue(cohort?.nominationsSend ? format(utcToZonedTime(parseISO(cohort.nominationsSend), 'UTC'), 'HH:mm') : (cohort.nominationInviteTime ?? defaultTime));
        setDebriefDateValue(cohort.debriefDate ?? '');
        setDebriefTimeValue(cohort.debriefTime ?? defaultTime);
        setNominationReminder1DateValue(cohort?.nominationsReminder1 ? utcToZonedTime(parseISO(cohort.nominationsReminder1), 'UTC') : (cohort.nominationReminder1Date ?? ''));
        setNominationReminder1TimeValue(cohort?.nominationsReminder1 ? format(utcToZonedTime(parseISO(cohort.nominationsReminder1), 'UTC'), 'HH:mm') : (cohort.nominationReminder1Time ?? defaultTime));
        setNominationsDeadlineDateValue(cohort?.nominationDeadline ? utcToZonedTime(parseISO(cohort.nominationDeadline), 'UTC') : (cohort.nominationsDeadlineDate ?? ''));
        setNominationsDeadlineTimeValue(cohort?.nominationDeadline ? format(utcToZonedTime(parseISO(cohort.nominationDeadline), 'UTC'), 'HH:mm') : (cohort.nominationsDeadlineTime ?? defaultTime));
        setSurveyReminder1DateValue(cohort?.surveyReminder1 ? utcToZonedTime(parseISO(cohort.surveyReminder1), 'UTC') : (cohort.surveyReminder1Date ?? ''));
        setSurveyReminder1TimeValue(cohort?.surveyReminder1 ? format(utcToZonedTime(parseISO(cohort.surveyReminder1), 'UTC'), 'HH:mm') : (cohort.surveyReminder1Time ?? defaultTime));
        setSurveyReminder2DateValue(cohort?.surveyReminder2 ? utcToZonedTime(parseISO(cohort.surveyReminder2), 'UTC') : (cohort.surveyReminder2Date ?? ''));
        setSurveyReminder2TimeValue(cohort?.surveyReminder2 ? format(utcToZonedTime(parseISO(cohort.surveyReminder2), 'UTC'), 'HH:mm') : (cohort.surveyReminder2Time ?? defaultTime));
        setSurveyDeadlineDateValue(cohort?.deadline ? utcToZonedTime(parseISO(cohort.deadline), 'UTC') : (cohort.surveyDeadlineDate ?? ''));
        setSurveyDeadlineTimeValue(cohort?.deadline ? format(utcToZonedTime(parseISO(cohort.deadline), 'UTC'), 'HH:mm') : (cohort.surveyDeadlineTime ?? defaultTime));

        setDatesGenerated(cohort?.nominationsReminder1 || cohort?.nominationDeadline || cohort?.surveyReminder1 || cohort?.surveyReminder2);
    }, [cohort, agents, cohortAgent, defaultTime]);

    const onSubmitAddNewCohortForm = e => {
        e.preventDefault();

        setFormLoading(true);

        setFormErrors(false);
        setNameError(false);
        setAgentError(false);
        setNominationInviteDateError(false);
        setNominationReminder1DateError(false);
        setNominationsDeadlineDateError(false);
        setSurveyReminder1DateError(false);
        setSurveyReminder2DateError(false);
        setSurveyDeadlineDateError(false);
        setFormSuccess(false);

        const saveThen = response => {
            if (response?.success && response?.id) {
                setCohortId(response.id);
                updateCohortData(index, 'id', response.id, newCohort);
                setFormSuccess('Your cohort has been saved. You can now add leaders to the cohort.');
                updateCohortData(index, 'formChange', false, newCohort);
            }
            
            if (response?.errors) {
                setNameError(response.errors?.Name ?? false);
                setAgentError(response.errors?.AgentId ?? false);
                setNominationInviteDateError(response.errors?.NominationInviteDate ?? false);
                setNominationReminder1DateError(response.errors?.NominationsReminder1 ?? false);
                setNominationsDeadlineDateError(response.errors?.NominationsDeadline ?? false);
                setSurveyReminder1DateError(response.errors?.SurveyReminder1 ?? false);
                setSurveyReminder2DateError(response.errors?.SurveyReminder2 ?? false);
                setSurveyDeadlineDateError(response.errors?.SurveyDeadline ?? false);

                let errors = Object.assign({}, response.errors);

                delete errors.Name;
                delete errors.AgentId;
                delete errors.NominationInviteDate;
                delete errors.NominationsReminder1;
                delete errors.NominationsDeadline;
                delete errors.SurveyReminder1;
                delete errors.SurveyReminder2;
                delete errors.SurveyDeadline;

                setFormErrors(errors);
            }

            setFormLoading(false);
        }

        if (!cohortId) {
            createCohort(clientId, programmeId, {
                name: nameValue,
                agentId: agentValue,
                nominationInviteDate: dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue),
                debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                nominationsDeadline: dateTimeToUTC(nominationsDeadlineDateValue, nominationsDeadlineTimeValue),
                surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                nominationsReminder1: dateTimeToUTC(nominationReminder1DateValue, nominationReminder1TimeValue),
                surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue)
            }).then(response => saveThen(response));
        } else {
            updateCohort(clientId, programmeId, cohortId, {
                //eTag: eTag,
                name: nameValue,
                agentId: agentValue,
                nominationInviteDate: dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue),
                debriefDate: dateTimeToUTC(debriefDateValue, debriefTimeValue),
                nominationsDeadline: dateTimeToUTC(nominationsDeadlineDateValue, nominationsDeadlineTimeValue),
                surveyDeadline: dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue),
                nominationsReminder1: dateTimeToUTC(nominationReminder1DateValue, nominationReminder1TimeValue),
                surveyReminder1: dateTimeToUTC(surveyReminder1DateValue, surveyReminder1TimeValue),
                surveyReminder2: dateTimeToUTC(surveyReminder2DateValue, surveyReminder2TimeValue)
            }).then(response => saveThen(response));
        }
    };

    const onClickGenerateDatesBtn = () => {
        setFormLoading(true);
        setNominationInviteDateError(false);
        setSurveyDeadlineDateError(false);

        if (!dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue)) {
            setNominationInviteDateError(['Nomination deadline date is required']);
        }

        if (!dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue)) {
            setSurveyDeadlineDateError(['Survey deadline date is required']);
        }

        if (!dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue) || !dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue)) {
            setFormLoading(false);
            return false;
        }

        generateCohortDates(dateTimeToUTC(nominationInviteDateValue, nominationInviteTimeValue), dateTimeToUTC(surveyDeadlineDateValue, surveyDeadlineTimeValue))
            .then(response => {
                setNominationReminder1DateValue(response?.nominationsReminder1 ? utcToZonedTime(parseISO(response.nominationsReminder1), 'UTC') : null);
                setNominationReminder1TimeValue(response?.nominationsReminder1 ? format(utcToZonedTime(parseISO(response.nominationsReminder1), 'UTC'), 'HH:mm') : null);
                setNominationsDeadlineDateValue(response?.nominationsDeadline ? utcToZonedTime(parseISO(response.nominationsDeadline), 'UTC') : null);
                setNominationsDeadlineTimeValue(response?.nominationsDeadline ? format(utcToZonedTime(parseISO(response.nominationsDeadline), 'UTC'), 'HH:mm') : null);

                setSurveyReminder1DateValue(response?.surveyReminder1 ? utcToZonedTime(parseISO(response.surveyReminder1), 'UTC') : null);
                setSurveyReminder1TimeValue(response?.surveyReminder1 ? format(utcToZonedTime(parseISO(response.surveyReminder1), 'UTC'), 'HH:mm') : null);
                setSurveyReminder2DateValue(response?.surveyReminder2 ? utcToZonedTime(parseISO(response.surveyReminder2), 'UTC') : null);
                setSurveyReminder2TimeValue(response?.surveyReminder2 ? format(utcToZonedTime(parseISO(response.surveyReminder2), 'UTC'), 'HH:mm') : null);

                setDatesGenerated(true);
                setFormLoading(false);
            });
    };

    const onClickRemoveCohortBtn = e => {
        removeCohort(index);
    };

    const onClickUploadFileBtn = () => {
        setShowUploadLeadersModal(true);
    };

    const onSubmitUploadLeadersFormSuccess = () => {
        setAddLeadersSuccess('Your leaders have been added. These will now appear in your cohort list.');
        setShowUploadLeadersModal(false);
    };

    const onChangeNameInput = e => {
        setNameValue(e.target.value);
        updateCohortData(index, 'name', e.target.value, newCohort);
    };

    const onChangeAgentSelect = e => {
        setAgentValue(e.target.value);
        updateCohortData(index, 'agentId', e.target.value, newCohort);
    };

    const onChangeNominationInviteDateInput = date => {
        setNominationInviteDateValue(date);
        updateCohortData(index, 'nominationSendDate', date, newCohort);
    };

    const onChangeNominationInviteTimeInput = e => {
        setNominationInviteTimeValue(e.target.value);
        updateCohortData(index, 'nominationSendTime', e.target.value, newCohort);
    };

    const onChangeNominationReminder1DateInput = date => {
        setNominationReminder1DateValue(date);
        updateCohortData(index, 'nominationReminder1Date', date, newCohort);
    };

    const onChangeNominationReminder1TimeInput = e => {
        setNominationReminder1TimeValue(e.target.value);
        updateCohortData(index, 'nominationReminder1Time', e.target.value, newCohort);
    };

    const onChangeNominationsDeadlineDateInput = date => {
        setNominationsDeadlineDateValue(date);
        updateCohortData(index, 'nominationsDeadlineDate', date, newCohort);
    };

    const onChangeNominationsDeadlineTimeInput = e => {
        setNominationsDeadlineTimeValue(e.target.value);
        updateCohortData(index, 'nominationsDeadlineTime', e.target.value, newCohort);
    };

    const onChangeSurveyReminder1DateInput = date => {
        setSurveyReminder1DateValue(date);
        updateCohortData(index, 'surveyReminder1Date', date, newCohort);
    };

    const onChangeSurveyReminder1TimeInput = e => {
        setSurveyReminder1TimeValue(e.target.value);
        updateCohortData(index, 'surveyReminder1Time', e.target.value, newCohort);
    };

    const onChangeSurveyReminder2DateInput = date => {
        setSurveyReminder2DateValue(date);
        updateCohortData(index, 'surveyReminder2Date', date, newCohort);
    };

    const onChangeSurveyReminder2TimeInput = e => {
        setSurveyReminder2TimeValue(e.target.value);
        updateCohortData(index, 'surveyReminder2Time', e.target.value, newCohort);
    };

    const onChangeSurveyDeadlineDateInput = date => {
        setSurveyDeadlineDateValue(date);
        updateCohortData(index, 'surveyDeadlineDate', date, newCohort);
    };

    const onChangeSurveyDeadlineTimeInput = e => {
        setSurveyDeadlineTimeValue(e.target.value);
        updateCohortData(index, 'surveyDeadlineTime', e.target.value, newCohort);
    };

    return (
        <AdminCard>
            <AdminCardNumber>
                {number}
            </AdminCardNumber>

            <AdminCardBody>
                { formErrors ? <ErrorMessage message={formErrors} /> : null }

                { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

                <form className="add-new-cohort-form" 
                    onSubmit={onSubmitAddNewCohortForm}>

                    { formLoading ? <div className="loading-circle"></div> : null }

                    <div className={`add-new-cohort-form-inner ${formLoading ? 'loading' : ''}`}>
                        <InputRow>
                            <Input type="text" 
                                label="Cohort Name*" 
                                name="name" 
                                value={nameValue} 
                                placeholder="Enter cohort name" 
                                errors={nameError} 
                                onChange={onChangeNameInput} />
                                
                            <Input type="select" 
                                label="Assign a Holos agent for this cohort*" 
                                name="agent" 
                                selectText="Holos Agent" 
                                value={agentValue} 
                                options={ agents?.users?.sort(sortNameAlphabetically).map( agent => {
                                    return {
                                        value: agent.id,
                                        name: agent.name
                                    }
                                } ) } 
                                loading={!agents?.users} 
                                errors={agentError} 
                                onChange={onChangeAgentSelect} />
                        </InputRow>

                        <InputRow>
                            <InputGroup className="date-time-input-group" 
                                label="Nomination Invite*" 
                                errors={nominationInviteDateError}>

                                <Input type="datepicker" 
                                    name="nomination-invite-date" 
                                    size="small" 
                                    value={nominationInviteDateValue} 
                                    placeholder="Enter date" 
                                    onChange={onChangeNominationInviteDateInput} />
                                
                                <Input name="nomination-invite-time" 
                                    type="select" 
                                    value={nominationInviteTimeValue} 
                                    options={times.map( time => {
                                        return {
                                            value: time.value,
                                            name: time.text
                                        }
                                    } ) } 
                                    size="extra-small" 
                                    onChange={onChangeNominationInviteTimeInput} />
                            </InputGroup>
                            
                            <InputGroup className="date-time-input-group" 
                                label="Survey Deadline*" 
                                errors={surveyDeadlineDateError}>

                                <Input type="datepicker" 
                                    name="survey-deadline-date" 
                                    size="small" 
                                    value={surveyDeadlineDateValue} 
                                    placeholder="Enter date" 
                                    onChange={onChangeSurveyDeadlineDateInput} />

                                <Input name="survey-deadline-time" 
                                    type="select" 
                                    value={surveyDeadlineTimeValue} 
                                    options={times.map( time => {
                                        return {
                                            value: time.value,
                                            name: time.text
                                        }
                                    } ) } 
                                    size="extra-small" 
                                    onChange={onChangeSurveyDeadlineTimeInput} />
                            </InputGroup>

                            <div className="align-bottom">
                                <button className="btn blue small" 
                                    type="button" 
                                    onClick={onClickGenerateDatesBtn}>
                                        
                                    { !datesGenerated ? 'Generate Reminder Dates' : 'Re-Generate Dates' }
                                </button>
                            </div>
                        </InputRow>

                        { datesGenerated ?
                            <div className="add-cohort-reminder-dates">

                                <h3 className="sub-header">
                                    <div className="icon">
                                        <BellIcon />
                                    </div> 

                                    Automated Email Reminders:
                                </h3>

                                <div className="add-cohort-reminder-dates-inner">
                                    <div className="add-cohort-reminder-dates-inputs">
                                        <InputRow>
                                            <InputGroup className="date-time-input-group" 
                                                label="Nomination Reminder 1" 
                                                errors={nominationReminder1DateError}>

                                                <Input type="datepicker" 
                                                    name="nomination-reminder-1-date" 
                                                    size="small" 
                                                    value={nominationReminder1DateValue} 
                                                    placeholder="Enter date" 
                                                    onChange={onChangeNominationReminder1DateInput} />

                                                <Input name="nomination-reminder-1-time" 
                                                    type="select" 
                                                    value={nominationReminder1TimeValue} 
                                                    options={ times.map( time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeNominationReminder1TimeInput} />
                                            </InputGroup>
                                            
                                            <InputGroup className="date-time-input-group" 
                                                label="Nomination Deadline (Nomination Reminder 2)" 
                                                errors={nominationsDeadlineDateError}>

                                                <Input type="datepicker" 
                                                    name="nominations-deadline-date" 
                                                    size="small" 
                                                    value={nominationsDeadlineDateValue} 
                                                    placeholder="Enter date" 
                                                    onChange={onChangeNominationsDeadlineDateInput} />

                                                <Input name="nomination-deadline-time" 
                                                    type="select" 
                                                    value={nominationsDeadlineTimeValue} 
                                                    options={ times.map( time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeNominationsDeadlineTimeInput} />
                                            </InputGroup>
                                        </InputRow>

                                        <InputRow>
                                            <InputGroup className="date-time-input-group" 
                                                label="Survey Reminder 1" 
                                                errors={surveyReminder1DateError}>

                                                <Input type="datepicker" 
                                                    name="survey-reminder-1-date" 
                                                    size="small" 
                                                    value={surveyReminder1DateValue} 
                                                    placeholder="Enter date" 
                                                    onChange={onChangeSurveyReminder1DateInput} />

                                                <Input name="survey-reminder-1-time" 
                                                    type="select" 
                                                    value={surveyReminder1TimeValue} 
                                                    options={ times.map( time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeSurveyReminder1TimeInput} />
                                            </InputGroup>

                                            <InputGroup className="date-time-input-group" 
                                                label="Survey Reminder 2" 
                                                errors={surveyReminder2DateError}>

                                                <Input type="datepicker" 
                                                    name="survey-reminder-2-date" 
                                                    size="small" 
                                                    value={surveyReminder2DateValue} 
                                                    placeholder="Enter date" 
                                                    onChange={onChangeSurveyReminder2DateInput} />

                                                <Input name="survey-reminder-2-time" 
                                                    type="select" 
                                                    value={surveyReminder2TimeValue}  
                                                    options={ times.map( time => {
                                                        return {
                                                            value: time.value,
                                                            name: time.text
                                                        }
                                                    } ) } 
                                                    size="extra-small" 
                                                    onChange={onChangeSurveyReminder2TimeInput} />
                                            </InputGroup>
                                        </InputRow>
                                    </div>

                                    <div className="add-cohort-reminder-dates-info">
                                        <div className="add-cohort-reminder-dates-info-inner">
                                            <div>
                                                <div className="icon">i</div>
                                            </div>
                                            The email reminder dates have been generated based on the dates above and will be sent out automatically until nominations and surveys are completed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null }

                        { addLeadersSuccess ? <SuccessMessage className="add-leader-success" message={addLeadersSuccess} setFormSuccess={setAddLeadersSuccess} /> : null }

                        <InputRow className="add-new-cohort-btns">
                            { cohortId ?
                                <InputGroup label="Add Leaders:">
                                    <div>
                                        <a className="btn" href={uploadLeaderTemplateLink} target="_blank" rel="noopener noreferrer">Download Template</a>
                                    </div>
                                    <div>
                                        <button className="btn" type="button" onClick={onClickUploadFileBtn}><UploadIcon /><div>Upload File</div></button>
                                    </div>
                                    <div>
                                        <Link className="btn" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/add-leader`}><PlusIcon /><div>Add Manually</div></Link>
                                    </div>
                                </InputGroup>
                            : <div></div> }

                            <InputGroup>
                                { index && !cohortId ?
                                    <div>
                                        <button type="button" className="btn" onClick={onClickRemoveCohortBtn}>Remove Cohort</button>
                                    </div>
                                : null }
                                { datesGenerated ?
                                    <div>
                                        <button type="submit" className="btn blue">{ !cohortId ? 'Save Cohort' : 'Update Cohort' }</button>
                                    </div>
                                : null }
                            </InputGroup>
                        </InputRow>
                    </div>
                </form>

                <UploadLeadersFormModal clientId={clientId} programmeId={programmeId} cohortId={cohortId} showUploadLeadersModal={showUploadLeadersModal} setShowUploadLeadersModal={setShowUploadLeadersModal} onSubmitSuccess={onSubmitUploadLeadersFormSuccess} />
            </AdminCardBody>
        </AdminCard>
    );
}

export default function AddCohort({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const { state } = useContext(Store);
    const { defaultTime, surveyTypes } = state;

    const location = useLocation();
    const params = useMemo(()=> new URLSearchParams(location.search), [location] );

    const {clientId, programmeId} = useParams();

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [previousLink, setPreviousLink] = useState(params.get('previousLink'));

    const newEmptyCohort = {
        id: false, 
        eTag: false, 
        name: '',  
        agentId: '' ,
        nominationInviteDate: '',
        nominationInviteTime: defaultTime,
        debriefDate: '',
        debriefTime: defaultTime,
        nominationReminder1Date: '',
        nominationReminder1Time: defaultTime,
        nominationsDeadlineDate: '',
        nominationsDeadlineTime: defaultTime,
        surveyReminder1Date: '',
        surveyReminder1Time: defaultTime,
        surveyReminder2Date: '',
        surveyReminder2Time: defaultTime,
        surveyDeadlineDate: '',
        surveyDeadlineTime: defaultTime,
        formChange: false
    };

    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [cohorts, setCohorts] = useState(false);
    const [cohortAgent, setCohortAgent] = useState(false);
    const [agents, setAgents] = useState(false);
    const [newCohorts, setNewCohorts] = useState([newEmptyCohort]);

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchCohorts = useCallback((clientId, programmeId) => {
        getCohorts(clientId, programmeId).then(response => {
            if (response.success) {
                setCohorts(response.cohorts);
                setCohortAgent(response.agentName);
            } else {
                setPageError(response.error);
            }
        }).catch(error => {
            if ( error.response?.data?.errors ) {
                setPageError(error.response.data.errors);
            } else if ( error.response?.data?.message ) {
                setPageError(error.response.data.message);
            } else {
                setPageError('There was an error. Please try again.');
            }
        }).finally(() => {
            setPageLoading(false);
        })
    }, []);

    const fetchAgents = useCallback(() => {
        getAgents().then(response => {
            if (response.success) setAgents(response);
        });
    }, []);

    useEffect(() => {
        document.title = `Add Cohort - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: 'Add a new cohort'
            }
        ]);
    }, [urlPrepend, clientId, programmeId, setHeaderBreadcrumbs]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchCohorts(clientId, programmeId);
    }, [fetchCohorts, clientId, programmeId]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    useEffect(() => {
        if (params.get('previousLink')) {
            let currentLinkUrl = new URL(window.location.href);
            let currentLinkSearchParams = currentLinkUrl.searchParams;

            newCohorts.forEach( cohort => {
                if (cohort?.id) {
                    currentLinkSearchParams.append('cohort', cohort.id);
                }
            });

            let previousLinkUrl = new URL(params.get('previousLink'), window.location.origin);
            let previousLinkSearchParams = previousLinkUrl.searchParams;

            previousLinkSearchParams.set('commsNextLink', currentLinkUrl.toString().replace(window.location.origin, ''));

            setPreviousLink(previousLinkUrl.toString().replace(window.location.origin, ''));
        }
    }, [params, newCohorts, setPreviousLink]);

    const onClickAddAnotherCohortBtn = e => {
        setNewCohorts(newCohorts.concat(newEmptyCohort));
    };

    const removeCohort = index => {
        setNewCohorts(newCohorts.filter((newCohort, newCohortIndex) => newCohortIndex !== index));
    };

    const updateCohortData = (index, field, value, newCohort) => {
        let cohortsData = newCohort ? [...newCohorts] : [...cohorts];
        cohortsData[index][field] = value;
        if ( field !== 'formChange' ) cohortsData[index]['formChange'] = true;

        if (newCohort) {
            setNewCohorts(cohortsData);
        } else {
            setCohorts(cohortsData);
        }
    };

    return (
        <div>
            { !pageLoading && !pageError && client && programme && agents ?
                <AdminInfoBar>
                    { client?.name && programme?.name ? 
                        <div>
                            <h3>{client.name}</h3>
                            <div>{programme.name}</div>
                        </div>
                    : null }
                    { programme?.agentId && agents?.length && agents?.filter(agent => agent.id === programme.agentId)?.length ?
                        <div>
                            <h3>Holos Programme Lead</h3>
                            <div>{agents.filter(agent => agent.id === programme.agentId)[0].name}</div>
                        </div>
                    : null }
                    { programme?.debriefDate ?
                        <div>
                            <h3>Debrief Start Date</h3>
                            <div>{format(utcToZonedTime(parseISO(programme.debriefDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                        </div>
                    : null }
                    { programme?.surveyType && surveyTypes ?
                        <div>
                            <h3>Survey Type</h3>
                            <div>{ surveyTypes?.filter(surveyType => surveyType.value === programme.surveyType)[0]?.name }</div>
                        </div>
                    : null }
                </AdminInfoBar>
            : null }

            <AdminContainer pageError={pageError} pageLoading={pageLoading}>
                <AdminTop header="Add a new cohort" />

                <div className="add-new-cohort-card-wrap">
                    { cohorts ?
                        cohorts.map((cohort, index) => {
                            if (!params.getAll('cohort') || !params.getAll('cohort').includes(cohort.id)) return false;

                            return (
                                <AddCohortCard key={index} cohort={cohort} index={index} number={index + 1} agents={agents} cohortAgent={cohortAgent} removeCohort={removeCohort} updateCohortData={updateCohortData} urlPrepend={urlPrepend} newCohort={false} />
                            )
                        }) 
                    : null }
                    { newCohorts ?
                        newCohorts.map((cohort, index) => {
                            return (
                                <AddCohortCard key={index} cohort={cohort} index={index} number={cohorts ? cohorts.length + 1 + index : null} agents={agents} cohortAgent={cohortAgent} removeCohort={removeCohort} updateCohortData={updateCohortData} urlPrepend={urlPrepend} newCohort={true} />
                            )
                        }) 
                    : null }
                </div>

                <button className="btn blue" type="button" onClick={onClickAddAnotherCohortBtn}><PlusIcon /><div>Add Another Cohort</div></button>
            </AdminContainer>

            <AdminActionBar>
                <div>
                    { previousLink ? 
                        <div><Link className="btn white-border" to={previousLink}>Previous</Link></div>
                    : null }
                </div>
                <div>
                    <div><Link className="btn" to={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}>Return to Programme</Link></div>
                </div>
            </AdminActionBar>

            <AdminLeavingPageModal formChange={(newCohorts?.length ? newCohorts.filter(cohort => cohort.formChange === true).length : false) || (cohorts?.length ? cohorts.filter(cohort => cohort.formChange === true).length : false)}>
                <h2>Leaving this page?</h2>
                <p>The cohort has not been saved.</p>
            </AdminLeavingPageModal>
        </div>
    );
}
