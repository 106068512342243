import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../../Store';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ReportHeader from './ReportHeader/ReportHeader';
import ReportNav from './ReportNav/ReportNav';
import ReportOverview from './ReportOverview/ReportOverview';
import ReportResponses from './ReportResponses/ReportResponses';
import ReportResponsesQuestions from './ReportResponsesQuestions/ReportResponsesQuestions';

import './Report.scss';

export default function Report() {
    const { state } = useContext(Store);
    const { apiUrl, fullScreenHeight } = state;

    const { bearerToken } = useParams();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const [averageScorePercentage, setAverageScorePercentage] = useState(false);
    const [clientName, setClientName] = useState(false);
    const [cohortName, setCohortName] = useState(false);
    const [clientId, setClientId] = useState(false);
    const [leaderName, setLeaderName] = useState(false);
    const [respondentsCompleted, setRespondentsCompleted] = useState(false);
    const [respondentsInvited, setRespondentsInvited] = useState(false);
    const [sections, setSections] = useState(false);
    const [activePage, setActivePage] = useState();
    const [productType, setProductType] = useState();
    const [reportType, setReportType] = useState();
    const [teamName, setTeamName] = useState();
    const [programmeName, setProgrammeName] = useState();
    const [downloadFileName, setDownloadFileName] = useState();
    const [completedOn, setCompletedOn] = useState();
    const [reportId, setReportId] = useState();

    const [reports, setReports] = useState();

    const getReportOverview = useCallback(() => {
        setLoading(true);
        setErrorMessage(false);
  
        axios.get(`${apiUrl}Reports/Leader/Overview`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        }).then(response => {
            setAverageScorePercentage(response?.data?.averageScorePercentage ? Math.round(response.data.averageScorePercentage) : false);
            setClientName(response?.data?.clientName ?? false);
            setCohortName(response?.data?.cohortName ?? false);
            setClientId(response?.data?.clientId ?? false);
            setLeaderName(response?.data?.leaderName ?? false);
            setRespondentsCompleted(response?.data?.respondentsCompleted ?? false);
            setRespondentsInvited(response?.data?.respondentsInvited ?? false);
            setSections(response?.data?.sections ?? false);
            setProductType(response?.data?.productType ?? false);
            setReportType(response?.data?.reportType ?? 0);
            setTeamName(response?.data?.teamName ?? false);
            setProgrammeName(response?.data?.programmeName ?? false);
            setDownloadFileName(response?.data?.downloadFileName ?? false);
            setCompletedOn(response?.data?.completedOn ?? false);
            setReportId(response?.data?.reportId ?? false);
  
            setLoading(false);
        }).catch(err => {
            setErrorMessage(err.response?.data?.errors ?? err.message);
            setLoading(false);
        });
    }, [apiUrl, bearerToken]);

    const fetchReports = useCallback(() => {
        if (reportType === 1) {
            axios.get(`${apiUrl}clients/${clientId}/Leader/CohortReports/${productType}`, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            }).then(response => {
                if (response.data.success) {
                    setReports(response.data.cohortReports);
                } else {
                    setReports(false);
                }
            });
        }

        if (reportType === 2) {
            axios.get(`${apiUrl}clients/${clientId}/Leader/QuarterlyReports/${productType}`, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            }).then(response => {
                if (response.data.success) {
                    setReports(response.data.reports);
                } else {
                    setReports(false);
                }
            });
        }
    }, [clientId, productType, reportType, apiUrl, bearerToken]);

    useEffect(() => {
        getReportOverview();
    }, [getReportOverview]);

    useEffect(() => {
        if ( clientId && productType ) {
            fetchReports(clientId, productType);
        }
    }, [clientId, productType, fetchReports]);

    return (
        <div className="report page-wrap" 
            style={{ minHeight: fullScreenHeight }}>

            <ReportHeader clientName={clientName} 
                teamName={teamName} 
                productType={productType} />

            {loading ? 
                <div className="loading-circle"></div> 
            : null}

            { errorMessage ? 
                <div className="container-lg container-padding-lg">
                    <ErrorMessage message={errorMessage} />
                </div> 
            : null }

            { !loading && !errorMessage ? 
                <div className="report-inner">
                    <ReportNav productType={productType} 
                        reportType={reportType} 
                        leaderName={leaderName} 
                        clientName={clientName} 
                        teamName={teamName} 
                        programmeName={programmeName} 
                        sections={sections} 
                        activePage={activePage} 
                        completedOn={completedOn} />

                    <Switch>
                        <Route exact path="/report/:bearerToken">
                            <ReportOverview productType={productType} 
                                reportType={reportType} 
                                leaderName={leaderName} 
                                clientName={clientName} 
                                teamName={teamName} 
                                cohortName={cohortName}
                                averageScorePercentage={averageScorePercentage} 
                                respondentsCompleted={respondentsCompleted} 
                                respondentsInvited={respondentsInvited} 
                                sections={sections} 
                                downloadFileName={downloadFileName} 
                                completedOn={completedOn} 
                                reports={reports} 
                                reportId={reportId} 
                                setActivePage={setActivePage} />
                        </Route>

                        <Route exact path="/report/:bearerToken/responses/:sectionId">
                            <ReportResponses productType={productType} 
                                downloadFileName={downloadFileName} 
                                setActivePage={setActivePage} />
                        </Route>

                        <Route exact path="/report/:bearerToken/responses/:sectionId/questions">
                            <ReportResponsesQuestions productType={productType} 
                                reportType={reportType} 
                                downloadFileName={downloadFileName} 
                                setActivePage={setActivePage} />
                        </Route>
                    </Switch>
                </div>
            : null }
        </div>
    );
}
