import React from 'react';

import './AdminActionBar.scss';

export default function AdminActionBar({children}) {
    return (
        <div className="admin-action-bar">
            <div className="admin-action-bar-container container-padding-lg">
                <div className="admin-action-bar-inner">
                    {children}
                </div>
            </div>
        </div>
    );
}
