import React from 'react';

import './AdminModal.scss';

export function AdminModalBtns({children}) {
    return (
        <div className="admin-modal-btns">
            {children}
        </div>
    )
}

export default function AdminModal({className, children, show, closeModal}) {
    return (
        <div className={`admin-modal ${className ? className : ''} ${show ? 'show' : ''}`}>
            <button className="admin-modal-close-bg" type="button" onClick={closeModal}></button>

            <div className="admin-modal-content">
                {children}
            </div>
        </div>
    );
}
