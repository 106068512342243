import React, { useEffect, useState, useCallback } from 'react';
import { getUsers, createUser, updateUser, deleteUser } from '../../../../api';

import AdminTop from '../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody, AdminCardHeader } from '../../AdminCard/AdminCard';
import AdminTable, { AdminTableHead, AdminTableBody } from '../../AdminTable/AdminTable';
import Input, { InputRow } from '../../../Input/Input';
import AdminModal, { AdminModalBtns } from '../../AdminModal/AdminModal';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../../SuccessMessage/SuccessMessage';
import AdminLeavingPageModal from '../../AdminLeavingPageModal/AdminLeavingPageModal';

import PencilIcon from '../../../Icons/PencilIcon';
import BinIcon from '../../../Icons/BinIcon';

import './Agents.scss';

const userTypes = ['User', 'Admin'];

function AddAnAgent({users, setUsers, setFormChange}) {
    const [formLoading, setFormLoading] = useState(false);

    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [emailAddressValue, setEmailAddressValue] = useState('');
    const [userTypeValue, setUserTypeValue] = useState(userTypes[0]);

    const [formErrors, setFormErrors] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailAddressError, setEmailAddressError] = useState(false);
    const [userTypeError, setUserTypeError] = useState(false);

    const onChangeFirstName = e => {
        setFirstNameValue(e.target.value);
        setFormChange(true);
    };

    const onChangeLastName = e => {
        setLastNameValue(e.target.value);
        setFormChange(true);
    };

    const onChangeEmailAddress = e => {
        setEmailAddressValue(e.target.value);
        setFormChange(true);
    };

    const onChangeUserType = e => {
        setUserTypeValue(e.target.value);
        setFormChange(true);
    };

    const onFormSubmit = e => {
        e.preventDefault();

        setFormLoading(true);

        setFormErrors(false);
        setFirstNameError(false);
        setLastNameError(false);
        setEmailAddressError(false);
        setUserTypeError(false);
        setFormSuccess(false);

        createUser({
            'firstName': firstNameValue,
            'lastName': lastNameValue,
            'emailAddress': emailAddressValue,
            'holosAdmin': userTypeValue === 'Admin'
        }).then(response => {
            if (response.success) {
                setUsers(users.concat(response.user));

                setFirstNameValue('');
                setLastNameValue('');
                setEmailAddressValue('');
                setUserTypeValue(userTypes[0]);
                
                setFormSuccess('Your agent has been saved.');

                setFormChange(false);
            }
            
            
            if (response.errors) {
                setFirstNameError(response.errors.FirstName ?? false);
                setLastNameError(response.errors.LastName ?? false);
                setEmailAddressError(response.errors.EmailAddress ?? false);
                setUserTypeError(response.errors.IsAdmin ?? false);
    
                let errors = Object.assign({}, response.errors);

                delete errors.FirstName;
                delete errors.LastName;
                delete errors.EmailAddress;
                delete errors.IsAdmin;

                setFormErrors(errors);
            }

            setFormLoading(false);
        }).catch(() => {
            setFormLoading(false);
        });
    };

    return (
        <form className="add-an-agent-form" onSubmit={e => onFormSubmit(e)}>
            {formLoading ? <div className="loading-circle"></div> : null}

            { formErrors ? <ErrorMessage message={formErrors} /> : null }

            { formSuccess ? <SuccessMessage message={formSuccess} setFormSuccess={setFormSuccess} /> : null }

            <div className={`add-an-agent-form-inner ${formLoading ? 'loading' : ''}`}>
                <InputRow>
                    <Input label="First name*" name="add-user-first-name" type="text" value={firstNameValue} onChange={onChangeFirstName} errors={firstNameError} />
                    <Input label="Last name*" name="add-user-last-name" type="text" value={lastNameValue} onChange={onChangeLastName} errors={lastNameError} />
                    <Input label="Email address*" name="add-user-email-address" type="email" value={emailAddressValue} onChange={onChangeEmailAddress} errors={emailAddressError} />
                    <Input label="User type" name="add-user-type" type="select" value={userTypeValue} options={userTypes} onChange={onChangeUserType} size="small" errors={userTypeError} />
                </InputRow>
                <button className="btn blue" type="submit">Save Agent</button>
            </div>
        </form>
    );
}

function AgentTableRow({index, user, users, setUsers, updateUserField}) {
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [emailAddress, setEmailAddress] = useState(user.emailAddress);
    const [userType, setUserType] = useState(user.isAdmin ? 'Admin' : 'User');
    
    const [firstNameValue, setFirstNameValue] = useState(user.firstName);
    const [lastNameValue, setLastNameValue] = useState(user.lastName);
    const [emailAddressValue, setEmailAddressValue] = useState(user.emailAddress);
    const [userTypeValue, setUserTypeValue] = useState(user.isAdmin ? 'Admin' : 'User');

    const [firstNameValueErrors, setFirstNameValueErrors] = useState(false);
    const [lastNameValueErrors, setLastNameValueErrors] = useState(false);
    const [emailAddressValueErrors, setEmailAddressValueErrors] = useState(false);
    const [userTypeValueErrors, setUserTypeValueErrors] = useState(false);

    const onChangeFirstName = e => {
        setFirstNameValue(e.target.value);
        updateUserField(index, 'firstName', e.target.value);
    };

    const onChangeLastName = e => {
        setLastNameValue(e.target.value);
        updateUserField(index, 'lastName', e.target.value);
    };

    const onChangeEmailAddress = e => {
        setEmailAddressValue(e.target.value);
        updateUserField(index, 'emailAddress', e.target.value);
    };

    const onChangeUserType = e => {
        setUserTypeValue(e.target.value);
        updateUserField(index, 'isAdmin', e.target.value === 'Admin');
    };

    const onClickSave = () => {
        setLoading(true);

        setFirstNameValueErrors(false);
        setLastNameValueErrors(false);
        setEmailAddressValueErrors(false);
        setUserTypeValueErrors(false);

        updateUser(user.id, {
            'firstName': firstNameValue,
            'lastName': lastNameValue,
            'emailAddress': emailAddressValue,
            'holosAdmin': userTypeValue === 'Admin'
        }).then(response => {
            if (response.success) {
                setFirstName(firstNameValue);
                setLastName(lastNameValue);
                setEmailAddress(emailAddressValue);
                setUserType(userTypeValue);

                setShowEdit(false);
                updateUserField(index, 'formChange', false);
            }

            if (response.errors) {
                setFirstNameValueErrors(response.errors.FirstName ?? false);
                setLastNameValueErrors(response.errors.LastName ?? false);
                setEmailAddressValueErrors(response.errors.EmailAddress ?? false);
                setUserTypeValueErrors(false);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onClickEdit = () => {
        if (showEdit) updateUserField(index, 'formChange', false);
        setShowEdit(!showEdit);
    };

    const onClickDelete = () => {
        setShowDeleteModal(true);
    };

    const onClickDeleteConfirm = () => {
        deleteUser(user.id).then(response => {
            if (response.success) {
                setDeleted(true);
                setShowDeleteModal(false);
                updateUserField(index, 'formChange', false);

                setTimeout(() => {
                    setUsers(users.filter(filterUser => filterUser.id !== user.id));
                }, 250);
            }
        });
    };

    return (
        <tr className={`agent-table-row ${loading ? 'loading' : ''} ${deleted ? 'deleted' : ''}`}>
            <td className="first-name">{ showEdit ? <Input name={`${user.id}-first-name`} type="text" value={firstNameValue} size="full" onChange={onChangeFirstName} errors={firstNameValueErrors} /> : firstName }</td>
            <td className="last-name">{ showEdit ? <Input name={`${user.id}-last-name`} type="text" value={lastNameValue} size="full" onChange={onChangeLastName} errors={lastNameValueErrors} /> : lastName }</td>
            <td className="email-address">{ showEdit ? <Input name={`${user.id}-name`} type="text" value={emailAddressValue} size="full" onChange={onChangeEmailAddress} errors={emailAddressValueErrors} /> : emailAddress }</td>
            <td className="user-type no-border">{ showEdit ? <Input name="add-user-type" type="select" value={userTypeValue} size="full" options={userTypes} onChange={onChangeUserType} errors={userTypeValueErrors} /> : userType }</td>
            <td className="actions">
                <AdminModal show={showDeleteModal} closeModal={() => setShowDeleteModal(false)}>
                    <h2>Are you sure you want to delete {user.firstName} {user.lastName}?</h2> 

                    <AdminModalBtns>
                        <button className="btn" type="button" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                        <button className="btn blue" type="button" onClick={onClickDeleteConfirm}>Delete user</button>
                    </AdminModalBtns>
                </AdminModal>
                <div className={`save-btn-wrap ${showEdit ? 'show' : ''}`}><button className="btn small" onClick={onClickSave}>Save Changes</button></div>
                <button className="delete" onClick={onClickDelete}><BinIcon className="icon" /></button>
                <button className={`edit ${showEdit ? 'active' : ''}`} onClick={onClickEdit}><PencilIcon className="icon" /></button>
            </td>
        </tr>
    );
}

export default function Agents({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const [users, setUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const [formChange, setFormChange] = useState(false);

    const fetchUsers = useCallback(() => {
        setLoadingUsers(true);

        getUsers().then(response => {
            if (response.success) {
                setUsers(response.users.slice(0).reverse());
            }

            setLoadingUsers(false);
        }).catch(() => {
            setLoadingUsers(false);
        });
    }, []);

    const updateUserField = (index, field, value, enableFormChange = true) => {
        let usersData = [...users];
        usersData[index][field] = value;
        if ( field !== 'formChange' ) usersData[index]['formChange'] = true;
        setUsers(usersData);
        return true;
    };

    useEffect(() => {
        document.title = `Agents - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: 'Agents'
            }
        ]);
    }, [urlPrepend, setHeaderBreadcrumbs]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <div className="admin-container container-padding-lg">
            <AdminTop header="Agents" />
            <AdminCard>
                <AdminCardHeader header="Add an agent" />
                <AdminCardBody>
                    <AddAnAgent users={users} setUsers={setUsers} setFormChange={setFormChange} />
                </AdminCardBody>
            </AdminCard>
            <AdminCard>
                <AdminCardHeader header="All agents" />
                {loadingUsers ? <div className="loading-circle relative"></div> : null}

                {!loadingUsers && users.length ?
                    <AdminCardBody>
                        <div className="all-agents-count">Showing <strong>{users.length}</strong> agents</div>
                        <AdminTable>
                            <AdminTableHead>
                                <tr>
                                    <th>Agent first name</th>
                                    <th>Agent last name</th>
                                    <th>Agent email address</th>
                                    <th colSpan="2">User type</th>
                                </tr>
                            </AdminTableHead>
                            <AdminTableBody>
                                { users.map((user, index) => {
                                    return (
                                        <AgentTableRow key={user.id} index={index} user={user} users={users} setUsers={setUsers} updateUserField={updateUserField} />
                                    );
                                }) }
                            </AdminTableBody>
                        </AdminTable>
                    </AdminCardBody>
                : null }
            </AdminCard>

            <AdminLeavingPageModal formChange={formChange || users?.length ? users.filter(user => user.formChange === true).length : false}>
                <h2>Leaving this page?</h2>
                <p>The agent has not been saved.</p>
            </AdminLeavingPageModal>
        </div>
    );
}
