import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Store } from '../../../../../Store';
import { parseISO, format, isBefore, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
    getClient,
    getProgramme,
    getCohort,
    getSurvey,
    updateLeaderEmailAddress,
    updateRespondent,
    updateRespondentEmailAddress,
    revokeRespondent,
    unrevokeRespondent,
    generateReport,
    sendRespondentReminder,
    sendReport,
    getRespondentTypes
} from '../../../../../api';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import AdminTable, { AdminTableBody, AdminTableHead, AdminTableActionsCell } from '../../../AdminTable/AdminTable';
import AdminStatusLabel from '../../../AdminStatusLabel/AdminStatusLabel';
import Input from '../../../../Input/Input';
import ToolTip from '../../../../ToolTip/ToolTip';
import AdminModal, { AdminModalBtns } from '../../../AdminModal/AdminModal';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';

import CheckIcon from '../../../../Icons/CheckIcon';
import PlusIcon from '../../../../Icons/PlusIcon';
import PlusCircleIcon from '../../../../Icons/PlusCircleIcon';
import CancelIcon from '../../../../Icons/CancelIcon';
import MailIcon from '../../../../Icons/MailIcon';
import BellIcon from '../../../../Icons/BellIcon';
import PencilIcon from '../../../../Icons/PencilIcon';

import './Survey.scss';

function SurveyTableRow({respondent}) {
    const {surveyId} = useParams();

    const [loading, setLoading] = useState(false);
    const [revoked, setRevoked] = useState(respondent?.revoked);
    const [showEdit, setShowEdit] = useState(respondent?.emailBounced);
    const [showRespondentEdit, setShowRespondentEdit] = useState(false);

    const [emailAddressValue, setEmailAddressValue] = useState(respondent?.emailAddress ?? '');
    const [emailAddressErrors, setEmailAddressErrors] = useState(false);

    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [respondentTypeValue, setRespondentTypeValue] = useState(0);

    const [reSendSurveyLoading, setReSendSurveyLoading] = useState(false);
    const [showSendRespondentReminderModal, setShowSendRespondentReminderModal] = useState(false);

    const [respondentTypes, setRespondentTypes] = useState(false);

    const fetchRespondentTypes = useCallback(() => {
        getRespondentTypes().then(response => {
            setRespondentTypes(response);
        });
    }, []);
    
    const onChangeEmailAddressInput = e => {
        setEmailAddressValue(e.target.value);
    };

    const updateSurveyRespondentEmailAddress = () => {
        setLoading(true);
        setEmailAddressErrors(false);

        const thenResponse = response => {
            if (response.success) {
                setShowEdit(false);
            } else {
                setEmailAddressErrors(Object.values(response.errors));
            }

            setLoading(false);
            console.log(response);
        };
        
        if (respondent?.role === 'Leader') {
            updateLeaderEmailAddress(surveyId, {
                emailAddress: emailAddressValue
            }).then(thenResponse).catch(() => {
                setLoading(false);
            });
        } else {
            updateRespondentEmailAddress(surveyId, respondent.id, {
                emailAddress: emailAddressValue
            }).then(thenResponse).catch(() => {
                setLoading(false);
            });
        }
    };

    const onSubmitRespondentEmailAddressForm = e => {
        e.preventDefault();

        updateSurveyRespondentEmailAddress();
    };

    const onClickResendInviteBtn = () => {
        updateSurveyRespondentEmailAddress();
    };

    const onClickReSendSurveyBtn = () => {
        setShowSendRespondentReminderModal(true);
    };

    const onClickReSendSurveyConfirmBtn = () => {
        setReSendSurveyLoading(true);

        sendRespondentReminder(surveyId, respondent.id).then(response => {
            if (response.success) setShowSendRespondentReminderModal(false);
        }).finally(() => {
            setReSendSurveyLoading(false);
        });
    };

    const onClickRevokeBtn = () => {
        setLoading(true);

        revokeRespondent(surveyId, respondent.id).then(response => {
            console.log(response);

            if (response.success) {
                setRevoked(true);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onClickReinstateBtn = () => {
        setLoading(true);

        unrevokeRespondent(surveyId, respondent.id).then(response => {
            console.log(response);

            if (response.success) {
                setRevoked(false);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const onClickEditRespondentBtn = () => {
        setShowRespondentEdit(!showRespondentEdit);
    }

    const onClickUpdateRespondentsBtn = () => {
        updateRespondent(surveyId, respondent.id, {
            firstName: firstNameValue,
            lastName: lastNameValue,
            respondentType: parseInt(respondentTypeValue)
        }).then(response => {
            console.log(response);
        });
    }

    useEffect(() => {
        fetchRespondentTypes();
    }, [fetchRespondentTypes]);

    return (
        <tr className={`admin-table-row ${loading ? 'loading' : ''} ${revoked ? 'revoked' : ''}`}>
            <td className="name-cell">
                {showRespondentEdit ?
                    <form>
                        <Input type="text" 
                            size="small"
                            name="first-name" 
                            value={firstNameValue} 
                            placeholder="First Name" 
                            onChange={e => setFirstNameValue(e.target.value)} />

                        <Input type="text" 
                            size="small"
                            name="last-name" 
                            value={lastNameValue} 
                            placeholder="Last Name" 
                            onChange={e => setLastNameValue(e.target.value)} />
                    </form>
                : null}

                {!showRespondentEdit ?
                    <>
                        { respondent?.name ?? '' }
                        { respondent?.function ? <div className="team-member-function">{respondent.function}</div> : '' }
                    </>
                : null}
            </td>
            <td className="email-address-cell">
                <div className="cell-icon-wrap">
                    { !showEdit ? <div>{emailAddressValue}</div> : null }
                    { showEdit ? <form className="full-width" onSubmit={onSubmitRespondentEmailAddressForm}><Input type="email" value={emailAddressValue} size="full" onChange={onChangeEmailAddressInput} errors={emailAddressErrors} /></form> : null }
                    { respondent?.emailBounced ? <div className="warning">!</div> : null }
                </div>
            </td>
            <td className="role-cell">
                {showRespondentEdit ?
                    <form>
                        <Input
                            type="select"
                            name="respondent-role" 
                            value={respondentTypeValue} 
                            options={respondentTypes ? respondentTypes.map(respondentType => {
                                return {
                                    value: respondentType.value,
                                    name: respondentType.name
                                }
                            } ) : null} 
                            loading={!respondentTypes}
                            size="small"
                            onChange={e => setRespondentTypeValue(e.target.value)} />
                    </form>
                : null}

                {!showRespondentEdit ?
                    respondent?.role === 'Leader' ? <AdminStatusLabel text="Leader" colour="blue" /> : respondent?.role
                : null}
            </td>
            <td className="survey-sent-cell">
                <div className="cell-icon-wrap">
                    <div>
                        { respondent?.surveySent ? 
                            <>
                                <div>{format(utcToZonedTime(parseISO(respondent.surveySent), 'UTC'), 'dd MMM yy')}</div>
                                <div>{format(utcToZonedTime(parseISO(respondent.surveySent), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                            </> 
                        : null }
                    </div>
                    { respondent?.remindersSent?.length ?
                        <ToolTip className="alert date" toolTipText={respondent.remindersSent.map((date, index) => isValid(parseISO(date)) ? `${format(utcToZonedTime(parseISO(date), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong>: Reminder ${index + 1} received` : false )}>
                            <BellIcon />
                            <div className="overlay-no">{respondent.remindersSent.length}</div>
                        </ToolTip>
                    : null }
                </div>
            </td>
            <td className="status-cell">
                <div className="status-percentage">
                    <div className="status-bar">
                        <div style={{width: `${respondent.progress ? Math.round(respondent.progress) : 0}%`}}></div>
                    </div>
                    <div className="percentage">{respondent.progress ? Math.round(respondent.progress) : 0}%</div>
                </div>
            </td>
            <td className="survey-completed-cell">
                { !respondent?.surveyCompleted ? <div className="cell-dash"></div> : null }
                { respondent?.surveyCompleted ? 
                    <>
                        <div>{format(utcToZonedTime(parseISO(respondent.surveyCompleted), 'UTC'), 'dd MMM yy')}</div>
                        <div>{format(utcToZonedTime(parseISO(respondent.surveyCompleted), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                    </> 
                : null }
            </td>
            <td className="included-in-report-cell">
                { !respondent?.includedInReport ? <div className="cell-dash"></div> : null }
                { respondent?.includedInReport ? <div className="icon"><CheckIcon /></div> : null }
            </td>
            <AdminTableActionsCell>
                { respondent?.emailBounced ?
                    <div>
                        <button className={`btn small ${!showEdit ? 'hide' : ''}`} type="button" onClick={onClickResendInviteBtn}>
                            Re-Send {respondent?.role === 'Leader' ? 'Invite' : 'Survey'}
                        </button>
                    </div>
                : null }

                {showRespondentEdit ?
                    <div>
                        <button className="btn small"
                            type="button"
                            onClick={onClickUpdateRespondentsBtn}>
                            
                            Update
                        </button>
                    </div>
                : null}

                <div className="admin-table-actions-btn-wrap" style={{ display: 'none' }}>
                    <button type="button" onClick={onClickEditRespondentBtn}>
                        <ToolTip toolTipText="Edit Respondent">
                            <PencilIcon className="icon" />
                        </ToolTip>
                    </button>
                </div>

                <div className="admin-table-actions-btn-wrap">
                    <button type="button" onClick={onClickReSendSurveyBtn}>
                        <ToolTip toolTipText="Re-Send Survey Link">
                            <MailIcon className="icon" />
                        </ToolTip>
                    </button>

                    <AdminModal className={`resend-survey-modal ${reSendSurveyLoading ? 'loading' : ''}`} show={showSendRespondentReminderModal} closeModal={() => setShowSendRespondentReminderModal(false)}>
                        {reSendSurveyLoading ? <div className="loading-circle"></div> : null}

                        <div className="resend-survey-modal-inner">
                            {respondent?.role === 'Leader' ? <>
                                <h2>Re-send the nomination invite link</h2>

                                <p>Are you sure you want to re-send the <br />nomination invite link to {respondent?.name}</p>
                            </> : null }

                            {respondent?.role !== 'Leader' ? <>
                                <h2>Re-send the survey link</h2>

                                <p>Are you sure you want to re-send the <br />survey link to {respondent?.name}</p>
                            </> : null }

                            <AdminModalBtns>
                                <button className="btn" type="button" onClick={() => setShowSendRespondentReminderModal(false)}>Close</button>
                                <button className="btn blue" type="button" onClick={onClickReSendSurveyConfirmBtn}>
                                    Re-Send {respondent?.role === 'Leader' ? 'Invite' : 'Survey'}
                                </button>
                            </AdminModalBtns>
                        </div>
                    </AdminModal>
                </div>
                <div>
                    {!revoked ? 
                        <button type="button" disabled={respondent.role === 'Leader'} onClick={onClickRevokeBtn}>
                            <ToolTip toolTipText="Ignore">
                                <CancelIcon className="icon" />
                            </ToolTip>
                        </button>
                    : null }
                    {revoked ? 
                        <button type="button" onClick={onClickReinstateBtn}>
                            <ToolTip toolTipText="Include">
                                <PlusCircleIcon className="icon light-blue" />
                            </ToolTip>
                        </button>
                    : null }
                </div>
            </AdminTableActionsCell>
        </tr>
    );
}

export default function Survey({setHeaderBreadcrumbs, urlPrepend, productName}) {
    const { state } = useContext(Store);
    const { statuses } = state;

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [loadingSurvey, setLoadingSurvey] = useState(true);
    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [cohort, setCohort] = useState(false);
    const [survey, setSurvey] = useState(false);

    const [reGenerateReportLoading, setReGenerateReportLoading] = useState(false);
    const [generateReportLoading, setGenerateReportLoading] = useState(false);
    const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
    const [showGenerateReportSuccessModal, setShowGenerateReportSuccessModal] = useState(false);

    const [sendReportLoading, setSendReportLoading] = useState(false);
    const [showSendReportModal, setShowSendReportModal] = useState(false);
    const [sendReportSuccess, setSendReportSuccess] = useState(false);
    const [sendReportError, setSendReportError] = useState(false);

    const {clientId, programmeId, cohortId, surveyId} = useParams();

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchCohort = useCallback((clientId, programmeId, cohortId) => {
        getCohort(clientId, programmeId, cohortId).then(response => {
            if (response.success) setCohort(response);
        });
    }, []);

    const fetchSurvey = useCallback((surveyId) => {
        getSurvey(surveyId).then(response => {
            if (response.success) {
                setSurvey(response);
                setPageError(false);
            } else {
                setPageError(response?.errors);
            }

            setLoadingSurvey(false);
            setPageLoading(false);
        });
    }, []);

    useEffect(() => {
        document.title = `Survey - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);
    
    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);
    
    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);
    
    useEffect(() => {
        fetchCohort(clientId, programmeId, cohortId);
    }, [fetchCohort, clientId, programmeId, cohortId]);
    
    useEffect(() => {
        fetchSurvey(surveyId);
    }, [fetchSurvey, surveyId]);
    
    useEffect(() => {
        if (!programme || !cohort || !survey) return;

        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: programme.name,
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: cohort.name,
                link: `${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`
            }, {
                text: survey.leaderName
            }
        ]);
    }, [programme, cohort, survey, urlPrepend, clientId, programmeId, cohortId, setHeaderBreadcrumbs]);

    const onClickGenerateReportBtn = () => {
        if (survey.totalCompleted === survey.totalRespondents) {
            setReGenerateReportLoading(true);
            
            generateReport(surveyId).then(response => {
                if (response.success) setShowGenerateReportSuccessModal(true);
            }).finally(() => {
                setReGenerateReportLoading(false);
            });
        } else {
            setShowGenerateReportModal(true);
        }
    };

    const onClickGenerateReportConfirmBtn = () => {
        setGenerateReportLoading(true);

        generateReport(surveyId).then(response => {
            if (response.success) {
                setShowGenerateReportModal(false);
                setShowGenerateReportSuccessModal(true);
            }
        }).finally(() => {
            setGenerateReportLoading(false);
        });
    };

    const onClickSendReportToLeaderBtn = () => {
        setSendReportLoading(false);
        setSendReportSuccess(false);
        setSendReportError(false);
        setShowSendReportModal(true);
    };

    const onClickSendReportConfirmBtn = () => {
        setSendReportLoading(true);

        sendReport(surveyId).then(response => {
            if (response.success) {
                setSendReportSuccess(true);
            }
        }).catch(err => {
            if (err?.response?.data?.errors) {
                setSendReportError(err.response.data.errors);
            }
        }).finally(() => {
            setSendReportLoading(false);
        });
    };

    return (
        <AdminContainer pageError={pageError} pageLoading={pageLoading}>
            <AdminTop labelText={survey.status && statuses?.length ? statuses.filter(status => status.id === survey.status)[0]?.text : false} 
                labelColour={survey.status && statuses?.length ? statuses.filter(status => status.id === survey.status)[0]?.colour : false} 
                header={survey?.leaderName ?? ''}
                backLink={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`}
                backLinkText="Back to Cohort">

                { survey?.reportLink ?
                    <div>
                        <div className="survey-view-report">
                            { survey?.reportGeneratedOn && isValid(parseISO(survey.reportGeneratedOn)) ?
                                <div>
                                    <div className="survey-view-report-generated-on">Generated {format(utcToZonedTime(parseISO(survey.reportGeneratedOn), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                            <div>
                                <a className="btn blue" href={survey.reportLink} target="_blank" rel="noopener noreferrer">View Completed Report</a>
                            </div>
                        </div>
                    </div>
                : null }

                { survey?.reportLink ?
                    <div>
                        <button className="btn" type="button" onClick={onClickSendReportToLeaderBtn}>Send Report to Leader</button>
                    </div>
                : null }

                { cohort?.surveyDeadline && isBefore(new Date(), parseISO(cohort.surveyDeadline)) ?
                    <div>
                        <Link className="btn blue" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/survey/${surveyId}/add-respondent`}><PlusIcon /><div>Add Respondent</div></Link>
                    </div>
                : null }
            </AdminTop>
            <AdminCard>
                <AdminCardBody>
                    <div className="info-panel">
                        <div>
                            { client?.name && programme?.name ? 
                                <div>
                                    <h3>{client.name}</h3>
                                    <div><Link to={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}>{programme.name}</Link></div>
                                </div>
                            : null }
                            { cohort?.name ?
                                <div>
                                    <h3>Cohort:</h3>
                                    <div><Link to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}`}>{cohort.name}</Link></div>
                                </div>
                            : null }
                            { cohort?.agentName ?
                                <div>
                                    <h3>Holos Cohort Lead:</h3>
                                    <div>{cohort.agentName}</div>
                                </div>
                            : null }
                            { survey?.totalCompleted !== false && survey?.totalRespondents !== false ?
                                <div>
                                    <h3>Number Completed:</h3>
                                    <div>{survey.totalCompleted ?? 0}/{survey.totalRespondents ?? 0}</div>
                                </div>
                            : null }
                            { cohort?.surveyDeadline ?
                                <div>
                                    <h3>Completion Deadline:</h3>
                                    <div>{format(utcToZonedTime(parseISO(cohort.surveyDeadline), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                            { survey?.reportSentDate ?
                                <div>
                                    <h3>Report sent:</h3>
                                    <div>{format(utcToZonedTime(parseISO(survey.reportSentDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null }
                        </div>
                        <div>
                            { survey?.totalCompleted !== null || survey?.reportLink ?
                                <div>
                                    <div className={`generate-report-btn ${reGenerateReportLoading ? 'loading' : ''}`}>
                                        <button className="btn blue" 
                                            disabled={survey.totalCompleted < 3 && !survey?.reportLink} 
                                            onClick={onClickGenerateReportBtn}>
                                                
                                            {survey?.reportLink ? 'Re-Generate Report' : 'Generate Report' }
                                        </button>
                                    </div>
                                </div>
                            : null }
                        </div>
                    </div>
                </AdminCardBody>
                <AdminCardBody className="survey-details">
                    <div className="table-details">
                        { survey?.respondents ? <div>Showing <strong>{survey?.respondents.length}</strong> {survey?.respondents.length === 1 ? 'respondent' : 'respondents'}</div> : null }
                    </div>
                    <AdminTable className="survey-table">
                        <AdminTableHead>
                            <tr>
                                <th className="name-cell">Name</th>
                                <th className="email-address-cell">Email address</th>
                                <th className="role-cell">Role</th>
                                <th className="survey-sent-cell">Survey <br />Sent</th>
                                <th className="status-cell">Status</th>
                                <th className="survey-completed-cell">Survey <br />Complete</th>
                                <th className="included-in-report-cell">Included <br />in report</th>
                                <th className="actions-cell"></th>
                            </tr>
                        </AdminTableHead>
                            {survey?.respondents?.length ?
                                <AdminTableBody>
                                    { survey.respondents.map(respondent => {
                                        return (
                                            <SurveyTableRow key={respondent.id} respondent={respondent} />
                                        )
                                    }) }
                                </AdminTableBody>
                            : null }
                    </AdminTable>
                    { !loadingSurvey && survey?.respondents?.length === 0 ?
                        <div className="no-respondents">
                            You have not added any Respondents<br />
                            for this leader
                        </div>
                    : null }
                </AdminCardBody>
            </AdminCard>
            <AdminModal className={`generate-report-modal ${generateReportLoading ? 'loading' : ''}`} show={showGenerateReportModal} closeModal={() => {
                setShowGenerateReportModal(false);
                setTimeout(() => { setGenerateReportLoading(false) }, 500);
            }}>
                {generateReportLoading ? <div className="loading-circle"></div> : null}
                
                <div className="generate-report-modal-inner">
                    <h2>Are you sure you want to generate report?</h2>

                    <p>You still only have {survey?.totalCompleted} respondents but this does meet the minimum number needed to generate a report.</p>

                    <AdminModalBtns>
                        <button className="btn" type="button" onClick={() => setShowGenerateReportModal(false)}>Close</button>
                        <button className="btn blue" type="button" onClick={onClickGenerateReportConfirmBtn}>Generate Report</button>
                    </AdminModalBtns>
                </div>
            </AdminModal>
            <AdminModal show={showGenerateReportSuccessModal} closeModal={() => setShowGenerateReportSuccessModal(false)}>
                <h2>Generating Report</h2>

                <p>Your report will be emailed to you once successfully generated.</p>

                <AdminModalBtns>
                    <button className="btn blue" type="button" onClick={() => setShowGenerateReportSuccessModal(false)}>Close</button>
                </AdminModalBtns>
            </AdminModal>

            <AdminModal className={`send-report-modal ${sendReportLoading ? 'loading' : ''}`} show={showSendReportModal} closeModal={() => {
                setShowSendReportModal(false);
                setTimeout(() => { setSendReportLoading(false) }, 500);
            }}>
                {sendReportLoading ? <div className="loading-circle"></div> : null}
                
                <div className="send-report-modal-inner">
                    <h2>Send report to leader?</h2>

                    { sendReportSuccess ? <p>The report has been successfully sent to the leader.</p> : null }

                    { sendReportError ? <ErrorMessage message={sendReportError} /> : null }

                    { !sendReportSuccess && !sendReportError ? <>
                        <p>Are you sure you want to send the report PDF to <br />{ survey?.leaderName ? survey.leaderName : 'the leader' }?</p>
                        { cohort?.automaticReportSendDate ? <p>There is already an automatic send date set up for {format(utcToZonedTime(parseISO(cohort.automaticReportSendDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong>.</p> : null }
                    </> : null }

                    <AdminModalBtns>
                        <button className="btn" type="button" onClick={() => setShowSendReportModal(false)}>Close</button>
                        <button className="btn blue" type="button" disabled={sendReportSuccess} onClick={onClickSendReportConfirmBtn}>Send Report</button>
                    </AdminModalBtns>
                </div>
            </AdminModal>
        </AdminContainer>
    );
}
