import React from 'react';

import './ReportSidebar.scss';

export default function ReportSidebar({children}) {
    return (
        <div className="report-sidebar">
            {children}
        </div>
    );
}
