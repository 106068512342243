import React from 'react';

import logoWhite from '../../../images/logo-white.svg';

import './ReportOverviewHeader.scss';

export default function ReportOverviewHeader({header, subheader}) {
    return (
        <header className="report-overview-header">
            <img className="report-overview-header-logo" src={logoWhite} alt="Holos" />
            <div className="report-overview-header-text">
                { header ? <h1>{header}</h1> : null }
                { subheader ? <h2>{subheader}</h2> : null }
            </div>
        </header>
    );
}
