import React from 'react';
import { getReportScoreColour } from '../../../helpers';

import './ReportScoreCard.scss';

export default function ReportScoreCard({text, score}) {
    if (!score) return false;

    return (
        <div className={`report-card total-average ${ getReportScoreColour(score) }`}>

            {text}
            <div className="percentage">{score}%</div>
        </div>
    )
}
