import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Store } from '../../Store';
import axios from 'axios';

import ErrorMessage from '../ErrorMessage/ErrorMessage';

import '../Login/Login.scss';

import logoWhite from '../../images/logo-white.svg';
import handIllustrationLightGrey from '../../images/hand-illustration-light-grey.svg';

export default function ForgotPassword() {
    const { state } = useContext(Store);
    const { apiUrl, fullScreenHeight } = state;

    const location = useLocation();

    const [urlParams, setUrlParams] = useState();
    const [emailAddressValue, setEmailAddressValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [repeatPasswordValue, setRepeatPasswordValue] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState(false);
    const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
    const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState(false);

    useEffect(() => {
        setUrlParams(new URLSearchParams(location.search));
    }, [location]);
  
    const onSubmitForgotPasswordForm = e => {
        e.preventDefault();

        setLoginLoading(true);
        setLoginErrorMessage(false);
        setEmailAddressErrorMessage(false);
        setPasswordErrorMessage(false);

        axios.post(`${apiUrl}Account/ForgotPassword`, {
            emailAddress: emailAddressValue
        }).then(response => {
            console.log(response);

            if (response.data.success) {
                setShowSuccess(true);
            }

            setLoginLoading(false);
        }).catch(err => {
            console.log(err.response);

            if (err.response.status === 409) {
                console.log(err.response.data.errors)
                setEmailAddressErrorMessage(err.response.data?.errors[''] ?? false);
            }
            
            setLoginLoading(false);
        });
    };
  
    const onSubmitResetPasswordEmailForm = e => {
        e.preventDefault();

        setLoginLoading(true);
        setLoginErrorMessage(false);
        setEmailAddressErrorMessage(false);
        setPasswordErrorMessage(false);
        setRepeatPasswordErrorMessage(false);

        axios.post(`${apiUrl}Account/ResetPassword`, {
            emailAddress: urlParams?.get('email'),
            password: passwordValue,
            confirmPassword: repeatPasswordValue,
            token: urlParams?.get('token')
        }).then(response => {
            console.log(response);

            if (response.data.success) {
                setShowSuccess(true);
            }

            setLoginLoading(false);
        }).catch(err => {
            console.log(err.response);

            if (err.response.status) {
                console.log(err.response.data.errors)
                setPasswordErrorMessage(err.response.data?.errors[''] ?? false);
            }
            
            setLoginLoading(false);
        });
    };
  
    return (
        <section className="login" style={{ minHeight: fullScreenHeight }}>
            <div className="login-inner container-lg">
                <div className="login-logo container-padding-lg">
                    <img src={logoWhite} alt="Holos" />
                </div>
                { !urlParams?.get('email') || !urlParams?.get('token') ?
                    <div className="login-form-wrap container-padding-lg">
                        <h1 className="header">Forgot Password</h1>

                        { showSuccess ?
                            <div className="reset-success">
                                <p>A password reset link has been emailed to you.</p>
                                <div>
                                    <Link className="btn blue" to="/login">Back to Login</Link>
                                </div>
                            </div>
                        : null }

                        { !showSuccess ?
                            <form className="login-form" action="#" onSubmit={onSubmitForgotPasswordForm}>
                                {loginLoading ? <div className="loading-circle"></div> : null}

                                <div className={`login-form-inner ${loginLoading ? 'loading' : ''}`}>

                                    { loginErrorMessage ? <ErrorMessage message={loginErrorMessage} /> : null }
                                
                                    <div className={`login-input-wrap ${emailAddressErrorMessage ? 'error' : ''}`}>
                                        <label htmlFor="password">Email address</label>
                                        <input id="password" type="email" value={emailAddressValue} onChange={e => setEmailAddressValue(e.target.value)} />
                                        { emailAddressErrorMessage ? 
                                            <div className="login-error-message">
                                                {emailAddressErrorMessage.map((error,index) => <div key={index}>{error}</div>)}
                                            </div> 
                                        : null }
                                    </div>
                                    <div className="login-submit-btn-wrap">
                                        <button className="btn blue" type="submit">
                                            <div>Reset Password</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.8 11.2"><path d="M26.7 6.9l-.1-.1c-2-1.6-4.2-3-6.4-4.1C17.9 1.4 15.5.6 13 0c-.3-.1-.6.1-.8.3-.5.7-.8 1.5-.8 2.4 0 .3.3.6.6.5.2 0 .5-.2.5-.4 0-.4.2-.8.5-1.2 2.2.7 4.4 1.5 6.5 2.5 1.7.9 3.4 1.9 5 3-1.6.6-3.2 1-4.8 1.2-1.9.4-3.7.9-5.6 1.3.1-.8.1-1.5.1-2.3.8-.1 1.6-.2 2.3-.3.8-.1.5-1.1-.2-1l-2.1.3c0-.9-.1-1.8-.3-2.6-.1-.7-1.2-.4-1.1.3.1.8.2 1.6.2 2.5-4.1.4-8.3.5-12.5.3-.7 0-.7 1 0 1 4.2.3 8.4.2 12.6-.2 0 .9-.1 1.9-.3 2.8-.1.3.1.7.5.8h.3l6.9-1.5c2.1-.3 4.1-.9 6-1.7.3-.3.4-.7.2-1.1z"/></svg>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        : null }
                    </div>
                : null }
                { urlParams?.get('email') && urlParams?.get('token') ?
                    <div className="login-form-wrap container-padding-lg">
                        <h1 className="header">Reset Password</h1>

                        { showSuccess ?
                            <Redirect to="/login?message=password-reset" />
                        : null }

                        { !showSuccess ?
                            <form className="login-form" action="#" onSubmit={onSubmitResetPasswordEmailForm}>
                                {loginLoading ? <div className="loading-circle"></div> : null}

                                <div className={`login-form-inner ${loginLoading ? 'loading' : ''}`}>

                                    { loginErrorMessage ? <ErrorMessage message={loginErrorMessage} /> : null }
                                
                                    <div className={`login-input-wrap ${passwordErrorMessage ? 'error' : ''}`}>
                                        <label htmlFor="password">Password</label>
                                        <input id="password" type="password" value={passwordValue} onChange={e => setPasswordValue(e.target.value)} />
                                        { passwordErrorMessage ? 
                                            <div className="login-error-message">
                                                {passwordErrorMessage.map((error,index) => <div key={index}>{error}</div>)}
                                            </div> 
                                        : null }
                                    </div>
                                    <div className={`login-input-wrap ${repeatPasswordErrorMessage ? 'error' : ''}`}>
                                        <label htmlFor="repeat-password">Repeat Password</label>
                                        <input id="repeat-password" type="password" value={repeatPasswordValue} onChange={e => setRepeatPasswordValue(e.target.value)} />
                                        { repeatPasswordErrorMessage ? 
                                            <div className="login-error-message">
                                                {repeatPasswordErrorMessage.map((error,index) => <div key={index}>{error}</div>)}
                                            </div> 
                                        : null }
                                    </div>
                                    <div className="login-submit-btn-wrap">
                                        <button className="btn blue" type="submit">
                                            <div>Reset Password</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.8 11.2"><path d="M26.7 6.9l-.1-.1c-2-1.6-4.2-3-6.4-4.1C17.9 1.4 15.5.6 13 0c-.3-.1-.6.1-.8.3-.5.7-.8 1.5-.8 2.4 0 .3.3.6.6.5.2 0 .5-.2.5-.4 0-.4.2-.8.5-1.2 2.2.7 4.4 1.5 6.5 2.5 1.7.9 3.4 1.9 5 3-1.6.6-3.2 1-4.8 1.2-1.9.4-3.7.9-5.6 1.3.1-.8.1-1.5.1-2.3.8-.1 1.6-.2 2.3-.3.8-.1.5-1.1-.2-1l-2.1.3c0-.9-.1-1.8-.3-2.6-.1-.7-1.2-.4-1.1.3.1.8.2 1.6.2 2.5-4.1.4-8.3.5-12.5.3-.7 0-.7 1 0 1 4.2.3 8.4.2 12.6-.2 0 .9-.1 1.9-.3 2.8-.1.3.1.7.5.8h.3l6.9-1.5c2.1-.3 4.1-.9 6-1.7.3-.3.4-.7.2-1.1z"/></svg>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        : null }
                    </div>
                : null }
            </div>
            <div className="login-bg" style={{ minHeight: fullScreenHeight }}>
                <div className="login-bg-inner container-padding-lg">
                    <img src={handIllustrationLightGrey} alt="" />
                </div>
            </div>
        </section>
    )
}
