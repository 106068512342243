import React, {
    useContext, 
    useState, 
    useEffect, 
    useCallback
} from 'react';
import {
    useParams, 
    Link
} from 'react-router-dom';
import {
    isValid, 
    parseISO, 
    format
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
    getClient, 
    getCohort, 
    getProgramme, 
    getSurveys, 
    revokeSurvey, 
    unrevokeSurvey, 
    updateLeaderEmailAddress, 
    deleteSurvey, 
    sendLeaderNominate
} from '../../../../../api';
import { getReportScoreColour } from '../../../../../helpers';
import { Store } from '../../../../../Store';

import AdminContainer from '../../../AdminContainer/AdminContainer';
import AdminTop from '../../../AdminTop/AdminTop';
import AdminCard, { AdminCardBody } from '../../../AdminCard/AdminCard';
import AdminTable, {
    AdminTableBody, 
    AdminTableHead, 
    AdminTableActionsCell
} from '../../../AdminTable/AdminTable';
import Input from '../../../../Input/Input';
import UploadLeadersFormModal from '../../../UploadLeadersFormModal/UploadLeadersFormModal';
import ToolTip from '../../../../ToolTip/ToolTip';
import AdminModal, { AdminModalBtns } from '../../../AdminModal/AdminModal';

import PlusIcon from '../../../../Icons/PlusIcon';
import UploadIcon from '../../../../Icons/UploadIcon';
import BinIcon from '../../../../Icons/BinIcon';
import CancelIcon from '../../../../Icons/CancelIcon';
import MailIcon from '../../../../Icons/MailIcon';
import BellIcon from '../../../../Icons/BellIcon';
import CheckIcon from '../../../../Icons/CheckIcon';
import PlusCircleIcon from '../../../../Icons/PlusCircleIcon';

import './Cohort.scss';

function CohortTableRow({
    survey,
    index,
    updateSurvey,
    surveys,
    setSurveys,
    surveysInfo,
    setSurveysInfo,
    urlPrepend
}) {
    const {
        clientId,
        programmeId,
        cohortId
    } = useParams();

    const [loading, setLoading] = useState(false);
    const [revoked, setRevoked] = useState(survey?.revoked);
    const [deleted, setDeleted] = useState(false);
    const [showEdit, setShowEdit] = useState(survey?.emailBounced);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [emailAddressValue, setEmailAddressValue] = useState(survey?.leaderEmailAddress ?? '');
    const [emailAddressErrors, setEmailAddressErrors] = useState(false);

    const [reSendNominateLoading, setReSendNominateLoading] = useState(false);
    const [showSendLeaderNominateModal, setShowSendLeaderNominateModal] = useState(false);

    const updateLeader = () => {
        setLoading(true);
        setEmailAddressErrors(false);
        
        updateLeaderEmailAddress(survey.id, {
            leaderId: survey?.leaderId,
            emailAddress: emailAddressValue
        }).then(response => {
            if (response.success) {
                setShowEdit(false);
            } else {
                setEmailAddressErrors(Object.values(response.errors));
            }

            setLoading(false);
            console.log(response);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onChangeEmailAddressInput = e => {
        setEmailAddressValue(e.target.value);
        updateSurvey(index, 'emailAddress', e.target.value);
    };

    const onSubmitRespondentEmailAddressForm = e => {
        e.preventDefault();

        updateLeader();
    };

    const onClickResendInviteBtn = () => {
        updateLeader();
    };

    const onClickDelete = () => {
        setShowDeleteModal(true);
    };

    const onClickDeleteConfirm = () => {
        deleteSurvey(survey.id).then(response => {
            if (response.success) {
                setDeleted(true);
                setShowDeleteModal(false);

                setTimeout(() => {
                    let surveysInfoData = surveysInfo;
                    surveysInfoData.totalLeaders = surveysInfo.totalLeaders - 1;

                    setSurveysInfo(surveysInfoData);
                    setSurveys(surveys.filter(filterSurveys => filterSurveys.id !== survey.id));
                }, 250);
            }
        });
    };

    const onClickRevokeBtn = () => {
        setLoading(true);

        revokeSurvey(survey.id).then(response => {
            if (response.success) {
                setRevoked(true);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onClickReinstateBtn = () => {
        setLoading(true);

        unrevokeSurvey(survey.id).then(response => {
            if (response.success) {
                setRevoked(false);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onClickReSendNominateBtn = () => {
        setShowSendLeaderNominateModal(true);
    };

    const onClickReSendNominateConfirmBtn = () => {
        setReSendNominateLoading(true);

        sendLeaderNominate(survey.id).then(response => {
            if (response.success) setShowSendLeaderNominateModal(false);
        }).finally(() => {
            setReSendNominateLoading(false);
        });
    };

    return (
        <tr className={`admin-table-row 
            ${loading ? 'loading' : ''} 
            ${revoked ? 'revoked' : ''} 
            ${deleted ? 'deleted' : ''}`}>

            <td className="leader-name-cell">
                {survey?.respondentInvitesSent || survey?.surveyCompleted ?
                    <Link to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/survey/${survey.id}`}>{survey?.leaderName ?? ''}</Link>
                : survey?.leaderName}
            </td>

            <td className="email-address-cell">
                <div className="cell-icon-wrap">
                    {!showEdit ? <div>{emailAddressValue}</div> : null}

                    {showEdit ? 
                        <form className="full-width" 
                            onSubmit={onSubmitRespondentEmailAddressForm}>

                            <Input type="email" 
                                value={emailAddressValue} 
                                size="full" 
                                onChange={onChangeEmailAddressInput} errors={emailAddressErrors} />
                        </form> 
                    : null}

                    {survey?.emailBounced ? 
                        <div className="warning">!</div> 
                    : null }
                </div>
            </td>

            <td className="invitation-sent-date-cell">
                <div className="cell-icon-wrap center-content">
                    {(!survey?.nominationsInvitesSent?.length || !isValid(parseISO(survey.nominationsInvitesSent[0])) || 
                        survey?.emailBounced) &&
                        (!survey?.respondentInvitesSent?.length || !isValid(parseISO(survey.respondentInvitesSent))) ? 

                        <div className="cell-dash"></div> 
                    : null}

                    {(survey?.totalRespondents > 1 && survey?.nominationsInvitesSent?.length && isValid(parseISO(survey.nominationsInvitesSent[0])) && !survey?.emailBounced) || 
                        (survey?.respondentInvitesSent && isValid(parseISO(survey.respondentInvitesSent))) ? 

                        <ToolTip toolTipText={survey?.totalRespondents > 1 && survey?.nominationsInvitesSent?.length && isValid(parseISO(survey.nominationsInvitesSent[0])) && !survey?.emailBounced ?
                            <div>
                                <div>{format(utcToZonedTime(parseISO(survey.nominationsInvitesSent[0]), 'UTC'), 'dd MMM yy')}</div>
                                <div>{format(utcToZonedTime(parseISO(survey.nominationsInvitesSent[0]), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                            </div>
                        : false}>
                            <div className="check">
                                <CheckIcon />
                            </div>
                        </ToolTip>
                    : null}
                    
                    {(survey?.totalRespondents === 1 && survey?.nominationsInvitesSent?.length > 1) && 
                        (survey?.respondentInvitesSent && (!survey?.respondentInvitesSent || !isValid(parseISO(survey.respondentInvitesSent)))) ?

                        <ToolTip className="alert date" toolTipText={survey.nominationsInvitesSent.map((date, index) => isValid(parseISO(date)) ? 
                                `${format(utcToZonedTime(parseISO(date), 'UTC'), 'dd MMM yy, H:mm')} <strong>UTC</strong>: ${index === 0 ? 'Invitation sent' : `Reminder ${index} sent`}` 
                            : false)}>
                            <BellIcon />

                            <div className="overlay-no">{survey.nominationsInvitesSent.length - 1}</div>
                        </ToolTip>
                    : null }
                </div>
            </td>

            <td className="nominations-completed-cell">
                <div className="cell-icon-wrap center-content">
                    { !survey?.respondentInvitesSent || !isValid(parseISO(survey.respondentInvitesSent)) ? 
                        <div className="cell-dash"></div> 
                    : null }
                    
                    { survey?.respondentInvitesSent && ( !survey?.respondentInvitesSent || !isValid(parseISO(survey.respondentInvitesSent)) ) ? 
                        <div className="check"><CheckIcon /></div> 
                    : null }

                    {survey?.respondentInvitesSent && survey?.respondentInvitesSent && isValid(parseISO(survey.respondentInvitesSent)) ? 
                        <ToolTip toolTipText={
                            <div>
                                <div>{format(utcToZonedTime(parseISO(survey.respondentInvitesSent), 'UTC'), 'dd MMM yy')}</div>
                                <div>{format(utcToZonedTime(parseISO(survey.respondentInvitesSent), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                            </div>
                        }>
                            <div className="check">
                                <CheckIcon />
                            </div>
                        </ToolTip>
                    : null}
                </div>
            </td>

            <td className="survey-completed-cell">
                <div className="cell-icon-wrap center-content">
                    {!survey?.surveyCompleted || !isValid(parseISO(survey.surveyCompleted)) ? 
                        <div className="cell-dash"></div> 
                    : null}

                    {survey?.surveyCompleted && ( !survey?.surveyCompleted || !isValid(parseISO(survey.surveyCompleted)) ) ? 
                        <div className="check"><CheckIcon /></div> 
                    : null}

                    {survey?.surveyCompleted && survey?.surveyCompleted && isValid(parseISO(survey.surveyCompleted)) ? 
                        <ToolTip toolTipText={
                            <div>
                                <div>{format(utcToZonedTime(parseISO(survey.surveyCompleted), 'UTC'), 'dd MMM yy')}</div>
                                <div>{format(utcToZonedTime(parseISO(survey.surveyCompleted), 'UTC'), 'H:mm')} <strong>UTC</strong></div>
                            </div>
                        }>
                            <div className="check">
                                <CheckIcon />
                            </div>
                        </ToolTip>
                    : null}
                </div>
            </td>

            <td className="respondents-completed-cell">
                <div className="status-percentage">
                    <div className="status-bar">
                        <div style={{
                            width: `${survey?.respondentsCompleted !== null && survey?.totalRespondents !== null ?
                                Math.round((survey.respondentsCompleted / survey.totalRespondents) * 100) 
                            : 0}%`
                        }}></div>
                    </div>

                    <div className="percentage">
                        {survey?.respondentsCompleted !== null && survey?.totalRespondents !== null ? 
                            `${survey.respondentsCompleted}/${survey.totalRespondents}` 
                        : null}
                    </div>
                </div>
            </td>

            <td className={`report-score-cell ${survey?.reportScore ? getReportScoreColour(Math.round(survey.reportScore)) : ''}`}>
                {!survey?.reportScore ? <div className="cell-dash"></div> : null}
                {survey?.reportScore ? `${Math.round(survey.reportScore)}%` : null}
            </td>

            <AdminTableActionsCell>
                {survey?.emailBounced ? 
                    <div><button className={`btn small ${!showEdit ? 'hide' : ''}`} type="button" onClick={onClickResendInviteBtn}>Re-Send Invite</button></div> 
                : null}
                
                {survey?.reportLink ? 
                    <div><a className="btn small" href={survey.reportLink} target="_blank" rel="noopener noreferrer">View Report</a></div> 
                : null}
                
                <div>
                    <button type="button" disabled={survey?.respondentInvitesSent || survey?.surveyCompleted} 
                        onClick={onClickReSendNominateBtn}>

                        <ToolTip toolTipText="Re-Send Nominate Link">
                            <MailIcon className="icon" />
                        </ToolTip>
                    </button>

                    <AdminModal className={`resend-nominate-modal ${reSendNominateLoading ? 'loading' : ''}`} 
                        show={showSendLeaderNominateModal} 
                        closeModal={() => setShowSendLeaderNominateModal(false)}>
                            
                        {reSendNominateLoading ? <div className="loading-circle"></div> : null}

                        <div className="resend-nominate-modal-inner">
                            <h2>Re-Send the Nominate Link</h2>

                            <p>Are you sure you want to re-send the <br />nominate link {survey?.leaderName ? `to ${survey.leaderName}` : ''}?</p>

                            <AdminModalBtns>
                                <button className="btn" type="button" onClick={() => setShowSendLeaderNominateModal(false)}>Close</button>
                                <button className="btn blue" type="button" onClick={onClickReSendNominateConfirmBtn}>Re-Send</button>
                            </AdminModalBtns>
                        </div>
                    </AdminModal>
                </div>

                <div>
                    {!survey?.respondentInvitesSent ?
                        <AdminModal show={showDeleteModal} 
                            closeModal={() => setShowDeleteModal(false)}>

                            <h2>Are you sure you want to delete {survey?.leaderName !== ' ' ? survey.leaderName : 'this leader'}?</h2> 

                            <AdminModalBtns>
                                <button className="btn" 
                                    type="button" 
                                    onClick={() => setShowDeleteModal(false)}>
                                        
                                    Cancel
                                </button>

                                <button className="btn blue" 
                                    type="button" 
                                    onClick={onClickDeleteConfirm}>
                                        
                                    Delete Leader
                                </button>
                            </AdminModalBtns>
                        </AdminModal>
                    : null}

                    <button className="delete" 
                        type="button" 
                        disabled={survey?.respondentInvitesSent} 
                        onClick={onClickDelete}>
                            
                        <BinIcon className="icon" />
                    </button>
                </div>

                <div>
                    {!revoked ? 
                        <button type="button" 
                            onClick={onClickRevokeBtn}>

                            <ToolTip toolTipText="Ignore">
                                <CancelIcon className="icon" />
                            </ToolTip>
                        </button>
                    : null}

                    {revoked ? 
                        <button type="button" 
                            onClick={onClickReinstateBtn}>

                            <ToolTip toolTipText="Include">
                                <PlusCircleIcon className="icon light-blue" />
                            </ToolTip>
                        </button>
                    : null}
                </div>
            </AdminTableActionsCell>
        </tr>
    )
}

export default function Cohorts({
    setHeaderBreadcrumbs, 
    urlPrepend, 
    productName
}) {
    const { state } = useContext(Store);
    const {
        statuses,
        uploadLeaderTemplateLink
    } = state;

    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [loadingSurveys, setLoadingSurveys] = useState(true);
    const [client, setClient] = useState(false);
    const [programme, setProgramme] = useState(false);
    const [cohort, setCohort] = useState(false);
    const [surveys, setSurveys] = useState(false);
    const [surveysInfo, setSurveysInfo] = useState(false);

    const [showUploadLeadersModal, setShowUploadLeadersModal] = useState(false);

    const {
        clientId,
        programmeId,
        cohortId
    } = useParams();

    const fetchClient = useCallback(clientId => {
        getClient(clientId).then(response => {
            if (response.success) setClient(response);
        });
    }, []);

    const fetchProgramme = useCallback((clientId, programmeId) => {
        getProgramme(clientId, programmeId).then(response => {
            if (response.success) setProgramme(response);
        });
    }, []);

    const fetchCohort = useCallback((clientId, programmeId, cohortId) => {
        getCohort(clientId, programmeId, cohortId).then(response => {
            if (response.success) {
                setCohort(response);
                setPageError(false);
            } else {
                setPageError(response?.errors);
            }

            setPageLoading(false);
        });
    }, []);

    const fetchSurveys = useCallback((clientId, programmeId, cohortId) => {
        getSurveys(clientId, programmeId, cohortId).then(response => {
            if (response.success) {
                setSurveys(response.surveys);
                setSurveysInfo(response);
            }

            setLoadingSurveys(false);
        });
    }, []);

    useEffect(() => {
        document.title = `Cohort - ${productName ? `${productName} -` : ''} Holos Change`;
    }, [productName]);

    useEffect(() => {
        fetchClient(clientId);
    }, [fetchClient, clientId]);

    useEffect(() => {
        fetchProgramme(clientId, programmeId);
    }, [fetchProgramme, clientId, programmeId]);

    useEffect(() => {
        fetchCohort(clientId, programmeId, cohortId);
    }, [fetchCohort, clientId, programmeId, cohortId]);

    useEffect(() => {
        fetchSurveys(clientId, programmeId, cohortId);
    }, [fetchSurveys, clientId, programmeId, cohortId]);

    useEffect(() => {
        if (!programme || !cohort) return;

        setHeaderBreadcrumbs([
            {
                text: 'Programmes',
                link: `${urlPrepend}/`
            }, {
                text: programme.name,
                link: `${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`
            }, {
                text: cohort.name
            }
        ]);
    }, [setHeaderBreadcrumbs, urlPrepend, programme, clientId, programmeId, cohort]);

    const onClickUploadFileBtn = () => {
        setShowUploadLeadersModal(true);
    };

    const updateSurvey = (index, field, value) => {
        let surveysData = [...surveys];
        surveysData[index][field] = value;
        setSurveys(surveysData);
    };

    const onSubmitUploadLeadersFormSuccess = () => {
        setShowUploadLeadersModal(false);
        fetchSurveys(clientId, programmeId, cohortId);
    };

    return (
        <AdminContainer pageError={pageError} pageLoading={pageLoading}>
            <AdminTop labelText={surveysInfo.status && statuses?.length ? statuses.filter(status => status.id === surveysInfo.status)[0]?.text : false} 
                labelColour={surveysInfo.status && statuses?.length ? statuses.filter(status => status.id === surveysInfo.status)[0]?.colour : false} 
                header={cohort?.name} editTo={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/edit`}
                backLink={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}
                backLinkText="Back to Programme">

                <div>Add New Leader</div>
                <div><a className="btn" href={uploadLeaderTemplateLink} target="_blank" rel="noopener noreferrer">Download Template</a></div>
                <div><button className="btn blue" type="button" onClick={onClickUploadFileBtn}><UploadIcon /><div>Upload File</div></button></div>
                <div><Link className="btn" to={`${urlPrepend}/client/${clientId}/programme/${programmeId}/cohort/${cohortId}/add-leader`}><PlusIcon /><div>Add Manually</div></Link></div>
            </AdminTop>

            <AdminCard>
                <AdminCardBody>
                    <div className="info-panel">
                        <div>
                            {client?.name && programme?.name ? 
                                <div>
                                    <h3>{client.name}</h3>
                                    <div><Link to={`${urlPrepend}/?clientId=${clientId}&programmeId=${programmeId}`}>{programme.name}</Link></div>
                                </div>
                            : null}

                            {cohort?.agentName ?
                                <div>
                                    <h3>Holos Cohort Lead:</h3>
                                    <div>{cohort.agentName}</div>
                                </div>
                            : null}

                            {cohort?.nominationInviteDate ?
                                <div>
                                    <h3>Invitation Date:</h3>
                                    <div>{format(utcToZonedTime(parseISO(cohort.nominationInviteDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null}

                            {cohort?.nominationsDeadline ?
                                <div>
                                    <h3>Nomination Deadline:</h3>
                                    <div>{format(utcToZonedTime(parseISO(cohort.nominationsDeadline), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null}

                            {cohort?.surveyDeadline ?
                                <div>
                                    <h3>Completion Deadline:</h3>
                                    <div>{format(utcToZonedTime(parseISO(cohort.surveyDeadline), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null}

                            {programme?.debriefDate ?
                                <div>
                                    <h3>Debrief Start Date:</h3>
                                    <div>{format(utcToZonedTime(parseISO(programme.debriefDate), 'UTC'), 'dd MMM yy, HH:mm')} <strong>UTC</strong></div>
                                </div>
                            : null}
                        </div>

                        <div>
                            {surveysInfo?.reportOverviewsToken ?
                                <div>
                                    <a className="btn blue" 
                                        href={`/report-overview/${surveysInfo.reportOverviewsToken}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                            
                                        View Comparison
                                    </a>
                                </div>
                            : null}
                        </div>
                    </div>
                </AdminCardBody>

                <AdminCardBody className="cohort-details">
                    <div className="table-details">
                        <div>
                            {surveys ? 
                                <>Showing <strong>{ surveys.length }</strong> { surveys.length === 1 ? 'leader' : 'leaders' }</>
                            : null}
                        </div>

                        {/* <div className="last-updated">Last updated 12 Oct 20</div> */}
                    </div>

                    <AdminTable className="cohort-table">
                        <AdminTableHead>
                            <tr>
                                <th className="leader-name-cell">Leader name</th>
                                <th className="email-address-cell">Email address</th>
                                <th className="invitation-sent-date-cell">Invitation <br />sent</th>
                                <th className="nominations-completed-cell">Nominations <br />completed</th>
                                <th className="survey-completed-cell">Survey <br />completed</th>
                                <th className="respondents-completed-cell">Respondents completed</th>
                                <th className="report-score-cell">Report <br />score</th>
                                <th className="actions-cell"></th>
                            </tr>
                        </AdminTableHead>

                        {surveys?.length ?
                            <AdminTableBody>
                                { surveys.map((survey, index) => {
                                    return (
                                        <CohortTableRow key={survey.id} 
                                            index={index} 
                                            survey={survey} 
                                            updateSurvey={updateSurvey} 
                                            surveys={surveys} 
                                            setSurveys={setSurveys} 
                                            surveysInfo={surveysInfo} 
                                            setSurveysInfo={setSurveysInfo} 
                                            urlPrepend={urlPrepend} />
                                    );
                                }) }
                            </AdminTableBody>
                        : null}
                    </AdminTable>

                    {(!loadingSurveys && !surveys) || surveys?.length === 0 ?
                        <div className="no-surveys">
                            You have not created any Surveys<br />
                            for this cohort
                        </div>
                    : null}
                </AdminCardBody>
            </AdminCard>

            <UploadLeadersFormModal clientId={clientId} 
                programmeId={programmeId} 
                cohortId={cohortId} 
                showUploadLeadersModal={showUploadLeadersModal} 
                setShowUploadLeadersModal={setShowUploadLeadersModal} 
                onSubmitSuccess={onSubmitUploadLeadersFormSuccess} />
        </AdminContainer>
    );
}
