import React from 'react';

import './ReportHeader.scss';

import logo from '../../../images/logo.svg';
import whiteLogo from '../../../images/logo-white.svg';
import teamEffectivenessImage from '../../../images/clients/astrazeneca/team-effectiveness.png';
import astrazenecaLogo from '../../../images/clients/astrazeneca/logo.png';

export default function ReportHeader({clientName = false, productType}) {
    if ( !clientName ) return null;

    return (
        <header className={`report-header ${productType === 2 && clientName === 'AstraZeneca' ? 'report-header-custom' : ''} ${clientName ? `client-${clientName.replace(/[^a-zA-Z ]/g, '').replace(' ', '-')}` : ''} ${productType ? `product-type-${productType}` : ''}`} style={productType === 2 && clientName === 'AstraZeneca' ? { backgroundColor: '#464845', color: '#fff' } : null}>
            { productType === 2 ? <>
                { clientName === 'AstraZeneca' ? <>

                    <div className="report-header-client-logos">
                        <div><img src={teamEffectivenessImage} alt="Team Effectiveness" /></div>
                        <div><img src={astrazenecaLogo} alt={clientName} /></div>
                    </div>

                    <div>
                        {clientName}: Post-Conventional Team Diagnostic
                    </div>
                
                    { productType !== 2 || clientName !== 'AstraZeneca' ? <img className="logo" src={logo} alt="Holos" /> : null }
                    { productType === 2 && clientName !== 'AstraZeneca' ? <img className="white-logo" src={whiteLogo} alt="Holos" /> : null }

                </> : null }

                { clientName !== 'AstraZeneca' ? <>

                    <img src={logo} alt="Holos" />
                    <div className="header">{clientName}</div>

                </> : null }
            </> : <>
                <img src={logo} alt="Holos" />
                <div className="header">{clientName}</div>
            </> }
        </header>
    );
}
